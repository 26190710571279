import { DUMMY_MARKDOWN } from '../constants';
import { ComponentTypes } from './types';

const DEFAULT_VALUES = {
  [ComponentTypes.IMAGE]: {
    src: 'https://i.natgeofe.com/n/34861553-e7b4-441d-af1f-3f91b21b1755/01-einstein-other-inventions_3x4.jpg',
  },
  [ComponentTypes.MEDIA]: {
    src: 'https://www.youtube.com/watch?v=fn3KWM1kuAw',
  },
  [ComponentTypes.IMAGE_GALLERY]: {
    images: [
      { url: 'https://images.unsplash.com/photo-1648309626651-9122794c8a10?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648185924326-20ebc958c4aa?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648222604786-8e80813db112?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1640622300930-6e8daa98536f?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648229010023-137a95b87903?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648138754712-999480887c0a?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648285533370-981a81bf01dc?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648290023964-9e8fca75b234?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648248056921-15dfe3a340b8?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648187618115-232983390fe3?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648285618065-3d421fa03e03?q=40&w=800' },
      { url: 'https://images.unsplash.com/photo-1648063756442-1a92ef6c8b17?q=40&w=800' },
    ],
  },
  [ComponentTypes.CHECKBOXES]: {
    options: [
      { label: 'Avocado', value: 'avocado' },
      { label: 'Cabbage', value: 'cabbage' },
      { label: 'Apple', value: 'apple' },
      { label: 'Orange', value: 'orange' },
    ],
  },
  [ComponentTypes.RADIO_BUTTONS]: {
    options: [
      { label: 'Toyota Camry', value: 'toyota_camry' },
      { label: 'Honda Accord', value: 'honda_accord' },
      { label: 'Ford Mustang', value: 'ford_mustang' },
      { label: 'Tesla Model X', value: 'tesla_model_x' },
    ],
  },
  [ComponentTypes.TEXT]: {
    initialValue: DUMMY_MARKDOWN,
  },
  [ComponentTypes.CALLOUT]: {
    initialValue:
      'Callouts highlight important content for the user. They can contain a title, an icon, and body text. Each intent has a default icon associated with it.',
  },
  [ComponentTypes.SELECT]: {
    options: [
      { value: 'option1', label: 'Option 1' },
      { value: 'option2', label: 'Option 2' },
    ],
  },
  [ComponentTypes.TABLE]: {
    data: [
      { id: 1, name: 'Kenichi' },
      { id: 2, name: 'Ippei' },
    ],
  },
  [ComponentTypes.JSON_VIEWER]: {
    data: {
      team: [{ name: 'Kenichi' }, { name: 'Ippei' }],
    },
  },
  [ComponentTypes.PLOTLY_CHART]: {
    sampleJson: {
      data: [
        {
          type: 'bar',
          x: [1, 2, 3],
          y: [1, 3, 2],
        },
      ],
      layout: {
        title: {
          text: 'Sample Plotly chart',
        },
      },
    },
  },
};

export { DEFAULT_VALUES };
