import { ComponentType, FC } from 'react';

import useSyncState from '@/hooks/useSyncState';

import { getDisplayName } from './utils';

/**
 * HOC that only starts rendering the underlying component once the `open` prop becomes true and continues
 * rendering the component until the HOC is unmounted.
 *
 * This is particularly useful for lazy loading an underlying component like a Dialog while allowing a hide/disppear
 * animation when `open` transitions from true to false.
 */
export default function withLazyOpen<TProps extends { open: boolean }>(
  InnerComponent: ComponentType<TProps>,
): FC<TProps> {
  function Wrapped(props: TProps) {
    const { open } = props;

    const [hasBeenOpen] = useSyncState<boolean>((prev) => open || !!prev);

    if (hasBeenOpen) {
      return <InnerComponent {...props} />;
    }

    return null;
  }

  Wrapped.displayName = `withLazyOpen(${getDisplayName(InnerComponent)})`;

  return Wrapped;
}
