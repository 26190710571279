/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ViewFragment = { __typename?: 'PubliclyVisibleViewType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any, components: any, layout: any, currentVersionId: string | null | undefined };

export const ViewFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ViewFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PubliclyVisibleViewType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}},{"kind":"Field","name":{"kind":"Name","value":"components"}},{"kind":"Field","name":{"kind":"Name","value":"layout"}},{"kind":"Field","alias":{"kind":"Name","value":"currentVersionId"},"name":{"kind":"Name","value":"current_version_id"}}]}}]} as unknown as DocumentNode<ViewFragment, unknown>;
export const namedOperations = {
  Fragment: {
    ViewFragment: 'ViewFragment'
  }
}
