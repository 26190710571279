import { FC, ReactNode } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { Dimensions, DirectionLock } from '../Canvas/types';
import { ContextualAction, EventHandler, SupplierId } from '../types';

enum ComponentTypes {
  BUTTON = 'button',
  IMAGE = 'image',
  MEDIA = 'media',
  MICROPHONE = 'microphone',
  IMAGE_GALLERY = 'image_gallery',
  TABLE = 'table',
  TEXT = 'text',
  TEXT_AREA = 'text_area',
  TEXT_INPUT = 'text_input',
  HEADING = 'heading',
  CALLOUT = 'callout',
  DIVIDER = 'divider',
  PLOTLY_CHART = 'plotly_chart',
  FILEPICKER = 'filepicker',
  SELECT = 'select',
  RADIO_BUTTONS = 'radio_buttons',
  CHECKBOXES = 'checkboxes',
  SLIDER = 'slider',
  RANGE = 'range',
  JSON_VIEWER = 'json_viewer',
}

enum Orientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

type DimensionConstraints = {
  minWidth?: number;
  minHeight?: number;
};

type Metadata = {
  name: string;
  icon: FC<SvgIconProps>;
  description: string;
  chrome: {
    border: boolean;
    header: boolean;
  };
  dimensions: Dimensions & DimensionConstraints;
  resizeable?: DirectionLock;
  flex?: boolean;
  orient?: Orientation;
};

enum EditorGroup {
  Display = 'display',
  Data = 'data',
}

enum PropType {
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
  Json = 'json',
  Collection = 'collection',
  Dropdown = 'dropdown',
  Radio = 'radio',
  Color = 'color',
  AlertColor = 'alert_color',
  List = 'list',
  Checkbox = 'checkbox',
  ParamList = 'param_list',
}

type Option = { value: string; label?: string };
type Param = { name: string; value: string };
type DynamicReference = { from: string };

type BaseConfigAttributes = {
  id: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  alias?: string; // this is so we can retrieve old values
  group?: EditorGroup;
};

type TextConfig = BaseConfigAttributes & {
  type: PropType.Text;
  defaultValue?: string;
};

type NumberConfig = BaseConfigAttributes & {
  type: PropType.Number;
  defaultValue?: number;
  min?: number;
  max?: number;
};

type BooleanConfig = BaseConfigAttributes & {
  type: PropType.Boolean;
  defaultValue: boolean;
  dynamic: boolean;
};

type ObjectConfig = BaseConfigAttributes & {
  type: PropType.Json | PropType.Collection;
  defaultValue: object;
};

type OneOfOptionsConfig<T> = BaseConfigAttributes & {
  type: PropType.Dropdown | PropType.Radio;
  options: Array<T> | DynamicReference;
  defaultValue?: T;
};

type StaticMultiOptionsConfig<T> = BaseConfigAttributes & {
  type: PropType.Checkbox;
  icons?: [typeof SvgIcon, typeof SvgIcon];
  options?: Array<T> | DynamicReference;
  defaultValue?: Array<T>;
};

type DynamicMultiOptionsConfig<T> = BaseConfigAttributes & {
  type: PropType.List | PropType.ParamList;
  initialOptions?: Array<T> | DynamicReference;
  defaultValue?: Array<T>;
};

type AlertColorConfig = BaseConfigAttributes & {
  type: PropType.AlertColor;
  initialOptions?: Array<string>;
  defaultValue: string;
};

type ColorConfig = BaseConfigAttributes & {
  type: PropType.Color;
  initialOptions?: Array<string>;
  defaultValue: string;
};

type PropEditorConfig =
  | TextConfig
  | NumberConfig
  | BooleanConfig
  | ObjectConfig
  | OneOfOptionsConfig<string | Option>
  | StaticMultiOptionsConfig<string | Option>
  | DynamicMultiOptionsConfig<string | Param>
  | ColorConfig
  | AlertColorConfig;

interface FieldConfig {
  name: string;
  defaultValue: any;
  hidden?: boolean;
}

interface ContextualConfig {
  label: string;
  tooltip?: string;
  refField?: DynamicReference;
}

type ComponentDefinition = {
  meta: Metadata;
  props: Array<PropEditorConfig>;
  fields?: Array<FieldConfig>;
  events?: Array<string>;
  context?: ContextualConfig; // this is set to just a single context for now, we can replan if we need to support multiple contexts
};

/**
 * This contains information that describes the "chrome" surrounding the component
 * things like icon, border, header
 */
interface Chrome {
  header: boolean;
  border: boolean;
  headerText?: SupplierId;
  orientation?: Orientation;
}

interface Location {
  x: number;
  y: number;
  w: number;
  h: number;
}

type ComponentConfig = {
  type: ComponentTypes;
  location: Location;
  // a new property; see note above regarding this interface
  chrome?: Chrome;
  // describes the incoming properties to the component
  propSuppliers: Record<string, SupplierId>;
  fields?: Record<string, SupplierId>;
  eventHandlers?: Array<EventHandler>;
  contextualActions?: Record<string, ContextualAction>;
};

type ActionElement = {
  key: string;
  element: ReactNode;
};

type SetField = (fieldName: string, fieldValue: any) => void;
type TriggerEvent = (eventName: string, ...args: any[]) => Promise<void>;

type ComponentBaseProps = {
  id: string;
  loading: boolean;
  height?: number;
  orientation?: Orientation;
  actions?: ActionElement[];
  setField: SetField;
  triggerEvent: TriggerEvent;
};

type ComponentChrome = {
  configurableHeader: boolean;
  configurableBorder: boolean;
  showHeader: boolean;
  showBorder: boolean;
  headerText: string;
  canOrient: boolean;
  orientation?: Orientation;
  canFlex: boolean;
};

export {
  ComponentTypes,
  Orientation,
  PropType,
  Option,
  Param,
  DynamicReference,
  EditorGroup,
  PropEditorConfig,
  FieldConfig,
  ComponentDefinition,
  Chrome,
  Location,
  ComponentConfig,
  ActionElement,
  ComponentBaseProps,
  ComponentChrome,
  SetField,
  ContextualConfig,
};
