import { ReactNode } from 'react';

interface MatchParams {
  tabId: DataTabs;
}

enum DataTabs {
  DatabaseModels = 'database_models',
  Connections = 'connections',
  Queries = 'queries',
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export { MatchParams, DataTabs, TabPanelProps };
