import { Stack, Typography } from '@mui/material';

import { FeatureItemProps } from './types';

function FeatureItem({ icon, title, description }: FeatureItemProps) {
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems="center">
        {icon}
        <Typography variant="h5">{title}</Typography>
      </Stack>
      <Typography color="text.muted" ml={3}>
        {description}
      </Typography>
    </Stack>
  );
}

export default FeatureItem;
