import { useMount } from 'react-use';

import { ProductCategory } from '@/graphql';
import { namedOperations as monetaryCreditInfoNamedOperations } from '@/hooks/useMonetaryCreditInfo/__generated__/queries.generated';
import { useSearchParams } from '@/routes/hooks';
import { namedOperations as paymentOverdueNamedOperations } from '@/sections/GlobalHeader/Right/LoggedIn/PaymentOverdue/__generated__/queries.generated';
import { getTimeZoneName } from '@/utils/network/geo';
import { getStripePromise } from '@/utils/stripe';
import { useSnackbar } from 'notistack';

import {
  useGenerateStripeSetupIntentMutation,
  useSetPaymentMethodMutation,
} from './__generated__/mutations.generated';
import {
  PaymentMethodDocument,
  namedOperations as billingNamedOperations,
  useAvailableBillingPeriodsQuery,
  useBillingThresholdQuery,
  useCanUpdatePaymentMethodQuery,
  useCurrentBillingPeriodQuery,
  useInvoicesQuery,
  usePaymentMethodQuery,
  useUsageForBillingPeriodQuery,
} from './__generated__/queries.generated';

function useGenerateStripeSetupIntent() {
  const { enqueueSnackbar } = useSnackbar();

  return useGenerateStripeSetupIntentMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}

function usePaymentMethod() {
  const { data, loading } = usePaymentMethodQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    paymentMethod: data?.stripePaymentMethod,
    isLoading: loading,
  };
}

function useSetPaymentMethod() {
  return useSetPaymentMethodMutation({
    update: (cache, { data }) => {
      if (data) {
        cache.writeQuery({
          query: PaymentMethodDocument,
          data: {
            stripePaymentMethod: data.setStripeDefaultPaymentMethod.paymentMethod,
          },
        });
      }
    },
  });
}

function useUsageForBillingPeriodCategory(
  category: ProductCategory,
  billingPeriodIndex: number = 0,
) {
  const { data, loading } = useUsageForBillingPeriodQuery({
    fetchPolicy: 'cache-first',
    variables: {
      billingPeriodIndex,
      timezoneName: getTimeZoneName(),
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    isLoading: loading,
    data: data?.usageForBillingPeriod?.productCategoryUsages.find(
      (option) => option.category === category,
    ),
    billingPeriod: data?.usageForBillingPeriod?.billingPeriod,
    currentPeriodTotal: data?.usageForBillingPeriod?.currentPeriodTotal,
    monetaryCreditUsage: data?.usageForBillingPeriod?.monetaryCreditUsage,
  };
}

function useAvailableBillingPeriods() {
  const { data, loading } = useAvailableBillingPeriodsQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    availableBillingPeriods: [...(data?.availableBillingPeriods || [])].sort(
      (a, b) => Number(b.isCurrent) - Number(a.isCurrent),
    ),
  };
}

function useInvoices() {
  const { data, loading } = useInvoicesQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    invoices: data?.invoices,
    isLoading: loading,
  };
}

function useClientSecretFromSearchParams() {
  const [searchParams] = useSearchParams();
  return searchParams.get('setup_intent_client_secret');
}

function useHandleSetupIntentCallback() {
  const [setPaymentMethod] = useSetPaymentMethod();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const clientSecretFromSearchParams = useClientSecretFromSearchParams();

  useMount(() => {
    (async () => {
      if (clientSecretFromSearchParams) {
        // Remove the client secret from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        const stripe = await getStripePromise();

        const loadingKey = enqueueSnackbar('Saving payment method...', {
          variant: 'default',
          persist: true,
        });

        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecretFromSearchParams);
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded':
            try {
              const { data } = await setPaymentMethod({
                variables: {
                  id: setupIntent.payment_method as string,
                },
                refetchQueries: [
                  monetaryCreditInfoNamedOperations.Query.MonetaryCredits,
                  billingNamedOperations.Query.PaymentMethod,
                  billingNamedOperations.Query.Invoices,
                  paymentOverdueNamedOperations.Query.PaymentOverdue,
                ],
              });

              if (data?.setStripeDefaultPaymentMethod?.allInvoicesPaid === false) {
                const errorMessage = data.setStripeDefaultPaymentMethod.paymentAttemptErrorMessage;
                enqueueSnackbar(
                  [
                    'Your payment method has been saved but some invoices failed to process.',
                    errorMessage,
                  ]
                    .filter(Boolean)
                    .join(' '),
                  {
                    variant: 'warning',
                  },
                );
              } else {
                enqueueSnackbar('Success! Your payment method has been saved.', {
                  variant: 'success',
                });
              }
            } catch (error) {
              enqueueSnackbar('Failed to save payment method.', {
                variant: 'error',
              });
            }
            break;

          case 'processing':
            enqueueSnackbar(
              "Processing payment details. We'll update you when processing is complete.",
              {
                variant: 'info',
              },
            );
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            enqueueSnackbar(
              'Failed to process payment details. Please try another payment method.',
              {
                variant: 'error',
              },
            );
            break;
        }

        closeSnackbar(loadingKey);
      }
    })();
  });
}

function useBillingThreshold() {
  return useBillingThresholdQuery({
    fetchPolicy: 'cache-first',
  });
}

function useCurrentBillingPeriod() {
  const { data, loading } = useCurrentBillingPeriodQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    currentBillingPeriod: data?.usageForCurrentBillingPeriod,
  };
}

function useCurrentBillingPeriodCategory(category: ProductCategory) {
  const { currentBillingPeriod, isLoading } = useCurrentBillingPeriod();

  return {
    isLoading,
    data: currentBillingPeriod?.productCategoryUsages.find(
      (option) => option.category === category,
    ),
    billingPeriodStart: currentBillingPeriod?.currentBillingPeriodStart,
    billingPeriodEnd: currentBillingPeriod?.currentBillingPeriodEnd,
    currentPeriodTotal: currentBillingPeriod?.currentPeriodTotal,
    monetaryCreditUsage: currentBillingPeriod?.monetaryCreditUsage,
  };
}

function useCanUpdatePaymentMethod() {
  const { data, loading } = useCanUpdatePaymentMethodQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    canUpdatePaymentMethod: data?.organization?.canUpdatePaymentMethod,
    isLoading: loading,
  };
}

export {
  useBillingThreshold,
  useGenerateStripeSetupIntent,
  useHandleSetupIntentCallback,
  useInvoices,
  usePaymentMethod,
  useSetPaymentMethod,
  useAvailableBillingPeriods,
  useUsageForBillingPeriodCategory,
  useCurrentBillingPeriodCategory,
  useCanUpdatePaymentMethod,
};
