import { configureStore } from '@reduxjs/toolkit';

import apolloClient from '@/apollo';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import listenerMiddleware from './middleware/listener';
import createRootReducer from './reducers/RootReducer';
import { ThunkExtras } from './types';

const history = createBrowserHistory();

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => {
    const extraArgument: ThunkExtras = { apolloClient, history };
    const middleware = getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
      serializableCheck: false,
      immutableCheck: false,
    }).prepend(routerMiddleware(history), listenerMiddleware.middleware);

    return middleware;
  },
});

export { history };

export default store;
