import MailOutline from '@mui/icons-material/MailOutline';

import LargeAlert from '../LargeAlert';

function CheckEmail() {
  return (
    <LargeAlert
      icon={MailOutline}
      title="Check your email for a password-free sign in link."
      severity="success"
      description="The link will expire shortly, so please check it soon."
    />
  );
}

export default CheckEmail;
