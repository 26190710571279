import { useSelector } from 'react-redux';

import withLazyOpen from '@/hocs/withLazyOpen';
import { queryDialogSelector } from '@/store/Selectors';
import asyncComponentLoader from '@/utils/react/loader';

function EmptyFallback(): null {
  return null;
}

const Query = withLazyOpen(
  asyncComponentLoader(() => import('./Query'), undefined, undefined, EmptyFallback),
);

export default function QueryDialogRenderer() {
  const { visible: open } = useSelector(queryDialogSelector);

  return <Query open={open} />;
}
