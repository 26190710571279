import { useCheckFeatureFlag } from './useFeatureFlags';
import { FeatureFlags } from './useFeatureFlags/types';

function useIsActivityFeedEnabled() {
  const checkFeatureFlag = useCheckFeatureFlag();

  return checkFeatureFlag(FeatureFlags.ORG_ENABLE_ACTIVITY_FEED);
}

export default useIsActivityFeedEnabled;
