import { useCallback } from 'react';

import { useSetPaymentMethodDialogOpen } from '@/sections/Billing/PaymentMethodDialog/hooks';

type AddPaymentMethodHandler = () => void;

/**
 * Use context to allow overriding the add payment method CTA handler since some pages
 * (namely the models page) have an in-context payment method dialog
 */
export default function useAddPaymentMethod(): AddPaymentMethodHandler {
  const setPaymentMethodDialogOpen = useSetPaymentMethodDialogOpen();

  return useCallback(() => {
    setPaymentMethodDialogOpen(true);
  }, [setPaymentMethodDialogOpen]);
}
