/**
 * Finds the next available name based on which names are existing.
 * @param items Existing items to avoid conflicting with
 * @param prefix Base of name, before the disambiguating number
 * @param key The field in items to look at for the name. Defaults to 'name'
 * @param extension An optional extension to add at the end
 */
function getInstanceName(
  items: { name: string }[],
  prefix: string,
  key?: undefined,
  extension?: string,
): string;
function getInstanceName<TKey extends string>(
  items: { [key in TKey]: string }[],
  prefix: string,
  key: TKey,
  extension?: string,
): string;
function getInstanceName<TKey extends string>(
  items: { [key in TKey | 'name']?: string }[],
  prefix: string,
  key?: TKey,
  extension = '',
): string {
  let postfix = items.length + 1;

  function generateName(): string {
    const defaultName = `${prefix}${postfix}${extension}`;
    const noMatch = !items.find((item) => item[key ?? 'name'] === defaultName);

    if (noMatch) {
      return defaultName;
    }

    postfix += 1;
    return generateName();
  }

  return generateName();
}

export { getInstanceName };
