/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ClassFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UdmClassBatchMutationVariables = Types.Exact<{
  encodedActionBatch: Types.Scalars['String'];
}>;


export type UdmClassBatchMutation = { __typename?: 'Mutation', udm_class_batch_mutation: { __typename?: 'UdmClassBatchMutation', klass: { __typename?: 'ClassType', id: string, name: string, typename: string, namespace: string, description: string | null | undefined, schema: any, created: any, modified: any, tablename: string | null | undefined } | null | undefined } | null | undefined };


export const UdmClassBatchMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UdmClassBatchMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"encodedActionBatch"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"udm_class_batch_mutation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"encoded_action_batch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"encodedActionBatch"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"klass"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ClassFragment"}}]}}]}}]}},...ClassFragmentDoc.definitions]} as unknown as DocumentNode<UdmClassBatchMutation, UdmClassBatchMutationVariables>;
export const namedOperations = {
  Mutation: {
    UdmClassBatchMutation: 'UdmClassBatchMutation'
  }
}

export type UdmClassBatchMutationMutationFn = Apollo.MutationFunction<UdmClassBatchMutation, UdmClassBatchMutationVariables>;

/**
 * __useUdmClassBatchMutation__
 *
 * To run a mutation, you first call `useUdmClassBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUdmClassBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [udmClassBatchMutation, { data, loading, error }] = useUdmClassBatchMutation({
 *   variables: {
 *      encodedActionBatch: // value for 'encodedActionBatch'
 *   },
 * });
 */
export function useUdmClassBatchMutation(baseOptions?: Apollo.MutationHookOptions<UdmClassBatchMutation, UdmClassBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UdmClassBatchMutation, UdmClassBatchMutationVariables>(UdmClassBatchMutationDocument, options);
      }
export type UdmClassBatchMutationHookResult = ReturnType<typeof useUdmClassBatchMutation>;
export type UdmClassBatchMutationMutationResult = Apollo.MutationResult<UdmClassBatchMutation>;
export type UdmClassBatchMutationMutationOptions = Apollo.BaseMutationOptions<UdmClassBatchMutation, UdmClassBatchMutationVariables>;