import { generatePath } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { draftOperatorViewRoute } from '@/pages/Creator/routes';
import { MatchParams as WorkspaceMatchParams } from '@/pages/Workspace/types';
import { ViewIdentifier } from '@/store/slices/entities/Views/types';
import { ReleaseEnv } from '@/types/releaseEnv';
import { addQueryParam } from '@/utils/url';

import { Routes, operatorViewRoute } from '..';
import { releaseEnvEnumToParam } from '../releaseEnvParam';
import { BasetenPageEnum, BasetenPageProps } from '../types';
import { ParamsForPage } from './types';

function getFirstPath({ path }: BasetenPageProps) {
  return Array.isArray(path) ? path[0] : path;
}

const ENV_PARAM_REGEX = /(^|\/):env($|\/)/;

function getPagePath<TPage extends BasetenPageEnum>(
  page: TPage,
  pathParams: ParamsForPage<TPage>,
  isDualEnvEnabled: boolean,
): string {
  if (page === BasetenPageEnum.Home) {
    return '/';
  }
  if (page === BasetenPageEnum.ViewOperator) {
    if (isDualEnvEnabled && (pathParams as ViewIdentifier).releaseEnv === ReleaseEnv.Draft) {
      return getFirstPath(draftOperatorViewRoute);
    }
    return getFirstPath(operatorViewRoute);
  }

  if (isDualEnvEnabled) {
    // Look first for env-aware Routes
    for (const route of Routes) {
      if (route.page === page) {
        for (const path of Array.isArray(route.path) ? route.path : [route.path]) {
          if (path.match(ENV_PARAM_REGEX)) {
            return path;
          }
        }
      }
    }
  }

  if (page === BasetenPageEnum.Workspace) {
    return getFirstPath(
      Routes.filter((route) => route.page === page).find((route) => {
        return getFirstPath(route).startsWith(`/${(pathParams as WorkspaceMatchParams).section}`);
      }),
    );
  }

  const pageProps = Routes.find((route) => route.page === page);
  if (!pageProps) {
    throw new Error(`Page not found in routes ${page}`);
  }

  return getFirstPath(pageProps);
}

export function calcSwitchPath<TPage extends BasetenPageEnum>(
  page: TPage,
  isDualEnvEnabled: boolean,
  pathParams: ParamsForPage<TPage>,
  queryParams: Record<string, string> = {},
): string {
  const modifiedPathParams: Record<string, string> = { ...pathParams };
  // Remap releaseEnv enum to `env`
  if (modifiedPathParams.releaseEnv) {
    modifiedPathParams.env = releaseEnvEnumToParam(modifiedPathParams.releaseEnv as ReleaseEnv);
    delete modifiedPathParams.releaseEnv;
  }

  const pathTemplate = getPagePath(page, pathParams, isDualEnvEnabled);
  const resolvedPath = generatePath(pathTemplate, modifiedPathParams);

  if (isEmpty(queryParams)) {
    return resolvedPath;
  }

  return Object.entries(queryParams).reduce(
    (accumulatedPath, [key, value]) => addQueryParam(accumulatedPath, key, value),
    resolvedPath,
  );
}
