import Loading from '@/components/Loading';

import { useExistingOrganizationQuery } from '../../__generated__/queries.generated';
import { Container, Description, Header, Title } from '../styled';
import { getExistingOrganizationData } from '../utils';
import { MAX_WIDTH } from './constants';

function ExistingWorkspaceSentStep() {
  const { data: existingOrganizationData, loading } = useExistingOrganizationQuery({
    fetchPolicy: 'no-cache',
  });

  if (loading || !existingOrganizationData) {
    return <Loading />;
  }

  const { adminName } = getExistingOrganizationData(existingOrganizationData);

  return (
    <Container sx={{ maxWidth: MAX_WIDTH }}>
      <Header>
        <Title data-cy="onb-title">Hold tight!</Title>
        <Description
          variant="subtitle1"
          color="text.muted"
        >{`An email has been sent to your workspace administrator, ${adminName}.`}</Description>
      </Header>
    </Container>
  );
}

export default ExistingWorkspaceSentStep;
