/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ViewFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateViewMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  components: Types.Scalars['String'];
  workflowId: Types.Scalars['String'];
  nodeId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateViewMutation = { __typename?: 'Mutation', createView: { __typename?: 'CreateView', id: string | null | undefined, currentVersionId: string | null | undefined } | null | undefined };

export type UpdateViewMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  name: Types.Scalars['String'];
  currentVersionId: Types.Scalars['String'];
  components: Types.Scalars['String'];
}>;


export type UpdateViewMutation = { __typename?: 'Mutation', updateView: { __typename?: 'UpdateView', currentVersionId: string | null | undefined } | null | undefined };

export type UpdateViewNameMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  name: Types.Scalars['String'];
  currentVersionId: Types.Scalars['String'];
}>;


export type UpdateViewNameMutation = { __typename?: 'Mutation', updateViewName: { __typename?: 'UpdateViewName', currentVersionId: string | null | undefined } | null | undefined };

export type DeleteViewMutationVariables = Types.Exact<{
  viewId: Types.Scalars['String'];
  currentVersionId: Types.Scalars['String'];
}>;


export type DeleteViewMutation = { __typename?: 'Mutation', deleteView: { __typename?: 'DeleteView', ok: boolean | null | undefined } | null | undefined };

export type DuplicateViewMutationVariables = Types.Exact<{
  viewId: Types.Scalars['String'];
}>;


export type DuplicateViewMutation = { __typename?: 'Mutation', duplicateView: { __typename?: 'DuplicateView', view: { __typename?: 'PubliclyVisibleViewType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any, components: any, layout: any, currentVersionId: string | null | undefined } | null | undefined } | null | undefined };


export const CreateViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"components"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nodeId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"createView"},"name":{"kind":"Name","value":"create_view"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"components"}}},{"kind":"Argument","name":{"kind":"Name","value":"workflow_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}}},{"kind":"Argument","name":{"kind":"Name","value":"node_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nodeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"currentVersionId"},"name":{"kind":"Name","value":"current_version_id"}}]}}]}}]} as unknown as DocumentNode<CreateViewMutation, CreateViewMutationVariables>;
export const UpdateViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"components"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateView"},"name":{"kind":"Name","value":"update_view"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"view_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"components"}}},{"kind":"Argument","name":{"kind":"Name","value":"current_version_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"currentVersionId"},"name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateViewMutation, UpdateViewMutationVariables>;
export const UpdateViewNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateViewName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateViewName"},"name":{"kind":"Name","value":"update_view_name"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"view_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"current_version_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"currentVersionId"},"name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateViewNameMutation, UpdateViewNameMutationVariables>;
export const DeleteViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"deleteView"},"name":{"kind":"Name","value":"delete_view"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"view_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viewId"}}},{"kind":"Argument","name":{"kind":"Name","value":"current_version_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<DeleteViewMutation, DeleteViewMutationVariables>;
export const DuplicateViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DuplicateView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"duplicateView"},"name":{"kind":"Name","value":"duplicate_view"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"view_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viewId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"view"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewFragment"}}]}}]}}]}},...ViewFragmentDoc.definitions]} as unknown as DocumentNode<DuplicateViewMutation, DuplicateViewMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreateView: 'CreateView',
    UpdateView: 'UpdateView',
    UpdateViewName: 'UpdateViewName',
    DeleteView: 'DeleteView',
    DuplicateView: 'DuplicateView'
  }
}

export type CreateViewMutationFn = Apollo.MutationFunction<CreateViewMutation, CreateViewMutationVariables>;

/**
 * __useCreateViewMutation__
 *
 * To run a mutation, you first call `useCreateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewMutation, { data, loading, error }] = useCreateViewMutation({
 *   variables: {
 *      name: // value for 'name'
 *      components: // value for 'components'
 *      workflowId: // value for 'workflowId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useCreateViewMutation(baseOptions?: Apollo.MutationHookOptions<CreateViewMutation, CreateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateViewMutation, CreateViewMutationVariables>(CreateViewDocument, options);
      }
export type CreateViewMutationHookResult = ReturnType<typeof useCreateViewMutation>;
export type CreateViewMutationResult = Apollo.MutationResult<CreateViewMutation>;
export type CreateViewMutationOptions = Apollo.BaseMutationOptions<CreateViewMutation, CreateViewMutationVariables>;
export type UpdateViewMutationFn = Apollo.MutationFunction<UpdateViewMutation, UpdateViewMutationVariables>;

/**
 * __useUpdateViewMutation__
 *
 * To run a mutation, you first call `useUpdateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewMutation, { data, loading, error }] = useUpdateViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      currentVersionId: // value for 'currentVersionId'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useUpdateViewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewMutation, UpdateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewMutation, UpdateViewMutationVariables>(UpdateViewDocument, options);
      }
export type UpdateViewMutationHookResult = ReturnType<typeof useUpdateViewMutation>;
export type UpdateViewMutationResult = Apollo.MutationResult<UpdateViewMutation>;
export type UpdateViewMutationOptions = Apollo.BaseMutationOptions<UpdateViewMutation, UpdateViewMutationVariables>;
export type UpdateViewNameMutationFn = Apollo.MutationFunction<UpdateViewNameMutation, UpdateViewNameMutationVariables>;

/**
 * __useUpdateViewNameMutation__
 *
 * To run a mutation, you first call `useUpdateViewNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewNameMutation, { data, loading, error }] = useUpdateViewNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      currentVersionId: // value for 'currentVersionId'
 *   },
 * });
 */
export function useUpdateViewNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewNameMutation, UpdateViewNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewNameMutation, UpdateViewNameMutationVariables>(UpdateViewNameDocument, options);
      }
export type UpdateViewNameMutationHookResult = ReturnType<typeof useUpdateViewNameMutation>;
export type UpdateViewNameMutationResult = Apollo.MutationResult<UpdateViewNameMutation>;
export type UpdateViewNameMutationOptions = Apollo.BaseMutationOptions<UpdateViewNameMutation, UpdateViewNameMutationVariables>;
export type DeleteViewMutationFn = Apollo.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>;

/**
 * __useDeleteViewMutation__
 *
 * To run a mutation, you first call `useDeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewMutation, { data, loading, error }] = useDeleteViewMutation({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      currentVersionId: // value for 'currentVersionId'
 *   },
 * });
 */
export function useDeleteViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteViewMutation, DeleteViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteViewMutation, DeleteViewMutationVariables>(DeleteViewDocument, options);
      }
export type DeleteViewMutationHookResult = ReturnType<typeof useDeleteViewMutation>;
export type DeleteViewMutationResult = Apollo.MutationResult<DeleteViewMutation>;
export type DeleteViewMutationOptions = Apollo.BaseMutationOptions<DeleteViewMutation, DeleteViewMutationVariables>;
export type DuplicateViewMutationFn = Apollo.MutationFunction<DuplicateViewMutation, DuplicateViewMutationVariables>;

/**
 * __useDuplicateViewMutation__
 *
 * To run a mutation, you first call `useDuplicateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateViewMutation, { data, loading, error }] = useDuplicateViewMutation({
 *   variables: {
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useDuplicateViewMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateViewMutation, DuplicateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateViewMutation, DuplicateViewMutationVariables>(DuplicateViewDocument, options);
      }
export type DuplicateViewMutationHookResult = ReturnType<typeof useDuplicateViewMutation>;
export type DuplicateViewMutationResult = Apollo.MutationResult<DuplicateViewMutation>;
export type DuplicateViewMutationOptions = Apollo.BaseMutationOptions<DuplicateViewMutation, DuplicateViewMutationVariables>;