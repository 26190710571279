import { ChangeEvent, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import first from 'lodash/first';

import SquareCheckedIcon from '@/components/Icons/SquareCheckedIcon';
import SquareIcon from '@/components/Icons/SquareIcon';
import { JoinExistingSetting, OrganizationJoinExistingSetting } from '@/graphql';
import useOrg from '@/hooks/useOrg';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useUpdateJoinExistingSettingMutation } from './__generated__/mutations.generated';
import { JoinExistingSettingsProps } from './types';

function JoinExistingSettings({ sx = {} }: JoinExistingSettingsProps) {
  const org = useOrg();
  const [settings, setSettings] = useState(null);
  const [updateJoinExistingSetting, { loading }] = useUpdateJoinExistingSettingMutation();
  const { enqueueSnackbar } = useSnackbar();
  const domain = org.domain !== null ? org.domain : org.email.split('@')[1];

  async function handleSettingsChange(ev: ChangeEvent<HTMLInputElement>) {
    try {
      const { data } = await updateJoinExistingSetting({
        variables: {
          joinExistingSetting: ev.target.checked
            ? JoinExistingSetting.Enabled
            : JoinExistingSetting.Disabled,
        },
      });

      setSettings(data.updateOrganization.organization.joinExistingSetting);
    } catch (err) {
      if (err) {
        const messageToShow =
          first((err as ApolloError).graphQLErrors)?.extensions?.description || err.message;
        enqueueSnackbar(messageToShow, { variant: 'error' });
      }
    }
  }

  return (
    <FormControlLabel
      disabled={loading}
      control={
        <Checkbox
          icon={<SquareIcon />}
          checkedIcon={<SquareCheckedIcon />}
          checked={
            (settings || org.joinExistingSetting) === OrganizationJoinExistingSetting.Enabled
          }
          onChange={handleSettingsChange}
        />
      }
      sx={{ alignItems: 'center', ...sx }}
      label={
        <Typography variant="body2">
          <Typography variant="h6" component="span">
            @{domain}
          </Typography>{' '}
          users can request to join this workspace
          {ENV_CONFIG.USE_ABRIDGED_ONBOARDING ? '' : ', pending approval from an admin.'}
        </Typography>
      }
    />
  );
}

export default JoinExistingSettings;
