import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const jsonResponseType: ResponseType = 'json';

class API {
  service: AxiosInstance;

  constructor() {
    const service = axios.create({
      headers: {
        'X-CSRFToken': cookies.get('csrftoken'),
      },
    });
    service.interceptors.response.use((response) => response);
    this.service = service;
  }

  request(config: AxiosRequestConfig, extraHeaders = {}, params = {}) {
    const headers = {
      'X-CSRFToken': cookies.get('csrftoken'),
      ...extraHeaders,
    };
    const service = axios.create({
      headers,
      params,
    });
    service.interceptors.response.use((response) => response);
    this.service = service;

    return this.service.request(config);
  }

  getWithoutCallback(path: string, extraHeaders = {}, params = {}) {
    const config = {
      method: 'GET',
      url: path,
      responseType: jsonResponseType,
    };
    return this.request(config, extraHeaders, params);
  }

  get(path: string, callback: (status: number, data: unknown) => void) {
    return this.getWithoutCallback(path).then((response) =>
      callback(response.status, response.data),
    );
  }

  patchWithoutCallback(path: string, payload: unknown) {
    const config = {
      method: 'PATCH',
      url: path,
      responseType: jsonResponseType,
      data: payload,
    };
    return this.request(config);
  }

  patch(path: string, payload: unknown, callback: (status: number, data: unknown) => void) {
    return this.patchWithoutCallback(path, payload).then((response) =>
      callback(response.status, response.data),
    );
  }

  postWithoutCallback(path: string, payload: unknown, extraHeaders = {}) {
    const config = {
      method: 'POST',
      url: path,
      responseType: jsonResponseType,
      data: payload,
    };
    return this.request(config, extraHeaders);
  }

  post(
    path: string,
    payload: unknown,
    callback: (status: number, data?: unknown) => void,
    extraHeaders = {},
  ) {
    return this.postWithoutCallback(path, payload, extraHeaders)
      .then((response) => callback(response.status, response.data))
      .catch(({ response }) => callback(response.status));
  }
}

export default new API();
