import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

export type ValidationErrors = Array<string>;
export type ParamValidator = (params: any) => ValidationErrors;

export const NoopValidator: ParamValidator = () => [];

export const fieldEmptyValidator = (fieldId: string, fieldName: string) => (params: any) => {
  const errs: ValidationErrors = [];
  if (isEmpty(params[fieldId])) {
    errs.push(`${fieldName} must not be empty.`);
  }
  return errs;
};

// TODO(pankaj): Differentiate between not found and actual null value of lens.
function getViaLens(state: any, lens: Array<string>): any {
  if (lens.length === 0) {
    return state;
  }

  if (!has(state, lens[0])) {
    return undefined;
  }

  return getViaLens(state[lens[0]], lens.slice(1));
}

/**
 * Combine validators.
 * Validtors are provided along with a path. Validtor validates part of the state referred by path.
 * Path is a dot separated list of field names. '.' refers to current path.
 */
export function combineValidators(validators: Record<string, ParamValidator>): ParamValidator {
  return (params: any) => {
    const errs: ValidationErrors = [];
    Object.entries(validators).forEach(([path, validator]) => {
      const lens = path === '.' ? [] : path.split('.');
      const paramValue = getViaLens(params, lens);
      if (paramValue === undefined) {
        errs.push(`validation path ${path} is not set on params ${params}`);
      } else {
        errs.push(...validator(paramValue));
      }
    });
    return errs;
  };
}
