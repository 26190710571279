/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ModelNameFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModelNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModelNamesQuery = { __typename?: 'QueryPrediction', models: Array<{ __typename?: 'ModelType', id: string, name: string | null | undefined } | null | undefined> | null | undefined };


export const ModelNamesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ModelNames"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"models"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ModelNameFragment"}}]}}]}},...ModelNameFragmentDoc.definitions]} as unknown as DocumentNode<ModelNamesQuery, ModelNamesQueryVariables>;
export const namedOperations = {
  Query: {
    ModelNames: 'ModelNames'
  }
}

/**
 * __useModelNamesQuery__
 *
 * To run a query within a React component, call `useModelNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelNamesQuery(baseOptions?: Apollo.QueryHookOptions<ModelNamesQuery, ModelNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelNamesQuery, ModelNamesQueryVariables>(ModelNamesDocument, options);
      }
export function useModelNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelNamesQuery, ModelNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelNamesQuery, ModelNamesQueryVariables>(ModelNamesDocument, options);
        }
export type ModelNamesQueryHookResult = ReturnType<typeof useModelNamesQuery>;
export type ModelNamesLazyQueryHookResult = ReturnType<typeof useModelNamesLazyQuery>;
export type ModelNamesQueryResult = Apollo.QueryResult<ModelNamesQuery, ModelNamesQueryVariables>;
export function refetchModelNamesQuery(variables?: ModelNamesQueryVariables) {
      return { query: ModelNamesDocument, variables: variables }
    }