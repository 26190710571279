import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import BaseTextField from '@mui/material/TextField';

import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';

import { TextFieldProps } from './types';

function TextField({ children, label = null, labelProps = {}, ...textFieldProps }: TextFieldProps) {
  return (
    <Box>
      {!isEmpty(label) && <InputLabel {...labelProps}>{label}</InputLabel>}
      <BaseTextField
        sx={(theme) => ({
          [`&	.${outlinedInputClasses.error}`]: {
            border: '1px solid transparent',
            [`&.${outlinedInputClasses.focused}`]: {
              border: '1px solid transparent',
            },
            [`.${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${theme.palette.error.main}`,
            },
          },
        })}
        data-cy={typeof label === 'string' ? `text-field-${camelCase(label)}` : undefined}
        {...textFieldProps}
      >
        {children}
      </BaseTextField>
    </Box>
  );
}

export default TextField;
