import isEqual from 'lodash/isEqual';

/**
 * Map which takes an object as a key and uses structural equality to compare keys.
 * The implementation is `O(n)` for all operations which is fine for small `n`
 */
export class ObjectMap<TKey extends {}, TValue> {
  private storage: [TKey, TValue][] = [];

  has(key: TKey): boolean {
    return this.storage.some(([otherKey]) => isEqual(otherKey, key));
  }

  get(key: TKey): TValue | undefined {
    return this.storage.find(([otherKey]) => isEqual(otherKey, key))?.[1];
  }

  remove(key: TKey): boolean {
    const index = this.storage.findIndex(([otherKey]) => isEqual(otherKey, key));
    if (index >= 0) {
      this.storage.splice(index, 1);
      return true;
    }

    return false;
  }

  set(key: TKey, value: TValue) {
    const existing = this.storage.find(([otherKey]) => isEqual(otherKey, key));
    if (existing) {
      existing[1] = value;
    } else {
      this.storage.push([key, value]);
    }
  }
}
