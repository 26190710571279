import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/get';
import nthArg from 'lodash/nthArg';
import pick from 'lodash/pick';

import { LayoutMap } from '@/pages/Application/View/Canvas/types';
import { getComponentDefinition } from '@/pages/Application/View/components/definitions';
import { ComponentConfig } from '@/pages/Application/View/components/types';
import { DOT_NOTATION } from '@/pages/Application/View/constants';
import { DatasourceBinding } from '@/pages/Application/View/datasources/types';
import { ParamMapSupplier } from '@/pages/Application/View/params/types';
import {
  ComponentCollection,
  ContainerConfig,
  Resolution,
  ResourceCategory,
  ValueSupplier,
} from '@/pages/Application/View/types';
import { parseResourcePath } from '@/pages/Application/View/utils';
import fuzzy from 'fuzzy';

import { viewsSelector } from '../../../Selectors';
import { RootState } from '../../../types';
import createDeepEqualSelector from '../../../utils/DeepEqualSelector';
import { workflowSelector } from '../Workflows/selectors';
import { WorkflowIdentifier } from '../Workflows/types';
import { Action } from './Actions/types';
import { ResourceMatch, ViewEntity, ViewIdentifier } from './types';
import { dereferenceData } from './utils';

const selectConfig = (view: ViewEntity) => view.config.present;
const selectLastEvaluatedResources = (view: ViewEntity) => view.lastEvaluatedResources;
const selectComponents = (config: ComponentCollection) => config.children;
const selectComponentId = (state: RootState, viewIdentifier: ViewIdentifier, componentId: string) =>
  componentId;
const selectComponent = (components: Record<string, ComponentConfig>, componentId: string) =>
  components[componentId];
const selectRefs = (config: ComponentCollection) => config.refs ?? {};
const selectParams = (config: ComponentCollection) => config.params ?? {};
const selectParamId = (state: RootState, viewId: string, paramId: string) => paramId;
const selectParam = (params: Record<string, ParamMapSupplier>, paramId: string) => params[paramId];
const selectActions = (config: ComponentCollection) => config.actions;
const selectActionId = (state: RootState, viewId: string, actionId: string) => actionId;
const selectAction = (actions: Record<string, Action>, actionId: string) => actions[actionId];
const selectStates = (config: ComponentCollection) => config.states ?? {};
const selectUrlParams = (config: ComponentCollection) => config.urlParams ?? {};
const selectVariables = (config: ComponentCollection) => config.vars ?? {};
const selectDatasources = (config: ComponentCollection) => config.datasources ?? {};
const selectDatasourceInstance = (state: RootState, viewId: string, instanceId: string) =>
  instanceId;
const selectDatasource = (datasources: Record<string, DatasourceBinding>, instanceId: string) =>
  datasources[instanceId];
const selectContainer = (config: ComponentCollection): ContainerConfig => {
  return {
    meta: config.meta,
    props: config.props,
    handlers: config.handlers,
  };
};
const selectLayout = (components: Record<string, ComponentConfig>): LayoutMap => {
  if (!components) {
    return {};
  }

  const layoutMap: LayoutMap = {};

  Object.keys(components).forEach((childId) => {
    const { location } = components[childId];
    const { x, y, w, h } = location;
    layoutMap[childId] = {
      id: childId,
      x0: x,
      x1: x + w,
      y0: y,
      y1: y + h,
      centroid: {
        x: x + w / 2,
        y: y + h / 2,
      },
    };
  });

  return layoutMap;
};
const selectValueSuppliers = (config: ComponentCollection) => config.valueSuppliers;
const selectResolvedData = (config: ComponentCollection) => config.resolvedData;
const selectSupplierIds = (
  state: RootState,
  viewIdentifier: ViewIdentifier,
  supplierIds: string[],
) => supplierIds;
const selectValues = (suppliers: Record<string, ValueSupplier>, supplierIds: string[]) =>
  pick(suppliers, supplierIds);
const selectData = (resolutions: Record<string, Resolution>, supplierIds: string[]) =>
  pick(resolutions, supplierIds);
const selectComponentSuppliers = (component: ComponentConfig): string[] => {
  if (!component) {
    return null;
  }
  const { type, propSuppliers, fields: fieldSuppliers } = component;
  const def = getComponentDefinition(type);
  if (!def) {
    return null;
  }
  return [...Object.values(propSuppliers), ...(def.fields ? Object.values(fieldSuppliers) : [])];
};
const selectResolutionQueue = (view: ViewEntity | undefined) => view?.resolutionQueue;
const selectTerm = (state: RootState, viewId: string, term: string) => term;
const selectMatchingResource = (
  config: ComponentCollection,
  term: string = '',
): ResourceMatch[] | null => {
  if (!config) {
    return null;
  }
  const { children: components, vars, states, urlParams } = config;

  const trimmedTerm = term.trim();
  const termSplit = trimmedTerm.lastIndexOf(DOT_NOTATION);
  const resourcePath = parseResourcePath(trimmedTerm.substring(0, termSplit));
  const fuzzyTerm = trimmedTerm.substring(termSplit + 1);
  const [resourceName, ...accessor] = resourcePath;
  const hasContext = !!resourceName;

  const matches: ResourceMatch[] = [];

  function addDataKeys(dataObj: any) {
    Object.keys(dataObj ?? {}).forEach((dataKey) => {
      if (fuzzy.test(fuzzyTerm, dataKey)) {
        matches.push({
          expanded: [...resourcePath, dataKey].join(DOT_NOTATION),
          display: dataKey,
          preview: dataObj[dataKey],
        });
      }
    });
  }

  function addMatch(
    resourceCategory: ResourceCategory,
    referenceDataId: string,
    referenceResourceName: string,
    metadata?: Record<string, any>,
  ) {
    let data = dereferenceData(config, referenceDataId);
    if (hasContext) {
      if (accessor.length) {
        data = get(data, accessor);
      }
      if (resourceName === referenceResourceName && typeof data === 'object') {
        addDataKeys(data);
      }
    } else if (fuzzy.test(fuzzyTerm, referenceResourceName)) {
      matches.push({
        category: resourceCategory,
        expanded: referenceResourceName,
        display: referenceResourceName,
        preview: data,
        meta: metadata,
      });
    }
  }

  if (components) {
    Object.keys(components).forEach((componentId) => {
      if (hasContext) {
        if (resourceName === componentId) {
          const component = components[componentId];
          const componentDef = getComponentDefinition(component.type);
          if (component.fields) {
            const validFields = componentDef.fields
              .filter((fieldConfig) => component.fields[fieldConfig.name] && !fieldConfig.hidden)
              .map((fieldConfig) => fieldConfig.name);
            const [prop, ...dataAccessor] = accessor; // components have 1 additional level of props
            const hasProp = !!prop;
            validFields.forEach((field) => {
              let data = dereferenceData(config, component.fields[field]);
              if (hasProp) {
                if (dataAccessor.length) {
                  data = get(data, dataAccessor);
                }
                if (prop === field && typeof data === 'object') {
                  addDataKeys(data);
                }
              } else if (fuzzy.test(fuzzyTerm, field)) {
                matches.push({
                  expanded: [componentId, field].join(DOT_NOTATION),
                  display: field,
                  preview: data,
                });
              }
            });
          }
        }
      } else if (fuzzy.test(fuzzyTerm, componentId)) {
        matches.push({
          category: ResourceCategory.Component,
          expanded: componentId,
          display: componentId,
        });
      }
    });
  }

  if (vars) {
    Object.entries(vars).forEach(([variableId, variable]) => {
      addMatch(ResourceCategory.Variable, variableId, variable.name);
    });
  }

  if (states) {
    Object.entries(states).forEach(([stateName, stateSupplier]) => {
      const { name, supplierId, isShared = false } = stateSupplier;
      addMatch(ResourceCategory.State, supplierId, name, { supplierId, isShared });
    });
  }

  if (urlParams) {
    Object.keys(urlParams).forEach((paramName) => {
      addMatch(ResourceCategory.URLParameter, urlParams[paramName], paramName);
    });
  }

  return matches.length > 0 ? matches : null;
};

const viewSelector = (rootState: RootState, { viewId, releaseEnv }: ViewIdentifier) =>
  rootState.entities.views[releaseEnv][viewId];
const configSelector = createSelector(viewSelector, selectConfig);
const componentsSelector = createSelector(configSelector, selectComponents);
const componentIdsSelector = createDeepEqualSelector(componentsSelector, Object.keys);
const componentSelector = createSelector([componentsSelector, selectComponentId], selectComponent);
const refsSelector = createSelector(configSelector, selectRefs);
const paramsSelector = createSelector(configSelector, selectParams);
const paramSelector = createSelector([paramsSelector, selectParamId], selectParam);
const actionsSelector = createSelector(configSelector, selectActions);
const actionSelector = createSelector([actionsSelector, selectActionId], selectAction);
const statesSelector = createSelector(configSelector, selectStates);
const lastEvaluatedResourcesSelector = createSelector(viewSelector, selectLastEvaluatedResources);
const variablesSelector = createSelector(configSelector, selectVariables);
const datasourcesSelector = createSelector(configSelector, selectDatasources);
const datasourceSelector = createSelector(
  [datasourcesSelector, selectDatasourceInstance],
  selectDatasource,
);
const containerSelector = createSelector(configSelector, selectContainer);
const layoutSelector = createSelector(componentsSelector, selectLayout);
const valueSupplierSelector = createSelector(configSelector, selectValueSuppliers);
const resolvedDataSelector = createSelector(configSelector, selectResolvedData);
const valuesSelector = createSelector([valueSupplierSelector, selectSupplierIds], selectValues);
const dataSelector = createSelector([resolvedDataSelector, selectSupplierIds], selectData);
const componentSuppliersSelector = createSelector(componentSelector, selectComponentSuppliers);
const urlParamsSelector = createSelector(configSelector, selectUrlParams);
const urlParamKeysSelector = createDeepEqualSelector(urlParamsSelector, Object.keys);
const doesViewExistSelector = createSelector(viewSelector, (view: ViewEntity) => Boolean(view));
const resolutionQueueSelector = createSelector(viewSelector, selectResolutionQueue);
const matchingResourceSelector = createSelector(
  [configSelector, selectTerm],
  selectMatchingResource,
);
export const viewIdSelector = createDeepEqualSelector(
  [workflowSelector, viewsSelector, nthArg(1)],
  // Only include workflow views that are loaded
  (workflow, views, workflowIdentifier: WorkflowIdentifier) =>
    workflow.views.filter((viewId) => !!views[workflowIdentifier.releaseEnv][viewId]),
);

export {
  viewSelector,
  configSelector,
  componentsSelector,
  componentIdsSelector,
  componentSelector,
  refsSelector,
  paramsSelector,
  paramSelector,
  actionsSelector,
  actionSelector,
  statesSelector,
  lastEvaluatedResourcesSelector,
  variablesSelector,
  datasourcesSelector,
  datasourceSelector,
  containerSelector,
  layoutSelector,
  valuesSelector,
  dataSelector,
  componentSuppliersSelector,
  urlParamsSelector,
  urlParamKeysSelector,
  doesViewExistSelector,
  resolutionQueueSelector,
  matchingResourceSelector,
  valueSupplierSelector,
};
