import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { useHighlightState } from '../../hooks';
import StepTitle from './StepTitle';
import { InstructionProps } from './types';

function Instruction({
  title,
  step,
  children,
  hideStepCount,
}: PropsWithChildren<InstructionProps>) {
  const { highlighted, highlight, unhighlight } = useHighlightState();
  const isHighlighted = highlighted === step;

  return (
    <Box onMouseOver={() => highlight(step)} onMouseLeave={unhighlight}>
      <StepTitle
        isHighlighted={isHighlighted}
        step={hideStepCount ? undefined : step}
        text={title}
      />
      {children}
    </Box>
  );
}

export default Instruction;
