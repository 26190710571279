import { BasetenPageEnum, BasetenPageProps } from '@/routes/types';

const Routes: Array<BasetenPageProps> = [
  {
    page: BasetenPageEnum.Workspace,
    path: '/applications',
  },
  {
    page: BasetenPageEnum.Onboarding,
    path: '/onboarding',
  },
];

export default Routes;
