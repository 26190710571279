import moment from 'moment';
import { plural } from 'pluralize';

export type AnyObject = {
  [key: string]: any;
};

export interface Class {
  id?: string;
  name: string;
  typename: string;
  tablename: string;
  namespace: string;
  description: string;
  schema?: AnyObject;
  fields: Array<Field>;
  created: moment.Moment;
  modified: moment.Moment;
}

export const ClassInit: Class = {
  id: null,
  name: '',
  typename: '',
  tablename: '',
  namespace: '',
  description: '',
  schema: null,
  fields: [],
  created: moment(),
  modified: moment(),
};

export interface Field {
  name: string;
  typ: string;
  defaultValue?: string;
  nullable: boolean;
}

export interface ClassMutation {
  classTypename: string;
  classNamespace: string;
}

export interface AddFieldAction extends ClassMutation {
  field: Field;
}

export interface RenameFieldAction extends ClassMutation {
  fieldName: string;
  newFieldName: string;
}

export interface RemoveFieldAction extends ClassMutation {
  fieldName: string;
  field: Field;
}

export interface CreateClassAction extends ClassMutation {
  className: string;
  classDescription: string;
  fields: Field[];
}

export interface UpdateClassAction extends ClassMutation {
  classId: string;
  className: string;
  classDescription: string;
}
export interface RenameClassAction extends ClassMutation {
  newClassTypename: string;
}
export interface DeleteClassAction extends ClassMutation {}

export type UdmMutation =
  | AddFieldAction
  | RenameFieldAction
  | RemoveFieldAction
  | CreateClassAction
  | RenameClassAction
  | DeleteClassAction
  | UpdateClassAction;

export interface ActionWrapper {
  name: string;
  body: UdmMutation;
}

export enum PrimitiveType {
  String = 'string',
  Integer = 'integer',
  Number = 'number',
  Boolean = 'boolean',
  Datetime = 'datetime',
  Json = 'json',
}

export interface ClassType {
  namespace: string;
  typename: string;
}

export interface ArrayType {
  element: UDMType;
}

export type UDMType = PrimitiveType | ClassType | ArrayType;

export function isPrimitiveType(typ: UDMType): typ is PrimitiveType {
  return typeof typ === 'string' && Object.values(PrimitiveType).includes(typ);
}

export function isClassType(typ: UDMType): typ is ClassType {
  return typeof typ === 'object' && (typ as ClassType).namespace !== undefined;
}

export function isArrayType(typ: UDMType): typ is ArrayType {
  return typeof typ === 'object' && 'element' in typ;
}

export function udmTypeToString(typ: UDMType): string {
  if (isPrimitiveType(typ)) {
    return typ.toString();
  }

  if (isClassType(typ)) {
    return `class[${typ.namespace}.${typ.typename}]`;
  }

  if (isArrayType(typ)) {
    return `[${udmTypeToString(typ.element)}]`;
  }

  throw new Error('Unknown type');
}

export function udmTypeToSelectType(typ: UDMType): string {
  if (isPrimitiveType(typ)) {
    return typ.toString();
  }

  if (isClassType(typ)) {
    return typ.typename;
  }

  if (isArrayType(typ)) {
    if (isClassType(typ.element)) {
      return `element_${typ.element.typename}`;
    }

    return `element_${typ.element}`;
  }

  throw new Error('Unknown type');
}

export function udmTypeToDisplayString(typ: UDMType): string {
  if (isPrimitiveType(typ)) {
    return typ.toString();
  }

  if (isClassType(typ)) {
    return typ.typename;
  }

  if (isArrayType(typ)) {
    return `List of ${plural(udmTypeToDisplayString(typ.element))}`;
  }

  throw new Error('Unknown type');
}

export function udmTypeFromString(typ: string): UDMType {
  if (typ.startsWith('class[') && typ.endsWith(']')) {
    const namespacedClass = typ.substr(6, typ.length - 7);
    const dottedParts = namespacedClass.split('.');
    const typename = dottedParts[dottedParts.length - 1];
    const namespace = dottedParts.slice(0, dottedParts.length - 1).join('.');
    return {
      namespace,
      typename,
    } as ClassType;
  }

  if (typ.startsWith('[') && typ.endsWith(']')) {
    return {
      element: udmTypeFromString(typ.substr(1, typ.length - 2)),
    } as ArrayType;
  }

  return typ as PrimitiveType;
}

export const PRIMITIVE_TYPES: Array<UDMType> = [
  PrimitiveType.String,
  PrimitiveType.Integer,
  PrimitiveType.Number,
  PrimitiveType.Boolean,
  PrimitiveType.Datetime,
  PrimitiveType.Json,
];

function extractTypeStringFromNullable(types: Array<string>): string {
  const nonNullTypes = types.filter((t) => t !== 'null');
  return nonNullTypes[0];
}

export function fieldSchemaToType(schema: AnyObject): UDMType {
  if ('$ref' in schema) {
    const refParts = schema.$ref.split('/');
    const len = refParts.length;
    return {
      namespace: refParts[len - 2],
      typename: refParts[len - 1],
    };
  }

  let schemaType = schema.type;
  if (Array.isArray(schemaType)) {
    schemaType = extractTypeStringFromNullable(schemaType);
  }

  switch (schemaType) {
    case 'integer':
      return PrimitiveType.Integer;
    case 'number':
      return PrimitiveType.Number;
    case 'boolean':
      return PrimitiveType.Boolean;
    case 'json':
      return PrimitiveType.Json;
    case 'string':
      if ('format' in schema && schema.format === 'date-time') {
        return PrimitiveType.Datetime;
      }
      return PrimitiveType.String;
    case 'array':
      return {
        element: fieldSchemaToType(schema.items),
      };
    default:
      throw new Error('Unexpected Schema');
  }
}
