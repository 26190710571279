import { WorkletRunStatus } from '@/store/types';
import { ReleaseEnv } from '@/types/releaseEnv';
import { Moment } from 'moment';

type DateRange = [Moment | null, Moment | null];

type Log = {
  level?: Level;
  msg: string;
  /**
   * Timstamp in microseconds from unix epoch
   */
  ts: number;
  replica?: string;
  isStatusMessage?: boolean;
};

type Frame = {
  filename: string;
  name: string;
  lineno: string;
  line: string;
};

type WorkletRunLog = {
  created?: string;
  applicationId?: string;
  applicationName?: string;
  env: string;
  inputData: string;
  output: string;
  scheduled: string;
  status: WorkletRunStatus;
  workletId: string;
  workletName: string;
  routeName?: string;
  workletRunId: string;
  latencyMs: number;
};

type UserCodeLog = {
  applicationId?: string;
  workletRunId: string;
  msg: string;
  levelName: string;
  tag?: string;
};

type EventLog = {
  component: string;
  event: string;
  [key: string]: string;
};

type ExceptionLog = {
  applicationId?: string;
  workletRunId: string;
  levelName: string;
  traceback: Array<Frame>;
  error: string;
  msg: string;
};

type ApplicationLog = Omit<Log, 'msg'> & {
  msg: WorkletRunLog | UserCodeLog | ExceptionLog | EventLog;
  type: LogType;
  uid?: string;
};

enum LogType {
  WORKLET_RUN = 'WORKLET_RUN',
  USER_CODE = 'USER_CODE',
  EXCEPTION = 'EXCEPTION',
  EVENT = 'EVENT',
}

type BaseLogProps = ApplicationLog & {
  isOpen?: boolean;
  isDisabled?: boolean;
};

type WorkletRunStatusColors = {
  [key in WorkletRunStatus]: string;
};

type LevelColors = {
  [key in Level]: string;
};

enum Level {
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
}

enum LogSubType {
  BUILD = 'BUILD',
  EXECUTION = 'EXECUTION',
}

interface FetchLogsParams {
  type: LogType | 'MODEL';
  entityId?: string;
  regex?: string;
  levels?: Array<Level>;
  dateRange?: DateRange;
  subtype?: LogSubType;
  releaseEnv?: ReleaseEnv;
  limit?: number;
  replica?: string;
  direction?: 'forward' | 'backward';
}
enum Shortcuts {
  LAST_5_MINUTES = 'Last 5 minutes',
  LAST_10_MINUTES = 'Last 10 minutes',
  LAST_30_MINUTES = 'Last 30 minutes',
  LAST_1_HOUR = 'Last 1 hour',
  LAST_6_HOURS = 'Last 6 hours',
  LAST_24_HOURS = 'Last 24 hours',
  LAST_3_DAYS = 'Last 3 days',
  LAST_7_DAYS = 'Last 7 days',
  DEFAULT = LAST_1_HOUR,
}

export {
  Log,
  LogType,
  ApplicationLog,
  ExceptionLog,
  EventLog,
  UserCodeLog,
  WorkletRunLog,
  Frame,
  BaseLogProps,
  WorkletRunStatusColors,
  LevelColors,
  FetchLogsParams,
  Shortcuts,
  Level,
  DateRange,
  LogSubType,
};
