import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import moment from 'moment';

import { RpcActionPrefix } from '../../../actions/ActionType';
import { fetchSageMakerModels } from './thunks';
import { DataSource, DataSourcesState, SageMakerModel } from './types';

const initialState: DataSourcesState = {
  sources: {},
  smModels: {},
};

function dataSourceFromResponse(response: any) {
  return {
    id: response.id,
    name: response.name,
    created: moment(response.created),
    updated: moment(response.modified),
    useProductionSettingsForDraft: response.useProductionSettingsForDraft,
    type: response.type,
  } as DataSource;
}

const DataSources = createSlice({
  name: 'datasources-entities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      `${RpcActionPrefix.REFRESH_DATA_SOURCES}.Done`,
      (state: DataSourcesState, action: any) => {
        const { dataSources } = action.payload;
        dataSources.forEach((ds: any) => {
          state.sources[ds.id] = dataSourceFromResponse(ds);
        });
      },
    );
    builder.addCase(
      `${RpcActionPrefix.REFRESH_DATA_SOURCE}.Done`,
      (state: DataSourcesState, action: any) => {
        const { payload } = action;
        const source = dataSourceFromResponse(payload.createOrUpdateDataSource.dataSource);
        state.sources[source.id] = source;
      },
    );
    builder.addCase(
      `${RpcActionPrefix.DELETE_DATA_SOURCE}.Done`,
      (state: DataSourcesState, action: any) => {
        const {
          meta: { id },
        } = action;
        const { name } = state.sources[id];
        delete state.sources[id];
        delete state.smModels[name];
      },
    );
    builder.addCase(
      fetchSageMakerModels.fulfilled,
      (
        state: DataSourcesState,
        action: PayloadAction<Array<SageMakerModel>, string, { arg: string }>,
      ) => {
        const connectionName = action.meta.arg;
        state.smModels = {
          ...state.smModels,
          [connectionName]: action.payload.map(({ endpointName, modelName, status }) => ({
            endpointName,
            modelName,
            status,
          })),
        };
      },
    );
  },
});

export default DataSources.reducer;
