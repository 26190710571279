import API from '@/API';
import validate from '@/utils/validators/workspaceName';

async function saveWorkspace(name: string) {
  validate(name);

  const response = await API.postWithoutCallback('/api/save_workspace', { org_name: name });

  if (!response.data.success) {
    throw new Error('There was a problem completing your profile. Please try again.');
  }

  return response;
}

export { saveWorkspace };
