import { MiddlewareAPI, UnsubscribeListener } from '@reduxjs/toolkit';

import sleep from '@/utils/async/sleep';

import { AppDispatch, RootState, ThunkExtras } from '../../types';
import { startAppListening } from '../listener';
import { EntityProcessorParams, EntityState } from './types';
import { ObjectMap } from './utils';

/**
 * Wrapper on Redux Toolkit's `createListenerMiddleware` that helps process entities in the store.
 * Processing can be any routine with these rules:
 * - The processing happens independently for each identifiable entity (usually an ID or a tuple of ids)
 * - The processing runs in response to certain actions as specified by a predicate function
 * - The processing reacts to state changes and not to action types or payloads
 * - The processing runs asynchronously and cannot be interrupted
 *
 * `startEntityProcessor` guarantees that whenever a state change needs to be processed, it eventually
 * gets processed either:
 * - Immediately if there's no debounce and the entity isn't currently being processed
 * - After a certain internal if debouncing is enabled and the entity isn't currently being processed
 * - After the current processing completes
 *
 * This is useful for ensuring that entities get saved to the backend or derived, async data like worklets
 * get updated with the most recent data.
 */
export function startEntityProcessor<TEntityIdentifier extends {}>({
  predicate,
  extractIdentifier,
  process,
  debounceMs,
  onComplete,
}: EntityProcessorParams<TEntityIdentifier>): UnsubscribeListener {
  let middlewareAPI: (MiddlewareAPI<AppDispatch, RootState> & { extra: ThunkExtras }) | undefined;
  const entityStates = new ObjectMap<TEntityIdentifier, EntityState>();

  return startAppListening({
    predicate,
    effect: async (action, listenerAPI) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      middlewareAPI = listenerAPI;

      const id = extractIdentifier(action);

      if (!id) {
        return;
      }

      switch (entityStates.get(id)) {
        case EntityState.Waiting:
        case EntityState.ProcessingAndQueued:
          // Entity will be processed again
          return;
        case EntityState.Processing:
          // Entity needs to be processed again after current processing round ends
          entityStates.set(id, EntityState.ProcessingAndQueued);
          return;
        case undefined:
          // continue running
          break;
      }

      do {
        if (debounceMs !== null) {
          entityStates.set(id, EntityState.Waiting);
          await sleep(debounceMs);
        }

        // Dequeued and processing
        entityStates.set(id, EntityState.Processing);

        try {
          await process(id, middlewareAPI);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      } while (entityStates.get(id) === EntityState.ProcessingAndQueued);

      // Done with processing loop, so reset state
      entityStates.remove(id);

      onComplete?.(id, middlewareAPI);
    },
  });
}
