import API from '@/API';
import getGeoData from '@/utils/network/geo';
import validate from '@/utils/validators/userName';

import type { SignupArgs } from './types';

async function completeSignup({
  firstName,
  lastName,
  useCase,
  companyName,
  companyWebsite,
}: SignupArgs) {
  if (!firstName?.trim() || !lastName?.trim()) {
    throw new Error('Some required fields are missing.');
  }

  validate(firstName, lastName);

  const response = await API.postWithoutCallback('/api/complete_signup', {
    first_name: firstName,
    last_name: lastName,
    profile_use: useCase,
    company_name: companyName,
    company_website: companyWebsite,
    geo: getGeoData(),
  });

  if (!response.data.success) {
    throw new Error('There was a problem completing your profile. Please try again.');
  }

  return response;
}

export { completeSignup };
