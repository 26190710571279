import { useState } from 'react';

import { Divider, IconButton, Menu, Typography } from '@mui/material';

import BookIcon from '@/components/Icons/BookIcon';
import BookOpenIcon from '@/components/Icons/BookOpenIcon';
import CodeIcon from '@/components/Icons/CodeIcon';
import DotIcon from '@/components/Icons/DotIcon';
import ExternalLinkIcon from '@/components/Icons/ExternalLinkIcon';
import HelpIcon from '@/components/Icons/HelpIcon';
import MailIcon from '@/components/Icons/MailIcon';
import MessageIcon from '@/components/Icons/MessageIcon';
import XSocialIcon from '@/components/Icons/XSocialIcon';
import { useAsOperator } from '@/hooks';
import { links } from '@/localization';
import { usePylon } from '@bolasim/react-use-pylon';

import { MenuItem, StyledLink } from '../../../styled';
import { useLatestChangelog } from './hooks';
import { ChangelogTitle, HeaderBadge, InlineBadge } from './styled';

function HelpMenu() {
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isHelpMenuOpen = Boolean(helpMenuAnchorEl);
  const asOperator = useAsOperator();
  const { show } = usePylon();
  const { latestChangelog, markChangelogAsSeen, seenChangelog, onChangelogClick } =
    useLatestChangelog();

  function openHelpMenu(event: React.MouseEvent<HTMLElement>): void {
    setHelpMenuAnchorEl(event.currentTarget);
  }

  function handleCloseHelpMenu(): void {
    setHelpMenuAnchorEl(null);
    markChangelogAsSeen();
  }

  const unseenChangelog = latestChangelog.filter((post) => !seenChangelog[post.id]);

  return (
    <>
      <IconButton
        onClick={openHelpMenu}
        aria-controls={isHelpMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isHelpMenuOpen ? 'true' : undefined}
      >
        <HeaderBadge badgeContent={unseenChangelog.length}>
          <HelpIcon />
        </HeaderBadge>
      </IconButton>
      <Menu
        anchorEl={helpMenuAnchorEl}
        open={isHelpMenuOpen}
        onClose={handleCloseHelpMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" color="secondary" sx={{ p: 1 }}>
          Help
        </Typography>
        <MenuItem onClick={show}>
          <StyledLink onClick={show} href="#">
            <MessageIcon color="secondary" /> Chat with support
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenDocs}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <BookIcon color="secondary" /> Documentation
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenDocsAPIReference}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <CodeIcon color="secondary" /> API documentation
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.trussDocsHome}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <BookOpenIcon color="secondary" /> Truss documentation
          </StyledLink>
        </MenuItem>
        <Divider sx={{ mx: -1, my: 1 }} />
        <Typography
          variant="h6"
          color={unseenChangelog.length > 0 ? 'default' : 'secondary'}
          sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 1.5 }}
        >
          What&apos;s new <InlineBadge badgeContent={unseenChangelog.length} />
        </Typography>
        {latestChangelog.map((changelog) => (
          <MenuItem
            key={changelog.id}
            onClick={() => onChangelogClick(latestChangelog, changelog.id)}
          >
            <StyledLink
              href={`${links.basetenChangeLog}/${changelog.slug}`}
              onClick={handleCloseHelpMenu}
              target="_blank"
              rel="noreferrer"
            >
              <DotIcon color={seenChangelog[changelog.id] ? 'secondary' : 'primary'} />{' '}
              <ChangelogTitle>{changelog.title}</ChangelogTitle>
            </StyledLink>
          </MenuItem>
        ))}
        <MenuItem>
          <StyledLink
            href={links.basetenChangeLog}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon color="secondary" /> Full changelog
          </StyledLink>
        </MenuItem>
        <Divider sx={{ mx: -1, my: 1 }} />
        {!asOperator && (
          <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}>
            <StyledLink href={links.basetenModelLibrary}>
              <BookOpenIcon color="secondary" /> Model library
            </StyledLink>
          </MenuItem>
        )}
        <MenuItem>
          <StyledLink
            href={links.sendFeedbackGoogleForm}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <MailIcon color="secondary" /> Send feedback
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenTwitter}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <XSocialIcon color="secondary" /> Follow @basetenco
          </StyledLink>
        </MenuItem>
      </Menu>
    </>
  );
}

export default HelpMenu;
