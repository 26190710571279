import { useState } from 'react';

import { LoadingButton } from '@mui/lab';

import { ApiKeyType } from '@/graphql';
import { useIsNonAdminCreator } from '@/hooks/useIsCreator';
import { useSnackbar } from 'notistack';

import OptionsDialog from './OptionsDialog';
import PreviewDialog from './PreviewDialog';
import { useCreateUserApiKeyMutation, useCreateWorkspaceApiKeyMutation } from './hooks';
import { GenerateAPIKeyProps } from './types';

function GenerateAPIKey({
  buttonProps = {},
  onCreate,
  createOnlyPersonalKey,
}: GenerateAPIKeyProps) {
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const isNonAdminCreator = useIsNonAdminCreator();
  const [createUserApiKey, { loading: creatingUserApiKey }] = useCreateUserApiKeyMutation();
  const [createWorkspaceApiKey, { loading: creatingWorkspaceApiKey }] =
    useCreateWorkspaceApiKeyMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedApiKeyType, setSelectedApiKeyType] = useState<ApiKeyType>(ApiKeyType.Personal);

  const isLoading = creatingUserApiKey || creatingWorkspaceApiKey;

  function openOptionsDialog() {
    setShowOptionsDialog(true);
  }

  function closeOptionsDialog() {
    setShowOptionsDialog(false);
  }

  function closePreviewDialog() {
    setApiKey(null);
  }

  function handleApiCreate(apiKey: string, apiKeyType: ApiKeyType) {
    setSelectedApiKeyType(apiKeyType);

    if (onCreate) {
      onCreate(apiKey);
    } else {
      setApiKey(apiKey);
    }
  }

  async function createPersonalKey() {
    try {
      const { data } = await createUserApiKey();
      handleApiCreate(data?.createUserApiKey?.textKey, ApiKeyType.Personal);
    } catch (e) {
      enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
    }
  }

  return (
    <>
      <LoadingButton
        data-cy="create-api-key-dialog"
        color="primary"
        variant="contained"
        loading={(isNonAdminCreator || createOnlyPersonalKey) && isLoading}
        onClick={isNonAdminCreator || createOnlyPersonalKey ? createPersonalKey : openOptionsDialog}
        {...buttonProps}
      >
        {buttonProps.children || 'Generate API key'}
      </LoadingButton>
      <OptionsDialog
        onClose={closeOptionsDialog}
        onCreate={handleApiCreate}
        isOpen={showOptionsDialog}
        isLoading={isLoading}
        createUserApiKey={createUserApiKey}
        createWorkspaceApiKey={createWorkspaceApiKey}
      />
      <PreviewDialog
        textKey={apiKey}
        onClose={closePreviewDialog}
        isOpen={!!apiKey}
        apiKeyType={selectedApiKeyType}
      />
    </>
  );
}

export default GenerateAPIKey;
