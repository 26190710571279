/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExpandedOperatorWorkflowFragment = { __typename?: 'OperatorWorkflowType', id: string | null | undefined, name: string | null | undefined, description: string, tags: any | null | undefined, created: any, modified: any, startingView: string | null | undefined, sharedStates: Array<string | null | undefined> | null | undefined, builderVersion: number | null | undefined };

export const ExpandedOperatorWorkflowFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpandedOperatorWorkflowFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OperatorWorkflowType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}},{"kind":"Field","alias":{"kind":"Name","value":"startingView"},"name":{"kind":"Name","value":"starting_view_id"}},{"kind":"Field","alias":{"kind":"Name","value":"sharedStates"},"name":{"kind":"Name","value":"shared_states"}},{"kind":"Field","alias":{"kind":"Name","value":"builderVersion"},"name":{"kind":"Name","value":"builder_version"}}]}}]} as unknown as DocumentNode<ExpandedOperatorWorkflowFragment, unknown>;
export const namedOperations = {
  Fragment: {
    ExpandedOperatorWorkflowFragment: 'ExpandedOperatorWorkflowFragment'
  }
}
