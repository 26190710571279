const workspaceNameRegex = /^[a-zA-Z0-9\s]+$/;
const MAX_WORKSPACE_NAME_LENGTH = 75;

function validate(workspaceName: string) {
  if (!workspaceNameRegex.test(workspaceName)) {
    throw new Error('Workspace name should only contain alphabets, numbers, and spaces.');
  }

  if (workspaceName.length > MAX_WORKSPACE_NAME_LENGTH) {
    throw new Error('Workspace name should be less than 75 characters.');
  }
}

export default validate;
