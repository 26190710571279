import { useEffect } from 'react';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { SerializableParam, useSetRecoilState } from 'recoil';

import { pollerState } from './pollerState';
import { PollIntervalObject } from './types';

/**
 * useSharedQueryPoller allows multiple useQuery instances calling the same query with the same variables
 * to share the same polling logic. This is useful for queries that are used in multiple places. Apollo does
 * a good job of deduplicating the network calls to load the query, but it doesn't deduplicate polling.
 *
 * Because different parts of the code may need to poll on different intervals, the SharedQueryPoller will
 * select the smallest positive interval in order to meet the strictest responsiveness requirement.
 *
 * @param query The query document
 * @param variables The variables to use for the query
 * @param pollInterval The interval to poll at. If 0, polling will be disabled.
 */
export default function useSharedQueryPoller<TQuery, TVariables extends SerializableParam>(
  query: TypedDocumentNode<TQuery, TVariables>,
  variables: TVariables,
  pollInterval: number,
) {
  // We use recoil state here to keep track of all the active poll intervals
  const setState = useSetRecoilState(
    pollerState({ query: query as unknown as SerializableParam, variables }),
  );

  useEffect(() => {
    // We use an object so that we can use reference equality to remove the interval for this hook,
    // even if multiple hooks share the same interval
    const pollIntervalObject: PollIntervalObject = { pollInterval };

    setState((intervals) => [...intervals, pollIntervalObject]);

    return () => {
      setState((intervals) =>
        intervals.filter((intervalObject) => intervalObject !== pollIntervalObject),
      );
    };
  }, [pollInterval, setState]);
}
