enum Tabs {
  General = 'general',
  Members = 'members',
  Billing = 'billing',
  ActivityFeed = 'activity_feed',
  SystemPackages = 'system_packages',
  PythonEnvironment = 'python_environment',
  Secrets = 'secrets',
  Integrations = 'integrations',
  ApiKeys = 'api_keys',
}

type TabDefinition = {
  label: string;
  value: Tabs;
  component: JSX.Element;
  fullWidth?: boolean;
};

export { TabDefinition, Tabs };
