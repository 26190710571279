import { Reducer } from 'redux';

import uniq from 'lodash/uniq';

import ActionType from '../actions/ActionType';

interface PagesState {
  pages: Array<string>;
  loaded: boolean;
}

const pagesInit: PagesState = {
  pages: [],
  loaded: false,
};

const recentBasetenPagesReducer: Reducer<PagesState, any> = (
  state: PagesState = pagesInit,
  action: any,
) => {
  switch (action.type) {
    case ActionType.ROUTER_LOCATION_CHANGE: {
      return {
        ...state,
        pages: uniq([action.payload.location.pathname].concat(state.pages)).slice(0, 20),
      };
    }
    case ActionType.LOAD_RECENT_PAGES: {
      return {
        pages: action.pages,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export default recentBasetenPagesReducer;
