enum VariableType {
  Javascript = 'javascript',
  Python = 'python',
}

interface Variable {
  id?: string;
  type?: VariableType;
  name: string;
  code: string;
  paramRef: string;
}

export { VariableType, Variable };
