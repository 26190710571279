import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import each from 'lodash/each';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';

import moment from 'moment';

import { fetchOntology } from '../../../actions/udm';
import {
  AnyObject,
  Class,
  Field,
  fieldSchemaToType,
  udmTypeToString,
} from '../../../reducers/state/Udm';

export type Classes = Record<string, Class>;

function fieldsFromSchema(schema: AnyObject): Array<Field> {
  const props = schema.properties;
  const fields: Array<Field> = [];
  each(props, (propSchema, propName) => {
    const field: Field = {
      name: propName,
      typ: udmTypeToString(fieldSchemaToType(propSchema)),
      defaultValue: null,
      nullable: true,
    };
    fields[propSchema.index] = field;
  });
  return fields;
}

export function classFromResponse(classResponse: any): Class {
  const schema = JSON.parse(classResponse.schema);
  return {
    id: classResponse.id,
    name: classResponse.name,
    typename: classResponse.typename,
    tablename: classResponse.tablename,
    namespace: classResponse.namespace,
    description: classResponse.description,
    schema,
    fields: fieldsFromSchema(schema),
    created: moment(classResponse.created),
    modified: moment(classResponse.modified),
  };
}

const ClassesReducer = createSlice({
  name: 'classes-reducer',
  initialState: {} as Classes,
  reducers: {
    updateClass(state, { payload: klass }: PayloadAction<Class>) {
      return {
        ...state,
        [klass.id]: klass,
      };
    },
    deleteClass(state, { payload: classTypename }: PayloadAction<string>) {
      return omitBy(state, (klass) => klass.typename === classTypename);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOntology.fulfilled, (state, action) => {
      const { payload } = action;

      if (payload) {
        const { classes } = payload;

        const classesById = keyBy(classes, 'id');
        const classesToUpdate = mapValues(classesById, classFromResponse);

        return {
          ...state,
          ...classesToUpdate,
        };
      }

      return state;
    });
  },
});

export const { deleteClass, updateClass } = ClassesReducer.actions;

export default ClassesReducer.reducer;
