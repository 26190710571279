/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CodeFileFragment = { __typename?: 'CodeFileType', id: string | null | undefined, filepath: string, content: string, created: any | null | undefined, modified: any | null | undefined, versionId: string | null | undefined, entrypoints: Array<{ __typename?: 'FileEntrypoint', name: string | null | undefined, lineNo: number | null | undefined } | null | undefined> | null | undefined };

export const CodeFileFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CodeFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CodeFileType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"filepath"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"entrypoints"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","alias":{"kind":"Name","value":"lineNo"},"name":{"kind":"Name","value":"line_no"}}]}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}},{"kind":"Field","alias":{"kind":"Name","value":"versionId"},"name":{"kind":"Name","value":"version_id"}}]}}]} as unknown as DocumentNode<CodeFileFragment, unknown>;
export const namedOperations = {
  Fragment: {
    CodeFileFragment: 'CodeFileFragment'
  }
}
