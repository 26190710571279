import { CodeFile } from '@/store/slices/entities/Codebases/types';

import { RequirementsFile } from './types';

export const PYTHON_REQUIREMENTS_FILE_NAME = 'requirements.txt';

export const SYSTEM_PACKAGES_FILE_NAME = 'system-packages.txt';

export function checkIfRequirementsFile(
  fileId: string,
): fileId is typeof PYTHON_REQUIREMENTS_FILE_NAME | typeof SYSTEM_PACKAGES_FILE_NAME {
  return fileId === PYTHON_REQUIREMENTS_FILE_NAME || fileId === SYSTEM_PACKAGES_FILE_NAME;
}

export function isRequirementsFile(file: CodeFile | RequirementsFile): file is RequirementsFile {
  return file.id === PYTHON_REQUIREMENTS_FILE_NAME || file.id === SYSTEM_PACKAGES_FILE_NAME;
}

export function hasErrors(file: CodeFile | RequirementsFile): boolean {
  return !!file.errors?.length;
}
