import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function DotIcon({ size = 3, ...props }: SvgIconProps & { size?: number }) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx="8" cy="8" r={size} fill="currentColor" />
    </SvgIcon>
  );
}

export default DotIcon;
