import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function MailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66671 2.66666H13.3334C14.0667 2.66666 14.6667 3.26666 14.6667 3.99999V12C14.6667 12.7333 14.0667 13.3333 13.3334
        13.3333H2.66671C1.93337 13.3333 1.33337 12.7333 1.33337 12V3.99999C1.33337 3.26666 1.93337 2.66666 2.66671 2.66666Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 4L8.00004 8.66667L1.33337 4"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default MailIcon;
