import Left from './Left';
import Right from './Right';
import { Header } from './styled';
import { GlobalHeaderProps } from './types';

const GlobalHeader = ({
  logoStyle = 'icon',
  padding = 'default',
  page,
  breadcrumbs,
}: GlobalHeaderProps) => {
  return (
    <Header padding={padding}>
      <Left logoStyle={logoStyle} breadcrumbs={breadcrumbs} page={page} />
      <Right />
    </Header>
  );
};

export default GlobalHeader;
