export enum ComponentConfigNodeType {
  Ref,
  ParamMap,
  Datasource,
  Variable,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Action,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ValueSupplier,
  ResolvedData,
}

export type RefNode = {
  type: ComponentConfigNodeType.Ref;
  id: string;
};
export type ParamMapNode = {
  type: ComponentConfigNodeType.ParamMap;
  id: string;
};
export type DatasourceNode = {
  type: ComponentConfigNodeType.Datasource;
  id: string;
};
export type VariableNode = {
  type: ComponentConfigNodeType.Variable;
  id: string;
};
export type ActionNode = {
  type: ComponentConfigNodeType.Action;
  id: string;
};
export type ValueSupplierNode = {
  type: ComponentConfigNodeType.ValueSupplier;
  id: string;
  subType: 'prop' | 'chrome';
};
export type ResolvedDataNode = {
  type: ComponentConfigNodeType.ResolvedData;
  id: string;
  subType: 'field';
};

export type ComponentConfigNode =
  | RefNode
  | ParamMapNode
  | DatasourceNode
  | VariableNode
  | ActionNode
  | ValueSupplierNode
  | ResolvedDataNode;
