import unreachable from '@/utils/unreachable';

import { ReleaseEnv } from '../types/releaseEnv';

export type ReleaseEnvRouteParameter = 'prod' | 'draft';

export type MatchParams = {
  env: ReleaseEnvRouteParameter | null;
};

export function releaseEnvParamToEnum(param: ReleaseEnvRouteParameter): ReleaseEnv {
  switch (param) {
    case 'draft':
      return ReleaseEnv.Draft;
    case 'prod':
      return ReleaseEnv.Production;
    default:
      unreachable(param);
  }
}

export function releaseEnvEnumToParam(releaseEnv: ReleaseEnv): ReleaseEnvRouteParameter {
  switch (releaseEnv) {
    case ReleaseEnv.Draft:
      return 'draft';
    case ReleaseEnv.Production:
      return 'prod';
    default:
      unreachable(releaseEnv);
  }
}
