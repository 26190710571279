import { Stack } from '@mui/material';

import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

const ModelListContainer = styled(Stack)(({ theme }) => ({
  border: getBorder(theme.palette.border.default),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  height: 300,
  overflowY: 'auto',
  position: 'relative',
}));

const ModelListSection = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ModelListHeader = styled(ModelListSection)(({ theme }) => ({
  backgroundColor: theme.palette.background.subtle,
  borderBottom: getBorder(theme.palette.border.default),
  gap: theme.spacing(2),
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

const ModelListContent = styled(ModelListSection)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  gap: theme.spacing(1),
  flex: 1,
}));

export { ModelListContainer, ModelListHeader, ModelListContent };
