import BrandMarkIcon from '@/components/Icons/BrandMarkIcon';
import GitHubFilledIcon from '@/components/Icons/GitHubFilledIcon';
import GoogleIcon from '@/components/Icons/GoogleIcon';

import { SocialNetwork } from './types';

const socialNetworks: Record<string, SocialNetwork> = {
  google: {
    icon: GoogleIcon,
    link: '/auth/login/google-oauth2',
    name: 'Google',
  },
  github: {
    icon: GitHubFilledIcon,
    link: '/auth/login/github',
    name: 'GitHub',
  },
  test: {
    icon: BrandMarkIcon,
    link: '/auth/login/test',
    name: 'Test Provider',
  },
};

export { socialNetworks };
