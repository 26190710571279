import { Reducer } from 'redux';

import ActionType, { RpcActionPrefix } from '../actions/ActionType';

export enum PublishFlowScreen {
  UnpublishedCheck = 1,
  Publish = 2,
}

export interface VersionToPublish {
  major: string;
  minor: string;
  patch: string;
}

export interface PreReleaseData {
  udmChangesToPublish: boolean;
  requirementsChangesToPublish: boolean;
  workflowHasUndeployedChanges: boolean;
  versionToPublish: VersionToPublish;
}

export interface PublishSelections {
  publishUdmChanges: boolean;
  publishRequirementsChanges: boolean;
  version?: string;
  versionDescription: string;
}

export interface PublishFlowState {
  show: boolean;
  loading: boolean;
  screen: PublishFlowScreen;
  workflowId?: string;
  preReleaseState?: PreReleaseData;
  publishSelections: PublishSelections;
  error?: string;
  refreshId: number;
}

const PublishFlowStateInit: PublishFlowState = {
  show: false,
  loading: false,
  screen: PublishFlowScreen.UnpublishedCheck,
  refreshId: 0,
  preReleaseState: null,
  error: null,
  publishSelections: {
    publishRequirementsChanges: false,
    publishUdmChanges: false,
    versionDescription: '',
  },
};

function preReleaseDataFromBackendResponse(resp: any): PreReleaseData {
  const {
    preReleaseData,
    workflow: { hasUndeployedChanges },
  } = resp;
  return {
    udmChangesToPublish: preReleaseData.udmHasUndeployedChanges,
    requirementsChangesToPublish: preReleaseData.requirementHasUndeployedChanges,
    workflowHasUndeployedChanges: hasUndeployedChanges,
    versionToPublish: {
      major: preReleaseData.nextReleaseSemverMajor,
      minor: preReleaseData.nextReleaseSemverMinor,
      patch: preReleaseData.nextReleaseSemverPatch,
    },
  };
}

function areOutstandingChanges(preReleaseData: PreReleaseData): boolean {
  return (
    preReleaseData.requirementsChangesToPublish ||
    preReleaseData.udmChangesToPublish ||
    preReleaseData.workflowHasUndeployedChanges
  );
}

const publishFlowReducer: Reducer<PublishFlowState, any> = (
  state = PublishFlowStateInit,
  action: any,
) => {
  switch (action.type) {
    case ActionType.SHOW_PUBLISH_FLOW:
      return {
        ...state,
        ...PublishFlowStateInit,
        show: true,
        workflowId: action.workflowId,
        refreshId: state.refreshId + 1,
      };
    case ActionType.HIDE_PUBLISH_FLOW:
      return {
        ...state,
        show: false,
      };
    case `${RpcActionPrefix.FETCH_PRE_PUBLISH_STATE}.Start`:
      return {
        ...state,
        loading: true,
      };
    case `${RpcActionPrefix.FETCH_PRE_PUBLISH_STATE}.Done`: {
      const preReleaseData = preReleaseDataFromBackendResponse(action.payload);
      if (!areOutstandingChanges(preReleaseData)) {
        return {
          ...state,
          error: 'All changes to workflow are already published.',
          show: false,
        };
      }
      let page = PublishFlowScreen.UnpublishedCheck;
      if (!(preReleaseData.requirementsChangesToPublish || preReleaseData.udmChangesToPublish)) {
        page = PublishFlowScreen.Publish;
      }
      return {
        ...state,
        loading: false,
        preReleaseState: preReleaseData,
        screen: page,
        publishSelections: {
          ...state.publishSelections,
          version: preReleaseData.versionToPublish.major,
          publishUdmChanges: preReleaseData.udmChangesToPublish,
          publishRequirementsChanges: preReleaseData.requirementsChangesToPublish,
        },
      };
    }
    case `${RpcActionPrefix.FETCH_PRE_PUBLISH_STATE}.Failed`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case `${RpcActionPrefix.PUBLISH}.Done`:
      return {
        ...state,
        show: false,
      };
    case `${RpcActionPrefix.PUBLISH}.Failed`:
      return {
        ...state,
        show: false,
      };
    case ActionType.SHOW_PUBLISH_FLOW_SCREEN:
      return {
        ...state,
        screen: action.screen,
      };
    case ActionType.CLEAR_PUBLISH_ERROR:
      return {
        ...state,
        error: null,
      };
    case ActionType.SET_PUBLISH_SELECTIONS:
      return {
        ...state,
        publishSelections: {
          ...state.publishSelections,
          ...action.publishSelections,
        },
      };
    default:
      return state;
  }
};

export default publishFlowReducer;
