type ExistingOrganizationData = {
  adminName: string;
  emailDomain: string;
  workspaceName: string;
};

enum OnboardingStep {
  Profile,
  JoinExisting,
  RequestedToJoin,
  CreateWorkspace,
}

enum OnboardingEvent {
  DeployModel = 'onboarding_deploy_model',
  SelectObjective = 'onboarding_select_objective',
  CompleteProfile = 'signup_completed',
  NamedWorkflow = 'named_workflow',
  CreateApp = 'onboarding_create_app',
  ChooseStarter = 'onboarding_choose_starter',
  FineTuneOSModel = 'onboarding_fine_tune_os_model',
  // TODO: track Dismiss once an escape hatch is added to the flow
  Dismiss = 'onboarding_dismiss',
}

type SignupArgs = {
  firstName: string;
  lastName: string;
  useCase?: string;
  workspaceName?: string;
  companyName?: string;
  companyWebsite?: string;
  otherUseCase?: string;
};

type OnboardingProps = {
  providedStep?: OnboardingStep;
};

export { OnboardingEvent, ExistingOrganizationData, OnboardingStep, SignupArgs, OnboardingProps };
