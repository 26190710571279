import { Reducer } from 'redux';

import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import { ExternalConnectionConnectionType } from '@/graphql';

import { RpcActionPrefix } from '../actions/ActionType';
import { DeploymentEnvName } from './Types';

interface Column {
  dataType: string;
  columnName: string;
}

type Table = Column[];

interface DataSchema {
  tables: Record<string, Table>;
}

export interface ExternalConnection {
  id: string;
  connectionName: string;
  connectionType: ExternalConnectionConnectionType;
  dataSchema: DataSchema;
  created: string;
  deploymentEnv: DeploymentEnvName;
  isStreaming: boolean;
}

type ExternalConnections = Record<string, ExternalConnection>;

function columnFromBackendResponse(col: any): Column {
  return {
    dataType: col.data_type,
    columnName: col.column_name,
  };
}

function tableFromBackendResponse(table: any): Table {
  return map(table, columnFromBackendResponse);
}

function dataSchemeFromBackendResponse(dataSchema: any): DataSchema {
  return {
    tables: mapValues(dataSchema.tables, tableFromBackendResponse),
  };
}

function externalConnectionFromBackendResponse(externalConnection: any): ExternalConnection {
  return {
    id: externalConnection.id,
    connectionName: externalConnection.connectionName,
    connectionType: externalConnection.connectionType,
    dataSchema: dataSchemeFromBackendResponse(JSON.parse(externalConnection.dataSchema)),
    created: externalConnection.created,
    deploymentEnv:
      DeploymentEnvName[externalConnection.deploymentEnv as keyof typeof DeploymentEnvName],
    isStreaming: externalConnection.isStreaming,
  };
}

const externalConnectionsReducer: Reducer<ExternalConnections, any> = (
  state: ExternalConnections = {},
  action: any,
) => {
  switch (action.type) {
    case `${RpcActionPrefix.REFRESH_DATA_SOURCE}.Done`: {
      const { connections } = action.payload.createOrUpdateDataSource.dataSource;
      const updatedExternalConnections: Array<ExternalConnection> = map(
        connections,
        externalConnectionFromBackendResponse,
      );
      return {
        ...state,
        ...keyBy(updatedExternalConnections, 'id'),
      };
    }
    case `${RpcActionPrefix.REFRESH_DATA_SOURCES}.Done`: {
      const { payload } = action;
      const externalConnections: Record<string, ExternalConnection> = {};
      forEach(payload.dataSources, (dataSource) => {
        forEach(dataSource.connections, (connection) => {
          externalConnections[connection.id] = externalConnectionFromBackendResponse(connection);
        });
      });
      return {
        ...state,
        ...externalConnections,
      };
    }
    default:
      return state;
  }
};

export default externalConnectionsReducer;
