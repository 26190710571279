import { defaultPalette } from '@/theme';
import { ColorScale } from '@/theme/colors';

import { BadgeColorsMap, BadgeSize } from './types';

const COLORS: BadgeColorsMap = {
  primary: {
    color: defaultPalette.text.subtle,
    bgColor: defaultPalette.background.muted,
  },
  error: {
    color: ColorScale.mono.white,
    bgColor: defaultPalette.background.dangerEmphasis,
  },
  warn: {
    color: ColorScale.mono.black,
    bgColor: defaultPalette.background.warnEmphasis,
  },
  info: {
    color: ColorScale.mono.white,
    bgColor: defaultPalette.border.emphasis,
  },
  success: {
    color: ColorScale.mono.white,
    bgColor: defaultPalette.background.successEmphasis,
  },
};

const SIZES: Record<BadgeSize, string> = {
  small: '12px',
  medium: '16px',
  large: '24px',
};

export { COLORS, SIZES };
