import Stack from '@mui/material/Stack';

import { styled as muiStyled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

export const CodeBlock = muiStyled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.background.default,
  padding: theme.spacing(1, 1, 1, 2),
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: getBorder(theme.palette.border.default),
}));
