import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';

import { ReleaseEnv } from '@/types/releaseEnv';
import { handleStaleGraphQLError } from '@/utils/errorHandling';
import { Base64 } from 'js-base64';

import { workletSelector } from '../../../Selectors';
import { RpcActionPrefix } from '../../../actions/ActionType';
import { hydrateWorkflow } from '../../../actions/WorkflowAsyncActions';
import { BACKEND_WORKLET_ACTIONS } from '../../../actions/WorkletActions';
import { AsyncThunkConfig } from '../../../types';
import { updateWorkflow } from '../Workflows';
import {
  DeleteWorkletDocument,
  DisableWorkletScheduleDocument,
  DuplicateWorkletDocument,
  WorkletMutation,
  WorkletMutationDocument,
  WorkletMutationVariables,
} from './__generated__/mutations.generated';
import {
  CreateWorkletAsyncPayload,
  DeleteWorkletAsyncPayload,
  DisableWorkletSchedulePayload,
  DuplicateWorkletAsyncPayload,
  RenameWorkletAsyncPayload,
  Schedule,
  WorkletEntity,
  WorkletIdentifier,
  WorkletsMap,
} from './types';

const initialState: WorkletsMap = {
  [ReleaseEnv.Draft]: {},
  [ReleaseEnv.Production]: {},
};

// TODO(mike): export this for now until we get to refactoring the workletReducer
export const scheduleFromResponse = (backendSchedule: any) => {
  if (isEmpty(backendSchedule)) {
    return null;
  }
  const backendCron = backendSchedule.cron;
  return {
    displayTimezone: backendSchedule.display_timezone,
    cron: {
      minute: backendCron.minute,
      hour: backendCron.hour,
      dayOfWeek: backendCron.day_of_week,
      dayOfMonth: backendCron.day_of_month,
      monthOfYear: backendCron.month_of_year,
    },
    enabled: backendSchedule.enabled,
  } as Schedule;
};

const workletFromResponse = (backendWorklet: any) => {
  const nodeGraph = JSON.parse(backendWorklet.nodeGraph);
  return {
    _fetched: new Date(),
    created: backendWorklet.created,
    modified: backendWorklet.modified,
    allowedDomains: backendWorklet.allowedDomains,
    id: backendWorklet.id,
    name: backendWorklet.name,
    executeAsyncByDefault: backendWorklet.executeAsyncByDefault,
    nodes: backendWorklet.nodes,
    nodeGraph: {
      entryPoint: nodeGraph.entry_point,
      nodes: nodeGraph.nodes,
    },
    signature: backendWorklet.signature,
    schedule: scheduleFromResponse(backendWorklet.schedule),
    currentVersionId: backendWorklet.currentVersionId,
  } as WorkletEntity;
};

export const createWorklet = createAsyncThunk<
  WorkletEntity,
  CreateWorkletAsyncPayload,
  AsyncThunkConfig
>('worklets-entities/createWorklet', async ({ workflowId, name }, { dispatch, extra }) => {
  const action = {
    type: BACKEND_WORKLET_ACTIONS.CREATE,
    worklet_name: name,
    workflow_id: workflowId,
  };
  const { data } = await extra.apolloClient.mutate({
    mutation: WorkletMutationDocument,
    variables: {
      workletId: '',
      encodedAction: Base64.encode(JSON.stringify(action)),
      currentVersionId: '',
    },
  });
  // TODO(mike): can we remove need to hydrate after creation?
  await dispatch(hydrateWorkflow({ workflowId, releaseEnv: ReleaseEnv.Draft }, true));
  const created = data?.worklet_mutation.worklet;
  return workletFromResponse(created);
});

export const renameWorklet = createAsyncThunk<void, RenameWorkletAsyncPayload, AsyncThunkConfig>(
  'worklets-entities/renameWorklet',
  async ({ workletId, name, releaseEnv }, { dispatch, getState, extra }) => {
    const currentWorklet = workletSelector(getState(), { workletId, releaseEnv });
    const action = {
      type: 'rename',
      worklet_id: workletId,
      name,
    };

    // if (currentWorklet.name === name) {
    //   return;
    // }

    try {
      const { data } = await extra.apolloClient.mutate<WorkletMutation, WorkletMutationVariables>({
        mutation: WorkletMutationDocument,
        variables: {
          workletId,
          encodedAction: Base64.encode(JSON.stringify(action)),
          currentVersionId: currentWorklet.currentVersionId,
        },
      });
      const renamed = {
        ...data?.worklet_mutation.worklet,
        nodes: data?.worklet_mutation.worklet.nodes.map(({ id }) => id),
      };
      dispatch(
        updateWorkletEntity({ worklet: renamed, workletIdentifier: { workletId, releaseEnv } }),
      );
    } catch (ex) {
      handleStaleGraphQLError(dispatch, ex, 'rename worklet');
    }
  },
);

export const deleteWorklet = createAsyncThunk<string, DeleteWorkletAsyncPayload, AsyncThunkConfig>(
  'worklets-entities/deleteWorklet',
  async ({ workletId }, { dispatch, getState, extra }) => {
    try {
      await extra.apolloClient.mutate({
        mutation: DeleteWorkletDocument,
        variables: { workletId },
      });
    } catch (ex) {
      handleStaleGraphQLError(dispatch, ex, 'delete worklet');
    }

    return workletId;
  },
);

export const duplicateWorklet = createAsyncThunk<
  void,
  DuplicateWorkletAsyncPayload,
  AsyncThunkConfig
>(
  'worklets-entities/duplicateWorklet',
  async ({ workletId, workflowId }, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: DuplicateWorkletDocument,
      variables: {
        workletId,
      },
    });
    // // TODO(mike): can we do this without hydrateWorkflow?
    dispatch(hydrateWorkflow({ workflowId, releaseEnv: ReleaseEnv.Draft }, true));
  },
);

export const disableWorkletSchedule = createAsyncThunk<
  void,
  DisableWorkletSchedulePayload,
  AsyncThunkConfig
>('worklet-entities/disableSchedule', async ({ workletId }, { dispatch, getState, extra }) => {
  const { data } = await extra.apolloClient.mutate({
    mutation: DisableWorkletScheduleDocument,
    variables: {
      workletId,
    },
  });
  // TODO(mike): we are doing this to preserve the original functionality (passing
  // this action over to the `workletReducer`); until we refactor that fully
  dispatch({
    type: `${RpcActionPrefix.DISABLE_WORKLET_SCHDULE}.Done`,
    payload: data,
    meta: { workletId, releaseEnv: ReleaseEnv.Draft },
  });
});

const Worklets = createSlice({
  name: 'worklets-entities',
  initialState,
  reducers: {
    updateWorkletEntity(
      state,
      {
        payload: { workletIdentifier, worklet },
      }: PayloadAction<{ worklet: unknown; workletIdentifier: WorkletIdentifier }>,
    ) {
      state[workletIdentifier.releaseEnv][workletIdentifier.workletId] =
        workletFromResponse(worklet);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createWorklet.fulfilled, (state, action) => {
      const worklet = action.payload;
      state[ReleaseEnv.Draft][worklet.id] = worklet;
    });
    builder.addCase(deleteWorklet.fulfilled, (state, action) => {
      const workletId = action.payload;
      delete state[ReleaseEnv.Draft][workletId];
    });
    builder.addCase(updateWorkflow, (state, action) => {
      const { entities } = action.payload;
      if (entities.worklets) {
        Object.values(entities.worklets).forEach((response) => {
          const worklet = workletFromResponse(response);
          state[action.payload.workflowIdentifier.releaseEnv][worklet.id] = worklet;
        });
      }
    });
  },
});

export const { updateWorkletEntity } = Worklets.actions;

export default Worklets.reducer;
