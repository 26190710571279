import { styled as muiStyled } from '@mui/system';

import { FlexColumnContainer, FlexRowContainer } from '@/components/Layout';

const Container = muiStyled(FlexRowContainer)({
  height: '100%',
});

const Content = muiStyled(FlexColumnContainer)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  overflow: 'auto',
}));

const ToasterContainer = muiStyled('div')({});

export { Container, Content, ToasterContainer };
