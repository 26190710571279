import { AnyAction, MiddlewareAPI } from '@reduxjs/toolkit';

import { AppDispatch, RootState, ThunkExtras } from '../../types';

type EntityProcessorParams<TEntityIdentifier extends {}> = {
  /**
   * The predicate specifies which actions and resulting states require processing
   */
  predicate: (action: AnyAction, currState: RootState) => boolean;
  /**
   * Given an action that satisfies the predicate, returns the identity of the entity the action updates.
   * Note this object must only contain properties relevant to the identifier and not extraneous properties
   * like `TEntityIdentifier & { blah: string }`.
   */
  extractIdentifier: (action: AnyAction) => TEntityIdentifier | null;

  /**
   * Function which processes the entity
   */
  process: (
    entityIdentifier: TEntityIdentifier,
    middlewareAPI: MiddlewareAPI<AppDispatch, RootState> & { extra: ThunkExtras },
  ) => Promise<void>;

  /**
   * Function which runs when processing completes
   */
  onComplete?: (
    entityIdentifier: TEntityIdentifier,
    middlewareAPI: MiddlewareAPI<AppDispatch, RootState> & { extra: ThunkExtras },
  ) => void;

  /**
   * Time to delay the onset of service by. If null, service is triggered synchronously
   */
  debounceMs: number | null;
};

enum EntityState {
  /**
   * Currently being debounced
   */
  Waiting = 'waiting',
  /**
   * Currently being run. Another process hasn't been queued yet
   */
  Processing = 'processing',
  /**
   * Currently being run. Another process has been queued, meaning it will process again after it finishes (possibly followed by a delay)
   */
  ProcessingAndQueued = 'queued',
}

export { EntityProcessorParams, EntityState };
