import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function ChevronsUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.3332 7.33333L7.99984 4L4.6665 7.33333"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3332 12.0001L7.99984 8.66675L4.6665 12.0001"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default ChevronsUpIcon;
