import { Stack, Typography } from '@mui/material';

import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

const Container = styled(Stack)(({ theme }) => ({
  border: getBorder(theme.palette.border.default),
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius + 2,
  boxShadow: theme.shadows[2],
  overflow: 'hidden',
}));

const Header = styled(Stack)(({ theme }) => ({
  borderBottom: getBorder(theme.palette.border.default),
  background: theme.palette.background.subtle,
  padding: theme.spacing(1.5, 2),
}));

const Bash = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  userSelect: 'none',
  fontFamily: theme.typography.monoFontFamily,
  color: theme.palette.text.subtle,
}));

export { Container, Header, Bash };
