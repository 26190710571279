const ActionType = Object.freeze({
  GROUP_SELECTED_NODES: 'GROUP_SELECTED_NODES',
  CLEAR_NODES_TO_GROUP: 'CLEAR_NODES_TO_GROUP',
  NODE_UPDATE_SRC: 'NODE_UPDATE_SRC',
  NODE_DIRTY: 'NODE_DIRTY',
  SET_GRAPH: 'SET_GRAPH',
  NODE_RESET_SRC: 'NODE_RESET_SRC',
  NODE_UPDATE: 'NODE_UPDATE',
  ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  LOAD_RECENT_PAGES: 'LOAD_RECENT_PAGES',
  VIEW_BUILDER_APPLICATION_FETCH_COMPLETED: 'VIEW_BUILDER_APPLICATION_FETCH_COMPLETED',
  VIEW_BUILDER_APPLICATION_FETCH_ERROR: 'VIEW_BUILDER_APPLICATION_FETCH_ERROR',
  VIEW_BUILDER_UPDATE_CONTEXT: 'VIEW_BUILDER_UPDATE_CONTEXT',
  ADD_CHILD_TO: 'ADD_CHILD_TO',
  SELECT_GROUP: 'SELECT_GROUP',
  UNSELECT_GROUP: 'UNSELECT_GROUP',
  SELECT_NODE: 'SELECT_NODE',
  SELECT_NODE_TO_GROUP: 'SELECT_NODE_TO_GROUP',
  SELECT_NOTHING: 'SELECT_NOTHING',
  SELECT_WORKLET: 'SELECT_WORKLET',
  SET_WORKLET_ID: 'SET_WORKLET_ID',
  CLEAR_WORKLET: 'CLEAR_WORKLET',
  SHOW_PANEL: 'SHOW_PANEL',
  EDIT_NODE_SRC: 'EDIT_NODE_SRC',
  MINIMIZE_NODE_SRC_EDITOR: 'MINIMIZE_NODE_SRC_EDITOR',
  CLASSVIEW_CLEAR_CLASS: 'CLASSVIEW_CLEAR_CLASS',
  CLASSVIEW_SET_CLASS: 'CLASSVIEW_SET_CLASS',
  CLASSVIEW_ADD_EMPTY_FIELD: 'CLASSVIEW_ADD_EMPTY_FIELD',
  CLASSVIEW_REMOVE_EXISTING_FIELD: 'CLASSVIEW_REMOVE_EXISTING_FIELD',
  CLASSVIEW_REMOVE_NEW_FIELD: 'CLASSVIEW_REMOVE_NEW_FIELD',
  CLASSVIEW_RENAME_NEW_FIELD: 'CLASSVIEW_RENAME_NEW_FIELD',
  CLASSVIEW_RENAME_EXISTING_FIELD: 'CLASSVIEW_RENAME_EXISTING_FIELD',
  CLASSVIEW_CHANGE_NEW_FIELD_DATA_TYPE: 'CLASSVIEW_CHANGE_NEW_FIELD_DATA_TYPE',
  CLASSVIEW_UPDATE_CLASS_NAME: 'CLASSVIEW_UPDATE_CLASS_NAME',
  CLASSVIEW_UPDATE_CLASS_TYPENAME: 'CLASSVIEW_UPDATE_CLASS_TYPENAME',
  CLASSVIEW_UPDATE_CLASS_DESCRIPTION: 'CLASSVIEW_UPDATE_CLASS_DESCRIPTION',
  CLASSVIEW_SHOW_CANCEL_MODAL: 'CLASSVIEW_SHOW_CANCEL_MODAL',
  CLASSVIEW_CLOSE_CANCEL_MODAL: 'CLASSVIEW_CLOSE_CANCEL_MODAL',
  CLASSVIEW_SHOW_DELETE_WARN_MODAL: 'CLASSVIEW_SHOW_DELETE_WARN_MODAL',
  CLASSVIEW_CLOSE_DELETE_WARN_MODAL: 'CLASSVIEW_CLOSE_DELETE_WARN_MODAL',
  CLASSVIEW_SHOW_VALIDATION_ERROR_MODAL: 'CLASSVIEW_SHOW_VALIDATION_ERROR_MODAL',
  CLASSVIEW_CLOSE_VALIDATION_ERROR_MODAL: 'CLASSVIEW_CLOSE_VALIDATION_ERROR_MODAL',
  SET_CONSOLE_SECTION: 'SET_CONSOLE_SECTION',
  SET_CONSOLE_HEIGHT: 'SET_CONSOLE_HEIGHT',
  SET_CONSOLE_FULLSCREEN: 'SET_CONSOLE_FULLSCREEN',
  SET_EDITOR_HEIGHT: 'SET_EDITOR_HEIGHT',
  SET_EDITOR_FULLSCREEN: 'SET_EDITOR_FULLSCREEN',
  SET_LAST_WORKLET_INPUT: 'SET_LAST_WORKLET_INPUT',
  SET_LAST_NODE_INPUT: 'SET_LAST_NODE_INPUT',
  SHOW_DATA_SOURCE: 'SHOW_DATA_SOURCE',
  HIDE_DATA_SOURCE: 'HIDE_DATA_SOURCE',
  SHOW_CREATE_NEW_DATA_SOURCE_MODAL: 'SHOW_CREATE_NEW_DATA_SOURCE_MODAL',
  SHOW_PUBLISH_FLOW: 'SHOW_PUBLISH_FLOW',
  HIDE_PUBLISH_FLOW: 'HIDE_PUBLISH_FLOW',
  SET_PUBLISH_SELECTIONS: 'SET_PUBLISH_SELECTIONS',
  CLEAR_PUBLISH_ERROR: 'CLEAR_PUBLISH_ERROR',
  SHOW_PUBLISH_FLOW_SCREEN: 'SHOW_PUBLISH_FLOW_SCREEN',
  UPDATE_COMPONENT_FIELD: 'UPDATE_COMPONENT_FIELD',
  CLEAR_COMPONENTS_STATE: 'CLEAR_COMPONENTS_STATE',
  UPDATE_COMPONENT_FIELDS: 'UPDATE_COMPONENT_FIELDS',
  UPDATE_COMPONENT_DATA: 'UPDATE_COMPONENT_DATA',
  UPDATE_COMPONENT_STATE: 'UPDATE_COMPONENT_STATE',
  MARK_COMPONENT_DATA_SOURCES_DIRTY: 'MARK_COMPONENT_DATA_SOURCES_DIRTY',
  MARK_COMPONENT_DATA_SOURCES_INITIALIZED: 'MARK_COMPONENT_DATA_SOURCES_INITIALIZED',
  MARK_COMPONENT_DATA_SOURCES_CLEAN: 'MARK_COMPONENT_DATA_SOURCES_CLEAN',
  RENAME_COMPONENT_DATA_SOURCE: 'RENAME_COMPONENT_DATA_SOURCE',
  SET_WORKLET_TESTING_SECTION: 'SET_WORKLET_TESTING_SECTION',
  WORKLET_TEST_CASES_FETCH_START: 'WORKLET_TEST_CASES_FETCH_START',
  WORKLET_TEST_CASES_FETCH_DONE: 'WORKLET_TEST_CASES_FETCH_DONE',
  WORKLET_TEST_CASES_FETCH_ERROR: 'WORKLET_TEST_CASES_FETCH_ERROR',
  WORKLET_TEST_CASES_SELECT_TEST: 'WORKLET_TEST_CASES_SELECT_TEST',
  WORKLET_TEST_CASES_RUNNING_START: 'WORKLET_TEST_CASES_RUNNING_START',
  WORKLET_TEST_CASES_RUNNING_SUCCESS: 'WORKLET_TEST_CASES_RUNNING_SUCCESS',
  WORKLET_TEST_CASES_RUNNING_ERROR: 'WORKLET_TEST_CASES_RUNNING_ERROR',
  WORKLET_TEST_CASES_SELECT_ALL_TEST: 'WORKLET_TEST_CASES_SELECT_ALL_TEST',
  SINGLE_WORKLET_TEST_CASE_FETCH_DONE: 'SINGLE_WORKLET_TEST_CASE_FETCH_DONE',
  WORKLET_TEST_CASES_DELETE: 'WORKLET_TEST_CASES_DELETE',
  NODE_HISTORY_FETCH: 'NODE_HISTORY_FETCH',
  SET_NODE_HISTORY_SELECTION: 'SET_NODE_HISTORY_SELECTION',
  SET_CONTEXT_COMPLETION_OBJECT: 'SET_CONTEXT_COMPLETION_OBJECT',
  WRAPPED_COMPONENT_ACTION: 'WRAPPED_COMPONENT_ACTION',
  HF_TASK_MODEL_FETCH_DONE: 'HF_TASK_MODEL_FETCH_DONE',
  SET_SAMPLE_WORKLET_INPUT: 'SET_SAMPLE_WORKLET_INPUT',
  SET_SAMPLE_NODE_INPUT: 'SET_SAMPLE_NODE_INPUT',
  GET_WORKLET_RUN_OUTPUT_DATA: 'GET_WORKLET_RUN_OUTPUT_DATA',
});

export const RpcActionPrefix = Object.freeze({
  CREATE_EXTERNAL_CONNECTION: 'CREATE_EXTERNAL_CONNECTION',
  CREATE_QUERY: 'CREATE_QUERY',
  DUPLICATE_QUERY: 'DUPLICATE_QUERY',
  DUPLICATE_WORKFLOW: 'DUPLICATE_WORKFLOW',
  EXECUTE_NODE: 'EXECUTE_NODE',
  EXECUTE_WORKLET: 'EXECUTE_WORKLET',
  EXECUTE_WORKLET_WITH_DEBUG_LOGGING: 'EXECUTE_WORKLET_WITH_DEBUG_LOGGING',
  SAVE_NODE: 'SAVE_NODE',
  UPDATE_USER: 'UPDATE_USER',
  WORKLET_ADD_CHILD_NODE: 'WORKLET_ADD_CHILD_NODE',
  WORKLET_ADD_PARENT_NODE: 'WORKLET_ADD_PARENT_NODE',
  WORKLET_ADD_ROOT: 'WORKLET_ADD_ROOT',
  WORKLET_DELETE_NODE: 'WORKLET_DELETE_NODE',
  WORKLET_DELETE: 'WORKLET_DELETE',
  WORKLET_DUPLICATE: 'WORKLET_DUPLICATE',
  WORKLET_CHANGE_ATOM_TYPE: 'WORKLET_CHANGE_ATOM_TYPE',
  WORKLET_SET_EXECUTE_ASYNC_BY_DEFAULT: 'WORKLET_SET_EXECUTE_ASYNC_BY_DEFAULT',
  FETCH_ONTOLOGY: 'FETCH_ONTOLOGY',
  UDM_MUTATION: 'UDM_MUTATION',
  UDM_CLASS_BATCH_MUTATION: 'UDM_CLASS_BATCH_MUTATION',
  REFRESH_DATA_SOURCES: 'REFRESH_DATA_SOURCES',
  REFRESH_RELEASES: 'REFRESH_RELEASES',
  REFRESH_DATA_SOURCE: 'REFRESH_DATA_SOURCE',
  DELETE_DATA_SOURCE: 'DELETE_DATA_SOURCE',
  FETCH_PRE_PUBLISH_STATE: 'FETCH_PRE_PUBLISH_STATE',
  TEST_EXTERNAL_CONNECTION: 'TEST_EXTERNAL_CONNECTION',
  PUBLISH: 'PUBLISH',
  FETCH_RELEASE_DEPLOYMENT: 'FETCH_RELEASE_DEPLOYMENT',
  CREATE_OR_UPDATE_WORKLET_SCHDULE: 'CREATE_OR_UPDATE_WORKLET_SCHDULE',
  DISABLE_WORKLET_SCHDULE: 'DISABLE_WORKLET_SCHDULE',
  SET_ALLOWED_DOMAINS: 'set_allowed_domains',
});

export default ActionType;
