/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateJoinExistingSettingMutationVariables = Types.Exact<{
  joinExistingSetting: Types.JoinExistingSetting;
}>;


export type UpdateJoinExistingSettingMutation = { __typename?: 'Mutation', updateOrganization: { __typename?: 'UpdateOrganization', organization: { __typename?: 'OrganizationType', joinExistingSetting: Types.OrganizationJoinExistingSetting } | null | undefined } | null | undefined };


export const UpdateJoinExistingSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateJoinExistingSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"joinExistingSetting"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JoinExistingSetting"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateOrganization"},"name":{"kind":"Name","value":"update_organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"join_existing_setting"},"value":{"kind":"Variable","name":{"kind":"Name","value":"joinExistingSetting"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"joinExistingSetting"},"name":{"kind":"Name","value":"join_existing_setting"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateJoinExistingSettingMutation, UpdateJoinExistingSettingMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdateJoinExistingSetting: 'UpdateJoinExistingSetting'
  }
}

export type UpdateJoinExistingSettingMutationFn = Apollo.MutationFunction<UpdateJoinExistingSettingMutation, UpdateJoinExistingSettingMutationVariables>;

/**
 * __useUpdateJoinExistingSettingMutation__
 *
 * To run a mutation, you first call `useUpdateJoinExistingSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJoinExistingSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJoinExistingSettingMutation, { data, loading, error }] = useUpdateJoinExistingSettingMutation({
 *   variables: {
 *      joinExistingSetting: // value for 'joinExistingSetting'
 *   },
 * });
 */
export function useUpdateJoinExistingSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJoinExistingSettingMutation, UpdateJoinExistingSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJoinExistingSettingMutation, UpdateJoinExistingSettingMutationVariables>(UpdateJoinExistingSettingDocument, options);
      }
export type UpdateJoinExistingSettingMutationHookResult = ReturnType<typeof useUpdateJoinExistingSettingMutation>;
export type UpdateJoinExistingSettingMutationResult = Apollo.MutationResult<UpdateJoinExistingSettingMutation>;
export type UpdateJoinExistingSettingMutationOptions = Apollo.BaseMutationOptions<UpdateJoinExistingSettingMutation, UpdateJoinExistingSettingMutationVariables>;