import { styled as muiStyled } from '@mui/system';

import get from 'lodash/get';

import styled from 'styled-components';

import { DotProps } from './types';

// TODO(vahan) other styled-components that wrapping FlexBox working poorly with MUI's styled.
// This should be replaced with muiStyled once we get rid of all styled-components
const FlexBox = styled('div')({
  display: 'flex',
});

const Ellipsis = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Dot = muiStyled('span', {
  shouldForwardProp: (propName) => propName !== 'bgColor',
})<DotProps>(({ theme, bgColor }) => {
  return {
    margin: '0 6px',
    '&:after': {
      content: '""',
      width: 5,
      height: 5,
      display: 'inline-block',
      backgroundColor: get(theme.palette, bgColor, bgColor) || theme.palette.text.subtle,
      borderRadius: '50%',
      verticalAlign: 'middle',
    },
  };
});

export { FlexBox, Ellipsis, Dot };
