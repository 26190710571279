import { Image } from './styled';
import type { LogoProps } from './types';

function Logo({ width, height = 24 }: LogoProps) {
  return (
    <Image
      sx={{ width, height, objectFit: 'contain' }}
      src="/static/logos/Baseten_Logo_Primary.svg"
    />
  );
}

export default Logo;
