import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { AppViewState } from './types';
import { LoadingState } from './types';

const initialState: AppViewState = {
  applications: LoadingState.None,
  userInfo: LoadingState.None,
  ontologies: LoadingState.None,
  queries: LoadingState.None,
};

const AppViewReducer = createSlice({
  name: 'app-view',
  initialState,
  reducers: {
    appsFetchStart(state) {
      state.applications = LoadingState.Loading;
    },
    appsFetchCompleted(state) {
      state.applications = LoadingState.Completed;
    },
    appsFetchFailed(state, action: PayloadAction<string>) {
      state.applications = LoadingState.Errored;
    },
    queriesFetchStart(state) {
      state.queries = LoadingState.Loading;
    },
    queriesFetchCompleted(state) {
      state.queries = LoadingState.Completed;
    },
    queriesFetchFailed(state, action: PayloadAction<string>) {
      state.queries = LoadingState.Errored;
    },
    userInfoFetchStart(state) {
      state.userInfo = LoadingState.Loading;
    },
    userInfoFetchCompleted(state) {
      state.userInfo = LoadingState.Completed;
    },
    userInfoFetchFailed(state, action: PayloadAction<string>) {
      state.userInfo = LoadingState.Errored;
    },
    ontologiesFetchStart(state) {
      state.ontologies = LoadingState.Loading;
    },
    ontologiesFetchCompleted(state) {
      state.ontologies = LoadingState.Completed;
    },
    ontologiesFetchFailed(state, action: PayloadAction<string>) {
      state.ontologies = LoadingState.Errored;
    },
  },
});

export const {
  appsFetchCompleted,
  appsFetchFailed,
  appsFetchStart,
  ontologiesFetchCompleted,
  ontologiesFetchFailed,
  ontologiesFetchStart,
  queriesFetchCompleted,
  queriesFetchFailed,
  queriesFetchStart,
  userInfoFetchCompleted,
  userInfoFetchFailed,
  userInfoFetchStart,
} = AppViewReducer.actions;

export { LoadingState as AppViewLoadingState, AppViewState };

export default AppViewReducer.reducer;
