const styles = {
  '.SnackbarItem-contentRoot': {
    // this is the root element of the SnackbarItem component
    // styles here will be applied to all SnackbarItem components
    // also to style "variant: 'default'" you can use this selector
    borderRadius: '8px !important',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12) !important',

    '&.SnackbarItem-lessPadding': {
      paddingLeft: '16px !important',
    },
  },

  '.SnackbarItem-variantSuccess': {
    backgroundColor: '#009959 !important',
    border: '1px solid #007B47 !important',
    '.SnackbarItem-message': {
      gap: '12px',
      fontSize: '14px',
    },
  },

  '.SnackbarItem-variantError': {
    backgroundColor: '#B83530 !important',
    border: '1px solid #932B27 !important',
    '.SnackbarItem-message': {
      gap: '12px',
      fontSize: '14px',
    },
  },

  '.SnackbarItem-variantInfo': {
    backgroundColor: '#1A5ECC !important',
    border: '1px solid #144799 !important',
    '.SnackbarItem-message': {
      gap: '12px',
      fontSize: '14px',
    },
  },

  '.SnackbarItem-variantWarning': {
    backgroundColor: '#CC9900 !important',
    border: '1px solid #A37A00 !important',
    '.SnackbarItem-message': {
      // color: '#523D00',
      gap: '12px',
      fontSize: '14px',
    },
  },
};

export { styles };
