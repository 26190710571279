import posthog from 'posthog-js';

import { PosthogIdentifyPropertiesType } from './types';

function initializePosthog() {
  if (!ENV_CONFIG.POSTHOG_KEY || !ENV_CONFIG.POSTHOG_API_HOST) {
    return;
  }

  posthog.init(ENV_CONFIG.POSTHOG_KEY, {
    api_host: ENV_CONFIG.POSTHOG_API_HOST,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

function capturePosthogEvent(eventName: string) {
  posthog.capture(eventName);
}

function identifyPosthogUser(userId: string, properties: PosthogIdentifyPropertiesType) {
  posthog.identify(userId, properties);
}

function resetPosthogUser() {
  posthog.reset();
}

export { initializePosthog, capturePosthogEvent, identifyPosthogUser, resetPosthogUser };
export { usePosthogIdentify, usePosthogEvent } from './hooks';
export { PosthogEvents } from './types';
