import { ChangeEvent, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { Button, Emoji, Group, Radio, Text } from './styled';
import type { RadioButtonGroupContext, RadioButtonGroupProps, RadioButtonProps } from './types';

const ButtonGroupContext = createContext<RadioButtonGroupContext>({
  selectedValue: null,
  name: null,
  onChange: (_: string) => {},
  size: 'normal',
});

function RadioButtonGroup({
  children,
  selectedValue,
  name,
  defaultValue,
  wrapped,
  size,
  onChange,
}: RadioButtonGroupProps) {
  // For handling local state if no controlled props (selectedValue, onChange) are passed
  const [selected, setSelected] = useState<string | null>(defaultValue);

  return (
    <ButtonGroupContext.Provider
      value={{
        name,
        size,
        onChange: onChange ?? setSelected,
        selectedValue: selectedValue ?? selected,
      }}
    >
      <Group
        sx={(theme) => ({
          flexWrap: wrapped ? 'wrap' : 'no-wrap',
          gap: theme.spacing(size === 'small' ? 1 : 2),
        })}
      >
        {children}
      </Group>
    </ButtonGroupContext.Provider>
  );
}

RadioButtonGroup.defaultProps = {
  size: 'normal',
};

function RadioButton({
  value,
  children,
  flex,
  direction,
  testId,
  emoji,
  alert = false,
  ...props
}: RadioButtonProps) {
  const { size, ...ctx } = useContext(ButtonGroupContext);

  const name = ctx.name ?? props.name;
  const id = useMemo(() => `${name}~${value}`, [name, value]);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        ctx.onChange(value);
      }
    },
    [ctx, value],
  );

  return (
    <>
      <Radio
        name={name}
        checked={value === ctx.selectedValue}
        value={value}
        onChange={handleChange}
        id={id}
        type="radio"
        alert={alert}
      />
      <Button htmlFor={id} size={size} style={{ flex }} data-cy={testId ?? `radio-btn-${value}`}>
        <Stack
          direction={direction}
          spacing={direction === 'column' ? 1 : 3}
          justifyContent={direction === 'column' ? 'start' : 'start'}
          alignItems={direction === 'column' ? 'start' : 'center'}
        >
          {emoji && <Emoji>{emoji}</Emoji>}
          <Stack spacing={1} direction="column" justifyContent="center" alignItems="start">
            {typeof children === 'string' ? <Text>{children}</Text> : children}
          </Stack>
        </Stack>
      </Button>
    </>
  );
}

RadioButton.defaultProps = {
  direction: 'row',
  size: 'normal',
};

export { RadioButton, RadioButtonGroup };
