import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';

import { enqueueNotification } from '@/store/slices/ui/Notifier';
import { AppDispatch } from '@/store/types';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

const RELOAD_TEXT = 'Please make a copy of your changes and refresh the page.';
const GRAPHQL_ERROR_REGEXP = /You are editing a stale version/;

function isStaleGraphQLErrorMessage({ message }: Error) {
  return message.includes('stale');
}

function isQueryUniquenessGraphQLErrorMessage({ message }: Error) {
  return message.includes('query');
}

// HACK(amir): Quick hack to get rid of the string graphene adds to all errors. Ultimately we'll be sending down
// more structured errors with error codes and translate those to displayable error messages. In the meantime this
// hack would do.

function cleanErrorMessage(error: Error & { graphQLErrors?: Array<Error> }) {
  let { message } = error;

  if (message.match(GRAPHQL_ERROR_REGEXP) && !isEmpty(error.graphQLErrors)) {
    message = message.replace('GraphQL error: ', '');
  }

  return message;
}

function handleQueryErrors(
  dispatch: AppDispatch | ThunkDispatch<unknown, unknown, AnyAction>,
  error: Error,
  action: string,
  bubble: boolean = true,
) {
  if (isQueryUniquenessGraphQLErrorMessage(error)) {
    const message = `${error.message.replace('GraphQL error: ', '')}.`;
    dispatch(enqueueNotification({ message, variant: 'error' }));

    if (bubble) {
      throw error;
    }
  } else {
    handleStaleGraphQLError(dispatch, error, action, bubble);
  }
}

function handleStaleGraphQLError(
  dispatch: AppDispatch | ThunkDispatch<unknown, unknown, AnyAction>,
  error: Error,
  action: string,
  bubble: boolean = true,
) {
  if (isStaleGraphQLErrorMessage(error)) {
    const message = `Failed to ${action}. ${error.message.replace(
      'GraphQL error: ',
      '',
    )}. ${RELOAD_TEXT}`;
    dispatch(enqueueNotification({ message, variant: 'error' }));

    if (bubble) {
      throw error;
    }
  } else {
    throw error; // bubble error up the stack
  }
}

export function getFirstGraphQLError(error: Error): GraphQLError | undefined {
  return (error as ApolloError | undefined)?.graphQLErrors?.[0];
}

function getCleanResourceValidationErrorOrDefault(error: GraphQLError) {
  if (error?.extensions == null) {
    return error.message;
  }
  if (error?.extensions.code === 'RESOURCE_VALIDATION_FAILED') {
    if (error.extensions.description) {
      return (error.extensions.description as string).replace?.('Field __all__ is invalid: ', '');
    }
  }
  return error?.extensions.description ?? error.message;
}

export {
  cleanErrorMessage,
  handleQueryErrors,
  handleStaleGraphQLError,
  getCleanResourceValidationErrorOrDefault,
};
