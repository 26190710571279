import useAsOperator from './useAsOperator';
import { useOrgLoading } from './useOrg';
import useUser from './useUser';

export function useIsApplicationsAndDataEnabled(): boolean {
  const user = useUser();
  const asOperator = useAsOperator();
  const { org } = useOrgLoading();

  return asOperator ? user.applicationsAndDataEnabled : org?.applicationsAndDataEnabled;
}
