import BarChartIcon from '@mui/icons-material/BarChart';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CollectionsIcon from '@mui/icons-material/Collections';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import InputIcon from '@mui/icons-material/Input';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import MicIcon from '@mui/icons-material/Mic';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TitleIcon from '@mui/icons-material/Title';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

import { basetenDocsComponentReference } from '@/localization';

import { DirectionLock } from '../Canvas/types';
import { DEFAULT_VALUES } from './defaults';
import { ComponentDefinition, ComponentTypes, EditorGroup, PropType } from './types';

const DEFINITIONS: Record<string, ComponentDefinition> = {
  [ComponentTypes.HEADING]: {
    meta: {
      name: 'Heading',
      icon: TitleIcon,
      description: 'Use a heading to define a title for the view.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 3,
      },
    },
    props: [
      {
        id: 'text',
        label: 'Heading text',
        placeholder: 'Text for heading',
        type: PropType.Text,
        defaultValue: 'Heading',
      },
      {
        id: 'headingLevel',
        label: 'Heading level',
        type: PropType.Dropdown,
        options: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        defaultValue: 'h4',
      },
    ],
  },
  [ComponentTypes.CALLOUT]: {
    meta: {
      name: 'Callout',
      icon: InfoIcon,
      description: 'Use a callout to highlight information in the view.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 22,
        h: 8,
      },
    },
    props: [
      {
        id: 'label',
        label: 'Callout label',
        placeholder: 'Callout label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'data',
        label: 'Text',
        placeholder: 'Text for callout',
        tooltip: `Supports Markdown. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'callout',
        )})`,
        type: PropType.Text,
        defaultValue: DEFAULT_VALUES[ComponentTypes.CALLOUT].initialValue,
        group: EditorGroup.Data,
      },
      {
        id: 'intent',
        label: 'Intent color',
        type: PropType.Dropdown,
        options: ['error', 'warning', 'info', 'success'],
        defaultValue: 'info',
      },
    ],
  },
  [ComponentTypes.TEXT]: {
    meta: {
      name: 'Text block',
      icon: TextIncreaseIcon,
      description: 'Provide instructions or helper text to users.',
      chrome: {
        border: true,
        header: false,
      },
      dimensions: {
        w: 24,
        h: 8,
      },
      flex: true,
    },
    props: [
      {
        id: 'data',
        label: 'Text',
        tooltip: `Supports Markdown. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'text-block',
        )})`,
        type: PropType.Text,
        defaultValue: DEFAULT_VALUES[ComponentTypes.TEXT].initialValue,
        group: EditorGroup.Data,
      },
    ],
  },
  [ComponentTypes.IMAGE]: {
    meta: {
      name: 'Image',
      icon: ImageIcon,
      description: 'Display an image or animated gif.',
      chrome: {
        border: false,
        header: true,
      },
      dimensions: {
        w: 24,
        h: 18,
      },
    },
    props: [
      {
        id: 'src',
        label: 'Image URI',
        placeholder: 'Provide image URI',
        tooltip: `Enter a URL to a PNG or JPG, or a base64 encoded image. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'image',
        )})`,
        type: PropType.Text,
        defaultValue: DEFAULT_VALUES[ComponentTypes.IMAGE].src,
      },
      {
        id: 'alt',
        label: 'Alt',
        placeholder: 'Specify an alternate text for the image',
        type: PropType.Text,
        defaultValue: '',
      },
      {
        id: 'objectFit',
        label: 'Object fit',
        type: PropType.Dropdown,
        options: ['contain', 'cover', 'fill', 'none', 'scale-down'],
        defaultValue: 'cover',
      },
      {
        id: 'emptyStatePlaceholder',
        label: 'Empty state placeholder',
        type: PropType.Text,
        defaultValue: 'There is no image to show',
      },
    ],
  },
  [ComponentTypes.MEDIA]: {
    meta: {
      name: 'Media',
      icon: PermMediaIcon,
      description: 'Embed media from YouTube, Vimeo, or a hosted audio or video file.',
      chrome: {
        border: false,
        header: true,
      },
      dimensions: {
        w: 24,
        h: 18,
      },
    },
    props: [
      {
        id: 'src',
        label: 'Media URI',
        placeholder: 'Provide media URI',
        tooltip: `Supports YouTube videos. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'media',
        )})`,
        type: PropType.Text,
        defaultValue: DEFAULT_VALUES[ComponentTypes.MEDIA].src,
      },
      {
        id: 'alt',
        label: 'Alt',
        placeholder: 'Specify an alternate text for the video',
        type: PropType.Text,
        defaultValue: '',
      },
      {
        id: 'startTime',
        label: 'Seek to time (seconds)',
        placeholder: 'e.g. 10',
        type: PropType.Number,
        defaultValue: null,
      },
    ],
  },
  [ComponentTypes.DIVIDER]: {
    meta: {
      name: 'Divider',
      icon: HorizontalRuleIcon,
      description: 'A horizontal divider to visually separate sections.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 24,
        h: 2,
      },
    },
    props: [],
  },
  [ComponentTypes.TEXT_INPUT]: {
    meta: {
      name: 'Text input',
      icon: InputIcon,
      description: 'A single input field; for multiples, use Form.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 4,
        minHeight: 4,
      },
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'placeholder',
        label: 'Placeholder text',
        placeholder: 'Input placeholder text',
        type: PropType.Text,
        defaultValue: 'Type something...',
      },
      {
        id: 'initialValue',
        label: 'Initial value',
        placeholder: 'Input initial value',
        type: PropType.Text,
        defaultValue: null,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: null,
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.MICROPHONE]: {
    meta: {
      name: 'Microphone',
      icon: MicIcon,
      description: 'Microphone to record audio files.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 3,
        minWidth: 10,
      },
      resizeable: DirectionLock.Horizontal,
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: null,
      },
    ],
    events: ['onUpload'],
  },
  [ComponentTypes.TEXT_AREA]: {
    meta: {
      name: 'Text area',
      icon: TextIncreaseIcon,
      description: 'A multi-line input field.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 6,
      },
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'placeholder',
        label: 'Placeholder text',
        placeholder: 'Input placeholder text',
        type: PropType.Text,
        defaultValue: 'Type something...',
      },
      {
        id: 'initialValue',
        label: 'Initial value',
        placeholder: 'Input initial value',
        type: PropType.Text,
        defaultValue: null,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: null,
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.SELECT]: {
    meta: {
      name: 'Select',
      icon: FeaturedPlayListIcon,
      description: 'A select component.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 16,
        h: 5,
      },
    },
    props: [
      {
        id: 'label',
        type: PropType.Text,
        defaultValue: 'Select',
      },
      {
        id: 'options',
        label: 'Options',
        placeholder: 'Type {{ to reference data...',
        tooltip: `A list of dictionaries enumerating options. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'select',
        )})`,
        type: PropType.Collection,
        defaultValue: DEFAULT_VALUES[ComponentTypes.SELECT].options,
        group: EditorGroup.Data,
      },
      {
        id: 'initialValue',
        label: 'Initial value',
        tooltip: `Choose a value to be selected by default. [Learn more](${basetenDocsComponentReference(
          'select',
        )})`,
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'disabled',
        label: 'Disabled',
        tooltip: `A boolean, or an expression that produces a boolean. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'select',
        )})`,
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: true,
      },
      {
        id: 'allowCreate',
        label: 'Allow operators to specify other',
        tooltip: `Let people using the application enter their own option for the select component. [Learn more](${basetenDocsComponentReference(
          'select',
        )})`,
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: false,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: null,
      },
      {
        name: 'createdOptions',
        defaultValue: [],
        hidden: true, // TODO (Suren): currently, this is not implemented
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.RADIO_BUTTONS]: {
    meta: {
      name: 'Radio buttons',
      icon: RadioButtonCheckedIcon,
      description: 'Define multiple options with a single selection.',
      chrome: {
        border: true,
        header: false,
      },
      dimensions: {
        w: 16,
        h: 8,
      },
      flex: true,
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'options',
        label: 'Options',
        tooltip: `A list of dictionaries enumerating options. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'radio-buttons',
        )})`,
        alias: 'fields',
        type: PropType.Collection,
        defaultValue: DEFAULT_VALUES[ComponentTypes.RADIO_BUTTONS].options,
        group: EditorGroup.Data,
      },
      {
        id: 'initialValue',
        label: 'Initial option',
        tooltip: `Choose a value to be selected by default. [Learn more](${basetenDocsComponentReference(
          'radio-buttons',
        )})`,
        type: PropType.Dropdown,
        options: { from: 'options' },
        defaultValue: null,
        group: EditorGroup.Data,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: '',
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.CHECKBOXES]: {
    meta: {
      name: 'Checkboxes',
      icon: CheckIcon,
      description: 'Define multi-select options.',
      chrome: {
        border: true,
        header: false,
      },
      dimensions: {
        w: 16,
        h: 8,
      },
      flex: true,
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'options',
        label: 'Options',
        tooltip: `A list of dictionaries enumerating options. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'checkboxes',
        )})`,
        alias: 'fields',
        type: PropType.Collection,
        defaultValue: DEFAULT_VALUES[ComponentTypes.CHECKBOXES].options,
        group: EditorGroup.Data,
      },
      {
        id: 'initialValue',
        label: 'Initial options',
        tooltip: `A list of values to be selected by default. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'checkboxes',
        )})`,
        type: PropType.Checkbox,
        options: { from: 'options' },
        defaultValue: [],
        group: EditorGroup.Data,
      },
    ],
    fields: [
      {
        name: 'values',
        defaultValue: [],
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.BUTTON]: {
    meta: {
      name: 'Button',
      icon: SmartButtonIcon,
      description: 'A lone button; to submit multiple inputs, use Form.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 12,
        h: 4,
      },
    },
    props: [
      {
        id: 'label',
        type: PropType.Text,
        defaultValue: 'Button',
      },
      {
        id: 'intent',
        label: 'Intent color',
        type: PropType.Dropdown,
        options: ['primary', 'secondary', 'success', 'error', 'info', 'warning'],
        defaultValue: 'primary',
      },
      {
        id: 'disabled',
        tooltip: `A boolean, or an expression that produces a boolean. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'button',
        )})`,
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: true,
      },
    ],
    events: ['onClick'],
  },
  [ComponentTypes.SLIDER]: {
    meta: {
      name: 'Slider',
      icon: LinearScaleIcon,
      description:
        'A slider is a numeric input for choosing numbers between lower and upper bounds.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 4,
      },
    },
    props: [
      {
        id: 'label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'min',
        label: 'Minimum value',
        type: PropType.Number,
        defaultValue: 1,
      },
      {
        id: 'max',
        label: 'Maximum value',
        type: PropType.Number,
        defaultValue: 10,
      },
      {
        id: 'initialValue',
        label: 'Initial value',
        type: PropType.Number,
        defaultValue: 4,
      },
      {
        id: 'step',
        label: 'Increment',
        type: PropType.Number,
        defaultValue: 1,
        min: 1,
      },
      {
        id: 'withMarks',
        label: 'Show marks',
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: false,
      },
    ],
    fields: [
      {
        name: 'value',
        defaultValue: null,
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.RANGE]: {
    meta: {
      name: 'Range',
      icon: HdrStrongIcon,
      description: 'A range between upper and lower bounds.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 4,
      },
    },
    props: [
      {
        id: 'label',
        placeholder: 'Enter a label for this ranage',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'min',
        label: 'Minimum value',
        placeholder: 'Input minimum value of the range...',
        type: PropType.Number,
        defaultValue: 0,
      },
      {
        id: 'max',
        label: 'Maximum value',
        placeholder: 'Input maximum value of the range...',
        type: PropType.Number,
        defaultValue: 10,
      },
      {
        id: 'initialFrom',
        label: 'Initial lower value',
        placeholder: 'Range initial lower boundary',
        type: PropType.Number,
        defaultValue: 0,
      },
      {
        id: 'initialTo',
        label: 'Initial upper value',
        placeholder: 'Range initial upper boundary',
        type: PropType.Number,
        defaultValue: 10,
      },
      {
        id: 'step',
        label: 'Increment',
        type: PropType.Number,
        defaultValue: 1,
        min: 1,
      },
      {
        id: 'withMarks',
        label: 'Show marks',
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: false,
      },
    ],
    fields: [
      {
        name: 'from',
        defaultValue: null,
      },
      {
        name: 'to',
        defaultValue: null,
      },
    ],
    events: ['onChange'],
  },
  [ComponentTypes.FILEPICKER]: {
    meta: {
      name: 'Filepicker',
      icon: CloudUploadIcon,
      description:
        'Upload data to S3 with a filepicker. Data will be accessible to anyone with the URL.',
      chrome: {
        border: false,
        header: false,
      },
      dimensions: {
        w: 20,
        h: 3,
      },
      resizeable: DirectionLock.Horizontal,
      flex: true,
    },
    props: [
      {
        id: 'label',
        label: 'Input label',
        placeholder: 'Input label',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'fileSizeLimitKb',
        label: 'File size limit (KB)',
        placeholder: 'No limit',
        type: PropType.Number,
        defaultValue: null,
      },
    ],
    fields: [
      {
        name: 'uploadFileUrls',
        defaultValue: null,
      },
    ],
    events: ['onUpload'],
  },
  [ComponentTypes.TABLE]: {
    meta: {
      name: 'Table',
      icon: TableChartIcon,
      description: 'Standard table display with numbered rows.',
      chrome: {
        border: true,
        header: true,
      },
      dimensions: {
        w: 24,
        h: 18,
      },
    },
    props: [
      {
        id: 'data',
        label: 'Data',
        placeholder: 'Type {{ to reference data...',
        tooltip: `A list of dictionaries with column names as keys. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'table',
        )})`,
        type: PropType.Collection,
        defaultValue: DEFAULT_VALUES[ComponentTypes.TABLE].data,
        group: EditorGroup.Data,
      },
      {
        id: 'hideColumns',
        label: 'Columns',
        tooltip: `Automatically generated from table data. Click the eye to hide the column. [Learn more](${basetenDocsComponentReference(
          'table',
        )})`,
        type: PropType.Checkbox,
        icons: [VisibilityOutlined, VisibilityOffOutlined],
        options: { from: 'columns' },
        defaultValue: [],
        placeholder: 'No columns added',
        group: EditorGroup.Data,
      },
      {
        id: 'paginate',
        label: 'Paginate rows',
        type: PropType.Boolean,
        defaultValue: false,
        dynamic: false,
      },
    ],
    fields: [
      {
        name: 'selectedRow',
        defaultValue: null,
      },
      {
        name: 'columns',
        defaultValue: [],
      },
    ],
    context: {
      label: 'Row action',
      tooltip: `A button per row that runs worklets, triggers queries, controls components and more. Create multiple sequential events per row action. [Learn more](${basetenDocsComponentReference(
        'table',
      )})`,
      refField: { from: 'selectedRow' },
    },
  },
  [ComponentTypes.JSON_VIEWER]: {
    meta: {
      name: 'JSON viewer',
      icon: WysiwygIcon,
      description: 'JSON viewer.',
      chrome: {
        border: true,
        header: true,
      },
      dimensions: {
        w: 20,
        h: 15,
      },
    },
    props: [
      {
        id: 'data',
        label: 'Data',
        tooltip: `Any valid JSON will be rendered. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'json-viewer',
        )})`,
        placeholder: '{ "for": "bar" }',
        type: PropType.Json,
        defaultValue: DEFAULT_VALUES[ComponentTypes.JSON_VIEWER].data,
        group: EditorGroup.Data,
      },
    ],
  },
  [ComponentTypes.IMAGE_GALLERY]: {
    meta: {
      name: 'Image gallery',
      icon: CollectionsIcon,
      description: 'Display a collection of images.',
      chrome: {
        border: true,
        header: true,
      },
      dimensions: {
        w: 24,
        h: 24,
      },
    },
    props: [
      {
        id: 'images',
        label: 'Images',
        tooltip: `A list of URLs to PNGs or JPGs, or base64 encoded images. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'image-gallery',
        )})`,
        placeholder: 'Type {{ to reference data...',
        type: PropType.Collection,
        defaultValue: DEFAULT_VALUES[ComponentTypes.IMAGE_GALLERY].images,
        group: EditorGroup.Data,
      },
      {
        id: 'cols',
        label: 'Columns',
        placeholder: 'The number of columns',
        type: PropType.Number,
        defaultValue: 2,
      },
    ],
    fields: [
      {
        name: 'selectedImage',
        defaultValue: null,
      },
    ],
    events: ['onSelect'],
  },
  [ComponentTypes.PLOTLY_CHART]: {
    meta: {
      name: 'Plotly chart',
      icon: BarChartIcon,
      description: 'Data visualizations using the Plotly.js library.',
      chrome: {
        border: true,
        header: false,
      },
      dimensions: {
        w: 24,
        h: 20,
      },
    },
    props: [
      {
        id: 'title',
        label: 'Title',
        type: PropType.Text,
        defaultValue: null,
      },
      {
        id: 'data',
        label: 'Chart data',
        tooltip: `A dictionary with the key “data” and a value that is valid Plotly data. Accepts bindings. [Learn more](${basetenDocsComponentReference(
          'plotly-chart',
        )})`,
        placeholder: 'Type {{ to reference data...',
        type: PropType.Json,
        defaultValue: DEFAULT_VALUES[ComponentTypes.PLOTLY_CHART].sampleJson,
        group: EditorGroup.Data,
      },
    ],
  },
};

function getComponentDefinition(type: string) {
  return DEFINITIONS[type];
}

function getComponentMetaProperties(type: string) {
  return getComponentDefinition(type)?.meta;
}

export { getComponentDefinition, getComponentMetaProperties };
