import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import { NoopValidator, ParamValidator, ValidationErrors } from './Validation';

export enum ParamsFormElementType {
  text = 'text',
  bool = 'bool',
  password = 'password',
  textarea = 'textarea',
}

export interface ParamsFormElementSpec {
  id: string;
  name: string;
  displayName: string;
  placeholder?: string;
  type: ParamsFormElementType;
  defaultValue: any;
  validate: ParamValidator;
  dependsOn?: Array<string>;
}

export enum StandardValidator {
  Noop = 1,
  Empty = 2,
}

export interface FormElemProps {
  id: string;
  name?: string;
  displayName?: string;
  placeholder?: string;
  validate?: ParamValidator | StandardValidator;
  type?: ParamsFormElementType;
  defaultValue?: any;
  dependsOn?: Array<string>;
}

class FormElem implements ParamsFormElementSpec {
  readonly id: string;

  readonly name: string;

  // eslint-disable-next-line react/static-property-placement
  readonly displayName: string;

  readonly placeholder: string;

  readonly type: ParamsFormElementType;

  readonly defaultValue: any;

  readonly validate: ParamValidator;

  readonly dependsOn: Array<string>;

  constructor({
    id,
    name,
    displayName,
    placeholder,
    validate,
    type,
    defaultValue,
    dependsOn,
  }: FormElemProps) {
    this.id = id;
    this.name = name || id;
    this.displayName = displayName;
    this.placeholder = placeholder || this.displayName;
    this.type = type || ParamsFormElementType.text;
    this.defaultValue = defaultValue || '';
    this.dependsOn = dependsOn || [];

    if (!validate || validate === StandardValidator.Empty) {
      this.validate = (param: any) => {
        const errs: ValidationErrors = [];
        if (isEmpty(param)) {
          errs.push(`${this.displayName} must not be empty.`);
        }
        return errs;
      };
    } else if (validate === StandardValidator.Noop) {
      this.validate = NoopValidator;
    } else if (isFunction(validate)) {
      this.validate = validate;
    }
  }
}

export default FormElem;
