import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import '@/components/DataGrid/license';
import Notifier from '@/components/Notifier';
import { IS_DEV_MODE } from '@/constants';
import { ErrorBoundary } from '@/error-handling';
import SnackbarProvider from '@/sections/Snackbar/Provider';
import { defaultTheme } from '@/theme';
import { AnalyticsProvider } from '@/utils/analytics/segment/AnalyticsContext';
import { ApolloProvider } from '@apollo/client';
import { PylonProvider } from '@bolasim/react-use-pylon';
import { RecoilRoot } from 'recoil';
import { RecoilDevTools } from 'recoil-toolkit';

import App from './App';
import apolloClient from './apollo';
import store from './store';
import { globalStyles } from './styled';
import { initializeSentry } from './utils/analytics/Sentry';
import { initializePosthog } from './utils/analytics/posthog';

initializeSentry();
initializePosthog();

const container = document.getElementById('react-app') as HTMLElement;
const root = createRoot(container);

root.render(
  <AnalyticsProvider>
    <ApolloProvider client={apolloClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <Provider store={store}>
            <RecoilRoot>
              {IS_DEV_MODE && <RecoilDevTools />}
              <SnackbarProvider>
                <PylonProvider
                  chatSettings={{
                    appId: ENV_CONFIG.PYLON_APP_ID,
                  }}
                  autoBoot
                  shouldInitialize={!!ENV_CONFIG.PYLON_APP_ID}
                >
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                  <Notifier />
                </PylonProvider>
              </SnackbarProvider>
            </RecoilRoot>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  </AnalyticsProvider>,
);
