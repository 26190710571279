import API from '@/API';

async function register(email: string) {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const parameters: { email: string; next?: string } = {
      email,
    };

    const nextPage = urlParams.get('next');
    parameters.next = nextPage;

    const { data } = await API.postWithoutCallback('/api/signup_new_user', parameters);

    return data as { next?: string; success: boolean; message?: string };
  } catch (error) {
    throw new Error('An unexpected error has occurred');
  }
}

export { register };
