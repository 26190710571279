enum Platforms {
  BasetenCli = 'basetenCli',
  SageMaker = 'sageMaker',
}

enum Frameworks {
  HuggingFace = 'Hugging Face',
  PyTorch = 'pyTorch',
  Keras = 'keras',
  LightGBM = 'lightGBM',
  ScikitLearn = 'scikitLearn',
  XGBoost = 'xGBoost',
}

enum DeploymentVariants {
  Framework = 'framework',
  Custom = 'custom',
}

type ModelDeploymentState = {
  selectedFramework: Frameworks;
  selectedDeploymentVariant: DeploymentVariants;
  apiKey: string;
  name: string;
  files: string;
  trussVariable: string;
};

type UseModelDeployment = ModelDeploymentState & {
  setSelectedFramework: (modelType: Frameworks) => void;
  setSelectedDeploymentVariant: (deploymentVariant: DeploymentVariants) => void;
  setApiKey: (apiKey: string) => void;
  setName: (apiKey: string) => void;
  setFiles: (apiKey: string) => void;
  setTrussVariable: (variable: string) => void;
  resetState: () => void;
};

export { Platforms, Frameworks, ModelDeploymentState, UseModelDeployment, DeploymentVariants };
