import { generateId } from '@/utils/ids';
import { exec } from '@x-python/core';
import { nanoid } from 'nanoid';

import { Variable, VariableType } from './types';

const WRAPPER_FUNCTION_NAME = 'wrapper';

function injectWrapper(code: string) {
  if (!code.trim()) return code;

  return `def ${WRAPPER_FUNCTION_NAME}():\n  ${code
    .split('\n')
    // NOTE: here we fix indentation;
    // that's a common problem with ecmascript string templates.
    // Once we import this file as a module we can use https://github.com/zspecza/common-tags
    // to handle this
    .map((line, index) => (index === 0 ? line : `${' '.repeat(2)}${line}`))
    .join('\n')}\n${WRAPPER_FUNCTION_NAME}()`;
}

const extractModuleExceptionLineRegExp = /File "<exec>", line (\d+)($|, in <module>)$/m;
const extractWrapperExceptionLineRegExp = new RegExp(
  `File "<exec>", line (\\d+), in ${WRAPPER_FUNCTION_NAME}`,
  'm',
);
const lineNumberRegExp = /line (\d+)/g;

function replaceLineNumber(messageLine: string) {
  return messageLine.replace(lineNumberRegExp, (_, lineNumber) => {
    return `line ${+lineNumber - 1}`;
  });
}

function extractMainErrorMessage(message: string) {
  const doesContainModuleExceptionLine = extractModuleExceptionLineRegExp.test(message);
  const doesContainWrapperExceptionLine = extractWrapperExceptionLineRegExp.test(message);

  if (!(doesContainModuleExceptionLine || doesContainWrapperExceptionLine)) return message;

  const errorMessageLines = message.split('\n');

  const firstLineIndex = errorMessageLines.findIndex((line) =>
    doesContainModuleExceptionLine
      ? extractModuleExceptionLineRegExp.test(line)
      : extractWrapperExceptionLineRegExp.test(line),
  );

  // TODO (Suren): this should be removed once we import this file as module
  let skipOtherLines = false;

  return errorMessageLines
    .slice(firstLineIndex)
    .reduce((acc, messageLine) => {
      if (skipOtherLines) {
        acc.push(replaceLineNumber(messageLine));
      } else {
        if (doesContainModuleExceptionLine && !doesContainWrapperExceptionLine) {
          if (extractModuleExceptionLineRegExp.test(messageLine)) {
            skipOtherLines = true;
            acc.push(replaceLineNumber(messageLine).replace(', in <module>', ''));
            return acc;
          }
        }

        if (doesContainWrapperExceptionLine) {
          if (extractWrapperExceptionLineRegExp.test(messageLine)) {
            skipOtherLines = true;
            acc.push(replaceLineNumber(messageLine).replace(`, in ${WRAPPER_FUNCTION_NAME}`, ''));
            return acc;
          }
        }
      }

      return acc;
    }, [])
    .join('\n');
}

function makeNewVariable(name: string, type: VariableType = VariableType.Python): Variable {
  return {
    id: generateId('var'),
    type,
    name,
    code: type === VariableType.Javascript ? 'return null;' : 'return None',
    paramRef: nanoid(7),
  };
}

async function runJSCode(code: string, context: Record<string, any> = {}): Promise<unknown> {
  // Lazy load module since evalJSWithContext relies on esprima, which is non-negligible in size
  const evalJSWithContext = (await import('./evalJSWithContext')).default;
  return evalJSWithContext(code, context);
}

async function runPython(code: string, context: Record<string, any> = {}) {
  const { result } = await exec({ code: injectWrapper(code), context });

  return result;
}

export { makeNewVariable, runJSCode, runPython, extractMainErrorMessage };
