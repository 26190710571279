import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function ShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.99999 14.6667C7.99999 14.6667 13.3333 12 13.3333 8.00004V3.33337L7.99999 1.33337L2.66666 3.33337V8.00004C2.66666 12 7.99999
        14.6667 7.99999 14.6667Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default ShieldIcon;
