import { CodeFile, CodebasesType, FileIdentifier, FileSaveState, ServerCodeFile } from './types';

// saveState and draft are just additions; not part of the actual resource and used only in the clientside
function normalize(file: ServerCodeFile): CodeFile {
  return {
    ...file,
    saveState: FileSaveState.SAVED,
    created: new Date(file.created),
    modified: new Date(file.modified),
  };
}

function getFile(state: CodebasesType, { workflowId, fileId, releaseEnv }: FileIdentifier) {
  return state.data[releaseEnv][workflowId]?.find((file) => file.id === fileId);
}

export { normalize, getFile };
