/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DataSourceFragmentDoc, ConnectionFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveDataSourceMutationVariables = Types.Exact<{
  encodedRequest: Types.Scalars['String'];
}>;


export type SaveDataSourceMutation = { __typename?: 'Mutation', createOrUpdateDataSource: { __typename?: 'CreateOrUpdateDataSource', dataSource: { __typename?: 'DataSourceType', id: string, name: string, type: string | null | undefined, created: any, modified: any, useProductionSettingsForDraft: boolean, connections: Array<{ __typename?: 'ExternalConnectionType', id: string, created: any, dataSchema: any, isStreaming: boolean | null | undefined, connectionParameters: any | null | undefined, connectionName: string, connectionType: Types.ExternalConnectionConnectionType, deploymentEnv: string | null | undefined } | null | undefined> | null | undefined } | null | undefined } | null | undefined };

export type DeleteDataSourceMutationVariables = Types.Exact<{
  dataSourceId: Types.Scalars['String'];
}>;


export type DeleteDataSourceMutation = { __typename?: 'Mutation', delete_data_source: { __typename?: 'DeleteDataSource', ok: boolean | null | undefined } | null | undefined };


export const SaveDataSourceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveDataSource"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"encodedRequest"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"createOrUpdateDataSource"},"name":{"kind":"Name","value":"create_or_update_data_source"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"encoded_request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"encodedRequest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"dataSource"},"name":{"kind":"Name","value":"data_source"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DataSourceFragment"}}]}}]}}]}},...DataSourceFragmentDoc.definitions,...ConnectionFragmentDoc.definitions]} as unknown as DocumentNode<SaveDataSourceMutation, SaveDataSourceMutationVariables>;
export const DeleteDataSourceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDataSource"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataSourceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"delete_data_source"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data_source_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataSourceId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;
export const namedOperations = {
  Mutation: {
    SaveDataSource: 'SaveDataSource',
    DeleteDataSource: 'DeleteDataSource'
  }
}

export type SaveDataSourceMutationFn = Apollo.MutationFunction<SaveDataSourceMutation, SaveDataSourceMutationVariables>;

/**
 * __useSaveDataSourceMutation__
 *
 * To run a mutation, you first call `useSaveDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDataSourceMutation, { data, loading, error }] = useSaveDataSourceMutation({
 *   variables: {
 *      encodedRequest: // value for 'encodedRequest'
 *   },
 * });
 */
export function useSaveDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<SaveDataSourceMutation, SaveDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDataSourceMutation, SaveDataSourceMutationVariables>(SaveDataSourceDocument, options);
      }
export type SaveDataSourceMutationHookResult = ReturnType<typeof useSaveDataSourceMutation>;
export type SaveDataSourceMutationResult = Apollo.MutationResult<SaveDataSourceMutation>;
export type SaveDataSourceMutationOptions = Apollo.BaseMutationOptions<SaveDataSourceMutation, SaveDataSourceMutationVariables>;
export type DeleteDataSourceMutationFn = Apollo.MutationFunction<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;

/**
 * __useDeleteDataSourceMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceMutation, { data, loading, error }] = useDeleteDataSourceMutation({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *   },
 * });
 */
export function useDeleteDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>(DeleteDataSourceDocument, options);
      }
export type DeleteDataSourceMutationHookResult = ReturnType<typeof useDeleteDataSourceMutation>;
export type DeleteDataSourceMutationResult = Apollo.MutationResult<DeleteDataSourceMutation>;
export type DeleteDataSourceMutationOptions = Apollo.BaseMutationOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;