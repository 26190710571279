import { AnyAction, isAnyOf } from '@reduxjs/toolkit';

import pick from 'lodash/pick';

import { BasetenPageEnum, getMatchFromPath } from '@/routes';
import { ReleaseEnv } from '@/types/releaseEnv';
import { handleStaleGraphQLError } from '@/utils/errorHandling';

import { updateCurrentVersionId } from '..';
import { startEntityProcessor } from '../../../../middleware/EntityProcessor';
import { RootState } from '../../../../types';
import { isSliceAction } from '../../../../utils/ActionUtils';
import {
  VIEW_CONFIG_SLICE_NAME,
  addState,
  deleteState,
  refreshDataSources,
  setComponentField,
  setData,
  setState,
  syncSharedStates,
  updateURLParams,
} from '../ViewConfigSlice';
import { UpdateViewDocument } from '../__generated__/mutations.generated';
import { viewSelector } from '../selectors';
import { ViewIdentifier } from '../types';
import { isViewIdentifier, serializeConfig } from '../utils';

export function startSaveViewListener() {
  startEntityProcessor<ViewIdentifier>({
    predicate: (action: AnyAction, state: RootState) => {
      const {
        router: { location },
      } = state;
      const page = getMatchFromPath(location.pathname)?.page;
      const asOperator = page === BasetenPageEnum.ViewOperator;

      return (
        !asOperator &&
        isSliceAction(VIEW_CONFIG_SLICE_NAME)(action) &&
        !isAnyOf(
          refreshDataSources,
          setComponentField,
          setData,
          updateURLParams,
          setState,
          syncSharedStates,
          addState,
          deleteState,
        )(action)
      );
    },
    debounceMs: 500,
    extractIdentifier: (action) =>
      isViewIdentifier(action.payload)
        ? pick(action.payload, 'viewId', 'workflowId', 'releaseEnv')
        : null,
    async process(viewIdenfier, { dispatch, getState, extra }) {
      const { viewId, releaseEnv } = viewIdenfier;

      const targetView = viewSelector(getState(), viewIdenfier);

      if (!targetView) {
        // this should not happen; maybe we need to throw an error here?
        return;
      }

      if (releaseEnv !== ReleaseEnv.Draft) {
        throw new Error(`File save not supported for files in ${releaseEnv} environment`);
      }

      try {
        const { data } = await extra.apolloClient.mutate({
          mutation: UpdateViewDocument,
          variables: {
            id: viewId,
            components: serializeConfig(targetView.config.present),
            name: targetView.name,
            currentVersionId: targetView.currentVersionId,
          },
        });

        dispatch(
          updateCurrentVersionId({
            ...viewIdenfier,
            currentVersionId: data.updateView.currentVersionId,
          }),
        );
      } catch (ex) {
        handleStaleGraphQLError(dispatch, ex, 'save view', false);
      }
    },
  });
}
