import { useEffect } from 'react';

import { batch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import NotFound from '@/components/NotFound';
import { useIsApplicationsAndDataEnabled } from '@/hooks/useIsApplicationsAndDataEnabled';
import useUser from '@/hooks/useUser';
import { BasetenPageEnum, findMatchingPageFromPath, renderRoutes } from '@/routes';
import PaymentMethodDialog from '@/sections/Billing/PaymentMethodDialog';
import Modals from '@/sections/Modal';
import Query from '@/sections/dialogs/Query';
import { refreshDataSources } from '@/store/actions/DataSourceActions';
import { fetchWorkflowsMetadata } from '@/store/actions/WorkflowsAsyncActions';
import { fetchOntology } from '@/store/actions/udm/UdmActions';
import { useAppDispatch } from '@/store/hooks';
import { fetchOnboardingChecklist } from '@/store/slices/entities/OnboardingChecklist';
import { fetchQueries } from '@/store/slices/entities/Queries';
import { ReleaseEnv } from '@/types/releaseEnv';
import { useSegmentIdentify, useSegmentPageViews } from '@/utils/analytics/segment/hooks';
import { usePylon } from '@bolasim/react-use-pylon';

import PageComponents from './PageComponents';
import Routes from './routes';
import { Container, Content } from './styled';

function Creator() {
  const user = useUser();
  const dispatch = useAppDispatch();
  useSegmentIdentify();
  useSegmentPageViews();
  const { update: updatePylon } = usePylon();
  const isApplicationsAndDataEnabled = useIsApplicationsAndDataEnabled();

  const location = useLocation();
  const page = findMatchingPageFromPath(location.pathname);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    batch(() => {
      if (isApplicationsAndDataEnabled) {
        dispatch(fetchWorkflowsMetadata(ReleaseEnv.Draft));
        dispatch(fetchQueries());
        dispatch(refreshDataSources());
      }
      dispatch(fetchOnboardingChecklist());

      if (page !== BasetenPageEnum.Onboarding) {
        dispatch(fetchOntology());
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (ENV_CONFIG.PYLON_APP_ID) {
      // Update Pylon with user's information
      updatePylon({
        name: [user.firstName, user.lastName].join(' '),
        email: user.email,
      });
    }
  }, [user]);

  return (
    <Container>
      <Content>
        <Switch>
          {/* The following redirects are needed to support the old model managment routes */}
          <Redirect from="/models/:model_id/versions/:version_id" to="/models/:model_id" exact />
          <Redirect
            from="/models/:model_id/versions/:version_id/overview"
            to="/models/:model_id"
            exact
          />
          <Redirect
            from="/models/:model_id/versions/:version_id/:tab_id"
            to="/models/:model_id/:tab_id/:version_id"
          />
          {/* ============================== */}
          {renderRoutes(Routes, PageComponents)}
          <Redirect from="/" to="/models" />
          <Redirect from="/data" to="/data/database_models" />
          <Route component={NotFound} />
        </Switch>
      </Content>
      <Modals />
      <Query />
      <PaymentMethodDialog />
    </Container>
  );
}

export default Creator;
