import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/react';
import { handleStaleGraphQLError } from '@/utils/errorHandling';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { setErrors } from '.';
import { AppDispatch } from '../../../types';
import { enqueueNotification } from '../../ui/Notifier';
import { FileIdentifier, GraphQLInvalidCodeError } from './types';

export function isGraphQLInvalidCodeError(error: GraphQLError): error is GraphQLInvalidCodeError {
  return error.extensions.code === 'SYNTAX_ERROR';
}

/**
 * @returns True if handled
 */
function handleSyntaxGraphQLError(
  fileIdentifier: FileIdentifier,
  dispatch: AppDispatch | ThunkDispatch<unknown, unknown, AnyAction>,
  error: Error,
): boolean {
  if (error instanceof ApolloError) {
    const firstGraphQLError = error.graphQLErrors[0];
    if (firstGraphQLError && isGraphQLInvalidCodeError(firstGraphQLError)) {
      const { msg, line, column } = firstGraphQLError.extensions;

      dispatch(
        setErrors({
          fileIdentifier,
          errors: [{ message: msg, line, column }],
        }),
      );
      return true;
    }
  }

  return false;
}

export function handleUpdateGraphQLErrors(
  fileIdentifier: FileIdentifier,
  dispatch: AppDispatch,
  error: Error,
) {
  if (handleSyntaxGraphQLError(fileIdentifier, dispatch, error)) {
    // handled
    return;
  }

  Sentry.captureException(error, { extra: { action: 'save file' } });
  dispatch(
    setErrors({
      fileIdentifier,
      errors: [{ message: error.message }],
    }),
  );
  try {
    handleStaleGraphQLError(dispatch, error, 'save file', false);

    // handled
    return;
  } catch {
    // not handled
  }

  dispatch(
    enqueueNotification({ message: `Failed to save file: ${error.message}`, variant: 'error' }),
  );
}
