import { Redirect, Route, Switch } from 'react-router-dom';

import NotFound from '@/components/NotFound';
import { AsOperatorContext } from '@/hooks/useAsOperator';
import { renderRoutes } from '@/routes';
import { useSegmentIdentify, useSegmentPageViews } from '@/utils/analytics/segment/hooks';

import PageComponents from './PageComponents';
import Routes from './routes';
import { Container, Page } from './styled';

function Operator() {
  useSegmentIdentify();
  useSegmentPageViews();

  return (
    <AsOperatorContext.Provider value>
      <Container>
        <Page>
          <Switch>
            {renderRoutes(Routes, PageComponents)}
            <Redirect exact from="/" to="/applications" />
            <Route component={NotFound} />
          </Switch>
        </Page>
      </Container>
    </AsOperatorContext.Provider>
  );
}

export default Operator;
