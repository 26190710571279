import { Check } from '@mui/icons-material';
import { Link } from '@mui/material';

import { links } from '@/localization';

import LargeAlert from '../../LargeAlert';

function Reviewing() {
  return (
    <LargeAlert
      icon={Check}
      title="We're reviewing your account"
      severity="success"
      description={
        <>
          You&apos;ll receive an email when your account is approved. Questions? Reach out to&nbsp;
          <Link href={links.basetenSupport}>support@baseten.co.</Link>
        </>
      }
    />
  );
}

export default Reviewing;
