import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';

import { ReleaseEnv } from '@/types/releaseEnv';

import { deleteQuery, saveQuery } from '../../entities/Queries';
import { QueryDialogState } from './types';

const initialState: QueryDialogState = {
  queryId: null,
  queryStr: '',
  queryParams: {},
  queryResult: null,
  queryStatus: {},
  executingQuery: false,
  savingQuery: false,
  queryError: '',
  closeCallback: null,
  visible: false,
  isNewQuery: false,
  showPreviewQueryDialog: false,
  releaseEnv: ReleaseEnv.Draft,
  workflowId: null,
};

const QueryDialog = createSlice({
  name: 'query-dialog',
  initialState,
  reducers: {
    setQuery(state, action) {
      state.queryId = action.payload.id;
      state.queryStr = action.payload.queryStr || '';
      if (typeof action.payload.visible === 'boolean') {
        state.visible = action.payload.visible;
      }
      if (typeof action.payload.closeCallback === 'function') {
        state.closeCallback = action.payload.closeCallback;
      }
      state.queryParams = state.queryParams || {};
      state.queryResult = null;
      state.queryError = '';
      state.showPreviewQueryDialog = !!action.payload.showPreviewQueryDialog;
      state.isNewQuery = false;
      if (action.payload.releaseEnv) {
        state.releaseEnv = action.payload.releaseEnv;
      }
      state.workflowId = action.payload.workflowId;
    },
    clearQuery() {
      return initialState;
    },
    executeQuery(state, action) {
      state.executingQuery = true;
      state.queryError = '';
      state.queryResult = null;
      if (action.payload && action.payload.queryParams) {
        state.queryStatus = {
          ...state.queryStatus,
          [action.payload.queryParams]: { running: true },
        };
      }
    },
    executeQueryFailed(state, action) {
      state.executingQuery = false;
      state.queryError = action.payload.error.message;
      state.queryResult = null;
      if (action.payload && action.payload.queryParams) {
        state.queryStatus = {
          ...state.queryStatus,
          [action.payload.queryParams]: { running: false },
        };
      }
    },
    executeQueryCompleted(state, action) {
      state.executingQuery = false;
      if (isEmpty(action.payload.error)) {
        state.queryResult = action.payload.results;
        state.queryError = '';
      } else {
        state.queryResult = null;
        state.queryError = action.payload.error;
      }
      if (action.payload && action.payload.queryParams) {
        state.queryStatus = {
          ...state.queryStatus,
          [action.payload.queryParams]: { running: false },
        };
      }
    },
    updateQueryParams(state, action) {
      state.queryParams = action.payload || {};
    },
    registerCallback(state, action) {
      if (typeof action.payload.callback === 'function') {
        state.closeCallback = action.payload.callback;
      }
    },
    hideDialog(state) {
      state.visible = false;
      state.showPreviewQueryDialog = false;
      state.closeCallback = null;
      state.isNewQuery = false;
    },
    setReleaseEnv(state, action: PayloadAction<ReleaseEnv>) {
      state.releaseEnv = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveQuery.fulfilled, (state, action) => {
      const { queryId } = action.meta.arg;
      const isNewQuery = !queryId;
      const query = action.payload;
      if (query && state.queryId !== query.id) {
        state.queryId = query.id;
        state.visible = true;
      }
      state.isNewQuery = isNewQuery;
    });
    builder.addCase(deleteQuery.fulfilled, (state, action) => {
      if (state.queryId === action.payload) {
        return initialState; // clear the query if it was deleted
      }
    });
  },
});

export const {
  setQuery,
  clearQuery,
  executeQuery,
  executeQueryFailed,
  executeQueryCompleted,
  updateQueryParams,
  registerCallback,
  hideDialog,
  setReleaseEnv,
} = QueryDialog.actions;

export default QueryDialog.reducer;
