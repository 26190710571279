import { matchPath } from 'react-router';

import { Routes } from './index';
import { BasetenPageEnum } from './types';
import { getWorkspaceSection } from './utils';

export function getMatchFromPath<TMatchParams>(
  path: string,
): { page: BasetenPageEnum; params: Partial<TMatchParams> } | null {
  for (const route of Routes) {
    const match = matchPath<TMatchParams>(path, { path: route.path, exact: true });
    if (match)
      return {
        page: route.page,
        params:
          route.page === BasetenPageEnum.Workspace
            ? { ...match.params, section: getWorkspaceSection(path) }
            : match.params,
      };
  }
  return null;
}
