/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ClassFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OntologyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OntologyQuery = { __typename?: 'QueryPrediction', ontology: { __typename?: 'OntologyType', classes: Array<{ __typename?: 'ClassType', id: string, name: string, typename: string, namespace: string, description: string | null | undefined, schema: any, created: any, modified: any, tablename: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const OntologyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Ontology"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ontology"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"classes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ClassFragment"}}]}}]}}]}},...ClassFragmentDoc.definitions]} as unknown as DocumentNode<OntologyQuery, OntologyQueryVariables>;
export const namedOperations = {
  Query: {
    Ontology: 'Ontology'
  }
}

/**
 * __useOntologyQuery__
 *
 * To run a query within a React component, call `useOntologyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOntologyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOntologyQuery({
 *   variables: {
 *   },
 * });
 */
export function useOntologyQuery(baseOptions?: Apollo.QueryHookOptions<OntologyQuery, OntologyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OntologyQuery, OntologyQueryVariables>(OntologyDocument, options);
      }
export function useOntologyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OntologyQuery, OntologyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OntologyQuery, OntologyQueryVariables>(OntologyDocument, options);
        }
export type OntologyQueryHookResult = ReturnType<typeof useOntologyQuery>;
export type OntologyLazyQueryHookResult = ReturnType<typeof useOntologyLazyQuery>;
export type OntologyQueryResult = Apollo.QueryResult<OntologyQuery, OntologyQueryVariables>;
export function refetchOntologyQuery(variables?: OntologyQueryVariables) {
      return { query: OntologyDocument, variables: variables }
    }