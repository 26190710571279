import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

import CircleFilledIcon from '@/components/Icons/CircleFilledIcon';
import CircleIcon from '@/components/Icons/CircleIcon';

import { PermissionsWithConfigurableAccess } from '../constants';
import { PermissionOptionsEnum } from '../types';
import ModelList from './ModelList';
import { PermissionsProps } from './types';

function Permissions({
  onChange,
  selectedOption,
  onModelSelect,
  selectedModelIds,
  onAllModelsSelect,
  selectedAllModels,
}: PermissionsProps) {
  return (
    <FormControl sx={{ width: '100%' }}>
      <Stack gap={2}>
        <Typography variant="h5">Permissions</Typography>
        <RadioGroup sx={{ gap: 2 }} value={selectedOption} onChange={onChange}>
          <FormControlLabel
            value={PermissionOptionsEnum.InvokeAllModels}
            control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
            sx={{ gap: 1 }}
            label={<Typography variant="h5">Manage and call all workspace models</Typography>}
          />
          <FormControlLabel
            value={PermissionOptionsEnum.CanInvokeModels}
            control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
            sx={{ gap: 1 }}
            label={<Typography variant="h5">Call models</Typography>}
          />
          <FormControlLabel
            value={PermissionOptionsEnum.ExportMetrics}
            control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
            sx={{ gap: 1 }}
            label={<Typography variant="h5">Export model metrics</Typography>}
          />
        </RadioGroup>
        {PermissionsWithConfigurableAccess.includes(selectedOption) && (
          <Stack>
            <Typography variant="h5">Access</Typography>
            <ModelList
              onModelSelect={onModelSelect}
              selectedModelIds={selectedModelIds}
              onAllModelsSelect={onAllModelsSelect}
              selectedAllModels={selectedAllModels}
            />
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
}

export default Permissions;
