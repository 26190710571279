import { BasetenPageEnum, BasetenPageProps } from '@/routes/types';

const routes: Array<BasetenPageProps> = [
  {
    page: BasetenPageEnum.View,
    path: ['/apps/:workflowId/views/:viewId', '/apps/:workflowId/:env/views/:viewId'],
  },
  {
    page: BasetenPageEnum.Worklet,
    path: [
      '/apps/:workflowId/worklets/:workletId/nodes/:nodeId?',
      '/apps/:workflowId/:env/worklets/:workletId/nodes/:nodeId?',
    ],
  },
  {
    page: BasetenPageEnum.Code,
    path: ['/apps/:workflowId/code/:fileId', '/apps/:workflowId/:env/code/:fileId'],
  },
];

export default routes;
