type FrameworkConfig = {
  colabLink: string;
  title: string;
};

export enum InstructionStep {
  INSTALL_TRUSS = 1,
  PACKAGE_MODEL = 2,
  DEPLOY_MODEL = 3,
  // We don't count this as a full step
  API_KEY = 3.5,
  INVOKE_MODEL = 4,
  WATCH_MODEL = 5,
}

export type { FrameworkConfig };
