import { ChangeEvent, FormEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import isEmpty from 'lodash/isEmpty';

import { ModalBox, ModalForm } from './styles';
import { CreateModalProps } from './types';

function CreateResource({ initialName, resourceType, onDone, container }: CreateModalProps) {
  const createModalId = `create-modal-${resourceType}`;

  const [resourceName, setResourceName] = useState(initialName ?? '');
  const [resourceNameChanged, setResourceNameChanged] = useState(false);
  const updateResourceName = (name: string) => {
    setResourceNameChanged(true);
    setResourceName(name);
  };

  function handleClose() {
    onDone(null);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    onDone({ name: resourceName });
  }

  const acceptableName = !isEmpty(resourceName);
  const error = resourceNameChanged && !acceptableName ? 'Resource name cannot be empty' : '';

  return (
    <Modal
      open
      container={container}
      aria-labelledby={`${createModalId}-title`}
      aria-describedby={`${createModalId}-description`}
    >
      <ModalBox sx={{ boxShadow: 2 }}>
        <Typography id={`${createModalId}-title`} variant="h6" component="h2" sx={{ mb: 2 }}>
          Create {resourceType}
        </Typography>
        <ModalForm onSubmit={handleSubmit}>
          <Tooltip title={error} color="error" placement="right" arrow>
            <TextField
              variant="outlined"
              autoFocus
              fullWidth
              value={resourceName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => updateResourceName(e.target.value)}
              placeholder={`Name this ${resourceType}`}
              error={Boolean(error)}
              data-cy={`${resourceType}-name-input`}
              sx={{ mb: 2 }}
            />
          </Tooltip>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => handleClose()}
              data-cy={`${createModalId}-cancel`}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!acceptableName}
              data-cy={`${createModalId}-ok`}
            >
              Create
            </Button>
          </Stack>
        </ModalForm>
      </ModalBox>
    </Modal>
  );
}

export default CreateResource;
