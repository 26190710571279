import { Divider as MuiDivider, Typography } from '@mui/material';

import type { DividerProps } from './types';

function Divider({ children }: DividerProps) {
  return (
    <MuiDivider>
      <Typography sx={{ px: 1 }} color="text.subtle" variant="body2">
        {children}
      </Typography>
    </MuiDivider>
  );
}

export default Divider;
