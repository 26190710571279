import { atom } from 'recoil';

import { OnboardingStep, SignupArgs } from './types';

const profileDataState = atom<SignupArgs>({
  key: 'profile-data-state',
  default: null,
});

const onboardingStep = atom<OnboardingStep>({
  key: 'onboarding-step-state',
  default: null,
});

export { profileDataState, onboardingStep };
