import { WorkflowIdentifier } from '../../entities/Workflows/types';
import {
  BackendLatestPyEnvRequirements,
  PyEnvRequirementsProps,
  PyEnvViewMap,
  PyEnvViewState,
} from './types';

const EMPTY_PYTHON_REQUIREMENTS_FILE = `# Use this file to provide the names of the Python packages you want to use in your worklets.
# Example:
# some_package==1.2.3
# -------
`;

const EMPTY_SYSTEM_REQUIREMENTS_FILE = `# Use this file to provide the names of the Debian packages you want to use in your worklets.
# Example:
# ffmpeg
# -------
`;

export function makeDefaultPyEnv(): PyEnvViewState {
  return {
    loading: false,
    deploymentStatusRefreshTimerId: null,
    latestPyEnvRequirements: {
      requirementsTxt: EMPTY_PYTHON_REQUIREMENTS_FILE,
      systemRequirements: EMPTY_SYSTEM_REQUIREMENTS_FILE,
      status: null,
      errorMessage: '',
      modified: null,
    },
    fetchError: '',
  };
}

export function ensurePyEnv(
  state: PyEnvViewMap,
  { releaseEnv, workflowId }: WorkflowIdentifier,
): PyEnvViewState {
  if (!state[releaseEnv][workflowId]) {
    // Ensure on read since workflows don't have a pyenv on the backend until one is "installed"
    state[releaseEnv][workflowId] = makeDefaultPyEnv();
  }

  return state[releaseEnv][workflowId];
}

export function pyEnvRequirementsFromBackend(
  response: BackendLatestPyEnvRequirements,
): PyEnvRequirementsProps {
  return {
    requirementsTxt: response.requirementsTxt,
    systemRequirements: response.systemRequirements,
    status: response.status,
    errorMessage: response.errorMessage,
    modified: new Date(response.modified),
  };
}
