import { Reducer } from 'react';

import ActionType from '../actions/ActionType';
import { fieldNotEmpty } from '../actions/udm';
import { Class, Field, PrimitiveType, UDMType, udmTypeFromString } from './state/Udm';

export interface FieldViewState {
  name: string;
  type?: UDMType;
  disabled: boolean;
}

export interface ClassViewState {
  classId?: string;
  name: string;
  typename: string;
  description: string;
  existingFields: Array<FieldViewState>;
  newFields: Array<FieldViewState>;
  dirty: boolean;
  showCancelModal: boolean;
  showDeleteWarnModal: boolean;
  showValidationErrorModal: boolean;
  toDeleteFieldName: string;
  toDeleteField: FieldViewState;
  validationErrors: Array<string>;
}

const ClassViewInit: ClassViewState = {
  classId: null,
  name: '',
  typename: '',
  description: '',
  existingFields: [
    {
      name: 'id',
      type: PrimitiveType.Integer,
      disabled: true,
    },
    {
      name: 'created_at',
      type: PrimitiveType.Datetime,
      disabled: true,
    },
    {
      name: 'updated_at',
      type: PrimitiveType.Datetime,
      disabled: true,
    },
  ],
  newFields: [
    { name: '', type: null, disabled: false },
    { name: '', type: null, disabled: false },
  ],
  dirty: false,
  showCancelModal: false,
  showDeleteWarnModal: false,
  showValidationErrorModal: false,
  toDeleteFieldName: '',
  toDeleteField: null,
  validationErrors: [],
};

function fieldViewStateFromField(field: Field): FieldViewState {
  return {
    name: field.name,
    type: udmTypeFromString(field.typ),
    disabled: ['id', 'created_at', 'updated_at'].includes(field.name),
  };
}

function classViewStateFromClass(klass: Class): ClassViewState {
  return {
    classId: klass.id,
    name: klass.name,
    typename: klass.typename,
    description: klass.description,
    existingFields: klass.fields.map(fieldViewStateFromField),
    newFields: [],
    dirty: false,
    showCancelModal: false,
    showDeleteWarnModal: false,
    showValidationErrorModal: false,
    toDeleteFieldName: '',
    toDeleteField: null,
    validationErrors: [],
  };
}

function removeArrayElementByIndex<T>(xs: Array<T>, index: number): Array<T> {
  return [...xs.slice(0, index), ...xs.slice(index + 1)];
}

function updateArrayElementAtIndex<T>(
  xs: Array<T>,
  index: number,
  transform: (t: T) => T,
): Array<T> {
  return [...xs.slice(0, index), transform(xs[index]), ...xs.slice(index + 1)];
}

function updateFieldNameAtIndex(
  fields: Array<FieldViewState>,
  index: number,
  newFieldName: string,
): Array<FieldViewState> {
  return updateArrayElementAtIndex(fields, index, (field) => ({ ...field, name: newFieldName }));
}

function updateFieldTypeAtIndex(
  fields: Array<FieldViewState>,
  index: number,
  newFieldType: UDMType,
): Array<FieldViewState> {
  return updateArrayElementAtIndex(fields, index, (field) => ({ ...field, type: newFieldType }));
}

function markFieldDeletedAtIndex(
  fields: Array<FieldViewState>,
  index: number,
): Array<FieldViewState> {
  return updateArrayElementAtIndex(fields, index, (field) => ({
    ...field,
    markedForDeletion: true,
  }));
}

function moldFieldName(origFieldName: string): string {
  let fieldName: string = origFieldName.replace(/[^0-9a-zA-Z_]/g, '');
  fieldName = fieldName.replace(/^[0-9_]*/g, '');
  return fieldName.toLowerCase();
}

function validateField(field: FieldViewState): Array<string> {
  const errors: Array<string> = [];
  if (field.name === '') {
    errors.push('The property name field cannot be empty.');
  }

  if (!field.type) {
    errors.push('You must define a data type for each property.');
  }
  return errors;
}

function validateClass(klass: ClassViewState): Array<string> {
  const errors: Array<string> = [];
  const fieldNameCounts = new Map<string, number>();
  if (!klass.typename) {
    errors.push('Model name cannot be empty');
  }
  const nonEmtpyNewFields = klass.newFields.filter(fieldNotEmpty);
  [...klass.existingFields, ...nonEmtpyNewFields].forEach((f: FieldViewState) => {
    const count = fieldNameCounts.get(f.name) || 0;
    fieldNameCounts.set(f.name, count + 1);
    errors.push(...validateField(f));
  });

  fieldNameCounts.forEach((count, fieldName) => {
    if (count > 1) {
      errors.push(
        `The property name, "${fieldName}", is used more than once. Property names must be unique.`,
      );
    }
  });
  return errors;
}

function validate(klass: ClassViewState): ClassViewState {
  return {
    ...klass,
    validationErrors: validateClass(klass),
  };
}

function validateWithClasses(klass: ClassViewState, classes: Array<Class>): ClassViewState {
  const crossClassesErrors = [];
  if (classes.some((k) => k.id !== klass.classId && k.typename === klass.typename)) {
    crossClassesErrors.push(`Model ${klass.typename} already exists, please use a different name.`);
  }
  return {
    ...klass,
    validationErrors: [...validateClass(klass), ...crossClassesErrors],
  };
}

const classViewReducer: Reducer<ClassViewState, any> = (state = ClassViewInit, action: any) => {
  switch (action.type) {
    case ActionType.CLASSVIEW_CLEAR_CLASS:
      return ClassViewInit;
    case ActionType.CLASSVIEW_SET_CLASS:
      return classViewStateFromClass(action.klass);
    case ActionType.CLASSVIEW_ADD_EMPTY_FIELD:
      return validate({
        ...state,
        newFields: [...state.newFields, { name: '', type: null, disabled: false }],
      });
    case ActionType.CLASSVIEW_REMOVE_EXISTING_FIELD:
      return validate({
        ...state,
        existingFields: markFieldDeletedAtIndex(state.existingFields, action.fieldIndex),
        dirty: true,
      });
    case ActionType.CLASSVIEW_REMOVE_NEW_FIELD:
      return validate({
        ...state,
        newFields: removeArrayElementByIndex(state.newFields, action.fieldIndex),
        dirty: true,
      });
    case ActionType.CLASSVIEW_RENAME_NEW_FIELD:
      return validate({
        ...state,
        newFields: updateFieldNameAtIndex(
          state.newFields,
          action.fieldIndex,
          moldFieldName(action.fieldName),
        ),
        dirty: true,
      });
    case ActionType.CLASSVIEW_RENAME_EXISTING_FIELD:
      return validate({
        ...state,
        existingFields: updateFieldNameAtIndex(
          state.existingFields,
          action.fieldIndex,
          moldFieldName(action.fieldName),
        ),
        dirty: true,
      });
    case ActionType.CLASSVIEW_CHANGE_NEW_FIELD_DATA_TYPE:
      return validate({
        ...state,
        newFields: updateFieldTypeAtIndex(state.newFields, action.fieldIndex, action.fieldType),
        dirty: true,
      });
    case ActionType.CLASSVIEW_UPDATE_CLASS_NAME:
      return validateWithClasses(
        {
          ...state,
          name: action.name,
          dirty: true,
        },
        action.classes,
      );
    case ActionType.CLASSVIEW_UPDATE_CLASS_TYPENAME: {
      let typename: string = action.typename.replace(/[^0-9a-zA-Z]/g, '');
      typename = typename.replace(/^[0-9]*/g, '');
      typename = typename.charAt(0).toUpperCase() + typename.slice(1);
      return validateWithClasses(
        {
          ...state,
          typename,
          dirty: true,
        },
        action.classes,
      );
    }
    case ActionType.CLASSVIEW_UPDATE_CLASS_DESCRIPTION:
      return validate({
        ...state,
        description: action.description,
        dirty: true,
      });
    case ActionType.CLASSVIEW_SHOW_CANCEL_MODAL:
      return {
        ...state,
        showCancelModal: true,
      };
    case ActionType.CLASSVIEW_CLOSE_CANCEL_MODAL:
      return {
        ...state,
        showCancelModal: false,
      };
    case ActionType.CLASSVIEW_SHOW_DELETE_WARN_MODAL:
      return {
        ...state,
        showDeleteWarnModal: true,
        toDeleteFieldName: action.toDeleteFieldName || state.toDeleteFieldName,
        toDeleteField: action.toDeleteField || state.toDeleteField,
      };
    case ActionType.CLASSVIEW_CLOSE_DELETE_WARN_MODAL:
      return {
        ...state,
        showDeleteWarnModal: false,
        toDeleteFieldName: '',
        toDeleteField: null,
      };
    case ActionType.CLASSVIEW_SHOW_VALIDATION_ERROR_MODAL:
      return {
        ...state,
        showValidationErrorModal: true,
      };
    case ActionType.CLASSVIEW_CLOSE_VALIDATION_ERROR_MODAL:
      return {
        ...state,
        showValidationErrorModal: false,
      };
    default:
      return state;
  }
};

export default classViewReducer;
