import { Link, Stack } from '@mui/material';

import { links } from '@/localization';

import { InstructionStep } from '../types';
import Code from './Code';
import GenerateKey from './GenerateKey';
import Instruction from './Instruction';

function Instructions() {
  return (
    <Stack direction="column" gap={3}>
      <Instruction
        step={InstructionStep.INSTALL_TRUSS}
        title={
          <>
            Install the{' '}
            <Link href={links.trussPypi} target="_blank">
              Truss CLI
            </Link>
          </>
        }
      >
        <Code>pip install --upgrade truss</Code>
      </Instruction>
      <Instruction step={InstructionStep.PACKAGE_MODEL} title="Package your model">
        <Code>truss init ./model-name</Code>
      </Instruction>
      <Instruction step={InstructionStep.DEPLOY_MODEL} title="Deploy your model">
        <Code>truss push</Code>
      </Instruction>
      <Instruction
        step={InstructionStep.API_KEY}
        title="First time deploying? Authenticate with an API key"
        hideStepCount
      >
        <GenerateKey />
      </Instruction>
      <Instruction step={InstructionStep.INVOKE_MODEL} title="Invoke your deployed model">
        <Code>{'truss predict -d \'"howdy"\''}</Code>
      </Instruction>
      <Instruction
        step={InstructionStep.WATCH_MODEL}
        title="Iterate on your model with a live reload workflow"
      >
        <Code>truss watch</Code>
      </Instruction>
    </Stack>
  );
}

export default Instructions;
