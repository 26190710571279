/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { WorkletWithNodesFragmentDoc, NodeFragmentDoc } from '../../../slices/entities/Worklets/__generated__/fragments.generated';
import { WorkletScheduleFragmentDoc } from '../../WorkletSchedule/__generated__/fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkletMutationVariables = Types.Exact<{
  workletId: Types.Scalars['String'];
  encodedAction: Types.Scalars['String'];
  currentVersionId: Types.Scalars['String'];
}>;


export type WorkletMutation = { __typename?: 'Mutation', worklet_mutation: { __typename?: 'WorkletMutation', worklet: { __typename?: 'WorkletType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any | null | undefined, currentVersionId: string | null | undefined, executeAsyncByDefault: boolean, allowedDomains: Array<string>, nodeGraph: any, nodes: Array<{ __typename?: 'NodeType', id: string, atom: any, name: string, groupName: string | null | undefined, signature: { __typename?: 'AtomSignature', returnAnnotation: string | null | undefined, args: Array<{ __typename?: 'CallableParam', name: string | null | undefined, kind: string | null | undefined, default: string | null | undefined, annotation: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, viewNodeViews: Array<{ __typename?: 'PubliclyVisibleViewType', created: any | null | undefined, modified: any, id: string | null | undefined, name: string } | null | undefined> | null | undefined } | null | undefined> | null | undefined, signature: { __typename?: 'AtomSignature', returnAnnotation: string | null | undefined, args: Array<{ __typename?: 'CallableParam', name: string | null | undefined, kind: string | null | undefined, default: string | null | undefined, annotation: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, schedule: { __typename?: 'WorkletScheduleType', display_timezone: string, enabled: boolean, cron: { __typename?: 'CeleryCrontabScheduleType', minute: string, hour: string, day_of_week: string, day_of_month: string, month_of_year: string } | null | undefined } | null | undefined } | null | undefined } | null | undefined };


export const WorkletMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"WorkletMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workletId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"encodedAction"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"worklet_mutation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"worklet_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workletId"}}},{"kind":"Argument","name":{"kind":"Name","value":"encoded_action"},"value":{"kind":"Variable","name":{"kind":"Name","value":"encodedAction"}}},{"kind":"Argument","name":{"kind":"Name","value":"current_version_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"worklet"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkletWithNodesFragment"}}]}}]}}]}},...WorkletWithNodesFragmentDoc.definitions,...NodeFragmentDoc.definitions,...WorkletScheduleFragmentDoc.definitions]} as unknown as DocumentNode<WorkletMutation, WorkletMutationVariables>;
export const namedOperations = {
  Mutation: {
    WorkletMutation: 'WorkletMutation'
  }
}

export type WorkletMutationMutationFn = Apollo.MutationFunction<WorkletMutation, WorkletMutationVariables>;

/**
 * __useWorkletMutation__
 *
 * To run a mutation, you first call `useWorkletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workletMutation, { data, loading, error }] = useWorkletMutation({
 *   variables: {
 *      workletId: // value for 'workletId'
 *      encodedAction: // value for 'encodedAction'
 *      currentVersionId: // value for 'currentVersionId'
 *   },
 * });
 */
export function useWorkletMutation(baseOptions?: Apollo.MutationHookOptions<WorkletMutation, WorkletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkletMutation, WorkletMutationVariables>(WorkletMutationDocument, options);
      }
export type WorkletMutationHookResult = ReturnType<typeof useWorkletMutation>;
export type WorkletMutationMutationResult = Apollo.MutationResult<WorkletMutation>;
export type WorkletMutationMutationOptions = Apollo.BaseMutationOptions<WorkletMutation, WorkletMutationVariables>;