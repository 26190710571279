import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';

import Loading from '@/components/Loading';
import { BasetenPageEnum, usePageSwitcher } from '@/routes';
import { useSnackbar } from 'notistack';
import { useSetRecoilState } from 'recoil';

import { useExistingOrganizationQuery } from '../../__generated__/queries.generated';
import { onboardingStep } from '../../atoms';
import { useCompleteOnboarding } from '../../hooks';
import { OnboardingStep } from '../../types';
import { Body, Container, Description, Header, Title } from '../styled';
import { getExistingOrganizationData } from '../utils';
import { useRequestToJoinExistingOrgMutation } from './__generated__/mutations.generated';
import { MAX_WIDTH } from './constants';

function ExistingWorkspace() {
  const setStep = useSetRecoilState(onboardingStep);
  const { data: existingOrganizationData, loading } = useExistingOrganizationQuery({
    fetchPolicy: 'no-cache',
  });
  const goToPage = usePageSwitcher();
  const { enqueueSnackbar } = useSnackbar();
  const [requestToJoin] = useRequestToJoinExistingOrgMutation();
  const completeOnboarding = useCompleteOnboarding();

  async function nextStep(joinExisting: boolean) {
    try {
      await requestToJoin({ variables: { joinExisting } });

      if (ENV_CONFIG.USE_ABRIDGED_ONBOARDING && joinExisting) {
        await completeOnboarding();
        goToPage(BasetenPageEnum.DeployModelViaTruss);
        return;
      }

      if (joinExisting) {
        await completeOnboarding();
        setStep(OnboardingStep.RequestedToJoin);
      } else {
        setStep(OnboardingStep.CreateWorkspace);
      }
    } catch (e) {
      enqueueSnackbar('The request could not be completed. Please try again.', {
        variant: 'error',
      });
    }
  }

  if (loading || !existingOrganizationData) {
    return <Loading />;
  }

  const { adminName, emailDomain, workspaceName } =
    getExistingOrganizationData(existingOrganizationData);

  return (
    <Container sx={{ maxWidth: MAX_WIDTH }}>
      <Header>
        <Title data-cy="onb-title">Join your team</Title>
        <Description variant="subtitle1" color="text.muted">
          {`You signed up with a @${emailDomain} email address. Would you like${
            ENV_CONFIG.USE_ABRIDGED_ONBOARDING ? '' : ' to request'
          } to join the ${workspaceName} workspace owned by ${adminName}?`}
        </Description>
      </Header>
      <Body>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            variant="contained"
            loading={loading}
            type="submit"
            data-cy="join-workspace"
            color="primary"
            onClick={() => nextStep(true)}
          >
            {ENV_CONFIG.USE_ABRIDGED_ONBOARDING
              ? `Yes, join the ${workspaceName} workspace`
              : `Yes, request to join the ${workspaceName} workspace`}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            loading={loading}
            type="submit"
            data-cy="create-workspace"
            color="secondary"
            onClick={() => nextStep(false)}
          >
            No, create my own workspace
          </LoadingButton>
        </Stack>
        <Typography variant="caption" color="text.secondary">
          Your email address can only be associated with one workspace. If you choose to create your
          own workspace, you cannot merge it with an existing workspace later.
        </Typography>
      </Body>
    </Container>
  );
}
export default ExistingWorkspace;
