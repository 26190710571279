import { Stack } from '@mui/material';

import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

import { ContainerProps } from './types';

const Container = styled(Stack, {
  shouldForwardProp: (prop) => !['isHighlighted', 'isOutput', 'multiline'].includes(prop as string),
})<ContainerProps>(({ theme, isHighlighted, isOutput, multiline }) => ({
  padding: theme.spacing(1, 2),
  gap: theme.spacing(isOutput ? 1 : 2),
  flexDirection: isOutput ? 'column' : 'row',
  transition: 'all 0.2s ease',
  borderTop: isHighlighted
    ? getBorder(theme.palette.border.success)
    : getBorder(theme.palette.background.default),
  background: isHighlighted ? theme.palette.background.success : 'inherit',
  ...(multiline
    ? {
        ':first-of-type': {
          borderBottom: getBorder(theme.palette.background.default),
        },
      }
    : {
        borderBottom: isHighlighted
          ? getBorder(theme.palette.border.success)
          : getBorder(theme.palette.background.default),
      }),
}));

export { Container };
