import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function SquareCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55556 1H13.4444C14.3036 1 15 1.69645 15 2.55556V13.4444C15 14.3036 14.3036 15 13.4444 15H2.55556C1.69645 15 1 14.3036 1 13.4444V2.55556C1 1.69645 1.69645 1 2.55556 1ZM11.9641 6.14527C12.2284
        5.88895 12.2349 5.46689 11.9786 5.20257C11.7223 4.93825 11.3002 4.93175 11.0359 5.18807L6.6875 9.4047L4.9641 7.73352C4.69977 7.47721 4.27771 7.4837 4.0214 7.74802C3.76509 8.01235 3.77158 8.43441 4.0359
        8.69072L6.2234 10.8119C6.48199 11.0627 6.89301 11.0627 7.1516 10.8119L11.9641 6.14527Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default SquareCheckedIcon;
