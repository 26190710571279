import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';

import { ReleaseEnv } from '@/types/releaseEnv';

import ActionType, { RpcActionPrefix } from '../../actions/ActionType';
import { refreshWorklet } from '../../actions/WorkletActions';
import { updateWorkflow } from '../../slices/entities/Workflows';
import { Node, NodeInit, nodeFromBackendNode } from '../state/Node';

type Nodes = Record<ReleaseEnv, Record<string, Node>>;

export const nodesReducer = createReducer<Nodes>(
  { [ReleaseEnv.Draft]: {}, [ReleaseEnv.Production]: {} },
  (builder) => {
    builder
      .addCase(updateWorkflow, (state, action) => {
        const { entities, workflowIdentifier } = action.payload;
        const nodes = mapValues(entities.nodes, nodeFromBackendNode);

        for (const [nodeId, node] of Object.entries(nodes)) {
          if (!state[workflowIdentifier.releaseEnv]) {
            state[workflowIdentifier.releaseEnv] = {};
          }
          state[workflowIdentifier.releaseEnv][nodeId] = node;
        }
      })
      .addCase(refreshWorklet, (state, action) => {
        const { entities, workletIdentifier } = action.payload;
        const nodes = mapValues(entities.nodes, nodeFromBackendNode);

        for (const [nodeId, node] of Object.entries(nodes)) {
          if (!state[workletIdentifier.releaseEnv]) {
            state[workletIdentifier.releaseEnv] = {};
          }
          state[workletIdentifier.releaseEnv][nodeId] = node;
        }
      });
  },
);

export const nodeReducer: Reducer<Node, any> = (state: Node = NodeInit, action: any): Node => {
  switch (action.type) {
    case `${RpcActionPrefix.SAVE_NODE}.Start`: {
      return {
        ...state,
        saving: true,
        runResponse: '',
      };
    }
    case `${RpcActionPrefix.SAVE_NODE}.Failed`: {
      return {
        ...state,
        saving: false,
        saveResponse: action.error.message,
      };
    }
    case `${RpcActionPrefix.SAVE_NODE}.Done`: {
      return {
        ...state,
        saving: false,
        unsaved: false,
        saveResponse: '',
      };
    }
    case ActionType.NODE_UPDATE_SRC: {
      return {
        ...state,
        atom: {
          ...state.atom,
          conf: {
            ...state.atom.conf,
            callableSource: action.src.split('\n'),
          },
        },
        unsaved: true,
        saving: !!state.saving,
      };
    }
    case ActionType.NODE_DIRTY: {
      return {
        ...state,
        unsaved: !action.reset,
      };
    }
    case ActionType.GROUP_SELECTED_NODES: {
      return {
        ...state,
        group: action.groupName,
      };
    }
    case ActionType.NODE_RESET_SRC: {
      return {
        ...state,
        atom: {
          ...state.atom,
          conf: {
            ...state.atom.originalConf,
          },
        },
        unsaved: false,
        saving: false,
      };
    }
    case ActionType.NODE_UPDATE: {
      const newState = cloneDeep(state);
      merge(newState, action.node);
      return newState;
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Start`: {
      return {
        ...state,
        executing: true,
      };
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Failed`: {
      return {
        ...state,
        executing: false,
        runResponse: action.error.message,
      };
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Done`: {
      return {
        ...state,
        executing: false,
        runResponse: action.payload,
      };
    }
    default:
      return state;
  }
};
