import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

import { getInstanceName } from '@/utils/collections';
import { handleQueryErrors } from '@/utils/errorHandling';
import moment from 'moment';

import { ThunkAPI } from '../../../types';
import {
  AppViewLoadingState,
  queriesFetchCompleted,
  queriesFetchFailed,
  queriesFetchStart,
} from '../../ui/AppView';
import { QueryFragment } from './__generated__/fragments.generated';
import { CreateQueryDocument, DeleteQueryDocument } from './__generated__/mutations.generated';
import { OrgQueriesDocument, UpdateQueryNameDocument } from './__generated__/queries.generated';
import { QueriesMap, QueryEntity, QueryIdentifier } from './types';

const initialState = {} as QueriesMap;

const queryFromResponse = (response: QueryFragment) => {
  return {
    created: moment(response.created),
    modified: moment(response.modified),
    id: response.id,
    name: response.name,
    queryStr: response.queryStr,
    queryParamNames: response.queryParamNames,
    dataSourceId: response.dataSource?.id,
    currentVersionId: response.currentVersionId,
  } as QueryEntity;
};

export const fetchQueries = createAsyncThunk<QueriesMap, boolean | void, ThunkAPI>(
  'queries-entities/fetchQueries',
  async (refetch = false, { dispatch, getState, extra }) => {
    const {
      ui: {
        appView: { queries },
      },
    } = getState();

    if (queries === AppViewLoadingState.None || refetch) {
      if (queries === AppViewLoadingState.None) {
        // Don't put queires back in loading state if they've already been loaded
        dispatch(queriesFetchStart());
      }

      const { data, error } = await extra.apolloClient.query({ query: OrgQueriesDocument });

      if (error) {
        dispatch(queriesFetchFailed(error.message));
      } else {
        dispatch(queriesFetchCompleted());
      }

      return keyBy(map(data.queries, queryFromResponse), 'id');
    }
  },
);

export const saveQuery = createAsyncThunk<
  QueryEntity,
  QueryIdentifier & {
    name?: string;
    queryString?: string;
    connectionName?: string;
  },
  ThunkAPI
>(
  'queries-entities/saveQuery',
  async (
    { queryId, name, queryString = '', connectionName = '' },
    { dispatch, getState, extra },
  ) => {
    const {
      entities: { queries },
    } = getState();

    const currentQuery = queries[queryId];
    const existingQuery = !!currentQuery;

    const queryName =
      name ?? currentQuery?.name ?? getInstanceName(Object.values(queries), 'Untitled query ');
    let saved;

    try {
      const { data } = await extra.apolloClient.mutate({
        mutation: CreateQueryDocument,
        variables: {
          name: queryName,
          queryString,
          connectionName,
          queryId,
          currentVersionId: existingQuery ? currentQuery.currentVersionId : null,
        },
      });
      saved = queryFromResponse(data.createQuery.query);
      return saved;
    } catch (ex) {
      handleQueryErrors(dispatch, ex, 'save query');
    }
  },
);

export const renameQuery = createAsyncThunk<
  void,
  QueryIdentifier & {
    name: string;
  },
  ThunkAPI
>('queries-entities/renameQuery', async ({ queryId, name }, { dispatch, getState, extra }) => {
  const {
    entities: { queries },
  } = getState();

  const currentQuery = queries[queryId];

  try {
    const { data } = await extra.apolloClient.mutate({
      mutation: UpdateQueryNameDocument,
      variables: {
        queryId,
        name,
        currentVersionId: currentQuery.currentVersionId,
      },
    });
    const saved = queryFromResponse(data.updateQueryName.query);
    dispatch(updateQueryEntity({ queryId, name, currentVersionId: saved.currentVersionId }));
  } catch (ex) {
    handleQueryErrors(dispatch, ex, 'rename query');
  }
});

export const deleteQuery = createAsyncThunk<string, QueryIdentifier, ThunkAPI>(
  'queries-entities/deleteQuery',
  async ({ queryId }, { dispatch, getState, extra }) => {
    const {
      entities: { queries },
    } = getState();

    const currentQuery = queries[queryId];

    try {
      await extra.apolloClient.mutate({
        mutation: DeleteQueryDocument,
        variables: {
          queryId,
          currentVersionId: currentQuery.currentVersionId,
        },
      });
      return queryId;
    } catch (ex) {
      handleQueryErrors(dispatch, ex, 'delete query');
    }
  },
);

const Queries = createSlice({
  name: 'queries-entities',
  initialState,
  reducers: {
    updateQueryEntity(state, action) {
      const query = state[action.payload.queryId];
      query.name = action.payload.name;
      query.currentVersionId = action.payload.currentVersionId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQueries.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(saveQuery.fulfilled, (state, action) => {
      const query = action.payload;
      state[query.id] = query;
    });
    builder.addCase(deleteQuery.fulfilled, (state, action) => {
      delete state[action.payload];
    });
  },
});

export const { updateQueryEntity } = Queries.actions;

export default Queries.reducer;
