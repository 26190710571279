import { ExistingOrganizationQuery } from '../__generated__/queries.generated';
import type { ExistingOrganizationData } from '../types';

function getExistingOrganizationData(
  existingOrganizationData: ExistingOrganizationQuery,
): ExistingOrganizationData {
  const { existingOrganization } = existingOrganizationData;

  if (!existingOrganization) {
    throw new Error('There was a problem extracting existing workspace data. Please try again.');
  }
  const { displayName: workspaceName, adminName, domain } = existingOrganization;

  return {
    adminName,
    emailDomain: domain,
    workspaceName,
  };
}

export { getExistingOrganizationData };
