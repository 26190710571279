import { Reducer } from 'redux';

import each from 'lodash/each';

import { cleanErrorMessage } from '@/utils/errorHandling';

import ActionType, { RpcActionPrefix } from '../actions/ActionType';
import { createWorklet, renameWorklet } from '../slices/entities/Worklets';

interface ConsoleViewState {
  title: string;
  text: string;
  section: string;
  testingSection: string;
  syntaxHighlightLanguage: string;
  showErrorState: boolean;
  showConsoleStatus: boolean;
  running: boolean;
}

export const CONSOLE_HEIGHTS = Object.freeze({
  MINIMUM_HEIGHT: 46,
  SET_HEIGHT: 300,
  MAXIMUM_HEIGHT: 1000,
});

export const CONSOLE_SECTION = {
  TESTING: 'testing',
  NODE_TESTING: 'node_testing',
  OUTPUT: 'output',
  RUNS: 'runs',
  UDM: 'udm',
};

export const TESTING_SECTION = {
  JSON_INPUT: 'jsonInput',
  WORKLET_TEST_CASES: 'workletTestCases',
};

const consoleViewInit: ConsoleViewState = {
  section: CONSOLE_SECTION.TESTING,
  testingSection: TESTING_SECTION.JSON_INPUT,
  title: 'Console',
  text: '',
  syntaxHighlightLanguage: 'plaintext',
  showConsoleStatus: false,
  showErrorState: false,
  running: false,
};

export const rpcErrorState = (state: any, action: any, title: string) => ({
  ...state,
  title,
  text: cleanErrorMessage(action.error),
  syntaxHighlightLanguage: 'python',
  showErrorState: true,
  showConsoleStatus: true,
  running: false,
});

function prefixLines(str: string, prefix: string): string {
  const lines = str.split('\n');
  const prefixed = lines.map((line) => `${prefix}${line}`);
  return prefixed.join('\n');
}

const suffixNewline = (str: string) => `${str}\n`;

export function toMessage(record: Record<string, string>): string {
  let msg = '';
  each(record, (value, key) => {
    msg = msg.concat(`${key}:\n`);
    msg = msg.concat(suffixNewline(prefixLines(value ?? '', '    ')));
  });
  return msg;
}

export const actionToWorkletOutput = (action: any): string => {
  const {
    worklet_output: workletOutput,
    execution_log: executionLog,
    message: outputMessage,
  } = action.payload.response;

  const outputStr: string = JSON.stringify(workletOutput, null, 2);
  const message =
    outputMessage ||
    toMessage({
      Output: outputStr,
      Logs: executionLog,
    });
  return message;
};

export const actionToNodeOutput = (action: any): string => {
  const { node_output: nodeOutput, execution_log: executionLog } = action.payload.response;

  const message = toMessage({
    Output: nodeOutput,
    Logs: executionLog,
  });
  return message;
};

const consoleViewReducer: Reducer<ConsoleViewState, any> = (
  state: ConsoleViewState = consoleViewInit,
  action: any,
) => {
  switch (action.type) {
    case `${RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING}.Start`:
    case `${RpcActionPrefix.EXECUTE_WORKLET}.Start`: {
      return {
        ...state,
        running: true,
        title: 'Executing worklet...',
        text: '',
        syntaxHighlightLanguage: 'plaintext',
      };
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Start`: {
      return {
        ...state,
        running: true,
        title: 'Executing node...',
        text: '',
        syntaxHighlightLanguage: 'plaintext',
      };
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Failed`:
      return rpcErrorState(state, action, 'Node execution failed');
    case `${RpcActionPrefix.EXECUTE_NODE}.Done`: {
      const nodeOutput = actionToNodeOutput(action);
      return {
        ...state,
        title: 'Node execution response',
        text: nodeOutput,
        syntaxHighlightLanguage: 'python',
        showErrorState: false,
        showConsoleStatus: true,
        running: false,
      };
    }
    case `${RpcActionPrefix.SAVE_NODE}.Failed`:
      return rpcErrorState(state, action, 'Error saving node');
    case `${RpcActionPrefix.WORKLET_ADD_CHILD_NODE}.Failed`:
      return rpcErrorState(state, action, 'Error adding child node');
    case `${RpcActionPrefix.WORKLET_ADD_PARENT_NODE}.Failed`:
      return rpcErrorState(state, action, 'Error adding parent node');
    case `${RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING}.Done`:
    case `${RpcActionPrefix.EXECUTE_WORKLET}.Done`: {
      const message = actionToWorkletOutput(action);
      return {
        ...state,
        title: 'Worklet execution response',
        text: message,
        syntaxHighlightLanguage: 'javascript',
        showErrorState: false,
        showConsoleStatus: true,
        running: false,
      };
    }
    case `${RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING}.Failed`:
    case `${RpcActionPrefix.EXECUTE_WORKLET}.Failed`:
      return rpcErrorState(state, action, 'Error executing worklet');
    case `${renameWorklet.rejected}`:
      return rpcErrorState(state, action, 'Error renaming worklet');
    case `${createWorklet.rejected}`:
      return rpcErrorState(state, action, 'Error creating worklet');
    case ActionType.SET_CONSOLE_SECTION:
      return {
        ...state,
        section: action.section,
      };
    case ActionType.SET_WORKLET_TESTING_SECTION:
      return {
        ...state,
        testingSection: action.section,
      };
    default:
      return state;
  }
};

export default consoleViewReducer;
