import { FC, ReactElement, ReactNode } from 'react';

enum TabsEnum {
  console = 'console',
  testing = 'testing',
  data = 'data',
  logs = 'logs',
}

type TabProps = {
  closeToolbar: () => void;
};

type WrapperProps = {
  hasSidePanel?: boolean;
};

type ContainerProps = {
  fullHeight?: boolean;
};

type TabConfig = {
  component: FC<TabProps>;
  icon: ReactElement;
  label: ReactNode;
  id: TabsEnum;
  disabledOnProd?: boolean;
  disabledMessage?: string;
};

export { TabsEnum, TabProps, WrapperProps, ContainerProps, TabConfig };
