import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../types';
import { WorkflowEntity, WorkflowIdentifier } from './types';

const selectSharedStates = (workflow: WorkflowEntity) => workflow.sharedStates;

const workflowSelector = (
  rootState: RootState,
  { workflowId, releaseEnv }: WorkflowIdentifier,
): WorkflowEntity => rootState.entities.workflows[releaseEnv][workflowId];
const sharedStatesSelector = createSelector(workflowSelector, selectSharedStates);

export { workflowSelector, sharedStatesSelector };
