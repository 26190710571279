import { useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { ParamsFormElementSpec, ParamsFormElementType } from './FormElem';
import ParamsFormSpec from './ParamsFormSpec';
import { ParamsFormActionElementProps } from './types';

function ParamsFormActionElement({
  elem,
  paramValue,
  onChange,
  disabled,
  dataCyPrefix,
  focus,
}: ParamsFormActionElementProps) {
  if (elem.type === ParamsFormElementType.bool) {
    return (
      <Checkbox
        sx={{ alignSelf: 'center', p: 0, m: 0, ml: 1 }}
        edge="end"
        size="small"
        checked={Boolean(paramValue)}
        onChange={(e) => onChange(e.target.checked)}
        inputProps={{ 'data-cy': `param-form-elem-${elem.id}` }}
        id={`param-form-elem-id-${elem.id}`}
        autoFocus={focus}
      />
    );
  }

  const extraProps =
    elem.type === ParamsFormElementType.textarea
      ? {
          multiline: true,
          maxRows: 4,
        }
      : {};

  return (
    <TextField
      fullWidth
      type={elem.type === ParamsFormElementType.password ? 'password' : 'text'}
      placeholder={elem.placeholder || ''}
      value={paramValue}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      autoFocus={focus}
      variant="outlined"
      inputProps={{ 'data-cy': `${dataCyPrefix}-param-form-elem-${elem.id}` }}
      {...extraProps}
    />
  );
}

interface ParamFormElementProps {
  elem: ParamsFormElementSpec;
  paramValue: any;
  width: string;
  onChange: (newParamValue: any) => void;
  disabled: boolean;
  dataCyPrefix?: string;
  focus?: boolean;
}

function ParamsFormElement({
  elem,
  paramValue,
  width,
  onChange,
  disabled,
  dataCyPrefix,
  focus,
}: ParamFormElementProps) {
  const boxProps =
    elem.type === ParamsFormElementType.bool
      ? {
          width,
          display: 'flex',
          alignItems: 'flex-start',
        }
      : {
          width,
        };
  return (
    <Box {...boxProps}>
      {elem.displayName && (
        <InputLabel
          sx={{ alignSelf: 'center', p: 0, m: 0 }}
          htmlFor={`param-form-elem-id-${elem.id}`}
        >
          {elem.displayName}
        </InputLabel>
      )}
      <ParamsFormActionElement
        elem={elem}
        paramValue={paramValue}
        onChange={onChange}
        disabled={disabled}
        dataCyPrefix={dataCyPrefix}
        focus={focus}
      />
    </Box>
  );
}

interface ParamsFormProps {
  params: Record<string, any>;
  onChange: (newParams: Record<string, any>) => void;
  disabled: boolean;
  dataCyPrefix?: string;
}

function ParamsFormBuilder(spec: ParamsFormSpec) {
  return ({ params, onChange, disabled, dataCyPrefix }: ParamsFormProps) => {
    // TODO(Suren -> Pankaj): Please check this
    // React Hook "useState" cannot be called inside a callback.
    // React Hooks must be called in a React function component or
    // a custom React Hook function  react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [localParams, setLocalParams] = useState(params);
    const updateParam = (
      paramName: string,
      newParamValue: any,
      triggerOnChange: boolean = true,
    ) => {
      const newLocalParams = {
        ...localParams,
        [paramName]: newParamValue,
      };
      setLocalParams(newLocalParams);
      if (triggerOnChange) {
        onChange(newLocalParams);
      }
    };

    return (
      <Box>
        {spec.layout.map((rowOfIds) => (
          <Stack mb={1} direction="row" gap={2} key={rowOfIds.join('.')}>
            {rowOfIds.map((paramId) => {
              const elem = spec.getElem(paramId);
              const showElem = !elem.dependsOn?.some((param) => !localParams[param]);
              if (!showElem && localParams[elem.name] !== elem.defaultValue) {
                // To not trigger a rebuild during render, we pass `false` to not trigger `onChange`. The component only tries
                // to re-render during render when we call onChange. Since this is the case where `showElem` is false, the
                // component won't render neither the first nor second time. This line simply clears the field if the component
                // it depends on is cleared (i.e. we clear text when we hide a text box).
                updateParam(elem.name, elem.defaultValue, false);
              }

              const paramName = elem.name;
              return (
                showElem && (
                  <ParamsFormElement
                    width="100%"
                    elem={elem}
                    paramValue={params[paramName]}
                    onChange={(newParamValue) => updateParam(paramName, newParamValue)}
                    disabled={disabled}
                    key={paramId}
                    dataCyPrefix={dataCyPrefix}
                    focus={spec.focusElem && elem.id === spec.focusElem}
                  />
                )
              );
            })}
          </Stack>
        ))}
      </Box>
    );
  };
}

export default ParamsFormBuilder;
