import API from '@/API';

async function login(usernameOrEmail: string, password?: string) {
  const parameters: { email: string; password?: string; next?: string } = {
    email: usernameOrEmail,
  };

  if (password) {
    parameters.password = password;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const nextPage = urlParams.get('next');

  if (nextPage) {
    parameters.next = nextPage;
  }

  try {
    const response = await API.postWithoutCallback('/api/login', parameters);
    const { success, next, error }: { success: boolean; next?: string; error?: string } =
      response.data;

    if (success && next) {
      window.location.href = next;
    }

    return { success, error };
  } catch (error) {
    throw new Error('An unexpected error has occurred');
  }
}

export { login };
