import { nanoid } from 'nanoid';

/**
 * Generates a 7 character nanoid
 * @param prefix To disambiguate between types of id's
 */
function generateId(prefix: string = ''): string {
  return prefix ? `${prefix}-${nanoid(7)}` : nanoid(7);
}

/**
 * Generates the next available id in a numerical sequence (ie. text_box_1, text_box_2)
 * given an existing list of ids
 * @param prefix The string prefix for the id (ie. text_box)
 * @param existingIds List of existing ids to determine the next numerical sequence
 */
function generateNextSequentialId(prefix: string, existingIds: string[]): string {
  let index = 0;
  existingIds.forEach((id) => {
    if (id.indexOf(prefix) >= 0) {
      index = Math.max(+id.replace(/^.*\D/g, ''), index);
    }
  });
  index += 1;
  return `${prefix}${index}`;
}

export { generateId, generateNextSequentialId };
