/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WorkletScheduleFragment = { __typename?: 'WorkletScheduleType', display_timezone: string, enabled: boolean, cron: { __typename?: 'CeleryCrontabScheduleType', minute: string, hour: string, day_of_week: string, day_of_month: string, month_of_year: string } | null | undefined };

export const WorkletScheduleFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkletScheduleFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WorkletScheduleType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"display_timezone"}},{"kind":"Field","name":{"kind":"Name","value":"cron"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"minute"}},{"kind":"Field","name":{"kind":"Name","value":"hour"}},{"kind":"Field","name":{"kind":"Name","value":"day_of_week"}},{"kind":"Field","name":{"kind":"Name","value":"day_of_month"}},{"kind":"Field","name":{"kind":"Name","value":"month_of_year"}}]}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]} as unknown as DocumentNode<WorkletScheduleFragment, unknown>;
export const namedOperations = {
  Fragment: {
    WorkletScheduleFragment: 'WorkletScheduleFragment'
  }
}
