import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { getOrgFromCache } from '@/hooks/useOrg';
import { getUserFromCache } from '@/hooks/useUser';
import { DatasourceBinding } from '@/pages/Application/View/datasources/types';
import {
  RenderAs,
  Resolution,
  ResolutionStatus,
  ResolutionTask,
} from '@/pages/Application/View/types';
import { Variable } from '@/pages/Application/View/vars/types';
import { MatchParams } from '@/pages/Application/types';
import { RoleTypes } from '@/pages/Settings/Tabs/Members/types';
import { BasetenPageEnum, getMatchFromPath } from '@/routes';

import { startEntityProcessor } from '../../../../middleware/EntityProcessor';
import { invokeCode, invokeDatasource, resolveParams, shouldInvoke } from '../data-resolution';
import { clearResolutionQueue, fetchOperatorViewById, setData } from '../index';
import { resolutionQueueSelector, viewSelector } from '../selectors';
import { ViewIdentifier } from '../types';
import { isViewIdentifier } from '../utils';

export function startDataResolutionListener() {
  // async data resolution listener
  startEntityProcessor<ViewIdentifier>({
    predicate: (action, currentState) => {
      if (!action.payload && !action.meta) {
        // not an RTK action
        return false;
      }
      const viewId = action.payload?.viewId || action.meta?.arg?.viewId;
      const releaseEnv = action.payload?.releaseEnv || action.meta?.arg?.releaseEnv;
      const workflowId = action.payload?.workflowId || action.meta?.arg?.workflowId;
      if (!viewId) {
        return false;
      }
      if (!workflowId) {
        throw new Error(`Not resolving data for action ${action.type}. Missing workflow id`);
      }
      if (!releaseEnv) {
        if (fetchOperatorViewById.pending.match(action)) {
          // Release env isn't known when the operator view is first fetched
          return false;
        }
        // Fix to try to unblock this crash: https://basetenlabs.slack.com/archives/C01HEHX9GSW/p1658878293324569
        // throw new Error(`Not resolving data for action ${action.type}. Missing releaseEnv`);
        // eslint-disable-next-line no-console
        console.error(`Not resolving data for action ${action.type}. Missing releaseEnv`);
        return false;
      }
      const currentQueue = resolutionQueueSelector(currentState, {
        viewId,
        releaseEnv,
        workflowId,
      });
      if (isEmpty(currentQueue)) {
        return false;
      }
      return true;
    },
    extractIdentifier: (action) =>
      isViewIdentifier(action.payload)
        ? pick(action.payload, 'viewId', 'workflowId', 'releaseEnv')
        : null,
    debounceMs: null, // process immediately
    process: async (viewIdentifier, { dispatch, getState }) => {
      const user = getUserFromCache();
      const state = getState();
      const {
        router: { location },
      } = state;
      const { viewId, workflowId, releaseEnv } = viewIdentifier;

      if (!location) {
        return;
      }

      const { page, params } = getMatchFromPath<MatchParams>(location.pathname);

      if (
        page !== BasetenPageEnum.View &&
        page !== BasetenPageEnum.ViewOperator &&
        page !== BasetenPageEnum.Application
      ) {
        return;
      }

      if (viewId !== params.viewId) {
        return;
      }

      const view = viewSelector(state, viewIdentifier);

      if (!view) {
        return;
      }

      const workQueue = view.resolutionQueue.slice(); // make a copy

      // Clear resolution queue once we've created our copy
      // This allows new resolutions to get queued while we process the current work queue.
      dispatch(clearResolutionQueue(viewIdentifier));

      const evaluatedTasks: ResolutionTask[] = [];
      const asOperator =
        user?.roleName === RoleTypes.OPERATOR_ROLE || page === BasetenPageEnum.ViewOperator;

      async function invoke(
        invokeFn: () => Promise<any>,
        resolution: {
          status: ResolutionStatus;
          params: Record<string, any>;
          chksum: string;
          error?: string;
        },
      ): Promise<Resolution> {
        if (resolution.status === ResolutionStatus.Errored) {
          // error evaluating params
          return {
            status: ResolutionStatus.Errored,
            as: RenderAs.Raw,
            resolvedValue: resolution.error,
            chksum: resolution.chksum,
          };
        }
        try {
          const results = await invokeFn();
          return {
            status: ResolutionStatus.Resolved,
            as: RenderAs.Raw,
            resolvedValue: results,
            chksum: resolution.chksum,
          };
        } catch (ex) {
          return {
            status: ResolutionStatus.Errored,
            as: RenderAs.Raw,
            // NOTE: exception is a string (Error.message) in case of
            // python execution.
            // Safari isn't able to transmit error objects
            // between workers, so, we send only an error message
            resolvedValue: typeof ex === 'string' ? ex : ex.message,
            chksum: resolution.chksum,
          };
          // maybe we need to sanitize exception?
        }
      }

      async function performWork(task: ResolutionTask) {
        const { type, id, callback } = task;
        const targetView = viewSelector(getState(), viewIdentifier);
        const org = getOrgFromCache();

        const datasource = targetView.config.present[type]?.[id];
        const currResolution = targetView.config.present?.resolvedData?.[id];
        if (!datasource) {
          return;
        }
        const resolution = resolveParams(
          targetView.config.present,
          datasource.paramRef,
          evaluatedTasks,
        );
        if (!isEmpty(resolution.requires)) {
          workQueue.unshift(...resolution.requires, task);
          return;
        }
        if (!shouldInvoke(currResolution, resolution.chksum)) {
          // Chksum was equal
          evaluatedTasks.push(task);
          callback?.();
          return;
        }
        let invokeFn;
        switch (type) {
          case 'datasources':
            invokeFn = async () =>
              invokeDatasource(
                datasource as DatasourceBinding,
                resolution.params,
                viewIdentifier,
                asOperator,
                org?.dataEnabled,
              );
            break;
          case 'vars':
            invokeFn = async () => invokeCode(datasource as Variable, resolution.params);
            break;
        }
        dispatch(
          setData({
            workflowId,
            viewId,
            releaseEnv,
            dataType: type,
            key: id,
            data: await invoke(invokeFn, resolution),
          }),
        );
        evaluatedTasks.push(task);
        callback?.();
      }

      while (workQueue.length) {
        const task = workQueue.shift();
        // we need to run the work queue in series
        await performWork(task);
      }
    },
  });
}
