import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useAsOperator } from '@/hooks';
import { links } from '@/localization';

import Credits from './Credits';
import HelpMenu from './HelpMenu';
import PaymentOverdue from './PaymentOverdue';
import UserMenu from './UserMenu';

function LoggedIn() {
  const asOperator = useAsOperator();

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <Credits />
      <PaymentOverdue />

      <Stack direction="row" alignItems="center" gap={0.5}>
        {!asOperator && ENV_CONFIG.MODEL_LIBRARY_ENABLED && (
          <Button
            variant="text"
            color="primary"
            href={links.basetenModelLibrary}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            Model Library
          </Button>
        )}
        <HelpMenu />
        <UserMenu />
      </Stack>
    </Stack>
  );
}

export default LoggedIn;
