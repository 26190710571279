enum EventTypes {
  // for self-serve onboarding
  FINISHED_ONBOARDING_FLOW = 'FINISHED_ONBOARDING_FLOW',
}

// NOTE: When adding a new environment, if you care about which atoms are accessible then check ATOM_ENVIRONMENT_EXCLUSION.
enum ENVIRONMENTS {
  DEVELOPMENT = 'DEVELOPMENT',
  TEST = 'TEST',
  DEMO = 'DEMO',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

export { EventTypes, ENVIRONMENTS };
