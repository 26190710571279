import { useCallback } from 'react';

import { useHistory } from 'react-router';

import { useIsDualEnvEnabled } from '@/hooks/useDualEnv';
import unreachable from '@/utils/unreachable';

import { BasetenPageEnum } from '../types';
import { calcSwitchPath } from './calcSwitchPath';
import { PageSwitcher, PageSwitchingMethod, ParamsForPage } from './types';

/**
 * Hook that provides way to switch to different baseten page.
 *
 *  Either this or switchPage method above should be used instead of
 *  history.push or connected routers' push because that allows us to
 *  preserve context e.g. the selected release.
 *
 *  @return A method that allows switching to a different page.
 *          The method takes the following parameters:
 *          @param page Page to switch to.
 *          @param pathParams Parameters taken by the page, as defined in the path format of the page.
 *          @param queryParams Any query parameters.
 *          @param method Indicates the style of navigation. 'push' and 'replace' use the corresponding
 *                        history stack methods. 'open' opens the page in a new tab. Defaults to 'push'.
 */
export function usePageSwitcher(): PageSwitcher {
  const history = useHistory();
  const isDualEnvEnabled = useIsDualEnvEnabled();
  const switcher = useCallback(
    <TPage extends BasetenPageEnum>(
      page: TPage,
      pathParams: ParamsForPage<TPage>,
      queryParams: Record<string, string> = {},
      method: PageSwitchingMethod = PageSwitchingMethod.Push,
    ) => {
      const path = calcSwitchPath(page, isDualEnvEnabled, pathParams, queryParams);

      switch (method) {
        case PageSwitchingMethod.Open:
          window.open(path, '_blank');
          return;
        case PageSwitchingMethod.Refresh:
          window.open(path, '_self');
          return;
        case PageSwitchingMethod.Push:
          history.push(path);
          return;
        case PageSwitchingMethod.Replace:
          history.replace(path);
          return;
        default:
          unreachable(method);
      }
    },
    [isDualEnvEnabled, history],
  );

  return switcher;
}
