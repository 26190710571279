import { Stack, Typography } from '@mui/material';

import { Container } from './styled';
import { LargeAlertProps } from './types';

function LargeAlert({ title, description, severity, icon: Icon }: LargeAlertProps) {
  return (
    <Container severity={severity}>
      <Stack
        direction="column"
        sx={{ width: '100%' }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Icon color={severity} fontSize="medium" />
        <Typography textAlign="center" variant="h3" sx={{ fontSize: 18 }}>
          {title}
        </Typography>
        <Typography
          textAlign="center"
          variant="body1"
          sx={{ overflowWrap: 'break-word', width: '100%' }}
        >
          {description}
        </Typography>
      </Stack>
    </Container>
  );
}

export default LargeAlert;
