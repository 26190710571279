import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9334 9.99984C12.8446 10.2009 12.8181 10.424 12.8574 10.6402C12.8966 10.8565 12.9997 11.0561 13.1534 11.2132L13.1934 11.2532C13.3173 11.377 13.4157 11.5241 13.4828 11.6859C13.5499 11.8478 13.5844 12.0213
        13.5844 12.1965C13.5844 12.3717 13.5499 12.5452 13.4828 12.7071C13.4157 12.869 13.3173 13.016 13.1934 13.1398C13.0695 13.2638 12.9225 13.3622 12.7606 13.4293C12.5987 13.4963 12.4252 13.5309 12.25 13.5309C12.0748
        13.5309 11.9013 13.4963 11.7394 13.4293C11.5776 13.3622 11.4305 13.2638 11.3067 13.1398L11.2667 13.0998C11.1096 12.9461 10.91 12.843 10.6937 12.8038C10.4775 12.7646 10.2544 12.7911 10.0534 12.8798C9.85617 12.9643
        9.68801 13.1047 9.56956 13.2835C9.45111 13.4624 9.38754 13.672 9.38669 13.8865V13.9998C9.38669 14.3535 9.24621 14.6926 8.99616 14.9426C8.74611 15.1927 8.40698 15.3332 8.05335 15.3332C7.69973 15.3332 7.36059 15.1927
        7.11055 14.9426C6.8605 14.6926 6.72002 14.3535 6.72002 13.9998V13.9398C6.71486 13.7192 6.64343 13.5052 6.51503 13.3256C6.38662 13.1461 6.20718 13.0094 6.00002 12.9332C5.79894 12.8444 5.57589 12.818 5.35963
        12.8572C5.14336 12.8964 4.94381 12.9995 4.78669 13.1532L4.74669 13.1932C4.62286 13.3171 4.4758 13.4155 4.31394 13.4826C4.15208 13.5497 3.97857 13.5842 3.80335 13.5842C3.62813 13.5842 3.45463 13.5497 3.29277 13.4826C3.1309
         13.4155 2.98385 13.3171 2.86002 13.1932C2.73605 13.0693 2.63771 12.9223 2.57061 12.7604C2.50351 12.5986 2.46897 12.4251 2.46897 12.2498C2.46897 12.0746 2.50351 11.9011 2.57061 11.7393C2.63771 11.5774 2.73605
          11.4303 2.86002 11.3065L2.90002 11.2665C3.05371 11.1094 3.15681 10.9098 3.19602 10.6936C3.23524 10.4773 3.20876 10.2542 3.12002 10.0532C3.03551 9.85599 2.89519 9.68783 2.71633 9.56938C2.53747 9.45093 2.32788
          9.38736 2.11335 9.3865H2.00002C1.6464 9.3865 1.30726 9.24603 1.05721 8.99598C0.807163 8.74593 0.666687 8.40679 0.666687 8.05317C0.666687 7.69955 0.807163 7.36041 1.05721 7.11036C1.30726 6.86031 1.6464 6.71984
          2.00002 6.71984H2.06002C2.28068 6.71468 2.49469 6.64325 2.67422 6.51484C2.85375 6.38644 2.9905 6.20699 3.06669 5.99984C3.15543 5.79876 3.1819 5.57571 3.14269 5.35945C3.10348 5.14318 3.00038 4.94362 2.84669
          4.7865L2.80669 4.7465C2.68272 4.62267 2.58437 4.47562 2.51727 4.31376C2.45018 4.15189 2.41564 3.97839 2.41564 3.80317C2.41564 3.62795 2.45018 3.45445 2.51727 3.29258C2.58437 3.13072 2.68272 2.98367 2.80669
          2.85984C2.93052 2.73587 3.07757 2.63752 3.23943 2.57042C3.4013 2.50333 3.5748 2.46879 3.75002 2.46879C3.92524 2.46879 4.09874 2.50333 4.26061 2.57042C4.42247 2.63752 4.56952 2.73587 4.69335 2.85984L4.73335
          2.89984C4.89047 3.05353 5.09003 3.15663 5.30629 3.19584C5.52256 3.23505 5.74561 3.20858 5.94669 3.11984H6.00002C6.1972 3.03533 6.36537 2.89501 6.48382 2.71615C6.60227 2.53729 6.66583 2.3277 6.66669
          2.11317V1.99984C6.66669 1.64622 6.80716 1.30708 7.05721 1.05703C7.30726 0.80698 7.6464 0.666504 8.00002 0.666504C8.35364 0.666504 8.69278 0.80698 8.94283 1.05703C9.19288 1.30708 9.33335 1.64622 9.33335
          1.99984V2.05984C9.33421 2.27436 9.39778 2.48395 9.51623 2.66281C9.63468 2.84167 9.80284 2.982 10 3.0665C10.2011 3.15525 10.4241 3.18172 10.6404 3.14251C10.8567 3.10329 11.0562 3.0002 11.2134 2.8465L11.2534
          2.8065C11.3772 2.68254 11.5242 2.58419 11.6861 2.51709C11.848 2.44999 12.0215 2.41546 12.1967 2.41546C12.3719 2.41546 12.5454 2.44999 12.7073 2.51709C12.8691 2.58419 13.0162 2.68254 13.14 2.8065C13.264 2.93033
          13.3623 3.07739 13.4294 3.23925C13.4965 3.40111 13.5311 3.57462 13.5311 3.74984C13.5311 3.92506 13.4965 4.09856 13.4294 4.26042C13.3623 4.42229 13.264 4.56934 13.14 4.69317L13.1 4.73317C12.9463 4.89029 12.8432
          5.08985 12.804 5.30611C12.7648 5.52237 12.7913 5.74543 12.88 5.9465V5.99984C12.9645 6.19702 13.1049 6.36518 13.2837 6.48363C13.4626 6.60208 13.6722 6.66565 13.8867 6.6665H14C14.3536 6.6665 14.6928 6.80698
          14.9428 7.05703C15.1929 7.30708 15.3334 7.64622 15.3334 7.99984C15.3334 8.35346 15.1929 8.6926 14.9428 8.94265C14.6928 9.19269 14.3536 9.33317 14 9.33317H13.94C13.7255 9.33403 13.5159 9.39759 13.337
          9.51604C13.1582 9.63449 13.0179 9.80266 12.9334 9.99984V9.99984Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default SettingsIcon;
