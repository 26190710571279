import moment from 'moment';

export interface WorkletTestCase {
  id: string;
  created: moment.Moment;
  workletRunId: string;
  testName: string;
  input: object;
  output: object;
  testResult: boolean;
  lastRun: moment.Moment;
  lastRunStatus: string;
  lastRunLatencyMs: number;
}

export function workletTestCaseFromResponse(backendTestCase: any): WorkletTestCase {
  return {
    id: backendTestCase.id,
    created: backendTestCase.created,
    workletRunId: backendTestCase.worklet_run_id,
    testName: backendTestCase.test_name,
    input: backendTestCase.input,
    output: backendTestCase.output,
    testResult: backendTestCase.test_result,
    lastRun: backendTestCase.last_run,
    lastRunStatus: backendTestCase.last_run_status,
    lastRunLatencyMs: backendTestCase.last_run_latency_ms,
  };
}
