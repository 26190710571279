/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ClassFragment = { __typename?: 'ClassType', id: string, name: string, typename: string, namespace: string, description: string | null | undefined, schema: any, created: any, modified: any, tablename: string | null | undefined };

export const ClassFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClassFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClassType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"typename"}},{"kind":"Field","alias":{"kind":"Name","value":"tablename"},"name":{"kind":"Name","value":"table_name"}},{"kind":"Field","name":{"kind":"Name","value":"namespace"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"schema"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}}]}}]} as unknown as DocumentNode<ClassFragment, unknown>;
export const namedOperations = {
  Fragment: {
    ClassFragment: 'ClassFragment'
  }
}
