import { Stack } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

import { getBorder } from '@/theme/utils';

const CodeBlock = muiStyled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 2),
  borderTop: getBorder(theme.palette.border.warnEmphasis),
  borderLeft: getBorder(theme.palette.border.warnEmphasis),
  borderRight: getBorder(theme.palette.border.warnEmphasis),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  background: theme.palette.background.default,
}));

const Alert = muiStyled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: getBorder(theme.palette.border.warnEmphasis),

  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  background: theme.palette.background.warn,
}));

export { CodeBlock, Alert };
