/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestToJoinExistingOrgMutationVariables = Types.Exact<{
  joinExisting: Types.Scalars['Boolean'];
}>;


export type RequestToJoinExistingOrgMutation = { __typename?: 'Mutation', requestToJoinExistingOrganization: { __typename?: 'RequestToJoinOrganization', ok: boolean | null | undefined, adminName: string | null | undefined } | null | undefined };


export const RequestToJoinExistingOrgDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestToJoinExistingOrg"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"joinExisting"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"requestToJoinExistingOrganization"},"name":{"kind":"Name","value":"request_to_join"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"join_existing"},"value":{"kind":"Variable","name":{"kind":"Name","value":"joinExisting"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}},{"kind":"Field","alias":{"kind":"Name","value":"adminName"},"name":{"kind":"Name","value":"admin_name"}}]}}]}}]} as unknown as DocumentNode<RequestToJoinExistingOrgMutation, RequestToJoinExistingOrgMutationVariables>;
export const namedOperations = {
  Mutation: {
    RequestToJoinExistingOrg: 'RequestToJoinExistingOrg'
  }
}

export type RequestToJoinExistingOrgMutationFn = Apollo.MutationFunction<RequestToJoinExistingOrgMutation, RequestToJoinExistingOrgMutationVariables>;

/**
 * __useRequestToJoinExistingOrgMutation__
 *
 * To run a mutation, you first call `useRequestToJoinExistingOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinExistingOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinExistingOrgMutation, { data, loading, error }] = useRequestToJoinExistingOrgMutation({
 *   variables: {
 *      joinExisting: // value for 'joinExisting'
 *   },
 * });
 */
export function useRequestToJoinExistingOrgMutation(baseOptions?: Apollo.MutationHookOptions<RequestToJoinExistingOrgMutation, RequestToJoinExistingOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestToJoinExistingOrgMutation, RequestToJoinExistingOrgMutationVariables>(RequestToJoinExistingOrgDocument, options);
      }
export type RequestToJoinExistingOrgMutationHookResult = ReturnType<typeof useRequestToJoinExistingOrgMutation>;
export type RequestToJoinExistingOrgMutationResult = Apollo.MutationResult<RequestToJoinExistingOrgMutation>;
export type RequestToJoinExistingOrgMutationOptions = Apollo.BaseMutationOptions<RequestToJoinExistingOrgMutation, RequestToJoinExistingOrgMutationVariables>;