import * as monacoType from 'monaco-editor/esm/vs/editor/editor.api';

type RegisteredCompletionItemProvider = {
  disposable: monacoType.IDisposable;
  dispose: () => void;
};

type RegisteredCompletionItemProviders = {
  [key: string]: RegisteredCompletionItemProvider;
};

type Cell = any;

type Console = {
  sessionId: string;
  cells: Array<Cell>;
};

type ConsoleState = {
  data: Record<string, Console>;
  isLoading: boolean;
};

enum UserConsoleRequestType {
  EXEC_CODE = 'EXEC_CODE',
  EXEC_CODE_STATUS = 'EXEC_CODE_STATUS',
  HISTORY = 'HISTORY',
  COMPLETE = 'COMPLETE',
  RESTART_SESSION = 'RESTART_SESSION',
}

enum ConsoleRequestStatus {
  STARTED = 'STARTED',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
}

type RestartConsoleSessionReturnValue = {
  applicationId: string;
  sessionId: string;
};

type CompletionResult = {
  end: number;
  start: number;
  type: string;
  text: string;
  displayText?: string;
};

type CompletionMatch = string | CompletionResult;

type CompletionResults = {
  status: string;
  cursor_start: number;
  cursor_end: number;
  matches: CompletionMatch[];
  metadata?: {
    _jupyter_types_experimental?: any;
  };
};

type ConsoleCompletionResponse = {
  session_id: string;
  completions: CompletionResults;
};

type SetCellResultParams = {
  applicationId: string;
  sessionId: string;
  cellId: number | string;
  input: string;
  result: any;
};

type UseConsolesReturnValue = ConsoleState;

type UseConsoleReturnValue = {
  console: Console;
  setCellResult: (params: SetCellResultParams) => void;
  restartConsoleSession: () => void;
};

export {
  Cell,
  Console,
  ConsoleState,
  CompletionMatch,
  CompletionResults,
  SetCellResultParams,
  UserConsoleRequestType,
  ConsoleRequestStatus,
  RestartConsoleSessionReturnValue,
  ConsoleCompletionResponse,
  UseConsolesReturnValue,
  UseConsoleReturnValue,
  RegisteredCompletionItemProvider,
  RegisteredCompletionItemProviders,
};
