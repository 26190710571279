/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardingChecklistQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OnboardingChecklistQuery = { __typename?: 'QueryPrediction', onboarding_checklist: { __typename?: 'OnboardingChecklistType', onboarded: boolean, finished_onboarding_flow: boolean, walkthrough_state: Types.OnboardingChecklistWalkthroughState, onboarding_state: any } | null | undefined };


export const OnboardingChecklistDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OnboardingChecklist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onboarding_checklist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onboarded"}},{"kind":"Field","name":{"kind":"Name","value":"finished_onboarding_flow"}},{"kind":"Field","name":{"kind":"Name","value":"walkthrough_state"}},{"kind":"Field","name":{"kind":"Name","value":"onboarding_state"}}]}}]}}]} as unknown as DocumentNode<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>;
export const namedOperations = {
  Query: {
    OnboardingChecklist: 'OnboardingChecklist'
  }
}

/**
 * __useOnboardingChecklistQuery__
 *
 * To run a query within a React component, call `useOnboardingChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingChecklistQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingChecklistQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>(OnboardingChecklistDocument, options);
      }
export function useOnboardingChecklistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>(OnboardingChecklistDocument, options);
        }
export type OnboardingChecklistQueryHookResult = ReturnType<typeof useOnboardingChecklistQuery>;
export type OnboardingChecklistLazyQueryHookResult = ReturnType<typeof useOnboardingChecklistLazyQuery>;
export type OnboardingChecklistQueryResult = Apollo.QueryResult<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>;
export function refetchOnboardingChecklistQuery(variables?: OnboardingChecklistQueryVariables) {
      return { query: OnboardingChecklistDocument, variables: variables }
    }