import { FormEvent, useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Alert, Link, Stack, Typography } from '@mui/material';

import Meta from '@/components/Meta';
import { links } from '@/localization';
import { PosthogEvents, capturePosthogEvent } from '@/utils/analytics/posthog';
import { EMAIL_REGEX } from '@/utils/email';

import Card from '../Card';
import CheckEmail from '../CheckEmail';
import Divider from '../Divider';
import SocialButton from '../SocialButton';
import { EMAIL_ERROR_MSG, USERNAME_ERROR_MSG } from '../constants';
import { Body, Form, SubmitButton, TextField } from '../styled';
import InfoBox from './InfoBox';
import { login } from './utils';

function Login() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCheckEmail, setShowCheckEmail] = useState<boolean>(false);
  const [hasPassword, setHasPassword] = useState(false);

  const toggleHasPassword = useCallback(() => setHasPassword((v) => !v), [setHasPassword]);

  useEffect(() => setError(null), [hasPassword]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      const data = new FormData(e.target as HTMLFormElement);
      const usernameOrEmail = (hasPassword ? data.get('username') : data.get('email')) as string;
      const password = data.get('password') as string;
      const parameters: { email: string; password?: string } = { email: usernameOrEmail };

      if (hasPassword) {
        if (!usernameOrEmail || !password) {
          setError(USERNAME_ERROR_MSG);
          return;
        }
        parameters.password = password;
      } else if (!usernameOrEmail || !EMAIL_REGEX.test(usernameOrEmail)) {
        setError(EMAIL_ERROR_MSG);
        return;
      }

      setIsLoading(true);

      (async () => {
        try {
          const response = await login(usernameOrEmail, password);

          if (response.success) {
            capturePosthogEvent(PosthogEvents.SIGNED_IN);
            if (!parameters.password) {
              setShowCheckEmail(true);
            }
          } else {
            setError(response.error);
          }
        } catch (error) {
          setError('An unexpected error has occurred.');
        } finally {
          setIsLoading(false);
        }
      })();
    },
    [setIsLoading, hasPassword, setError],
  );

  return (
    <Meta title="Sign in to Baseten" responsive>
      <Body>
        <Stack alignItems="center" direction="column" gap={4}>
          <Card withBranding contained title="Sign in to Baseten">
            {showCheckEmail ? (
              <CheckEmail />
            ) : (
              <>
                {ENV_CONFIG.SOCIAL_LOGINS.map((type) => (
                  <SocialButton type={type} key={type} />
                ))}
                {ENV_CONFIG.SOCIAL_LOGINS.length > 0 && <Divider>or</Divider>}
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column" spacing={1.5}>
                    {hasPassword ? (
                      <>
                        <TextField
                          variant="outlined"
                          name="username"
                          label="Username"
                          data-cy="username"
                          fullWidth
                          required
                          autoFocus
                        />
                        <TextField
                          label="Password"
                          type="password"
                          variant="outlined"
                          name="password"
                          data-cy="password"
                          fullWidth
                          required
                        />
                      </>
                    ) : (
                      <TextField
                        variant="outlined"
                        label="Email address"
                        name="email"
                        required
                        type="email"
                        placeholder="name@company.com"
                        data-cy="email"
                        fullWidth
                        autoFocus
                      />
                    )}

                    {error && <Alert severity="error">{error}</Alert>}

                    <InfoBox onTogglePassword={toggleHasPassword} hasPassword={hasPassword} />

                    <SubmitButton data-cy="submit-btn" color="primary" loading={isLoading}>
                      {hasPassword ? 'Sign in with password' : 'Sign in with email'}
                    </SubmitButton>
                  </Stack>
                </Form>
                <Typography align="center" color="text.muted" variant="body2">
                  By signing in you agree to our
                  <br />
                  <Link href={links.basetenTerms}>Terms of Service</Link>
                  &nbsp;and&nbsp;
                  <Link href={links.basetenPrivacy}>Privacy Policy</Link>.
                </Typography>
              </>
            )}
          </Card>

          <Typography color="text.muted">
            Don&apos;t have an account yet?&nbsp;
            <Link data-cy="signup-link" href={`/signup${location.search}`}>
              Sign up
            </Link>
          </Typography>
        </Stack>
      </Body>
    </Meta>
  );
}

export default Login;
