import { RoleTypes } from '@/pages/Settings/Tabs/Members/types';

import { useUserLoading } from './useUser';

/**
 * Returns whether the current user has creator permissions, meaning they can modify applications, models, data, etc.
 */
function useIsCreator(): boolean {
  const { user } = useUserLoading();
  return !!user?.roleName && user?.roleName !== RoleTypes.OPERATOR_ROLE;
}

/**
 * Returns whether the current user has creator permissions,
 * meaning they can modify applications, models, data, etc, but they are not admins.
 */
function useIsNonAdminCreator(): boolean {
  const { user } = useUserLoading();
  return !!user.roleName && user.roleName === RoleTypes.CREATOR_ROLE.toLowerCase();
}

export { useIsCreator, useIsNonAdminCreator };
