import { Link, Paper, Stack, Typography } from '@mui/material';

import MailIcon from '@/components/Icons/MailIcon';

import { InfoBoxProps } from './types';

function InfoBox({ hasPassword, onTogglePassword }: InfoBoxProps) {
  return (
    <Paper
      sx={{ background: (theme) => theme.palette.background.subtle, p: 1.5 }}
      variant="outlined"
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        <MailIcon color="secondary" />
        <Typography color="text.secondary" variant="body2">
          {hasPassword ? (
            <>
              Or you can&nbsp;
              <Link onClick={onTogglePassword} sx={{ cursor: 'pointer' }}>
                sign in with email
              </Link>
              &nbsp;instead.
            </>
          ) : (
            <>
              We&apos;ll send you a password-free email to sign in. Or&nbsp;
              <Link
                data-cy="sign-in-with-username-password-btn"
                onClick={onTogglePassword}
                sx={{ cursor: 'pointer' }}
              >
                sign in with a password
              </Link>
              &nbsp;instead.
            </>
          )}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default InfoBox;
