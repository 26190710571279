import filter from 'lodash/filter';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import FormElem, { FormElemProps, ParamsFormElementSpec } from './FormElem';
import ParamsFormBuilder from './ParamsForm';
import { NoopValidator, ParamValidator, ValidationErrors } from './Validation';

class ParamsFormSpec {
  public readonly elems: Array<ParamsFormElementSpec>;

  private elemById: Record<string, ParamsFormElementSpec>;

  private elemByName: Record<string, ParamsFormElementSpec>;

  private paramsForm?: any;

  constructor(
    elems: Array<FormElemProps>,
    public readonly layout: Array<Array<string>>,
    public readonly focusElem?: string,
    public readonly umbrellaValidate: ParamValidator = NoopValidator,
  ) {
    this.elems = elems.map((elem) => new FormElem(elem));
    this.elemById = keyBy(this.elems, 'id');
    this.elemByName = keyBy(this.elems, 'name');
  }

  public getInitParams(): Record<string, any> {
    const keyed = keyBy(this.elems, 'id');
    return mapValues(keyed, (elem) => elem.defaultValue);
  }

  public getElem(id: string) {
    return this.elemById[id];
  }

  public getElemByName(name: string) {
    return this.elemByName[name];
  }

  public replaceElem(elem: ParamsFormElementSpec): ParamsFormSpec {
    const newElems = Object.values({ ...this.elemById, [elem.id]: elem });
    return new ParamsFormSpec(newElems, this.layout, this.focusElem);
  }

  public addElem(elemProps: FormElemProps) {
    const elem = new FormElem(elemProps);
    const newElems = Object.values({ ...this.elemById, [elem.id]: elem });
    return new ParamsFormSpec(newElems, this.layout, this.focusElem);
  }

  public dropElemById(elemId: string): ParamsFormSpec {
    const newElems = filter(this.elemById, (o) => {
      return o.id !== elemId;
    });
    return new ParamsFormSpec(newElems, this.layout, this.focusElem);
  }

  public replaceElemProperty(elemId: string, prop: string, value: any): ParamsFormSpec {
    if (prop === 'id') {
      throw Error('id property of a form element cannot be modified.');
    }

    const elem = this.getElem(elemId);
    const newElem = {
      ...elem,
      [prop]: value,
    };
    const newElems = Object.values({ ...this.elemById, [newElem.id]: newElem });
    this.elemByName = keyBy(this.elems, 'name');
    return new ParamsFormSpec(newElems, this.layout, this.focusElem);
  }

  public replaceLayout(newLayout: Array<Array<string>>): ParamsFormSpec {
    return new ParamsFormSpec(this.elems, newLayout, this.focusElem);
  }

  public form() {
    if (!this.paramsForm) {
      this.paramsForm = ParamsFormBuilder(this);
    }
    return this.paramsForm;
  }

  // Returns a method that can be used for validation
  public validator(): ParamValidator {
    const elemsByName = this.elemByName;
    const { umbrellaValidate } = this;
    return (params: any) => {
      const errors: ValidationErrors = [];
      Object.entries(elemsByName).forEach(([elemName, elem]) => {
        if (!has(params, elemName)) {
          errors.push(`No value found for ${elemName}`);
        } else {
          const paramValue = params[elemName];
          errors.push(...elem.validate(paramValue));
        }
      });
      errors.push(...umbrellaValidate(params));
      return errors;
    };
  }
}

export default ParamsFormSpec;
