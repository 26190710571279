import { ChangeEvent, FormEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import kebabCase from 'lodash/kebabCase';

import { ModalBox, ModalFooter, ModalForm } from './styles';
import { SafeguardConfirmModalProps } from './types';

function SafeguardConfirm({
  title,
  color = 'error',
  validationText,
  validationHint = '',
  okText = 'Confirm',
  cancelText = 'Cancel',
  prompt,
  onDone,
  container,
}: SafeguardConfirmModalProps) {
  const [validation, setValidation] = useState('');
  const validationMatched = validation?.toLowerCase() === validationText.toLowerCase();
  const modalId = kebabCase(title);
  const validationModalId = `safeguard-confirm-${modalId}`;

  function handleClose(confirmed: boolean) {
    onDone({ confirmed, validation: confirmed ? validationText : null });
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    handleClose(true);
  }

  return (
    <Modal
      open
      container={container}
      onClose={() => handleClose(false)}
      aria-labelledby={`${validationModalId}-title`}
      aria-describedby={`${validationModalId}-description`}
    >
      <ModalBox>
        <Stack gap={2}>
          <Stack gap={2} sx={{ pt: 3, px: 3 }}>
            <Typography id={`${validationModalId}-title`} variant="h4">
              {title}
            </Typography>
            <Typography id={`${validationModalId}-description`}>{prompt}</Typography>
          </Stack>

          <Stack gap={1}>
            <Typography sx={{ px: 3 }}>
              Please type &apos;
              <Typography variant="h5" component="span">
                {validationText}
              </Typography>
              &apos; to confirm:
            </Typography>
            <ModalForm onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                autoFocus
                fullWidth
                value={validation}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setValidation(e.target.value)}
                placeholder={validationHint}
                data-cy="safeguard-confirm-input"
                sx={{ mb: 3, px: 3 }}
              />
              <ModalFooter gap={1} direction="row" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => handleClose(false)}
                  data-cy={`${validationModalId}-cancel`}
                >
                  {cancelText}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color={color}
                  disabled={!validationMatched}
                  data-cy={`${validationModalId}-ok`}
                >
                  {okText}
                </Button>
              </ModalFooter>
            </ModalForm>
          </Stack>
        </Stack>
      </ModalBox>
    </Modal>
  );
}

export default SafeguardConfirm;
