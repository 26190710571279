import apolloClient from '@/apollo';
import { DocumentNode } from 'graphql';
import { SerializableParam, atomFamily } from 'recoil';

import { PollIntervalObject } from './types';

/**
 * Given a list of poll interval objects, return the smallest positive interval
 */
function getSharedPollInterval(intervalObjects: PollIntervalObject[]) {
  return intervalObjects.reduce<number | null>((acc, { pollInterval }) => {
    if (pollInterval <= 0) {
      return acc;
    }
    if (acc === null) {
      return pollInterval;
    }
    return Math.min(acc, pollInterval);
  }, null);
}

/**
 * This state uses a side effect to coordinate between multiple instances of useSharedQueryPoller
 * that share the same query and variables but possibly are set to poll on different intervals.
 * Every time a poller is added or removed, the shared poll interval is updated to the smallest positive
 * interval. When there are no positive poll intervals, polling stops.
 */
export const pollerState = atomFamily<
  PollIntervalObject[],
  {
    query: SerializableParam;
    variables: SerializableParam;
  }
>({
  key: 'useSharedQueryPoller-pollerState',
  default: [],
  effects({ query, variables }) {
    return [
      ({ onSet }) => {
        const observableQuery = apolloClient.watchQuery({
          query: query as unknown as DocumentNode,
          variables,
          // Always fetch from the network, but store in the cache
          fetchPolicy: 'network-only',
        });

        // Need an observer so that the query is actually executed
        const subscription = observableQuery.subscribe(() => {});

        onSet((intervals) => {
          const sharedPollInterval = getSharedPollInterval(intervals);

          if (sharedPollInterval) {
            observableQuery.startPolling(sharedPollInterval);
          } else {
            observableQuery.stopPolling();
          }
        });

        // Cleanup function, should be run when state is no longer observed
        return () => {
          observableQuery.stopPolling();
          subscription.unsubscribe();
        };
      },
    ];
  },
});
