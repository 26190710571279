import { Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DialogTitleWithX from '@/components/DialogTitleWithX';
import CopyIcon from '@/components/Icons/CopyIcon';
import { ApiKeyType } from '@/graphql';
import { links } from '@/localization';
import copyToClipboard from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import { Alert, CodeBlock } from './styles';
import { PreviewDialogProps } from './types';

const mapApiKeyTypeToMessage: Record<ApiKeyType, string> = {
  [ApiKeyType.Personal]: 'It can be used to call and manage all models in your workspace.',
  [ApiKeyType.CreatorServiceAccount]:
    'It can be used to call and manage all models in your workspace.',
  [ApiKeyType.InvokeAllModelsServiceAccount]:
    'It can be used to call all models in your workspace.',
  [ApiKeyType.InvokeAllowedModelsServiceAccount]:
    'It can be used to call specific models in your workspace.',
  [ApiKeyType.ExportMetricsAllModelsServiceAccount]:
    'It can be used to export metrics for all models in your workspace.',
  [ApiKeyType.ExportMetricsAllowedModelsServiceAccount]:
    'It can be used to export metrics for specific models in your workspace.',
};

function PreviewDialog({
  textKey,
  onClose,
  isOpen,
  apiKeyType = ApiKeyType.Personal,
}: PreviewDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  function handleCopyToClipboard() {
    copyToClipboard(textKey);
    enqueueSnackbar('Copied to clipboard!');
  }

  const message = mapApiKeyTypeToMessage[apiKeyType] ?? '';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitleWithX dataCy="close-preview-dialog" onClose={onClose}>
        Your new API key
      </DialogTitleWithX>
      <Stack gap={2} px={3} mb={3} sx={{ width: 500 }}>
        <Typography sx={{ mt: 1 }}>
          <Typography variant="h5" component="span">
            Store this API key securely.
          </Typography>{' '}
          {message}{' '}
          <Link href={links.basetenDocsAPIKeys} target="_blank">
            Learn more
          </Link>
        </Typography>
        <Stack>
          <CodeBlock direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="mono1" data-cy="api-key">
              {textKey}
            </Typography>
            <Tooltip title="Copy API key">
              <IconButton onClick={handleCopyToClipboard}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </CodeBlock>
          <Alert direction="row" alignItems="center" justifyContent="center" gap={1}>
            <Typography variant="h5">Copy this key — we only show it once!</Typography>
          </Alert>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default PreviewDialog;
