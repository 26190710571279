import { useMemo } from 'react';

import useSharedQueryPoller from '../useSharedQueryPoller';
import {
  MonetaryCreditsDocument,
  useMonetaryCreditsQuery,
} from './__generated__/queries.generated';
import type { MonetaryCreditInfo } from './types';

// Once every 5 minutes
const POLLING_PERIOD = 5 * 60 * 1000;

export default function useMonetaryCreditInfo(): {
  monetaryCreditInfo: MonetaryCreditInfo | undefined;
  loading: boolean;
} {
  const { data, loading } = useMonetaryCreditsQuery({
    fetchPolicy: 'cache-first',
  });

  useSharedQueryPoller(MonetaryCreditsDocument, {}, POLLING_PERIOD);

  const monetaryCreditInfoRaw = data?.organization;

  const monetaryCreditInfo = useMemo(
    (): MonetaryCreditInfo =>
      monetaryCreditInfoRaw && {
        ...monetaryCreditInfoRaw,
        // Ensure that the credit balance is never negative
        creditBalance: Math.max(0, monetaryCreditInfoRaw.creditBalance),
      },
    [monetaryCreditInfoRaw],
  );

  return {
    monetaryCreditInfo,
    loading,
  };
}
