import { Action, Dispatch } from 'redux';

import { ApolloClient } from '@apollo/client';

export const BASETEN_ENV_REQUEST_HEADER_KEY = 'X-BASETEN-ENV';
export const BASETEN_ENV_DRAFT = 'DRAFT';

type AsyncAction<T> = () => Promise<T>;

const makeStartEvent = (actionTypePrefix: string, meta: any) => ({
  type: `${actionTypePrefix}.Start`,
  meta,
});

const makeDoneEvent = (actionTypePrefix: string, payload: any, meta: any) => ({
  type: `${actionTypePrefix}.Done`,
  payload,
  meta,
});

const makeFailedEvent = (actionTypePrefix: string, errorMessage: any, meta: any) => ({
  type: `${actionTypePrefix}.Failed`,
  error: errorMessage,
  meta,
});

const identity = (x: any) => x;

export async function executeAsyncWithEvents<T>(
  asyncAction: AsyncAction<T>,
  actionTypePrefix: string,
  actionMeta: any,
  dispatch: Dispatch,
  resultTransform = identity,
  errorTransform = identity,
): Promise<any> {
  dispatch(makeStartEvent(actionTypePrefix, actionMeta));
  try {
    const result = await asyncAction();
    const transformed = resultTransform(result);
    dispatch(makeDoneEvent(actionTypePrefix, transformed, actionMeta));
    return transformed;
  } catch (error) {
    dispatch(makeFailedEvent(actionTypePrefix, error, actionMeta));
    throw errorTransform(error);
  }
}

function graphqlPromiseEventDispatcher<T>(
  asyncAction: AsyncAction<T>,
  actionTypePrefix: string,
  dispatch: Dispatch,
  actionMeta: any,
): Promise<any> {
  return executeAsyncWithEvents(
    asyncAction,
    actionTypePrefix,
    actionMeta,
    dispatch,
    (result) => result.data,
  );
}

// TODO(pankaj) figure out how not have to pass dispatch.
export default function graphqlQuery(
  query: any,
  actionTypePrefix: string,
  dispatch: Dispatch,
  apolloClient: ApolloClient<object>,
  variables: Record<string, any> = {},
  actionMeta: any = {},
): Promise<any> {
  const runQuery = () =>
    apolloClient.query({
      query,
      variables,
    });
  return graphqlPromiseEventDispatcher(runQuery, actionTypePrefix, dispatch, actionMeta);
}

export function graphqlMutation(
  mutation: any,
  actionTypePrefix: string,
  dispatch: Dispatch,
  apolloClient: ApolloClient<object>,
  variables: Record<string, any> = {},
  actionMeta: any = {},
): Promise<any> {
  const runMutation = () =>
    apolloClient.mutate({
      mutation,
      variables,
    });
  return graphqlPromiseEventDispatcher(runMutation, actionTypePrefix, dispatch, actionMeta);
}

export const isSliceAction = (sliceName: string) => (action: Action) =>
  typeof action.type === 'string' && action.type.startsWith(`${sliceName}/`);
