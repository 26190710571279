import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { getOrgFromCache } from '@/hooks/useOrg';
import { RootState, ThunkExtras } from '@/store/types';
import { push } from 'connected-react-router';

import { BasetenPageEnum } from '../types';
import { calcSwitchPath } from './calcSwitchPath';
import { ParamsForPage } from './types';

/**
 *  To be used with dispatch for switching to a different Baseten Page.
 *
 *  Either this or usePageSwitcher method below should be used instead of
 *  history.push or connected routers' push because that allows us to
 *  preservse context e.g. the selected release.
 *
 * @param page Page to switch to.
 * @param pathParams Parameters taken by the page, as defined in the path format of the page.
 * @param queryParams Any query parameters.
 */
export function switchPage<TPage extends BasetenPageEnum>(
  page: BasetenPageEnum,
  pathParams: ParamsForPage<TPage>,
  queryParams: Record<string, string> = {},
): ThunkAction<void, RootState, ThunkExtras, Action> {
  return (dispatch) => {
    const org = getOrgFromCache();

    const path = calcSwitchPath(page, org?.dualEnvEnabled, pathParams, queryParams);
    dispatch(push(path));
  };
}
