import Link from '@mui/material/Link';

import useAppLink from '@/hooks/useAppLink';
import { BasetenPageEnum } from '@/routes';

import { AppLinkProps } from './types';

/**
 * AppLink is a thin wrapper on the MuiLink component intended for internal app navigation,
 * i.e. to different BasetenPageEnum pages. The destination URL is provided to the href attribute,
 * providing browser-navigation functionality like "copy hyperlink" and "open in new tab" while using
 * SPA routing on click.
 *
 */
function AppLink<TPage extends BasetenPageEnum>({
  children,
  page,
  params,
  disabled = false,
  withNewTab = false,
  onClick,
  color,
  refresh,
  queryParams,
  ...otherProps
}: AppLinkProps<TPage>) {
  const [resolvedUrl, handleClick] = useAppLink<HTMLAnchorElement>({
    disabled,
    refresh,
    withNewTab,
    page,
    queryParams,
    onClick,
    params,
  });

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Link
      href={resolvedUrl}
      onClick={handleClick}
      target={withNewTab ? '_blank' : null}
      color={color ?? 'primary'}
      rel="noreferrer"
      {...otherProps}
    >
      {children}
    </Link>
  );
}

export default AppLink;
