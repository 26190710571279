import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { updateWorkflow } from '@/store/slices/entities/Workflows';
import { UpdateWorkflowPayload, WorkflowIdentifier } from '@/store/slices/entities/Workflows/types';
import {
  setWorkflowRunLoading,
  setWorkflowRunsCount,
  updateWorkflowWorkletRuns,
  workflowFetchDone,
  workflowFetchError,
  workflowFetchStart,
} from '@/store/slices/ui/WorkflowView';
import { ThunkResult } from '@/store/types';
import { normalize } from 'normalizr';

import { applicationFetchCompleted } from './ViewBuilder';
import {
  WorkflowDocument,
  WorkletRunsCountDocument,
  WorkletRunsDocument,
  workflowSchema,
} from './Workflow';

/**
 *  Refresh data for a workflow from the backend.
 *  Workflow can be refreshed from a previous release.
 * @param workflowId
 * @param background
 * @param releaseEnv
 */
export function hydrateWorkflow(
  workflowIdentifier: WorkflowIdentifier,
  background = false,
): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const {
      ui: {
        workflowView: { loading },
      },
    } = getState();

    if (!loading) {
      dispatch(showLoading());
      if (!background) {
        dispatch(workflowFetchStart(workflowIdentifier.workflowId));
      }
      return apolloClient
        .query({
          query: WorkflowDocument,
          variables: {
            id: workflowIdentifier.workflowId,
            deploymentEnv: workflowIdentifier.releaseEnv,
          },
        })
        .then(
          (value) => {
            const { entities } = normalize(value.data.workflow, workflowSchema);
            dispatch(applicationFetchCompleted(value.data.workflow, dispatch));
            dispatch(
              updateWorkflow({
                workflowIdentifier,
                entities: entities as UpdateWorkflowPayload['entities'],
              }),
            );
            if (!background) {
              dispatch(workflowFetchDone());
            }
            dispatch(hideLoading());
          },
          (error) => dispatch(workflowFetchError(error.message)),
        );
    }

    return Promise.resolve();
  };
}

export const fetchWorkflowWorkletRuns =
  (
    workflowId: string,
    workletId: string,
    workletRunsPageOffset: number = 0,
    workletRunsLimit: number = 1000,
  ): ThunkResult<any> =>
  (dispatch, getState, { apolloClient }) => {
    dispatch(showLoading());
    dispatch(setWorkflowRunLoading());
    return apolloClient
      .query({
        query: WorkletRunsDocument,
        variables: {
          workflow_id: workflowId,
          worklet_id: workletId,
          page_offset: workletRunsPageOffset,
          limit: workletRunsLimit,
        },
      })
      .then(
        (value) => {
          dispatch(updateWorkflowWorkletRuns(value.data.worklet_runs));
          dispatch(hideLoading());
        },
        (error) => dispatch(workflowFetchError(error.message)),
      );
  };

export const fetchWorkflowWorkletRunsCount =
  (workflowId: string, workletId: string): ThunkResult<any> =>
  (dispatch, _, { apolloClient }) => {
    return apolloClient
      .query({
        query: WorkletRunsCountDocument,
        variables: {
          workflow_id: workflowId,
          worklet_id: workletId,
        },
      })
      .then((value) => {
        dispatch(setWorkflowRunsCount(value.data.worklet_runs_count));
      });
  };
