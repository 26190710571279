/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LogEventMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  payload: Types.Scalars['String'];
}>;


export type LogEventMutation = { __typename?: 'Mutation', logEvent: { __typename?: 'LogEvent', ok: boolean | null | undefined } | null | undefined };


export const LogEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"logEvent"},"name":{"kind":"Name","value":"log_event"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<LogEventMutation, LogEventMutationVariables>;
export const namedOperations = {
  Mutation: {
    LogEvent: 'LogEvent'
  }
}

export type LogEventMutationFn = Apollo.MutationFunction<LogEventMutation, LogEventMutationVariables>;

/**
 * __useLogEventMutation__
 *
 * To run a mutation, you first call `useLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logEventMutation, { data, loading, error }] = useLogEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useLogEventMutation(baseOptions?: Apollo.MutationHookOptions<LogEventMutation, LogEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogEventMutation, LogEventMutationVariables>(LogEventDocument, options);
      }
export type LogEventMutationHookResult = ReturnType<typeof useLogEventMutation>;
export type LogEventMutationResult = Apollo.MutationResult<LogEventMutation>;
export type LogEventMutationOptions = Apollo.BaseMutationOptions<LogEventMutation, LogEventMutationVariables>;