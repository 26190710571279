import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

// Source: Feather - activity
function ActivityIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
    </SvgIcon>
  );
}

export default ActivityIcon;
