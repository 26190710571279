export enum LoadingState {
  None = 'None',
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

export interface AppViewState {
  applications: LoadingState;
  userInfo: LoadingState;
  ontologies: LoadingState;
  queries: LoadingState;
}
