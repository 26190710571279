import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

const Avatar = styled(Stack)(({ theme }) => ({
  textTransform: 'capitalize',
  background: 'linear-gradient(133deg, #2176FF 0%, #00D27A 100%)',
  color: theme.palette.text.emphasis,
  fontSize: 12,
  fontWeight: 500,
  width: 24,
  height: 24,
  borderRadius: theme.shape.borderRadius,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

export { Avatar };
