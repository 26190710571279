import { PropsWithChildren } from 'react';

import GlobalStyles from '@mui/material/GlobalStyles';

import AlertTriangleIcon from '@/components/Icons/AlertTriangleIcon';
import CheckIcon from '@/components/Icons/CheckIcon';
import InfoIcon from '@/components/Icons/InfoIcon';
import XCircleIcon from '@/components/Icons/XCircleIcon';
import { SnackbarProvider } from 'notistack';

import { styles } from './styled';

function Provider({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <GlobalStyles styles={styles} />
      <SnackbarProvider
        maxSnack={3}
        disableWindowBlurListener
        iconVariant={{
          success: <CheckIcon />,
          error: <XCircleIcon />,
          warning: <AlertTriangleIcon />,
          info: <InfoIcon />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

export default Provider;
