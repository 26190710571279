import { batch } from 'react-redux';

import { createAsyncThunk } from '@reduxjs/toolkit';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

import { DeploymentEnvEnum } from '@/graphql';
import { switchPage } from '@/routes';
import { BasetenPageEnum } from '@/routes/types';
import { createView } from '@/store/slices/entities/Views';
import { updateWorkflowMetadata } from '@/store/slices/entities/WorkflowMetadata/index';
import { selectWorkflowMetadataMap } from '@/store/slices/entities/WorkflowMetadata/selectors';
import {
  UpdateWorkflowMetadataPayload,
  WorkflowMetadata,
} from '@/store/slices/entities/WorkflowMetadata/types';
import { updateWorkflow } from '@/store/slices/entities/Workflows';
import { UpdateWorkflowPayload } from '@/store/slices/entities/Workflows/types';
import {
  AppViewLoadingState,
  appsFetchCompleted,
  appsFetchFailed,
  appsFetchStart,
} from '@/store/slices/ui/AppView';
import { LoadingState } from '@/store/slices/ui/AppView/types';
import { AppAsyncThunk, ThunkResult } from '@/store/types';
import { graphqlMutation } from '@/store/utils/ActionUtils';
import { ReleaseEnv } from '@/types/releaseEnv';
import { getInstanceName } from '@/utils/collections';
import { normalize } from 'normalizr';

import { RpcActionPrefix } from './ActionType';
import { applicationFetchCompleted } from './ViewBuilder/actions';
import { duplicateApplicationResponseSchema, workflowSchema } from './Workflow';
import { ExpandedWorkflowMetadataFragment } from './Workflow/__generated__/fragments.generated';
import { hydrateWorkflow } from './WorkflowAsyncActions';
import {
  CreateWorkflowDocument,
  DuplicateApplicationDocument,
  UpdateWorkflowDocument,
} from './Workflows/__generated__/mutations.generated';
import {
  OperatorWorkflowsDocument,
  WorkflowsDocument,
  WorkflowsMetadataDocument,
} from './Workflows/__generated__/queries.generated';

export function fetchWorkflows(releaseEnv: ReleaseEnv): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const {
      ui: {
        appView: { applications },
      },
    } = getState();

    if (applications !== AppViewLoadingState.Loading) {
      dispatch(appsFetchStart());
      return apolloClient
        .query({
          query: WorkflowsDocument,
        })
        .then(
          (value) =>
            batch(() => {
              forEach(value.data.items, (workflow) => {
                const { entities } = normalize(workflow, workflowSchema);
                dispatch(applicationFetchCompleted(workflow, dispatch));
                dispatch(
                  updateWorkflow({
                    workflowIdentifier: { workflowId: workflow.id, releaseEnv },
                    entities: entities as UpdateWorkflowPayload['entities'],
                  }),
                );
              });

              dispatch(appsFetchCompleted());
            }),
          (error) => dispatch(appsFetchFailed(error.message)),
        );
    }
    return Promise.resolve();
  };
}

export const fetchWorkflowsMetadata: AppAsyncThunk<
  ExpandedWorkflowMetadataFragment[],
  DeploymentEnvEnum
> = createAsyncThunk(
  'fetch-workflows-metadata',
  async (releaseEnv: ReleaseEnv, { dispatch, getState, extra: { apolloClient } }) => {
    const {
      ui: {
        appView: { applications },
      },
    } = getState();

    if (applications === LoadingState.None) {
      dispatch(appsFetchStart());
      const response = await apolloClient.query({
        query: WorkflowsMetadataDocument,
      });
      forEach(response.data.items, (workflow) => {
        const { entities } = normalize(workflow, workflowSchema);
        dispatch(applicationFetchCompleted(workflow, dispatch));
        dispatch(
          updateWorkflowMetadata({
            workflowIdentifier: { workflowId: workflow.id, releaseEnv },
            entities: entities as UpdateWorkflowMetadataPayload['entities'],
          }),
        );
      });
      dispatch(appsFetchCompleted());

      return response.data.items;
    }

    return [];
  },
);

export function fetchOperatorWorkflows(): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const {
      ui: {
        applicationsView: { loading },
      },
    } = getState();
    if (!loading) {
      dispatch(appsFetchStart());
      return apolloClient
        .query({
          query: OperatorWorkflowsDocument,
        })
        .then(
          (value) =>
            batch(() => {
              forEach(value.data.items, (workflow) => {
                const { entities } = normalize(workflow, workflowSchema);
                dispatch(applicationFetchCompleted(workflow, dispatch));
                dispatch(
                  updateWorkflow({
                    workflowIdentifier: { workflowId: workflow.id, releaseEnv: ReleaseEnv.Draft },
                    entities: {
                      ...(entities as UpdateWorkflowPayload['entities']),
                      // Don't hydrate views for workflows
                      views: {},
                    },
                  }),
                );
                dispatch(
                  updateWorkflowMetadata({
                    workflowIdentifier: { workflowId: workflow.id, releaseEnv: ReleaseEnv.Draft },
                    entities: entities as UpdateWorkflowMetadataPayload['entities'],
                  }),
                );
              });

              dispatch(appsFetchCompleted());
            }),
          (error) => dispatch(appsFetchFailed(error.message)),
        );
    }
    return Promise.resolve();
  };
}

export function duplicateWorkflow(workflowId: string): ThunkResult<any> {
  return async (dispatch, getState, { apolloClient }) => {
    const response = await graphqlMutation(
      DuplicateApplicationDocument,
      RpcActionPrefix.DUPLICATE_WORKFLOW,
      dispatch,
      apolloClient,
      {
        workflowId,
      },
    );
    const { duplicateApplication } = response;
    const { entities } = normalize(duplicateApplication, duplicateApplicationResponseSchema);

    await dispatch(
      updateWorkflow({
        workflowIdentifier: {
          workflowId: duplicateApplication.workflow.id,
          releaseEnv: ReleaseEnv.Draft,
        },
        entities: entities as UpdateWorkflowPayload['entities'],
      }),
    );
    return duplicateApplication.workflow;
  };
}

interface UpdateWorkflowArgs {
  workflowId: string;
  newName: string;
  newDescription?: string;
}

export const updateWorkflowOnBackend: AppAsyncThunk<void, UpdateWorkflowArgs> = createAsyncThunk(
  'update-workflow',
  async ({ workflowId, newName, newDescription }, { extra }) => {
    const { apolloClient } = extra;
    await apolloClient.mutate({
      mutation: UpdateWorkflowDocument,
      variables: { name: newName, id: workflowId, description: newDescription },
    });
  },
);

interface CreateWorkflowArgs {
  name: string;
  description: string;
  modelId?: string;
  entry?: string;
}

export const createWorkflowOnBackend: AppAsyncThunk<WorkflowMetadata, CreateWorkflowArgs> =
  createAsyncThunk(
    'create-workflow',
    async (
      { name, description, modelId, entry },
      { dispatch, extra },
    ): Promise<WorkflowMetadata> => {
      const { apolloClient } = extra;
      const result = await apolloClient.mutate({
        mutation: CreateWorkflowDocument,
        variables: {
          name,
          description,
          modelId: isEmpty(modelId) ? null : modelId,
          entry: isEmpty(entry) ? null : entry,
        },
      });
      const workflowId = result.data.createWorkflow.id;
      const createdWorkflowResp = result.data.createWorkflow;

      return {
        id: workflowId,
        name,
        description,
        shouldShowNav: false,
        isStarterApp: false,
        created: new Date(createdWorkflowResp.created),
        modified: new Date(createdWorkflowResp.created),
        builderVersion: createdWorkflowResp.builderVersion,
        hasUndeployedChanges: true,
        lastDeployedToProductionAt: null,
        isGitSynced: false,
      };
    },
  );

export function createWorkflow(modelId?: string): ThunkResult<void> {
  return async (dispatch, getState) => {
    const workflows = values(selectWorkflowMetadataMap(getState()));
    const createResponse = await dispatch(
      createWorkflowOnBackend({
        name: getInstanceName(workflows, 'Untitled Application '),
        description: '',
        modelId,
      }),
    );
    const {
      payload: { id: workflowId },
    } = createResponse as any;

    await dispatch(createView({ workflowId, name: getInstanceName([], 'View') }));
    await dispatch(hydrateWorkflow({ workflowId, releaseEnv: ReleaseEnv.Draft }));

    dispatch(switchPage(BasetenPageEnum.Application, { workflowId, releaseEnv: ReleaseEnv.Draft }));
  };
}
