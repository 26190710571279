import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import pick from 'lodash/pick';

import { hasErrors as hasErrorsFn } from '@/pages/Application/Code/utils';
import { ReleaseEnv } from '@/types/releaseEnv';

import { startEntityProcessor } from '../../../middleware/EntityProcessor';
import { AsyncThunkConfig } from '../../../types';
import { enqueueNotification } from '../../ui/Notifier';
import {
  CreateCodeFileDocument,
  DeleteCodeFileDocument,
  UpdateCodeFileDocument,
} from './__generated__/mutations.generated';
import { CodebaseDocument } from './__generated__/queries.generated';
import { handleUpdateGraphQLErrors } from './errorHandling';
import { fileSelector } from './selectors';
import {
  CodeFileError,
  CodebasesType,
  CreateFileAsyncPayload,
  CreateFileAsyncResult,
  DeleteFileAsyncPayload,
  FetchCodebaseAsyncPayload,
  FetchCodebaseAsyncResult,
  FileIdentifier,
  FileSaveState,
  UpdateFileAfterSavePayload,
  UpdateFilePayload,
  UpdateSaveStatePayload,
} from './types';
import { getFile, normalize } from './utils';

const initialState: CodebasesType = {
  data: {
    [ReleaseEnv.Draft]: {},
    [ReleaseEnv.Production]: {},
  },
};

const fetchCodebase = createAsyncThunk<
  FetchCodebaseAsyncResult,
  FetchCodebaseAsyncPayload,
  AsyncThunkConfig
>(
  'codebase/fetch-codebase',
  async (
    { workflowIdentifier: { workflowId, releaseEnv } },
    { extra },
  ): Promise<FetchCodebaseAsyncResult> => {
    const { data } = await extra.apolloClient.query({
      query: CodebaseDocument,
      variables: { workflowId, deploymentEnv: releaseEnv },
    });

    return { workflowId, releaseEnv, data: data.codebase.code_files };
  },
);

const createFile = createAsyncThunk<
  CreateFileAsyncResult,
  CreateFileAsyncPayload,
  AsyncThunkConfig
>(
  'codebase/create-file',
  async (
    { workflowId, releaseEnv, fileContent, filePath },
    { extra, dispatch },
  ): Promise<CreateFileAsyncResult> => {
    try {
      const { data } = await extra.apolloClient.mutate({
        mutation: CreateCodeFileDocument,
        variables: { workflowId, fileContent, filePath },
      });

      return { workflowId, releaseEnv, data };
    } catch (error) {
      dispatch(enqueueNotification({ message: error.message, variant: 'error' }));
    }
  },
);

const deleteFile = createAsyncThunk<
  DeleteFileAsyncPayload,
  DeleteFileAsyncPayload,
  AsyncThunkConfig
>('codebase/delete-file', async ({ workflowId, fileId, releaseEnv }, { extra, dispatch }) => {
  if (releaseEnv !== ReleaseEnv.Draft) {
    throw new Error(`File deletion not supported for files in ${releaseEnv} environment`);
  }

  try {
    await extra.apolloClient.mutate({
      mutation: DeleteCodeFileDocument,
      variables: { workflowId, fileId },
    });

    return { workflowId, fileId, releaseEnv };
  } catch (error) {
    dispatch(enqueueNotification({ message: error.message, variant: 'error' }));
  }
});

const Codebases = createSlice({
  name: 'codebase-entities',
  initialState,
  reducers: {
    setSaveState(state, { payload }: PayloadAction<UpdateSaveStatePayload>) {
      const file = getFile(state, payload);
      if (file) {
        file.saveState = payload.saveState;
      }
    },

    updateFile(
      state,
      { payload: { fileContent, filepath, ...fileIdentifier } }: PayloadAction<UpdateFilePayload>,
    ) {
      const file = getFile(state, fileIdentifier);
      if (!file) {
        return;
      }
      file.content = fileContent;
      if (filepath) {
        file.filepath = filepath;
      }
    },

    updateFileAfterSave(state, { payload }: PayloadAction<UpdateFileAfterSavePayload>) {
      const file = getFile(state, payload);
      if (!file) {
        return;
      }
      Object.assign(file, payload.file);
      // Clear errors after a successful save
      delete file.errors;
    },

    setErrors(
      state,
      {
        payload: { errors, fileIdentifier },
      }: PayloadAction<{ fileIdentifier: FileIdentifier; errors: CodeFileError[] }>,
    ) {
      const file = getFile(state, fileIdentifier);
      if (!file) {
        return;
      }
      file.errors = errors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCodebase.fulfilled, (state, { payload, meta }) => {
      state.data[payload.releaseEnv][payload.workflowId] = payload.data.map(normalize);
    });
    builder.addCase(createFile.fulfilled, (state, { payload }) => {
      state.data[ReleaseEnv.Draft][payload.workflowId].push(
        normalize(payload.data.create_or_update_code_file.code_file),
      );
    });
    builder.addCase(deleteFile.fulfilled, (state, { payload }) => {
      if (payload) {
        const files = state.data[ReleaseEnv.Draft][payload.workflowId];

        state.data[ReleaseEnv.Draft][payload.workflowId] = files.filter(
          (file) => file.id !== payload.fileId,
        );
      }
    });
  },
});

export { fetchCodebase, createFile, deleteFile };

export const { updateFile, setSaveState, setErrors } = Codebases.actions;
const { updateFileAfterSave } = Codebases.actions;
export default Codebases.reducer;

startEntityProcessor<FileIdentifier>({
  predicate: updateFile.match,
  debounceMs: null,
  extractIdentifier: (action: PayloadAction<UpdateFilePayload>) =>
    pick(action.payload, 'workflowId', 'fileId', 'releaseEnv'),
  async process(fileIdentifier, { dispatch, extra, getState }) {
    const { workflowId, fileId, releaseEnv } = fileIdentifier;
    if (releaseEnv !== ReleaseEnv.Draft) {
      throw new Error(`File save not supported for files in ${releaseEnv} environment`);
    }

    dispatch(
      setSaveState({
        ...fileIdentifier,
        saveState: FileSaveState.SAVING,
      }),
    );

    try {
      const codebasesState = getState().entities.codebases;
      const targetFile = codebasesState.data[releaseEnv][workflowId].find(
        (file) => file.id === fileId,
      );

      const { data } = await extra.apolloClient.mutate({
        mutation: UpdateCodeFileDocument,
        variables: {
          ...fileIdentifier,
          fileVersionId: targetFile.versionId,
          fileContent: targetFile.content,
          filePath: targetFile.filepath,
        },
      });
      dispatch(
        updateFileAfterSave({
          ...fileIdentifier,
          file: data.create_or_update_code_file.code_file,
        }),
      );
    } catch (err) {
      handleUpdateGraphQLErrors(fileIdentifier, dispatch, err);
    }
  },
  onComplete(fileIdentifier, { dispatch, getState }) {
    const file = fileSelector(getState(), fileIdentifier);
    const hasErrors = hasErrorsFn(file);

    // When file finishes saving, set saveState to FileSaveState.SAVED
    dispatch(
      setSaveState({
        ...fileIdentifier,
        // If the file has errors, it was not saved
        saveState: hasErrors ? FileSaveState.UNSAVED : FileSaveState.SAVED,
      }),
    );
  },
});
