import { styled } from '@mui/system';

import BaseAppLink from '@/components/AppLink';

const AppLink = styled(BaseAppLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.7, 1),
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    color: theme.palette.text.default,
    background: theme.palette.background.muted,
  },
}));

AppLink.defaultProps = {
  color: 'text.default',
  underline: 'none',
  fontWeight: 500,
};

export { AppLink };
