import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function BasetenIcon(props: SvgIconProps) {
  return (
    <SvgIcon version="1.1" x="0px" y="0px" viewBox="0 0 114.81 172.35" {...props}>
      <path
        fill="currentColor"
        d="M114.59,86.29c0-7.23-4.19-14.06-11.64-20.14c7.44-6.08,11.64-12.91,11.64-20.14
	c0-25.28-51.11-45.78-114.15-45.78v40.29V80.8v10.98v40.29v40.29c63.04,0,114.15-20.5,114.15-45.78c0-7.23-4.19-14.06-11.64-20.14
	C110.39,100.35,114.59,93.52,114.59,86.29z M11.41,11.41c10.91,0.43,21.51,1.51,31.62,3.23c12.82,2.18,24.27,5.26,34.03,9.18
	c9.06,3.63,16.32,7.9,21,12.33c3.68,3.49,5.55,6.8,5.55,9.85c0,3.05-1.87,6.36-5.55,9.85c-1.37,1.3-2.98,2.57-4.77,3.83
	C74.59,49.2,45.16,42.01,11.41,40.72V11.41z M11.41,80.58V51.7c10.91,0.43,21.51,1.51,31.62,3.23c12.82,2.18,24.27,5.26,34.03,9.18
	c1.64,0.66,3.2,1.34,4.71,2.04c-1.52,0.7-3.07,1.38-4.71,2.04c-9.76,3.91-21.21,7-34.03,9.18C32.92,79.07,22.33,80.15,11.41,80.58z
	 M43.03,95.22c12.82,2.18,24.27,5.26,34.03,9.18c1.64,0.66,3.2,1.34,4.71,2.04c-1.52,0.7-3.07,1.38-4.71,2.04
	c-9.76,3.91-21.21,7-34.03,9.18c-10.11,1.71-20.71,2.8-31.62,3.23V91.99C22.33,92.42,32.92,93.5,43.03,95.22z M98.07,116.72
	c3.68,3.49,5.55,6.8,5.55,9.85c0,3.05-1.87,6.36-5.55,9.85c-4.67,4.43-11.93,8.69-21,12.33c-9.76,3.91-21.21,7-34.03,9.18
	c-10.11,1.71-20.71,2.8-31.62,3.23v-29.31c33.74-1.29,63.18-8.48,81.88-18.96C95.09,114.15,96.7,115.43,98.07,116.72z M98.07,96.14
	c-1.37,1.3-2.98,2.57-4.77,3.83c-10.09-5.65-23.29-10.35-38.63-13.68c15.34-3.33,28.54-8.03,38.63-13.68
	c1.79,1.26,3.4,2.53,4.77,3.83c3.68,3.49,5.55,6.8,5.55,9.85C103.61,89.33,101.75,92.65,98.07,96.14z"
      />
    </SvgIcon>
  );
}

export default BasetenIcon;
