import { Reducer } from 'redux';

import { createWorkflowOnBackend } from '../actions/WorkflowsAsyncActions';
import { appsFetchCompleted, appsFetchFailed, appsFetchStart } from '../slices/ui/AppView';

interface ApplicationsViewState {
  loading: boolean;
  error: string;
}

const initialApplications: ApplicationsViewState = {
  loading: false,
  error: '',
};

const applicationsViewReducer: Reducer<ApplicationsViewState, any> = (
  state = initialApplications,
  action: any,
) => {
  switch (action.type) {
    case [appsFetchStart.type]:
      return {
        ...state,
        loading: true,
      };
    case [appsFetchCompleted.type]: {
      return {
        ...state,
        loading: false,
      };
    }
    case [appsFetchFailed.type]: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case [createWorkflowOnBackend.rejected as any]:
      return {
        ...state,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default applicationsViewReducer;
