import { Paper } from '@mui/material';
import { styled } from '@mui/system';

import { defaultTheme } from '@/theme';

const Body = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: defaultTheme.palette.background.subtle,
});

const Container = styled(Paper)({
  width: '400px',
  padding: '40px',
});

const Form = styled('form')({});

export { Body, Container, Form };
