import { ChangeEvent, useState } from 'react';

import { InputLabel, Stack, TextField } from '@mui/material';

import { OrganizationJoinExistingSetting } from '@/graphql';
import useOrg from '@/hooks/useOrg';
import { useUpdateOrganizationMutation } from '@/hooks/useOrg/__generated__/mutations.generated';
import JoinExistingSettings from '@/sections/JoinExistingSettings';
import { useAnalytics } from '@/utils/analytics/segment/hooks';
import { useSnackbar } from 'notistack';

import { useExistingOrganizationQuery } from '../../__generated__/queries.generated';
import { useCompleteOnboarding, useExitOnboarding } from '../../hooks';
import { OnboardingEvent } from '../../types';
import { Body, Container, Description, Header, NextButton, Title } from '../styled';
import { saveWorkspace } from './utils';

const INPUT_WIDTH = 452;

function WorkspaceStep() {
  const analytics = useAnalytics();
  const { data: existingOrganizationData, loading: existingOrganizationDataLoading } =
    useExistingOrganizationQuery({
      fetchPolicy: 'no-cache',
    });
  const org = useOrg();
  const [updateOrg] = useUpdateOrganizationMutation();
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const completeOnboarding = useCompleteOnboarding();
  const exitOnboarding = useExitOnboarding();

  async function nextStep() {
    await completeOnboarding();

    exitOnboarding();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setWorkspaceName(e.currentTarget.value);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await saveWorkspace(workspaceName);
      analytics.track(OnboardingEvent.NamedWorkflow);

      await updateOrg({ variables: { workspaceName } });
      nextStep();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const isLoading = existingOrganizationDataLoading || loading;
  const showJoinExistingSettings =
    !existingOrganizationDataLoading &&
    !existingOrganizationData?.existingOrganization &&
    [OrganizationJoinExistingSetting.Enabled, OrganizationJoinExistingSetting.Disabled].includes(
      org?.joinExistingSetting,
    );

  return (
    <Container>
      <Header>
        <Title data-cy="onb-title">Create your workspace</Title>
        <Description>
          Next, let’s give your Baseten workspace a name. This could be the name of your company,
          team, a project, or something else.
        </Description>
      </Header>
      <Body>
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={1}>
            <InputLabel htmlFor="workspace-name">Workspace name</InputLabel>

            <TextField
              fullWidth
              sx={{ width: INPUT_WIDTH }}
              id="workspace-name"
              name="workspaceName"
              variant="outlined"
              inputProps={{
                'data-cy': 'onb-workspace-name',
              }}
              placeholder="e.g. Baseten"
              onChange={handleChange}
              value={workspaceName}
              autoFocus
            />
          </Stack>
          {showJoinExistingSettings && (
            <Stack sx={{ width: INPUT_WIDTH }}>
              <JoinExistingSettings />
            </Stack>
          )}
        </Stack>
        <NextButton
          onClick={handleSubmit}
          disabled={workspaceName.trim().length === 0}
          data-cy="onb-submit"
          loading={isLoading}
        >
          Continue
        </NextButton>
      </Body>
    </Container>
  );
}

export default WorkspaceStep;
