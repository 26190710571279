import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { dialogOpenState } from './atoms';

export function useIsPaymentMethodDialogOpen() {
  return useRecoilValue(dialogOpenState);
}

export function useSetPaymentMethodDialogOpen() {
  return useSetRecoilState(dialogOpenState);
}

export function usePaymentMethodDialogOpenState() {
  return useRecoilState(dialogOpenState);
}
