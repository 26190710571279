import { FC } from 'react';

import { BasetenPageEnum } from '@/routes';
import asyncComponentLoader from '@/utils/react/loader';

const PageComponents: Partial<Record<BasetenPageEnum, FC>> = {
  [BasetenPageEnum.Workspace]: asyncComponentLoader(() => import('@/pages/Workspace')),
  [BasetenPageEnum.Onboarding]: asyncComponentLoader(() => import('@/pages/Onboarding')),
};

export default PageComponents;
