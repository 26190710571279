import { ChangeEvent, useMemo, useState } from 'react';

import { CircularProgress, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import SrarchIcon from '@/components/Icons/SearchIcon';
import SquareCheckedIcon from '@/components/Icons/SquareCheckedIcon';
import SquareIcon from '@/components/Icons/SquareIcon';
import Fuse from 'fuse.js';

import { ModelNameFragment } from '../__generated__/fragments.generated';
import { useModelNamesQuery } from '../__generated__/queries.generated';
import { ModelListContainer, ModelListContent, ModelListHeader } from './styled';
import { ModelListProps } from './types';

const SEARCH_OPTIONS: Fuse.IFuseOptions<ModelNameFragment> = {
  isCaseSensitive: false,
  threshold: 0.2,
  keys: ['name', 'id'],
};

function ModelList({
  onModelSelect,
  selectedModelIds,
  onAllModelsSelect,
  selectedAllModels,
}: ModelListProps) {
  const { data, loading } = useModelNamesQuery({
    fetchPolicy: 'cache-first',
  });
  const [filter, setFilter] = useState('');

  const fuse = useMemo(
    () => new Fuse<ModelNameFragment>(data?.models || [], SEARCH_OPTIONS),
    [data?.models],
  );

  const filteredModels = useMemo(
    () => (filter ? fuse.search(filter).map(({ item }) => item) : data?.models || []),
    [fuse, filter, data?.models],
  );

  function handleModelChange(selectedId: string, checked: boolean) {
    onAllModelsSelect(false);
    if (checked) {
      onModelSelect([...selectedModelIds, selectedId]);
    } else {
      onModelSelect(selectedModelIds.filter((id) => id !== selectedId));
    }
  }

  function handleSelectAllModels(event: ChangeEvent<HTMLInputElement>) {
    onAllModelsSelect(event.target.checked);
    onModelSelect([]);
  }

  function getContent() {
    if (loading) {
      return (
        <Stack justifyContent="center" alignItems="center" flex={1}>
          <CircularProgress size={16} color="secondary" />
        </Stack>
      );
    }

    if (!data?.models) {
      <Stack justifyContent="center" alignItems="center" flex={1}>
        <Typography color="text.muted">You don&apos;t have any models yet</Typography>
      </Stack>;
    }

    const modelsCount = data.models.length;

    function getList() {
      if (!filteredModels.length) {
        return (
          <Stack height="100%" justifyContent="center" alignItems="center" flex={1}>
            <Typography color="text.muted">No models found</Typography>
          </Stack>
        );
      }

      return filteredModels.map((model) => {
        const isSelected = selectedModelIds.includes(model.id);

        return (
          <FormControlLabel
            key={model.id}
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="h5">{model.name}</Typography>
                <Typography color="text.subtle" variant="body1">
                  {model.id}
                </Typography>
              </Stack>
            }
            sx={{ gap: 1 }}
            color="secondary"
            control={
              <Checkbox
                checked={isSelected}
                icon={<SquareIcon />}
                checkedIcon={<SquareCheckedIcon />}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleModelChange(model.id, event.target.checked)
                }
              />
            }
          />
        );
      });
    }

    return (
      <Stack gap={0.5} flex={1}>
        <ModelListHeader>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SrarchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Filter by model name or ID"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              label={
                <Typography variant="h5">
                  {modelsCount > 1 ? `All ${modelsCount} models` : 'All models'}
                </Typography>
              }
              sx={{ gap: 1 }}
              color="secondary"
              control={
                <Checkbox
                  checked={selectedAllModels}
                  icon={<SquareIcon />}
                  checkedIcon={<SquareCheckedIcon />}
                  onChange={handleSelectAllModels}
                />
              }
            />
            <Typography variant="body2" color="text.subtle">
              Sorted by last deployed to
            </Typography>
          </Stack>
        </ModelListHeader>
        <ModelListContent>{getList()}</ModelListContent>
      </Stack>
    );
  }

  return <ModelListContainer>{getContent()}</ModelListContainer>;
}

export default ModelList;
