import difference from 'lodash/difference';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import partial from 'lodash/partial';
import unset from 'lodash/unset';

import { extractConfigFromAction } from '@/pages/Application/View/actions/utils';
import { ComponentConfig, Location } from '@/pages/Application/View/components/types';
import { RefPath, RefType } from '@/pages/Application/View/refs/types';
import { StateData, StateSupplier } from '@/pages/Application/View/states/types';
import { fromStateDataToResolutionValue } from '@/pages/Application/View/states/utils';
import {
  ComponentCollection,
  ContextualAction,
  EventHandler,
  ValueSupplier,
} from '@/pages/Application/View/types';
import { generateId } from '@/utils/ids';

import { Action, ActionType } from './Actions/types';
import { isEmptyAction } from './Actions/utils';
import { cloneStrongDependencies } from './strong-clone';
import { ComponentConfigNodeType } from './strong-clone-types';
import { wrapResolutionValue } from './utils';

function findAllRefsInAction(action: Action) {
  const refs: string[] = [];
  Object.values(action).forEach((configValue) => {
    if (typeof configValue === 'object' && configValue.refs) {
      refs.push(...configValue.refs);
    }
  });
  return refs;
}

function upsertAction(
  collection: ComponentCollection,
  actionId: string,
  action: Record<string, any>,
) {
  const { actions } = collection;
  const { config, extraKeys } = extractConfigFromAction(action);
  const refsToCleanup: string[] = [];
  extraKeys.forEach((key) => {
    if (key === 'paramRef') {
      cleanupParam(collection, action[key]);
    } else if (action[key]?.refs) {
      refsToCleanup.push(...action[key].refs);
    }
  });
  const existingAction = actions[actionId];
  if (existingAction) {
    // update
    const existingParamRef = get(existingAction, 'paramRef');
    if (existingParamRef !== get(config, 'paramRef')) {
      cleanupParam(collection, existingParamRef);
    }
    const existingRefs = findAllRefsInAction(existingAction);
    const refsNoLongerRelevant = difference(existingRefs, findAllRefsInAction(config));
    refsToCleanup.push(...refsNoLongerRelevant);
  }
  refsToCleanup.forEach((refId) => cleanupRef(collection, refId));

  actions[actionId] = config;
}

function cleanupAction(collection: ComponentCollection, actionId: string) {
  if (!actionId) {
    return;
  }
  const { actions } = collection;
  const action = actions[actionId];
  if (action) {
    // we are using the get() below to get past the typescript complaint that
    // `paramRef` doesn't exist on certain types (due to Action being an Union type)
    cleanupParam(collection, get(action, 'paramRef'));
    const refs = findAllRefsInAction(action);
    refs.forEach((refId) => {
      cleanupRef(collection, refId);
    });
    delete actions[actionId];
  }
}

function isStringArray<T>(arr: string[] | T[]): arr is string[] {
  return typeof arr[0] === 'string';
}

function cleanupExistingHandlers(
  collection: ComponentCollection,
  existingHandlers: EventHandler[] | string[],
  newHandlers: EventHandler[] | string[],
) {
  const existingActionsIds: string[] = isStringArray(existingHandlers)
    ? existingHandlers
    : existingHandlers.map(({ actionId }) => actionId);
  const newActionsIds = isStringArray(newHandlers)
    ? newHandlers
    : newHandlers.map(({ actionId }) => actionId);
  const removeActions = difference(existingActionsIds, newActionsIds);
  removeActions.forEach((actionId) => {
    cleanupAction(collection, actionId);
  });
}

function cleanupContextualAction(
  collection: ComponentCollection,
  componentId: string,
  actionId: string,
) {
  if (!componentId || !actionId) {
    return;
  }
  const { children } = collection;
  const component = children[componentId];
  if (!component) {
    return;
  }
  const action = component.contextualActions[actionId];
  if (!action) {
    return;
  }
  cleanupExistingHandlers(collection, action.handlerIds, []);
  delete component.contextualActions[actionId];
}

function cleanupRef(collection: ComponentCollection, refId: string) {
  if (!refId) {
    return;
  }
  const { refs, datasources } = collection;
  const ref = refs[refId];
  if (!ref) {
    return;
  }
  if (ref.type === RefType.DatasourceWorklet || ref.type === RefType.DatasourceQueries) {
    const { instanceId } = ref;
    const datasource = datasources[instanceId];
    if (datasource?.paramRef) {
      cleanupParam(collection, datasource.paramRef);
    }
    delete datasources[instanceId];
  }
  delete refs[refId];
}

function cleanupParam(collection: ComponentCollection, paramId: string) {
  if (!paramId) {
    return;
  }
  const { params } = collection;
  const param = params[paramId];
  if (!param) {
    return;
  }
  // loop through the param's refs and remove those
  if (param.refs && param.refs.length > 0) {
    param.refs.forEach((refId) => {
      cleanupRef(collection, refId);
    });
  }
  delete params[paramId];
}

function downgradeStateToLocal(collection: ComponentCollection, stateSupplier: StateSupplier) {
  if (!stateSupplier?.name) {
    return;
  }
  const { states, resolvedData } = collection;
  const existingState = states[stateSupplier.name];
  if (!existingState || !existingState.isShared) {
    return;
  }
  const oldData = resolvedData[existingState.supplierId];
  cleanupValueSupplier(collection, existingState.supplierId);
  const newLocalState: StateSupplier = {
    name: stateSupplier.name,
    supplierId: generateId('state'),
  };
  states[stateSupplier.name] = newLocalState;
  resolvedData[newLocalState.supplierId] = oldData ?? wrapResolutionValue(null);
}

function upgradeStateToShared(collection: ComponentCollection, stateSupplier: StateSupplier) {
  const { states, resolvedData } = collection;
  const existingState = states[stateSupplier.name];
  if (!existingState || existingState.isShared) {
    return;
  }
  const oldStateValue = resolvedData[existingState.supplierId];
  cleanupValueSupplier(collection, existingState.supplierId);
  states[stateSupplier.name] = stateSupplier;
  resolvedData[stateSupplier.supplierId] = oldStateValue;
}

function insertState(
  collection: ComponentCollection,
  stateSupplier: StateSupplier,
  withData?: StateData,
) {
  const { states, resolvedData } = collection;
  const existingState = states[stateSupplier.name];
  if (existingState) {
    if (stateSupplier.isShared && !existingState.isShared) {
      upgradeStateToShared(collection, stateSupplier);
    }
    if (withData) {
      resolvedData[stateSupplier.supplierId] = fromStateDataToResolutionValue(withData);
    }
    return;
  }
  states[stateSupplier.name] = stateSupplier;
  const writeData = withData ? fromStateDataToResolutionValue(withData) : wrapResolutionValue(null);
  resolvedData[stateSupplier.supplierId] = writeData;
}

function removeState(collection: ComponentCollection, stateName: string) {
  const { states } = collection;
  const existingState = states[stateName];
  if (!existingState) {
    return;
  }
  const allReferencedStates = findAllReferencedStates(collection);
  if (!allReferencedStates.includes(stateName)) {
    // no references to the state being removed, we can just clean it up
    cleanupState(collection, stateName);
    return;
  }
  if (existingState.isShared) {
    // the state being removed is a shared state, so we need to downgrade it and make a local copy
    downgradeStateToLocal(collection, existingState);
  }
  // TODO(mike): we may need to throw an error if we are trying to remove a state
  // that is still being referenced? this will depend on the proposed UX in the future
  // so will leave this comment as a placeholder for that when we decide
}

function cleanupState(collection: ComponentCollection, stateName: string, onlyLocal?: boolean) {
  if (!stateName) {
    return;
  }
  const { states } = collection;
  const stateSupplier = states[stateName];
  if (!stateSupplier) {
    return;
  }
  if (onlyLocal && stateSupplier.isShared) {
    return;
  }
  cleanupValueSupplier(collection, stateSupplier.supplierId);
  delete states[stateName];
}

function assignStateData(
  collection: ComponentCollection,
  stateName: string,
  stateData: StateData | null,
) {
  if (!stateName) {
    return;
  }
  const { states, resolvedData } = collection;
  const stateSupplier = states[stateName];
  if (!stateSupplier) {
    return;
  }
  resolvedData[stateSupplier.supplierId] = stateData
    ? fromStateDataToResolutionValue(stateData)
    : wrapResolutionValue(null);
}

function findAllReferencedStates(collection: ComponentCollection): string[] {
  const found = new Set<string>();
  const { refs, actions } = collection;
  Object.values(refs).forEach((ref) => {
    if (ref.type === RefType.ComponentState) {
      found.add(ref.stateId);
    }
  });
  Object.values(actions).forEach((actionConfig) => {
    if (actionConfig.type === ActionType.SetState) {
      found.add(actionConfig.saveState);
    }
  });
  return Array.from(found);
}

function cleanupValueSupplier(collection: ComponentCollection, supplierId: string) {
  if (!supplierId) {
    return;
  }
  const { valueSuppliers, resolvedData } = collection;
  delete resolvedData[supplierId];
  const valueSupplier = valueSuppliers[supplierId];
  if (!valueSupplier) {
    return;
  }
  if (valueSupplier.refs && valueSupplier.refs.length > 0) {
    valueSupplier.refs.forEach((refId) => {
      cleanupRef(collection, refId);
    });
  }
}

function findAllSuppliersForComponent(component: ComponentConfig): string[] {
  const supplierIds: string[] = [];
  const { chrome, propSuppliers } = component;
  if (chrome?.headerText) {
    supplierIds.push(chrome.headerText);
  }
  supplierIds.push(...Object.values(propSuppliers));
  return supplierIds;
}

function findActionsInComponent(component: ComponentConfig): string[] {
  const { eventHandlers } = component;
  if (eventHandlers) {
    return eventHandlers.map(({ actionId }) => actionId);
  }
  return [];
}

function findAllRefsForSuppliers(
  valueSuppliers: Record<string, ValueSupplier>,
  supplierIds: string[],
) {
  const refs: string[] = [];
  supplierIds.forEach((supplierId) => {
    const valueSupplier = valueSuppliers[supplierId];
    if (valueSupplier) {
      refs.push(...valueSupplier.refs);
    }
  });
  return refs;
}

function initializeSuppliers(valueSuppliers: Record<string, ValueSupplier>, supplierIds: string[]) {
  supplierIds.forEach((supplierId) => {
    valueSuppliers[supplierId] = { value: null, refs: [] };
  });
}

function updateRef(ref: RefPath, oldId: string, newId: string) {
  switch (ref.type) {
    case RefType.ComponentProp:
    case RefType.ComponentField:
      if (ref.componentId === oldId) {
        ref.componentId = newId;
      }
      break;
    case RefType.DatasourceWorklet:
    case RefType.DatasourceQueries:
      if (ref.instanceId === oldId) {
        ref.instanceId = newId;
      }
      break;
    case RefType.Variables:
      if (ref.name === oldId) {
        ref.name = newId;
      }
      break;
  }
}

function clearData(collection: ComponentCollection, supplierId: string) {
  const { resolvedData } = collection;
  unset(resolvedData, supplierId);
}

function cloneComponent(
  sourceConfig: ComponentCollection,
  sourceComponentId: string,
  targetConfig: ComponentCollection,
  targetComponentId: string,
  targetLocation: Location,
) {
  const cloneInternal = partial(cloneStrongDependencies, sourceConfig, targetConfig);

  if (targetConfig.children[targetComponentId]) {
    // prevent overwriting existing children
    // TODO(mike): maybe throw an error here instead of return?
    return;
  }

  const refComponent = sourceConfig.children[sourceComponentId];

  const newComponent: ComponentConfig = {
    type: refComponent.type,
    location: targetLocation,
    chrome: refComponent.chrome
      ? {
          ...refComponent.chrome,
          headerText:
            refComponent.chrome.headerText &&
            cloneInternal({
              type: ComponentConfigNodeType.ValueSupplier,
              id: refComponent.chrome.headerText,
              subType: 'chrome',
            }),
        }
      : undefined,
    propSuppliers: mapValues(refComponent.propSuppliers, (supplierId) =>
      cloneInternal({
        type: ComponentConfigNodeType.ValueSupplier,
        id: supplierId,
        subType: 'prop',
      }),
    ),
    fields: mapValues(refComponent.fields, (supplierId) =>
      cloneInternal({
        type: ComponentConfigNodeType.ResolvedData,
        id: supplierId,
        subType: 'field',
      }),
    ),
    eventHandlers: refComponent.eventHandlers.map(
      (handler): EventHandler => ({
        event: handler.event,
        actionId: cloneInternal({
          type: ComponentConfigNodeType.Action,
          id: handler.actionId,
        }),
      }),
    ),
    contextualActions: mapValues(
      refComponent.contextualActions,
      (contextualAction): ContextualAction => ({
        ...contextualAction,
        handlerIds: contextualAction.handlerIds.map((handlerId) =>
          cloneInternal({
            type: ComponentConfigNodeType.Action,
            id: handlerId,
          }),
        ),
      }),
    ),
  };

  targetConfig.children[targetComponentId] = newComponent;
}

/**
 * Add and remove states based on current action handlers. This should be called
 * whenever action handlers are added or removed
 */
function reconcileStates(config: ComponentCollection) {
  const referencedStates = new Set<string>();
  for (const action of Object.values(config.actions)) {
    if (
      !isEmptyAction(action) &&
      (action.type === ActionType.SetState ||
        action.type === ActionType.TriggerQuery ||
        action.type === ActionType.RunWorklet)
    ) {
      // TODO: context https://basetenlabs.slack.com/archives/C014JUXFGLU/p1684152439759899
      // some actions don't have a saveState field
      // which shouldn't be possible as it's a required field
      // This is a temporary fix to prevent the app from crashing
      if (action.saveState) {
        referencedStates.add(action.saveState);
      }
    }
  }

  for (const stateName of Object.keys(config.states)) {
    if (!referencedStates.has(stateName)) {
      cleanupState(config, stateName, true);
    }
  }

  const statesToAdd = difference(Array.from(referencedStates), Object.keys(config.states));
  for (const stateName of statesToAdd) {
    insertState(config, {
      name: stateName,
      supplierId: generateId('state'),
    });
  }
}

export {
  upsertAction,
  cleanupAction,
  cleanupExistingHandlers,
  cleanupContextualAction,
  cleanupRef,
  cleanupParam,
  downgradeStateToLocal,
  upgradeStateToShared,
  insertState,
  removeState,
  cleanupState,
  assignStateData,
  findAllReferencedStates,
  cleanupValueSupplier,
  findAllSuppliersForComponent,
  findActionsInComponent,
  findAllRefsForSuppliers,
  initializeSuppliers,
  updateRef,
  clearData,
  cloneComponent,
  reconcileStates,
};
