import once from 'lodash/once';

import type { loadStripe } from '@stripe/stripe-js';

let stripePromise: ReturnType<typeof loadStripe> | undefined;

export const getStripePromise = once(async () => {
  const { loadStripe } = await import('@stripe/stripe-js');

  if (!stripePromise) {
    stripePromise = loadStripe(ENV_CONFIG.STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
});
