/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserApiKeyMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateUserApiKeyMutation = { __typename?: 'Mutation', createUserApiKey: { __typename?: 'CreateUserAPIKey', id: string | null | undefined, created: any | null | undefined, prefix: string | null | undefined, textKey: string | null | undefined } | null | undefined };

export type CreateWorkspaceApiKeyMutationVariables = Types.Exact<{
  allowedModelIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  apiKeyType?: Types.InputMaybe<Types.ApiKeyType>;
}>;


export type CreateWorkspaceApiKeyMutation = { __typename?: 'Mutation', createWorkspaceApiKey: { __typename?: 'CreateWorkspaceAPIKey', id: string | null | undefined, created: any | null | undefined, prefix: string | null | undefined, textKey: string | null | undefined } | null | undefined };


export const CreateUserApiKeyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserApiKey"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"createUserApiKey"},"name":{"kind":"Name","value":"create_user_api_key"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"textKey"},"name":{"kind":"Name","value":"text_key"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"prefix"}}]}}]}}]} as unknown as DocumentNode<CreateUserApiKeyMutation, CreateUserApiKeyMutationVariables>;
export const CreateWorkspaceApiKeyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateWorkspaceApiKey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"allowedModelIds"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"apiKeyType"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"APIKeyType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"createWorkspaceApiKey"},"name":{"kind":"Name","value":"create_workspace_api_key"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"allowed_model_ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"allowedModelIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"api_key_type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"apiKeyType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"textKey"},"name":{"kind":"Name","value":"text_key"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"prefix"}}]}}]}}]} as unknown as DocumentNode<CreateWorkspaceApiKeyMutation, CreateWorkspaceApiKeyMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreateUserApiKey: 'CreateUserApiKey',
    CreateWorkspaceApiKey: 'CreateWorkspaceApiKey'
  }
}

export type CreateUserApiKeyMutationFn = Apollo.MutationFunction<CreateUserApiKeyMutation, CreateUserApiKeyMutationVariables>;

/**
 * __useCreateUserApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateUserApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserApiKeyMutation, { data, loading, error }] = useCreateUserApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUserApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserApiKeyMutation, CreateUserApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserApiKeyMutation, CreateUserApiKeyMutationVariables>(CreateUserApiKeyDocument, options);
      }
export type CreateUserApiKeyMutationHookResult = ReturnType<typeof useCreateUserApiKeyMutation>;
export type CreateUserApiKeyMutationResult = Apollo.MutationResult<CreateUserApiKeyMutation>;
export type CreateUserApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateUserApiKeyMutation, CreateUserApiKeyMutationVariables>;
export type CreateWorkspaceApiKeyMutationFn = Apollo.MutationFunction<CreateWorkspaceApiKeyMutation, CreateWorkspaceApiKeyMutationVariables>;

/**
 * __useCreateWorkspaceApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceApiKeyMutation, { data, loading, error }] = useCreateWorkspaceApiKeyMutation({
 *   variables: {
 *      allowedModelIds: // value for 'allowedModelIds'
 *      apiKeyType: // value for 'apiKeyType'
 *   },
 * });
 */
export function useCreateWorkspaceApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceApiKeyMutation, CreateWorkspaceApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceApiKeyMutation, CreateWorkspaceApiKeyMutationVariables>(CreateWorkspaceApiKeyDocument, options);
      }
export type CreateWorkspaceApiKeyMutationHookResult = ReturnType<typeof useCreateWorkspaceApiKeyMutation>;
export type CreateWorkspaceApiKeyMutationResult = Apollo.MutationResult<CreateWorkspaceApiKeyMutation>;
export type CreateWorkspaceApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceApiKeyMutation, CreateWorkspaceApiKeyMutationVariables>;