import apolloClient from '@/apollo';

import { OrganizationDocument, useOrganizationQuery } from './__generated__/queries.generated';

function useOrgLoading() {
  const { data, ...rest } = useOrganizationQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    org: data?.organization,
    ...rest,
  };
}

function useOrg() {
  const data = useOrgLoading();

  if (!data.org) {
    throw new Error('Organization not loaded');
  }

  return data.org;
}

function getOrgFromCache() {
  const data = apolloClient.readQuery({
    query: OrganizationDocument,
  });

  return data?.organization;
}

export { getOrgFromCache, useOrgLoading };
export default useOrg;
