export interface Pipe {
  id: string;
  workletId: string;
  dataSourceId: string;
}

export enum DeploymentEnvName {
  draft = 'draft',
  production = 'production',
}
