export function formatPrice(
  usdCents?: number,
  fractionDigits = 2,
  omitZeroDecimalPart = false,
  notation: Intl.NumberFormatOptions['notation'] = 'standard',
): string {
  const omitDecimalPart = omitZeroDecimalPart && usdCents % 100 === 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation,
    minimumFractionDigits: omitDecimalPart ? 0 : fractionDigits,
    maximumFractionDigits: omitDecimalPart ? 0 : fractionDigits,
  }).format(usdCents ? usdCents / 100 : 0);
}
