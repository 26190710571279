/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { PynodeRequirementsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LatestPyEnvRequirementsFromWorkflowQueryVariables = Types.Exact<{
  workflowId: Types.Scalars['String'];
  deploymentEnv?: Types.InputMaybe<Types.DeploymentEnvEnum>;
}>;


export type LatestPyEnvRequirementsFromWorkflowQuery = { __typename?: 'QueryPrediction', workflow: { __typename?: 'WorkflowType', latestPyEnvRequirements: { __typename?: 'PyNodeRequirementType', id: string, status: Types.PyNodeRequirementStatus, modified: any, requirementsTxt: string | null | undefined, systemRequirements: string | null | undefined, errorMessage: string | null | undefined } | null | undefined } | null | undefined };


export const LatestPyEnvRequirementsFromWorkflowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LatestPyEnvRequirementsFromWorkflow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DeploymentEnvEnum"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"workflow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}}},{"kind":"Argument","name":{"kind":"Name","value":"deployment_env"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"latestPyEnvRequirements"},"name":{"kind":"Name","value":"latest_pynode_requirement"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PynodeRequirementsFragment"}}]}}]}}]}},...PynodeRequirementsFragmentDoc.definitions]} as unknown as DocumentNode<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>;
export const namedOperations = {
  Query: {
    LatestPyEnvRequirementsFromWorkflow: 'LatestPyEnvRequirementsFromWorkflow'
  }
}

/**
 * __useLatestPyEnvRequirementsFromWorkflowQuery__
 *
 * To run a query within a React component, call `useLatestPyEnvRequirementsFromWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPyEnvRequirementsFromWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPyEnvRequirementsFromWorkflowQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      deploymentEnv: // value for 'deploymentEnv'
 *   },
 * });
 */
export function useLatestPyEnvRequirementsFromWorkflowQuery(baseOptions: Apollo.QueryHookOptions<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>(LatestPyEnvRequirementsFromWorkflowDocument, options);
      }
export function useLatestPyEnvRequirementsFromWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>(LatestPyEnvRequirementsFromWorkflowDocument, options);
        }
export type LatestPyEnvRequirementsFromWorkflowQueryHookResult = ReturnType<typeof useLatestPyEnvRequirementsFromWorkflowQuery>;
export type LatestPyEnvRequirementsFromWorkflowLazyQueryHookResult = ReturnType<typeof useLatestPyEnvRequirementsFromWorkflowLazyQuery>;
export type LatestPyEnvRequirementsFromWorkflowQueryResult = Apollo.QueryResult<LatestPyEnvRequirementsFromWorkflowQuery, LatestPyEnvRequirementsFromWorkflowQueryVariables>;
export function refetchLatestPyEnvRequirementsFromWorkflowQuery(variables: LatestPyEnvRequirementsFromWorkflowQueryVariables) {
      return { query: LatestPyEnvRequirementsFromWorkflowDocument, variables: variables }
    }