/**
 * This function should be called to ensure all possible cases have been exhausted according to type information,
 * e.g. in the default case of a switch statement
 * @param condition The exhausted expression
 */
function unreachable(condition: never): never {
  throw new Error('Not expecting condition');
}

export default unreachable;
