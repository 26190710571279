import { FC } from 'react';

import Header from './Header';
import { useCurrentStep } from './hooks';
import CreateWorkspace from './steps/CreateWorkspace';
import ExistingWorkspace from './steps/ExistingWorkspace';
import ExistingWorkspaceSent from './steps/ExistingWorkspaceSent';
import Profile from './steps/Profile';
import { Body, Container, Cover, Wrapper } from './styled';
import { OnboardingProps, OnboardingStep } from './types';

const mapStepToComponent: Record<OnboardingStep, FC> = {
  [OnboardingStep.Profile]: Profile,
  [OnboardingStep.CreateWorkspace]: CreateWorkspace,
  [OnboardingStep.JoinExisting]: ExistingWorkspace,
  [OnboardingStep.RequestedToJoin]: ExistingWorkspaceSent,
};

function Onboarding({ providedStep }: OnboardingProps) {
  const step = useCurrentStep();
  const Component = mapStepToComponent[providedStep || step];

  return (
    <Container>
      <Wrapper>
        <Body>
          <Header />
          <Component />
        </Body>
        <Cover />
      </Wrapper>
    </Container>
  );
}

export default Onboarding;
