import { RootState } from '../../../types';
import { WorkflowIdentifier } from '../Workflows/types';

export function selectWorkflowMetadata(
  state: RootState,
  workflowIdentifier: WorkflowIdentifier | string,
) {
  return selectWorkflowMetadataMap(state)[
    typeof workflowIdentifier === 'string' ? workflowIdentifier : workflowIdentifier?.workflowId
  ];
}

export function selectWorkflowMetadataMap(state: RootState) {
  return state.entities.workflowMetadata;
}
