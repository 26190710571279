import type { DateRange } from '@mui/x-date-pickers-pro';
import type { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';

import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';

import * as Sentry from '@sentry/react';
import API from '@/API';
import moment, { Moment } from 'moment';

import {
  ApplicationLog,
  ExceptionLog,
  FetchLogsParams,
  Level,
  Log,
  LogType,
  Shortcuts,
  UserCodeLog,
  WorkletRunLog,
} from './types';

async function fetchLogs({
  entityId,
  regex,
  levels = [],
  dateRange,
  type,
  subtype,
  releaseEnv,
  direction,
  replica,
  limit = 250,
}: FetchLogsParams): Promise<Log[]> {
  const [start, end] = dateRange;

  const filterOptions = {
    type,
    start: start.valueOf(),
    end: end.valueOf(),
    levels,
    regex,
    limit,
    entity_id: entityId,
    ...(direction && { direction }),
    ...(subtype && { subtype }),
    ...(releaseEnv && { deployment_env: releaseEnv.toLowerCase() }),
    ...(replica && { replica }),
  };
  const response = await API.postWithoutCallback('/logs', filterOptions);

  if (response.status !== 200) {
    throw new Error(`${response.status} /logs`);
  }

  return response.data.logs.map((log: Omit<Log, 'ts'> & { ts: string }) => ({
    ...log,
    ts: parseInt(log.ts, 10),
  }));
}

function getDateRangeTitle(from: Moment, to: Moment) {
  return `${moment(from).format('LL')} ${to ? `- ${moment(to).format('LL')}` : ''}`;
}

const shortcuts: Record<Shortcuts, PickersShortcutsItem<DateRange<Moment>>> = {
  [Shortcuts.LAST_5_MINUTES]: {
    label: Shortcuts.LAST_5_MINUTES,
    getValue() {
      const now = moment();
      return [now.clone().subtract(5, 'minutes'), now];
    },
  },

  [Shortcuts.LAST_10_MINUTES]: {
    label: Shortcuts.LAST_10_MINUTES,
    getValue() {
      const now = moment();
      return [now.clone().subtract(10, 'minutes'), now];
    },
  },

  [Shortcuts.LAST_30_MINUTES]: {
    label: Shortcuts.LAST_30_MINUTES,
    getValue() {
      const now = moment();
      return [now.clone().subtract(30, 'minutes'), now];
    },
  },
  [Shortcuts.LAST_1_HOUR]: {
    label: Shortcuts.LAST_1_HOUR,
    getValue() {
      const now = moment();
      return [now.clone().subtract(1, 'hours'), now];
    },
  },

  [Shortcuts.LAST_6_HOURS]: {
    label: Shortcuts.LAST_6_HOURS,
    getValue() {
      const now = moment();
      return [now.clone().subtract(6, 'hours'), now];
    },
  },

  [Shortcuts.LAST_24_HOURS]: {
    label: Shortcuts.LAST_24_HOURS,
    getValue() {
      const now = moment();
      return [now.clone().subtract(24, 'hours'), now];
    },
  },

  [Shortcuts.LAST_3_DAYS]: {
    label: Shortcuts.LAST_3_DAYS,
    getValue() {
      const now = moment();
      return [now.clone().subtract(3, 'days'), now];
    },
  },

  [Shortcuts.LAST_7_DAYS]: {
    label: Shortcuts.LAST_7_DAYS,
    getValue() {
      const now = moment();
      return [now.clone().subtract(7, 'days'), now];
    },
  },
};

function getShortcuts() {
  return Object.values(shortcuts);
}

function getStatusTitle(levels: Array<Level>) {
  return levels.map((level) => startCase(level.toLowerCase())).join(', ');
}

function getLiveDateRange(from: Moment, to: Moment): DateRange<Moment> {
  const now = moment();

  if (!to) {
    return [from, from.endOf('day')];
  }

  if (to.isSame(now, 'day')) {
    const diffFromNow = now.diff(to, 'seconds');
    return [from.add(diffFromNow, 'seconds'), to.add(diffFromNow, 'seconds')];
  }

  return [from, to];
}

function transformLogs(logs: Log[]): ApplicationLog[] {
  return logs
    .map((log): ApplicationLog | null => {
      try {
        const msg = JSON.parse(log.msg);
        const isException = 'traceback' in msg;
        if (isException) {
          return {
            ...log,
            ...{
              msg: transformExceptionLog(msg),
              type: LogType.EXCEPTION,
            },
          };
        }
        const isUserCode = msg.tag === 'user-code-log';
        return {
          ...log,
          ...{
            msg: isUserCode ? transformUserCodeLog(msg) : transformWorkletRunLog(msg),
            type: isUserCode ? LogType.USER_CODE : LogType.WORKLET_RUN,
          },
        };
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    })
    .filter(Boolean);
}

function transformUserCodeLog(msg: Record<string, any>): UserCodeLog {
  return {
    workletRunId: msg.worklet_run_id,
    levelName: msg.levelname,
    msg: stringifyLogData(msg.msg),
  };
}

function transformExceptionLog(msg: Record<string, any>): ExceptionLog {
  return {
    workletRunId: msg.worklet_run_id,
    levelName: msg.levelname,
    traceback: msg.traceback ?? [],
    error: msg.name,
    msg: stringifyLogData(msg.msg),
  };
}

function transformWorkletRunLog(msg: Record<string, any>): WorkletRunLog {
  return {
    env: stringifyLogData(msg.env),
    inputData: stringifyLogData(msg.input_data),
    output: stringifyLogData(msg.output),
    scheduled: msg.scheduled ? 'Yes' : 'NO',
    status: msg.status,
    workletId: msg.worklet_id,
    workletName: msg.worklet_name,
    routeName: msg.route_name,
    workletRunId: msg.worklet_run_id,
    latencyMs: msg.latency_ms,
  };
}

function stringifyLogData(value: any) {
  if (!value) {
    return JSON.stringify({});
  }

  if (isObject(value)) {
    return JSON.stringify(value);
  }

  return value;
}

function getCreatedAt(nanoseconds: number): string {
  const milliseconds = Number((nanoseconds / 1000000000).toFixed(0));
  return moment.unix(milliseconds).format('MMM DD h:mm:ss a');
}

export {
  fetchLogs,
  getDateRangeTitle,
  getShortcuts,
  getStatusTitle,
  shortcuts,
  getLiveDateRange,
  transformLogs,
  getCreatedAt,
};
