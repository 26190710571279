import { useCallback } from 'react';

import withLazyOpen from '@/hocs/withLazyOpen';
import asyncComponentLoader from '@/utils/react/loader';

import { useHandleSetupIntentCallback } from '../hooks';
import { usePaymentMethodDialogOpenState } from './hooks';
import { PaymentMethodDialogProps } from './types';

function EmptyFallback(): null {
  return null;
}

const PaymentMethodDialog = withLazyOpen(
  asyncComponentLoader<PaymentMethodDialogProps>(
    () => import('./PaymentMethodDialog'),
    undefined,
    undefined,
    EmptyFallback,
  ),
);

export default function DialogRenderer() {
  const [isPaymentMethodDialogOpen, setIsPaymentMethodDialogOpen] =
    usePaymentMethodDialogOpenState();

  const closeAddPaymentMethodDialog = useCallback(() => {
    setIsPaymentMethodDialogOpen(false);
  }, [setIsPaymentMethodDialogOpen]);

  useHandleSetupIntentCallback();

  return (
    <PaymentMethodDialog open={isPaymentMethodDialogOpen} onClose={closeAddPaymentMethodDialog} />
  );
}
