import { Paper, styled } from '@mui/material';

const Container = styled(Paper)<{ severity: 'error' | 'success' }>(({ severity, theme }) => ({
  // TODO: baseten and blueprint themes are incompatible
  // This component is used in both, so we need to handle both cases
  // borderColor: theme.palette.border[severity === 'error' ? 'danger' : severity],
  backgroundColor: theme.palette.background[severity === 'error' ? 'danger' : severity],
  padding: theme.spacing(3, 2.25),
}));

Container.defaultProps = {
  variant: 'outlined',
};

export { Container };
