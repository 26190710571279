import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function BrandMarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="24" viewBox="0 0 16 24" fill="none" {...props}>
      <path
        d="M16 12.0022C16 10.995 15.4113 10.0418 14.3685 9.19236C15.4113 8.3451 16 7.39193 16 6.38257C16 2.85735 8.83747 0 0 0V5.61744V11.2349V12.7651V18.3826V24C8.8353 24 16 21.1427
        16 17.6174C16 16.6102 15.4113 15.6571 14.3685 14.8076C15.4113 13.9625 16 13.0094 16 12.0022ZM1.54026 1.56052C3.06965 1.62104 4.5556 1.77233 5.97203 2.01009C7.76864 2.31268 9.37407
        2.74496 10.7427 3.28963C12.0136 3.79539 13.0303 4.39193 13.6864 5.00793C14.2012 5.49424 14.4641 5.95677 14.4641 6.38257C14.4641 6.80836 14.2034 7.27089 13.6864 7.7572C13.4952 7.93876
        13.2692 8.11599 13.0172 8.29107C10.3951 6.82997 6.26965 5.82709 1.54026 5.64769V1.56052V1.56052ZM1.54026 11.2068V7.18012C3.06965 7.24063 4.5556 7.39193 5.97203 7.62968C7.76864 7.93228
        9.37407 8.36455 10.7427 8.90922C10.973 9 11.1902 9.09726 11.4031 9.19236C11.1902 9.28963 10.973 9.38473 10.7427 9.4755C9.37407 10.0223 7.77081 10.4524 5.97203 10.755C4.55343 10.995
        3.06965 11.1463 1.54026 11.2068ZM5.97203 13.2471C7.76864 13.5497 9.37407 13.982 10.7427 14.5267C10.973 14.6174 11.1902 14.7147 11.4031 14.8098C11.1902 14.9071 10.973 15.0022 10.7427
        15.0929C9.37407 15.6398 7.77081 16.0699 5.97203 16.3725C4.5556 16.6124 3.06965 16.7615 1.54026 16.822V12.7976C3.06965 12.8581 4.55343 13.0072 5.97203 13.2471ZM13.6842 16.245C14.199
        16.7313 14.4619 17.1938 14.4619 17.6196C14.4619 18.0454 14.2012 18.5079 13.6842 18.9942C13.0303 19.6124 12.0114 20.2068 10.7405 20.7125C9.37189 21.2594 7.76864 21.6895 5.96986
        21.9921C4.55343 22.232 3.06748 22.3811 1.53809 22.4416V18.3545C6.26748 18.1751 10.3929 17.1722 13.0151 15.7111C13.2671 15.8862 13.493 16.0656 13.6842 16.245ZM13.6842 13.3768C13.493
        13.5584 13.2671 13.7356 13.0151 13.9107C11.6008 13.1218 9.7499 12.4669 7.60136 12.0022C9.7499 11.5375 11.6008 10.8826 13.0151 10.0937C13.2649 10.2687 13.4908 10.446 13.6842
        10.6275C14.199 11.1138 14.4619 11.5764 14.4619 12.0022C14.4619 12.428 14.199 12.8883 13.6842 13.3768Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default BrandMarkIcon;
