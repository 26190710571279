import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../hooks';
import {
  resetState as resetStateReducer,
  setApiKey as setApiKeyReducer,
  setFiles as setFilesReducer,
  setName as setNameReducer,
  setSelectedDeploymentVariant as setSelectedDeploymentVariantReducer,
  setSelectedFramework as setSelectedFrameworkReducer,
  setTrussVariable as setTrussVariableReducer,
} from './index';
import { modelDeploymentSelector } from './selectors';
import { DeploymentVariants, Frameworks, UseModelDeployment } from './types';

function useModelDeployment(): UseModelDeployment {
  const dispatch = useAppDispatch();
  const modelDeploymentState = useSelector(modelDeploymentSelector);

  const setSelectedFramework = useCallback(
    (modelType: Frameworks) => {
      dispatch(setSelectedFrameworkReducer(modelType));
    },
    [dispatch],
  );

  const setSelectedDeploymentVariant = useCallback(
    (deploymentVariant: DeploymentVariants) => {
      dispatch(setSelectedDeploymentVariantReducer(deploymentVariant));
    },
    [dispatch],
  );

  const setApiKey = useCallback(
    (apiKey: string) => {
      dispatch(setApiKeyReducer(apiKey));
    },
    [dispatch],
  );

  const resetState = useCallback(() => {
    dispatch(resetStateReducer());
  }, [dispatch]);

  const setName = useCallback(
    (name: string) => {
      dispatch(setNameReducer(name));
    },
    [dispatch],
  );

  const setFiles = useCallback(
    (files: string) => {
      dispatch(setFilesReducer(files));
    },
    [dispatch],
  );

  const setTrussVariable = useCallback(
    (variable: string) => {
      dispatch(setTrussVariableReducer(variable));
    },
    [dispatch],
  );

  return {
    ...modelDeploymentState,
    setSelectedFramework,
    setSelectedDeploymentVariant,
    setApiKey,
    resetState,
    setName,
    setTrussVariable,
    setFiles,
  };
}

export { useModelDeployment };
