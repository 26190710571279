/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { PynodeRequirementsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePynodeRequirementMutationVariables = Types.Exact<{
  requirementsTxt: Types.Scalars['String'];
  systemRequirements: Types.Scalars['String'];
  workflowId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreatePynodeRequirementMutation = { __typename?: 'Mutation', createPynodeRequirement: { __typename?: 'CreatePyNodeRequirement', requirement: { __typename?: 'PyNodeRequirementType', id: string, status: Types.PyNodeRequirementStatus, modified: any, requirementsTxt: string | null | undefined, systemRequirements: string | null | undefined, errorMessage: string | null | undefined } | null | undefined } | null | undefined };


export const CreatePynodeRequirementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePynodeRequirement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requirementsTxt"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"systemRequirements"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"createPynodeRequirement"},"name":{"kind":"Name","value":"create_pynode_requirement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requirements_txt"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requirementsTxt"}}},{"kind":"Argument","name":{"kind":"Name","value":"system_requirements"},"value":{"kind":"Variable","name":{"kind":"Name","value":"systemRequirements"}}},{"kind":"Argument","name":{"kind":"Name","value":"workflow_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requirement"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PynodeRequirementsFragment"}}]}}]}}]}},...PynodeRequirementsFragmentDoc.definitions]} as unknown as DocumentNode<CreatePynodeRequirementMutation, CreatePynodeRequirementMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreatePynodeRequirement: 'CreatePynodeRequirement'
  }
}

export type CreatePynodeRequirementMutationFn = Apollo.MutationFunction<CreatePynodeRequirementMutation, CreatePynodeRequirementMutationVariables>;

/**
 * __useCreatePynodeRequirementMutation__
 *
 * To run a mutation, you first call `useCreatePynodeRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePynodeRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPynodeRequirementMutation, { data, loading, error }] = useCreatePynodeRequirementMutation({
 *   variables: {
 *      requirementsTxt: // value for 'requirementsTxt'
 *      systemRequirements: // value for 'systemRequirements'
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useCreatePynodeRequirementMutation(baseOptions?: Apollo.MutationHookOptions<CreatePynodeRequirementMutation, CreatePynodeRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePynodeRequirementMutation, CreatePynodeRequirementMutationVariables>(CreatePynodeRequirementDocument, options);
      }
export type CreatePynodeRequirementMutationHookResult = ReturnType<typeof useCreatePynodeRequirementMutation>;
export type CreatePynodeRequirementMutationResult = Apollo.MutationResult<CreatePynodeRequirementMutation>;
export type CreatePynodeRequirementMutationOptions = Apollo.BaseMutationOptions<CreatePynodeRequirementMutation, CreatePynodeRequirementMutationVariables>;