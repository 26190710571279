import { ReactNode, createContext } from 'react';

import { getSegmentInstance } from './singleton';
import { AnalyticsTracker } from './types';

const AnalyticsContext = createContext<AnalyticsTracker>(undefined);

const tracker: AnalyticsTracker = {
  track(...args) {
    getSegmentInstance()?.track(...args);
  },
  identify(...args) {
    getSegmentInstance()?.identify(...args);
  },
  page(...args) {
    getSegmentInstance()?.page(...args);
  },
};

function AnalyticsProvider({ children }: { children: ReactNode }) {
  return <AnalyticsContext.Provider value={tracker}>{children}</AnalyticsContext.Provider>;
}

export { AnalyticsContext, AnalyticsProvider };
