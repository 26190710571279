import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.333"
          d="M8 14.667A6.667 6.667 0 1 0 8 1.334a6.667 6.667 0 0 0 0 13.333Z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.333"
          d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.334h.007"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default HelpIcon;
