import { useEffect } from 'react';

import { useUserLoading } from '@/hooks/useUser';

import { capturePosthogEvent, identifyPosthogUser } from '.';

function usePosthogEvent(eventName: string) {
  useEffect(() => {
    capturePosthogEvent(eventName);
  }, [eventName]);
}

function usePosthogIdentify() {
  const { user } = useUserLoading();

  useEffect(() => {
    if (user?.id) {
      identifyPosthogUser(user.id, { name: user.name, email: user.email });
    }
  }, [user]);
}

export { usePosthogIdentify, usePosthogEvent };
