import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function BookOpenIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M1.25 1.875H5C5.66304 1.875 6.29893 2.13839 6.76777 2.60723C7.23661 3.07607 7.5 3.71196 7.5 4.375V13.125C7.5 12.6277 7.30246 12.1508 6.95083
        11.7992C6.59919 11.4475 6.12228 11.25 5.625 11.25H1.25V1.875Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 1.875H10C9.33696 1.875 8.70107 2.13839 8.23223 2.60723C7.76339 3.07607 7.5 3.71196 7.5 4.375V13.125C7.5 12.6277 7.69754 12.1508 8.04917
        11.7992C8.40081 11.4475 8.87772 11.25 9.375 11.25H13.75V1.875Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default BookOpenIcon;
