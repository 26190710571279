import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AlertCircleIcon from '@/components/Icons/AlertCircleIcon';
import { useLoggedIn } from '@/hooks';
import { links } from '@/localization';
import { getBorder } from '@/theme/utils';
import reloadPage from '@/utils/device/reloadPage';
import { usePylon } from '@bolasim/react-use-pylon';

function Default() {
  const isLoggedIn = useLoggedIn();
  const { show } = usePylon();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      sx={{ background: (theme) => theme.palette.background.subtle }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={2.5}
        sx={{
          border: ({ palette }) => getBorder(palette.border.default),
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          boxShadow: (theme) => theme.shadows[1],
          background: (theme) => theme.palette.background.default,
          p: 5,
        }}
      >
        <AlertCircleIcon color="secondary" sx={{ width: 24, height: 24 }} />
        <Typography variant="h3">We hit a snag!</Typography>
        <Typography textAlign="center">
          Something went wrong while loading the page. <br /> Try reloading below or chat with
          support.
        </Typography>
        <Stack direction="row" gap={2}>
          <Button onClick={reloadPage}>Reload the page</Button>
          {isLoggedIn ? (
            <Button onClick={show}>Contact support</Button>
          ) : (
            <Button href={links.basetenSupport}>Contact support</Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export { Default };
