import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function BookIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.6665 12.9999C2.6665 12.5579 2.8421 12.134 3.15466 11.8214C3.46722 11.5088 3.89114 11.3333 4.33317 11.3333H13.3332"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33317 1.33325H13.3332V14.6666H4.33317C3.89114 14.6666 3.46722 14.491 3.15466 14.1784C2.8421 13.8659 2.6665 13.4419
        2.6665 12.9999V2.99992C2.6665 2.55789 2.8421 2.13397 3.15466 1.82141C3.46722 1.50885 3.89114 1.33325 4.33317 1.33325V1.33325Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default BookIcon;
