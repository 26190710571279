import each from 'lodash/each';
import map from 'lodash/map';
import without from 'lodash/without';

import produce, { Draft } from 'immer';

import ActionType from '../actions/ActionType';
import { WorkletTestCase, workletTestCaseFromResponse } from './state/WorkletTestCase';

interface WorkletTestCaseViewState {
  workletId: string;
  loading: boolean;
  error: string;
  selectedTestCases: Set<string>;
  runningTestCases: Array<string>;
  running: boolean;
}

export const INIT_STATE: WorkletTestCaseViewState = {
  workletId: null,
  loading: false,
  error: '',
  selectedTestCases: new Set<string>(),
  runningTestCases: [],
  running: false,
};

const workletTestCaseViewReducer = produce(
  (draft: Draft<WorkletTestCaseViewState>, action: any) => {
    switch (action.type) {
      case ActionType.WORKLET_TEST_CASES_FETCH_START: {
        draft.workletId = action.workletId;
        draft.loading = true;
        draft.error = '';
        break;
      }
      case ActionType.WORKLET_TEST_CASES_FETCH_DONE: {
        draft.loading = false;
        break;
      }
      case ActionType.WORKLET_TEST_CASES_FETCH_ERROR: {
        draft.loading = false;
        draft.error = action.error;
        break;
      }
      case ActionType.WORKLET_TEST_CASES_SELECT_TEST: {
        if (draft.selectedTestCases.has(action.testCaseId)) {
          draft.selectedTestCases.delete(action.testCaseId);
        } else {
          draft.selectedTestCases.add(action.testCaseId);
        }
        break;
      }
      case ActionType.WORKLET_TEST_CASES_SELECT_ALL_TEST: {
        if (action.workletTestCases.length !== draft.selectedTestCases.size) {
          const testCaseIds = map(action.workletTestCases, (testCase) => testCase.id);
          draft.selectedTestCases = new Set(testCaseIds);
        } else {
          draft.selectedTestCases = new Set();
        }
        break;
      }
      case ActionType.WORKLET_TEST_CASES_RUNNING_START: {
        draft.runningTestCases = action.testCaseIds;
        break;
      }
      case ActionType.SINGLE_WORKLET_TEST_CASE_FETCH_DONE: {
        const testCase: WorkletTestCase = workletTestCaseFromResponse(action.workletTestCase);
        draft.runningTestCases = without(draft.runningTestCases, testCase.id);
        break;
      }
      case ActionType.WORKLET_TEST_CASES_DELETE: {
        draft.runningTestCases = without(draft.runningTestCases, action.workletTestCaseIds);
        each(action.workletTestCaseIds, (testCaseId) => {
          draft.selectedTestCases.delete(testCaseId);
        });
        break;
      }
      default:
        break;
    }
  },
  INIT_STATE,
);

export default workletTestCaseViewReducer;
