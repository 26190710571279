import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function XIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M10.5 3.5L3.5 10.5"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 3.5L10.5 10.5"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default XIcon;
