import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLoggedIn } from '@/hooks';
import { links } from '@/localization';
import { BasetenPageEnum, usePageSwitcher } from '@/routes';
import { getBorder } from '@/theme/utils';
import { usePylon } from '@bolasim/react-use-pylon';

import { NotFoundProps } from './types';

function NotFound({ message = 'This page could not be found.' }: NotFoundProps) {
  const isLoggedIn = useLoggedIn();
  const goToPage = usePageSwitcher();
  const { show } = usePylon();

  function returnToModels() {
    goToPage(BasetenPageEnum.Home);
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      sx={{ background: (theme) => theme.palette.background.subtle }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={2.5}
        sx={{
          border: ({ palette }) => getBorder(palette.border.default),
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          boxShadow: (theme) => theme.shadows[1],
          background: (theme) => theme.palette.background.default,
          p: 5,
        }}
      >
        <Typography variant="h1" data-cy="404-page-message">
          404
        </Typography>
        <Typography>{message}</Typography>
        <Stack direction="row" gap={2}>
          <Button onClick={returnToModels}>Return to workspace</Button>
          {isLoggedIn ? (
            <Button onClick={show}>Contact support</Button>
          ) : (
            <Button href={links.basetenSupport}>Contact support</Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NotFound;
