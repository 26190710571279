import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

import BaseTextField from '@/components/TextField';
import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

import { CardProps } from './Card/types';

const Body = styled(Stack)(({ theme }) => ({
  background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.subtle} 100%)`,
  width: '100vw',
  height: '100vh',
}));

Body.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

const AuthCard = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'contained',
})<CardProps>(({ theme, contained }) => ({
  width: 320,
  [theme.breakpoints.up('sm')]: {
    width: 400,
  },
  ...(contained && {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      padding: theme.spacing(5),
      border: getBorder(theme.palette.border.default),
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
    },
  }),
}));

const SubmitButton = styled(LoadingButton)({});

SubmitButton.defaultProps = {
  variant: 'contained',
  type: 'submit',
  size: 'medium',
  color: 'secondary',
  fullWidth: true,
};

const Form = styled('form')({});

const TextField = styled(BaseTextField)({});
TextField.defaultProps = {
  labelProps: {
    sx: { backgroundColor: 'transparent' },
  },
};

export { Body, AuthCard, Form, SubmitButton, TextField };
