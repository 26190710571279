import { atom } from 'recoil';

import { InstructionStep } from './types';

const highlightState = atom<InstructionStep>({
  key: 'highlight-state',
  default: null,
});

export { highlightState };
