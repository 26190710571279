/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PipeFragment = { __typename?: 'PipeType', id: string, workletId: string | null | undefined, dataSourceId: string | null | undefined };

export const PipeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PipeFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PipeType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"workletId"},"name":{"kind":"Name","value":"worklet_id"}},{"kind":"Field","alias":{"kind":"Name","value":"dataSourceId"},"name":{"kind":"Name","value":"data_source_id"}}]}}]} as unknown as DocumentNode<PipeFragment, unknown>;
export const namedOperations = {
  Fragment: {
    PipeFragment: 'PipeFragment'
  }
}
