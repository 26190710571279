import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function CopyIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33334 10.0002H2.66667C2.31305 10.0002 1.97391 9.85969 1.72386 9.60964C1.47381 9.35959 1.33334 9.02045 1.33334 8.66683V2.66683C1.33334 2.31321 1.47381 1.97407 1.72386 1.72402C1.97391 1.47397 2.31305 1.3335
        2.66667 1.3335H8.66667C9.02029 1.3335 9.35943 1.47397 9.60948 1.72402C9.85953 1.97407 10 2.31321 10 2.66683V3.3335"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default CopyIcon;
