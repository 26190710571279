import { loadingBarReducer } from 'react-redux-loading-bar';

import { AnyAction, Reducer, combineReducers } from 'redux';

import has from 'lodash/has';
import identity from 'lodash/identity';
import partialRight from 'lodash/partialRight';

import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { enableMapSet } from 'immer';

import classesReducer from '../slices/entities/Classes';
import codebasesReducer from '../slices/entities/Codebases';
import dataSourcesReducer from '../slices/entities/DataSources';
import onboardingChecklistReducer from '../slices/entities/OnboardingChecklist';
// entities slices
import queriesReducer from '../slices/entities/Queries';
import toolbarReducer from '../slices/entities/Toolbar';
import viewsReducer from '../slices/entities/Views';
import workflowMetadataReducer from '../slices/entities/WorkflowMetadata';
import workflowsReducer from '../slices/entities/Workflows';
import workletsReducer from '../slices/entities/Worklets';
import appViewReducer from '../slices/ui/AppView';
import modalsReducer from '../slices/ui/Modals';
import modelDeploymentReducer from '../slices/ui/ModelDeployment';
import notifierReducer from '../slices/ui/Notifier';
import pyEnvViewReducer from '../slices/ui/PyEnvView';
import queryDialogReducer from '../slices/ui/QueryDialog';
import shareViewReducer from '../slices/ui/ShareView';
// ui slices
import workflowViewReducer from '../slices/ui/WorkflowView';
import applicationsViewReducer from './ApplicationsViewReducer';
import classViewReducer from './ClassViewReducer';
import consoleViewReducer from './ConsoleViewReducer';
import dataSourcesViewReducer from './DataSourcesViewReducer';
import externalConnectionsReducer from './ExternalConnectionsReducer';
import { nodeReducer, nodesReducer } from './NodeReducer';
import publishFlowReducer from './PublishFlowReducer';
import recentBasetenPagesReducer from './RecentBasetenPagesReducer';
import workletReducer from './WorkletReducer';
import workletTestCaseReducer from './WorkletTestCaseReducer';
import workletTestCaseViewReducer from './WorkletTestCaseViewReducer';
import workletViewReducer from './WorkletViewReducer';

type Rec<T> = Record<string, T>;

/**
 * No-op reducer initialized to empty dictionary.
 */
// eslint-disable-next-line
const objectIdentityReducer = <T>(state: Rec<T> = {}, action: any) => state;

// eslint-disable-next-line
const identityReducer = <S>(state: S, action: any) => state;

const defaultEntityIdExtractor: (action: AnyAction) => string = (action: AnyAction) => action.id;

enableMapSet();

/**
 * Reducer for a collection of entities keyed by id.
 * Calls the reducer for the entire collection first and then calls the
 * reducer for specific entity if applicable.
 * This allows for separating logic for handing events needed access to all
 * entities, e.g. create and delete operations, and those for
 * updating an individual entity.
 * @param entityReducer Reducer to use individual entity updates.
 * @param collectionReducer Reducer to use for updating the entire collection.
 * @param actionEntityIdPropertyName This property if set in action serves as id for entity lookup.
 * @param entityIdExtractor Function that returns entity id, should return null if not found.
 * @returns Reducer
 */
function byIdCollectionReducer<T>(
  entityReducer: Reducer<T, AnyAction> = identityReducer,
  collectionReducer: Reducer<Rec<T>, AnyAction> = objectIdentityReducer,
  entityIdExtractor = defaultEntityIdExtractor,
): Reducer<Rec<T>> {
  return (state: Rec<T>, action: AnyAction) => {
    const newState = collectionReducer(state, action);
    const id = entityIdExtractor(action);
    if (!id) {
      return newState;
    }

    return {
      ...newState,
      [id]: entityReducer(state[id], action),
    };
  };
}

function extractEntityIdFromActionMeta(action: any, entityIdName: string): string {
  if (!has(action, 'meta')) {
    return null;
  }
  if (!has(action.meta, entityIdName)) {
    return null;
  }
  return action.meta[entityIdName];
}

const uiReducer = combineReducers({
  applicationsView: applicationsViewReducer,
  workflowView: workflowViewReducer,
  queryDialog: queryDialogReducer,
  notifier: notifierReducer,
  appView: appViewReducer,
  consoleView: consoleViewReducer,
  modals: modalsReducer,
  workletView: workletViewReducer,
  classView: classViewReducer,
  dataSourcesView: dataSourcesViewReducer,
  publishFlow: publishFlowReducer,
  workletTestCaseView: workletTestCaseViewReducer,
  shareView: shareViewReducer,
  pyEnvView: pyEnvViewReducer,
  modelDeployment: modelDeploymentReducer,
});

const nodesByIdReducer = byIdCollectionReducer(
  byIdCollectionReducer(
    nodeReducer,
    identity,
    partialRight(extractEntityIdFromActionMeta, 'nodeId'),
  ),
  nodesReducer,
  partialRight(extractEntityIdFromActionMeta, 'releaseEnv'),
);

const workletsByIdReducer = byIdCollectionReducer(
  byIdCollectionReducer(
    workletReducer,
    identity,
    partialRight(extractEntityIdFromActionMeta, 'workletId'),
  ),
  workletsReducer,
  partialRight(extractEntityIdFromActionMeta, 'releaseEnv'),
);

const entitiesCombineReducer = combineReducers({
  nodes: nodesByIdReducer,
  worklets: workletsByIdReducer,
  workflows: workflowsReducer,
  queries: queriesReducer,
  views: viewsReducer,
  codebases: codebasesReducer,
  toolbar: toolbarReducer,
  externalConnections: externalConnectionsReducer,
  classes: classesReducer,
  dataSources: dataSourcesReducer,
  workletTestCases: workletTestCaseReducer,
  onboardingChecklist: onboardingChecklistReducer,
  workflowMetadata: workflowMetadataReducer,
});

const createRootReducer = (history: History) => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    entities: entitiesCombineReducer,
    ui: uiReducer,
    loadingBar: loadingBarReducer,
    recent: recentBasetenPagesReducer,
  });

  return rootReducer;
};

export default createRootReducer;
