import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';

function isJson(str: string): boolean {
  let result;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return false;
  }

  return isPlainObject(result);
}

function looksLikeUnquotedString(str: string) {
  return (
    Number.isNaN(Number(str)) &&
    !str.includes('[') &&
    !str.includes('{') &&
    str.trim().charAt(0) !== '"'
  );
}

/**
 * Parse a value that is expected to be json. Attempts to fix common user
 * mistakes while specifying json, e.g. strings without double quotes.
 */
function parseExpectingJson(argValue: any, attemptFix = true): any {
  if (typeof argValue === 'object') {
    return argValue;
  }

  try {
    return JSON.parse(argValue);
  } catch (err) {
    if (attemptFix) {
      if (isEmpty(argValue)) {
        return '';
      }
      if (typeof argValue === 'string' && looksLikeUnquotedString(argValue)) {
        return parseExpectingJson(`"${argValue}"`, false);
      }
    }
    throw SyntaxError(`Invalid input: '${argValue}'.\nInput should be valid json.\n${err.message}`);
  }
}

/**
 * An util for safe stringify circular objects
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#examples
 */
function getCircularReplacer(): (key: string, val: unknown) => unknown {
  const seen = new WeakSet();
  return (key: string, value: unknown) => {
    if (isObject(value)) {
      if (seen.has(value)) {
        return undefined;
      }
      seen.add(value);
    }
    return value;
  };
}

function safeStringify(value: unknown): string {
  if (isFunction(value)) {
    return value.toString();
  }

  if (isObject(value)) {
    return JSON.stringify(value, getCircularReplacer());
  }

  return String(value) ?? '';
}

export { isJson, parseExpectingJson, getCircularReplacer, safeStringify };
