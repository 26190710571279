import { useContext, useEffect } from 'react';

import { useLocation } from 'react-router';

import useUser from '@/hooks/useUser';

import { AnalyticsContext } from './AnalyticsContext';

function useAnalytics() {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
}

function useSegmentPageViews() {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.page(undefined, location.pathname);
  }, [analytics, location.pathname]);
}

function useSegmentIdentify() {
  const analytics = useAnalytics();
  const user = useUser();

  useEffect(() => {
    analytics.identify(user.id, {
      name: user.name,
      username: user.username,
      email: user.email,
    });
  }, [analytics, user]);
}

export { useAnalytics, useSegmentPageViews, useSegmentIdentify };
