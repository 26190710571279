import { ChangeEvent, FormEvent, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ModalBox, ModalForm } from './styles';
import { RenameModalProps } from './types';

function RenameResource({ originalName, resourceType, onDone, container }: RenameModalProps) {
  const [resourceName, setResourceName] = useState(originalName);
  const renameModalId = `rename-modal-${resourceType}`;
  const nameChanged = resourceName !== originalName;

  function handleClose(save: boolean) {
    if (save && nameChanged) {
      onDone({ newName: resourceName });
    } else {
      onDone(null);
    }
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    handleClose(true);
  }

  const error = useMemo(() => {
    if (resourceName === originalName) {
      return 'The new name must be different from the old name';
    }
    return null;
  }, [resourceName, originalName]);

  return (
    <Modal
      open
      container={container}
      onClose={() => handleClose(false)}
      aria-labelledby={`${renameModalId}-title`}
    >
      <ModalBox sx={{ boxShadow: 2 }}>
        <Typography id={`${renameModalId}-title`} variant="h6" component="h2" sx={{ mb: 2 }}>
          Rename {resourceType}
        </Typography>
        <ModalForm onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            autoFocus
            fullWidth
            value={resourceName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setResourceName(e.target.value)}
            placeholder={error}
            error={Boolean(error)}
            data-cy={`${resourceType}-name-input`}
            sx={{ mb: 2 }}
          />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => handleClose(false)}
              data-cy={`${renameModalId}-cancel`}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!nameChanged}
              data-cy={`${renameModalId}-ok`}
            >
              Rename
            </Button>
          </Stack>
        </ModalForm>
      </ModalBox>
    </Modal>
  );
}

export default RenameResource;
