import { useHighlightState } from '../../hooks';
import { Container } from './styled';
import { TerminalLineProps } from './types';

function TerminalLine({
  children,
  instruction,
  isOutput = false,
  multiline = false,
}: TerminalLineProps) {
  const { highlighted, highlight, unhighlight } = useHighlightState();

  return (
    <Container
      isHighlighted={highlighted === instruction}
      isOutput={isOutput}
      onMouseOver={() => highlight(instruction)}
      onMouseLeave={unhighlight}
      multiline={multiline}
    >
      {children}
    </Container>
  );
}

export default TerminalLine;
