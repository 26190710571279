import { LinearProgress, Stack, Typography, linearProgressClasses } from '@mui/material';

import { BasetenTheme } from '@/theme';
import { formatPrice } from '@/utils/currency';

import { getBorderColor } from '../../styled';
import { GaugeProps } from './types';
import { MINIMUM_PROGRESS_VALUE } from './utils';

export const gaugeClasses = {
  label: 'gaugeLabel',
};

function Gauge({ max, value, hasCreditCard }: GaugeProps) {
  const progress = Math.max(((max - value) / max) * 100, MINIMUM_PROGRESS_VALUE);
  const remainingAmount = max - value;

  return (
    <Stack gap={0.5}>
      <LinearProgress
        value={progress}
        variant="determinate"
        sx={(theme) => ({
          height: 8,
          borderRadius: 15,
          backgroundColor: theme.palette.background.muted,
          [`.${linearProgressClasses.bar}`]: {
            borderRadius: 15,
            backgroundColor: getBorderColor(
              theme as unknown as BasetenTheme,
              progress,
              hasCreditCard,
            ),
          },
        })}
      />
      <Stack direction="row">
        <Typography variant="body2" color="text.subtle" className={gaugeClasses.label}>
          {formatPrice(remainingAmount)} of {formatPrice(max)} used
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Gauge;
