import { useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { EventTypes } from '@/constants/types';
import { ApprovalStatus } from '@/graphql';
import { useAsOperator } from '@/hooks';
import { useUserLoading } from '@/hooks/useUser';
import { WorkspaceSections } from '@/pages/Workspace/types';
import { BasetenPageEnum, usePageSwitcher } from '@/routes';
import { useEventLogger } from '@/store/actions/hooks';
import { PosthogEvents, capturePosthogEvent } from '@/utils/analytics/posthog';
import { useSnackbar } from 'notistack';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { onboardingStep, profileDataState } from './atoms';
import { OnboardingStep } from './types';
import { completeSignup } from './utils';

function useCurrentStep() {
  const { user } = useUserLoading();
  const step = useRecoilValue(onboardingStep);

  return (
    step ||
    (user?.status === ApprovalStatus.PendingOrgApproval
      ? OnboardingStep.RequestedToJoin
      : OnboardingStep.Profile)
  );
}

function useCompleteOnboarding() {
  const logEvent = useEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useUserLoading();

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const profileData = await snapshot.getPromise(profileDataState);

        try {
          await completeSignup(profileData);
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
          return;
        }

        logEvent(EventTypes.FINISHED_ONBOARDING_FLOW);
        capturePosthogEvent(PosthogEvents.ONBOARDING_FINISHED);
        refetch();
      },
    [logEvent, refetch, enqueueSnackbar],
  );
}

function useProfileData() {
  return useRecoilState(profileDataState);
}

function useNextQueryParam() {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  return query.get('next');
}

function useExitOnboarding() {
  const goToPage = usePageSwitcher();
  const isOperator = useAsOperator();
  const history = useHistory();
  const nextPage = useNextQueryParam();

  return useCallback(() => {
    if (nextPage) {
      history.replace(nextPage);
    } else {
      goToPage(BasetenPageEnum.Workspace, {
        section: isOperator ? WorkspaceSections.Applications : WorkspaceSections.Models,
      });
    }
  }, [goToPage, isOperator, history, nextPage]);
}

export { useProfileData, useCompleteOnboarding, useCurrentStep, useExitOnboarding };
