import { createSlice } from '@reduxjs/toolkit';

import { WorkflowViewState } from './types';

const initialState = {
  workflowId: null,
  workletId: null,
  loading: false,
  fetchError: '',
  hydrated: false,
  workletRuns: [],
  workletRunsLoading: false,
  totalRunsCount: null,
} as WorkflowViewState;

const WorkflowView = createSlice({
  name: 'workflow-view',
  initialState,
  reducers: {
    setWorkflowId(state, action) {
      state.workflowId = action.payload;
    },
    setWorkflowRunLoading(state) {
      state.workletRunsLoading = true;
    },
    setWorkflowRunsCount(state, action) {
      state.totalRunsCount = action.payload;
    },
    updateWorkflowWorkletRuns(state, action) {
      state.workletRuns = action.payload;
      state.workletRunsLoading = false;
    },
    clearWorkflow() {
      return initialState;
    },
    workflowFetchStart(state, action) {
      state.workflowId = action.payload;
      state.loading = true;
      state.hydrated = false;
      state.fetchError = '';
    },
    workflowFetchDone(state) {
      state.hydrated = true;
      state.loading = false;
    },
    workflowFetchError(state, action) {
      state.loading = false;
      state.fetchError = action.payload;
    },
  },
});

export const {
  setWorkflowId,
  setWorkflowRunLoading,
  setWorkflowRunsCount,
  updateWorkflowWorkletRuns,
  clearWorkflow,
  workflowFetchStart,
  workflowFetchDone,
  workflowFetchError,
} = WorkflowView.actions;

export default WorkflowView.reducer;
