import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import kebabCase from 'lodash/kebabCase';

import DialogTitleWithX from '@/components/DialogTitleWithX';

import { ModalBox, ModalFooter } from './styles';
import { ConfirmationModalProps } from './types';

function Confirmation({
  container,
  title,
  onDone,
  prompt,
  modalId = kebabCase(title),
  okText = 'Confirm',
  okColor = 'error',
  cancelText = 'Cancel',
  autoFocus = true,
  width = 500,
}: ConfirmationModalProps) {
  function handleClose(confirmed: boolean) {
    onDone({ confirmed });
  }

  return (
    <Modal
      open
      container={container}
      onClose={() => handleClose(false)}
      aria-labelledby={`${modalId}-title`}
      aria-describedby={`${modalId}-description`}
    >
      <ModalBox sx={{ width }}>
        <Stack gap={2} sx={{ p: 3 }}>
          <DialogTitleWithX
            onClose={() => handleClose(false)}
            sx={{ p: (theme) => theme.spacing(0, 0, 0, 0) }}
          >
            {title}
          </DialogTitleWithX>
          <Typography
            component="div"
            whiteSpace="pre-line"
            id={`${modalId}-description`}
            variant="body1"
          >
            {prompt}
          </Typography>
        </Stack>
        <ModalFooter gap={1} direction="row" justifyContent="flex-end">
          {cancelText && (
            <Button
              variant="outlined"
              onClick={() => handleClose(false)}
              data-cy={`confirmation-${modalId}-cancel`}
            >
              {cancelText}
            </Button>
          )}
          <Button
            variant="contained"
            color={okColor}
            autoFocus={autoFocus}
            onClick={() => handleClose(true)}
            data-cy={`confirmation-${modalId}-ok`}
          >
            {okText}
          </Button>
        </ModalFooter>
      </ModalBox>
    </Modal>
  );
}

export default Confirmation;
