import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import first from 'lodash/first';

import { PyNodeRequirementStatus } from '@/graphql';
import { ReleaseEnv } from '@/types/releaseEnv';

import { updateWorkflow } from '../../entities/Workflows';
import { fetchPyEnvRequirements, installPyEnvRequirements } from './thunks';
import { PyEnvViewMap, UpdateDeploymentStatusRefreshTimerIdPayload } from './types';
import { ensurePyEnv, pyEnvRequirementsFromBackend } from './utils';

export const initialMap: PyEnvViewMap = {
  [ReleaseEnv.Draft]: {},
  [ReleaseEnv.Production]: {},
};

const PyEnvView = createSlice({
  name: 'py-env-view',
  initialState: initialMap,
  reducers: {
    updateDeploymentStatusRefreshTimerId(
      state,
      {
        payload: { timerId, workflowIdentifier },
      }: PayloadAction<UpdateDeploymentStatusRefreshTimerIdPayload>,
    ) {
      const pyEnv = ensurePyEnv(state, workflowIdentifier);

      pyEnv.deploymentStatusRefreshTimerId = timerId;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPyEnvRequirements.pending, (state, { meta: { arg } }) => {
        const pyEnv = ensurePyEnv(state, arg);

        pyEnv.loading = true;
        pyEnv.fetchError = '';
      })
      .addCase(
        fetchPyEnvRequirements.fulfilled,
        (state, { payload: latestPyEnvRequirements, meta: { arg } }) => {
          const pyEnv = ensurePyEnv(state, arg);

          if (latestPyEnvRequirements) {
            pyEnv.latestPyEnvRequirements = pyEnvRequirementsFromBackend(latestPyEnvRequirements);
          }
          pyEnv.loading = false;

          if (
            pyEnv.latestPyEnvRequirements.status !== PyNodeRequirementStatus.Processing &&
            pyEnv.deploymentStatusRefreshTimerId
          ) {
            clearInterval(pyEnv.deploymentStatusRefreshTimerId);
            pyEnv.deploymentStatusRefreshTimerId = null;
          }
        },
      )
      .addCase(
        fetchPyEnvRequirements.rejected,
        (state, { error, meta: { arg: workflowIdentifier } }) => {
          const pyEnv = ensurePyEnv(state, workflowIdentifier);

          pyEnv.loading = false;
          pyEnv.fetchError = error.message;
          pyEnv.latestPyEnvRequirements.status = PyNodeRequirementStatus.Failed;
        },
      )
      .addCase(
        installPyEnvRequirements.pending,
        (
          state,
          {
            meta: {
              arg: { workflowIdentifier },
            },
          },
        ) => {
          const pyEnv = ensurePyEnv(state, workflowIdentifier);

          pyEnv.fetchError = '';
          pyEnv.latestPyEnvRequirements.status = PyNodeRequirementStatus.Processing;
          pyEnv.latestPyEnvRequirements.errorMessage = undefined;
        },
      )
      .addCase(
        installPyEnvRequirements.fulfilled,
        (
          state,
          {
            payload: {
              createPynodeRequirement: { requirement },
            },
            meta: {
              arg: { workflowIdentifier },
            },
          },
        ) => {
          const pyEnv = ensurePyEnv(state, workflowIdentifier);

          pyEnv.latestPyEnvRequirements = pyEnvRequirementsFromBackend(requirement);
        },
      )
      .addCase(
        installPyEnvRequirements.rejected,
        (
          state,
          {
            error,
            meta: {
              arg: { workflowIdentifier },
            },
          },
        ) => {
          const pyEnv = ensurePyEnv(state, workflowIdentifier);

          pyEnv.fetchError = error.message;
        },
      )
      .addCase(
        updateWorkflow,
        (
          state,
          {
            payload: {
              entities: { latestPyEnvRequirements },
              workflowIdentifier,
            },
          },
        ) => {
          const latestPyEnvRequirement = latestPyEnvRequirements
            ? first(Object.values(latestPyEnvRequirements))
            : undefined;

          const pyEnv = ensurePyEnv(state, workflowIdentifier);
          if (latestPyEnvRequirement) {
            pyEnv.latestPyEnvRequirements = pyEnvRequirementsFromBackend(latestPyEnvRequirement);
            pyEnv.loading = false;
          }
        },
      );
  },
});

export const { updateDeploymentStatusRefreshTimerId } = PyEnvView.actions;

export { fetchPyEnvRequirements, installPyEnvRequirements };

export default PyEnvView.reducer;
