import { useCheckFeatureFlag } from './useFeatureFlags';
import { FeatureFlags } from './useFeatureFlags/types';
import { useOrgLoading } from './useOrg';

export function useIsDualEnvEnabled(): boolean {
  const checkFeatureFlag = useCheckFeatureFlag();
  const { org } = useOrgLoading();

  return checkFeatureFlag(FeatureFlags.DUAL_ENVIRONMENTS_FLAG) && org?.dualEnvEnabled;
}
