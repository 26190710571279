import { TypedStartListening, createListenerMiddleware } from '@reduxjs/toolkit';

import apolloClient from '@/apollo';

import { AppDispatch, RootState, ThunkExtras } from '../types';

const listenerMiddleware = createListenerMiddleware({
  extra: { apolloClient } as ThunkExtras,
});

const startAppListening = listenerMiddleware.startListening as TypedStartListening<
  RootState,
  AppDispatch,
  ThunkExtras
>;

export { startAppListening };

export default listenerMiddleware;
