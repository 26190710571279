import {
  InferenceHints,
  Resolution,
  ResolutionTask,
  ValueSupplier,
} from '@/pages/Application/View/types';
import { VariantType } from 'notistack';

import { AppDispatch, RootState, ThunkExtras } from '../../../../types';
import { SetStatePayload, ViewIdentifier } from '../types';

/**
 * API provided to action runners
 */
type ActionAPI = {
  viewIdentifier: ViewIdentifier;
  resolveParamRef(
    paramRef: string,
    evaluatedTasks?: ResolutionTask[],
  ): Promise<Record<string, any>>;
  resolveValue(value: ValueSupplier, hints?: InferenceHints): any;
  dispatch: AppDispatch;
  asOperator: boolean;
  extra: ThunkExtras;
  getState(): RootState;
};

enum ActionType {
  SetState = 'set_state',
  TriggerQuery = 'trigger_query',
  ControlComponent = 'control_component',
  RunWorklet = 'run_worklet',
  GoToView = 'go_to_view',
  GoToUrl = 'go_to_url',
  ShowNotification = 'show_notification',
  CopyToClipboard = 'copy_to_clipboard',
  // Temporary action until we have a better story around re-running data sources
  RefreshDataSources = 'refresh_data_sources',
}

type EmptyAction = {
  type: undefined;
};

type SetStateAction = {
  type: ActionType.SetState;
  saveState: string;
  value: ValueSupplier;
};

type TriggerQueryAction = {
  type: ActionType.TriggerQuery;
  queryId: string;
  paramRef: string;
  saveState: string;
};

type RunWorkletAction = {
  type: ActionType.RunWorklet;
  workletId: string;
  paramRef: string;
  saveState: string;
};

type ControlComponentAction = {
  type: ActionType.ControlComponent;
  componentId: string;
  field: string;
  value: ValueSupplier;
};

type GoToViewAction = {
  type: ActionType.GoToView;
  viewId: string;
  paramRef: string;
};

type GoToUrlAction = {
  type: ActionType.GoToUrl;
  url: ValueSupplier;
};

type ShowNotificationAction = {
  type: ActionType.ShowNotification;
  message: ValueSupplier;
  variant: VariantType;
};

type CopyToClipboard = {
  type: ActionType.CopyToClipboard;
  value: ValueSupplier;
};

type RefreshDataSources = {
  type: ActionType.RefreshDataSources;
};

type Action =
  | EmptyAction
  | SetStateAction
  | TriggerQueryAction
  | ControlComponentAction
  | RunWorkletAction
  | GoToViewAction
  | GoToUrlAction
  | ShowNotificationAction
  | CopyToClipboard
  | RefreshDataSources;

type SetStateOutput = SetStatePayload;

type TriggerQueryOutput = SetStatePayload & {
  params: Record<string, string>;
};

type ControlComponentOutput = {
  id: string;
  componentId: string;
  viewId: string;
  workflowId: string;
  data: Resolution;
};

type RunWorkletOutput = {
  name: string;
  viewId: string;
  workflowId: string;
  value?: Record<string, string>;
  error?: string;
};

type GoToViewOutput = {
  params: Record<string, string>;
};

type ShowNotificationOutput = {
  message: string;
  variant: string;
};

type CopyToClipboardOutput = {
  value: string;
};

type RefreshDataSourcesOutput = {
  viewId: string;
  workflowId: string;
};

type RunActionOutput =
  | SetStateOutput
  | TriggerQueryOutput
  | ControlComponentOutput
  | RunWorkletOutput
  | GoToViewOutput
  | ShowNotificationOutput
  | CopyToClipboardOutput
  | RefreshDataSourcesOutput
  | void; // Go to url case

export {
  ActionAPI,
  ActionType,
  EmptyAction,
  SetStateAction,
  TriggerQueryAction,
  RunWorkletAction,
  ControlComponentAction,
  GoToViewAction,
  GoToUrlAction,
  ShowNotificationAction,
  CopyToClipboard,
  RefreshDataSources,
  Action,
  RunActionOutput,
  SetStateOutput,
  TriggerQueryOutput,
  ControlComponentOutput,
  RunWorkletOutput,
  GoToViewOutput,
  ShowNotificationOutput,
  CopyToClipboardOutput,
  RefreshDataSourcesOutput,
};
