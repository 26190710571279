import { useRecoilState } from 'recoil';

import { highlightState } from './atoms';
import { InstructionStep } from './types';

function useHighlightState() {
  const [highlighted, setHighlighted] = useRecoilState(highlightState);

  function highlight(instruction: InstructionStep) {
    setHighlighted(instruction);
  }

  function unhighlight() {
    setHighlighted(null);
  }

  return {
    highlighted,
    highlight,
    unhighlight,
  };
}

export { useHighlightState };
