/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ModelNameFragment = { __typename?: 'ModelType', id: string, name: string | null | undefined };

export const ModelNameFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ModelNameFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ModelType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<ModelNameFragment, unknown>;
export const namedOperations = {
  Fragment: {
    ModelNameFragment: 'ModelNameFragment'
  }
}
