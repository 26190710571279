import { AnalyticsBrowser } from '@segment/analytics-next';

const ENABLEMENT_KEY = 'segment-analytics-enabled';

/**
 * Returns whether we're connected to Segment analytics. This should be called every time a trackable
 * event occurs to check the current enablement status since Segment may be enabled after page load.
 */
export function isSegmentEnabled(): boolean {
  if (!ENV_CONFIG.SEGMENT_WRITE_KEY) return false;

  const stringValue = localStorage.getItem(ENABLEMENT_KEY);
  return !!stringValue;
}

/**
 * Function that turns on Segment tracking. Segment tracking is turned off by default on first run,
 * but once it is enabled by a call to this function, it remains enabled for the lifetime of the page,
 * session, and browser.
 */
export function enableSegment(): void {
  if (!ENV_CONFIG.SEGMENT_WRITE_KEY) return;

  localStorage.setItem(ENABLEMENT_KEY, '1');
}

let instance: AnalyticsBrowser | undefined;

export function getSegmentInstance(): AnalyticsBrowser | undefined {
  if (instance === undefined && isSegmentEnabled()) {
    // Initialize
    instance = AnalyticsBrowser.load({ writeKey: ENV_CONFIG.SEGMENT_WRITE_KEY });
  }

  return instance;
}
