import Stack from '@mui/material/Stack';

import { styled as muiStyled } from '@/theme/styled';
import styled from 'styled-components';

import { FlexBox } from '..';
import { BodyProps } from './types';

const Container = styled(FlexBox)`
  flex-direction: column;
  height: 100%;
`;

const Action = styled.div``;

const Body = styled.div<BodyProps>`
  padding: ${({ padding = 24 }) => `${padding}px`};
  overflow: auto;
  height: 100%;
`;

const PageContent = muiStyled(Stack)(({ theme }) => ({
  background: theme.palette.background.subtle,
  flex: 1,
  overflow: 'auto',
}));

export { Container, Action, Body, PageContent };
