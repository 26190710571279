import {
  ConfirmationModalProps,
  CreateModalProps,
  DeleteModalProps,
  RenameModalProps,
  SafeguardConfirmModalProps,
} from '@/sections/Modal/types';

interface ModalsState {
  modalToShow?: ModalType;
  modalProps?:
    | ConfirmationModalProps
    | SafeguardConfirmModalProps
    | CreateModalProps
    | DeleteModalProps
    | RenameModalProps;
}

enum ModalType {
  CONFIRMATION = 'CONFIRMATION',
  SAFEGUARD_CONFIRM = 'SAFEGUARD_CONFIRM',
  CREATE_RESOURCE = 'CREATE_RESOURCE',
  RENAME_RESOURCE = 'RENAME_RESOURCE',
  DELETE_RESOURCE = 'DELETE_RESOURCE',
}

enum ResourceType {
  APPLICATION = 'application',
  WORKLET = 'worklet',
  VIEW = 'view',
  FILE = 'file',
  QUERY = 'query',
  MODEL = 'model',
  ACTION = 'action',
  FIELD = 'field',
  NODE = 'node',
  BLOCK = 'block',
}

interface ModalProps<Output> {
  onDone: (resp: Output) => void;
  container?: HTMLElement;
}

export { ModalsState, ModalType, ResourceType, ModalProps };
