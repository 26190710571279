import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';

import { modalsSelector } from '@/store/Selectors';
import { hideModal } from '@/store/slices/ui/Modals';
import { ModalType } from '@/store/slices/ui/Modals/types';

import Confirmation from './Confirmation';
import CreateResource from './CreateResource';
import RenameResource from './RenameResource';
import SafeguardConfirm from './SafeguardConfirm';
import {
  ConfirmationModalProps,
  ConfirmationOutput,
  CreateModalProps,
  CreateResourceOutput,
  DeleteModalProps,
  RenameModalProps,
  RenameResourceOutput,
  SafeguardConfirmModalProps,
  SafeguardConfirmOutput,
} from './types';

function Modals() {
  const dispatch = useDispatch();
  const { modalToShow, modalProps } = useSelector(modalsSelector);

  if (!modalToShow) {
    return null;
  }

  function handleDone(resp: any) {
    if (modalProps) {
      modalProps.onDone(resp);
    }
    dispatch(hideModal());
  }

  if (modalToShow === ModalType.CONFIRMATION) {
    const typedModalProps = modalProps as ConfirmationModalProps;
    return (
      <Confirmation
        {...typedModalProps}
        onDone={handleDone as (resp: ConfirmationOutput) => void}
      />
    );
  }
  if (modalToShow === ModalType.SAFEGUARD_CONFIRM) {
    const typedModalProps = modalProps as SafeguardConfirmModalProps;
    return (
      <SafeguardConfirm
        {...typedModalProps}
        onDone={handleDone as (resp: SafeguardConfirmOutput) => void}
      />
    );
  }
  if (modalToShow === ModalType.CREATE_RESOURCE) {
    const typedModalProps = modalProps as CreateModalProps;
    return (
      <CreateResource
        {...typedModalProps}
        onDone={handleDone as (resp: CreateResourceOutput) => void}
      />
    );
  }
  if (modalToShow === ModalType.RENAME_RESOURCE) {
    const typedModalProps = modalProps as RenameModalProps;
    return (
      <RenameResource
        {...typedModalProps}
        onDone={handleDone as (resp: RenameResourceOutput) => void}
      />
    );
  }
  if (modalToShow === ModalType.DELETE_RESOURCE) {
    const { resourceType, resourceName, showDualEnvWarning } = modalProps as DeleteModalProps;
    const title = `Delete ${resourceType}`;
    const deleteModalId = `delete-modal-${resourceType}`;

    return (
      <Confirmation
        modalId={deleteModalId}
        title={title}
        prompt={
          <>
            Are you sure you want to delete{' '}
            <Typography component="span" variant="subtitle2">
              {resourceName}
            </Typography>
            {showDualEnvWarning ? ' in all environments' : ''}? You will not be able to undo this
            action.
          </>
        }
        okText="Delete"
        onDone={handleDone as (resp: ConfirmationOutput) => void}
      />
    );
  }
}

export default Modals;
