import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import CopyIcon from '@/components/Icons/CopyIcon';
import copyToClipboard from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import { CodeBlock } from './styled';
import { CodeProps } from './types';

function Code({ children }: CodeProps) {
  const { enqueueSnackbar } = useSnackbar();

  function copyCommand() {
    copyToClipboard(children);
    enqueueSnackbar('Copied to clipboard!');
  }
  return (
    <CodeBlock>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="mono1" color="text.subtle" sx={{ userSelect: 'none' }}>
          $
        </Typography>
        <Typography variant="mono1">{children}</Typography>
      </Stack>
      <Tooltip title="Copy command">
        <IconButton onClick={copyCommand}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </CodeBlock>
  );
}

export default Code;
