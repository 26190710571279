import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import toString from 'lodash/toString';

import { boolean } from 'boolean';
import JSON5 from 'json5';
import mexp from 'math-expression-evaluator';
import { render as mustacheRender } from 'micromustache';

import { PropType } from './components/types';
import { CLOSE_TAG, DOT_NOTATION, OPEN_TAG, OPEN_TAG_WITH_PREFIX } from './constants';
import { ParamMapSupplier } from './params/types';
import { InferenceHints, RefData, RenderAs, ResolutionStatus, ValueSupplier } from './types';

function hasRefs(value: ValueSupplier | ParamMapSupplier) {
  return !!(value.refs && value.refs.length);
}

function getResolvedValue({ target, data }: RefData) {
  if (!data) {
    return null;
  }
  if (data.status !== ResolutionStatus.Resolved) {
    return null;
  }
  if (target.accessor) {
    return get(data.resolvedValue, target.accessor);
  }
  return data.resolvedValue;
}

function getRenderFormatForPropType(type: PropType) {
  switch (type) {
    case PropType.Boolean:
      return RenderAs.Logical;
    case PropType.Number:
      return RenderAs.Equation;
    case PropType.Collection:
      return RenderAs.Collection;
    case PropType.Json:
      return RenderAs.Object;
    case PropType.Text:
      return RenderAs.String;
    default:
      // every other case will be treated as is
      return RenderAs.Raw;
  }
}

function renderAs(value: any, as: RenderAs) {
  if (as === RenderAs.String && typeof value !== 'string') {
    if (isPlainObject(value)) {
      return JSON.stringify(value);
    }
    return toString(value);
  }
  if (as === RenderAs.Logical && typeof value !== 'boolean') {
    return boolean(value);
  }
  if (as === RenderAs.Equation && typeof value !== 'number') {
    if (typeof value === 'string') {
      return mexp.eval(value);
    }
    return Number(value);
  }
  if (as === RenderAs.Collection && !Array.isArray(value)) {
    if (!value) {
      return [];
    }
    const parsed = JSON5.parse(value); // JSON5 can understand parsing arrays without wrapping braces
    if (Array.isArray(parsed)) {
      return parsed;
    }
    // it's possible that we supplied something that was not considered an array,
    // if so, we can guarantee array typing, by wrapping it; which is what we do
    return [parsed];
  }
  if (as === RenderAs.Object && typeof value !== 'object') {
    if (!value) {
      return null;
    }
    const parsed = JSON5.parse(value); // assume value is parsable
    if (typeof parsed === 'object') {
      return parsed;
    }
    // if parsed was not an object still, we are going to fall through and
    // just let the original value be returned as is
  }
  return value;
}

const ONLY_TAG_CHECK = new RegExp(`^${OPEN_TAG_WITH_PREFIX}.{7}${CLOSE_TAG}$`);
const TAG_MATCH = new RegExp(`${OPEN_TAG_WITH_PREFIX}(.{7})${CLOSE_TAG}`, 'g');

function renderWithRefs(data: ValueSupplier, scope: Record<string, any>) {
  const valueToRender = (data?.value || '').trim();
  if (ONLY_TAG_CHECK.test(valueToRender)) {
    // we only have a tag, this value should behave like a passthrough
    const passthroughProp = stripControlChars(valueToRender, true);
    return get(scope, passthroughProp);
  }
  return mustacheRender(
    data?.value || '',
    // Render all refs as strings
    mapValues(scope, (val) => renderAs(val, RenderAs.String)),
    { tags: [OPEN_TAG_WITH_PREFIX, CLOSE_TAG] },
  );
}

function buildParamsWithRefs(paramMap: Record<string, ValueSupplier>, scope: Record<string, any>) {
  const resolvedMap: Record<string, any> = {};
  if (!paramMap) {
    return {};
  }
  Object.keys(paramMap).forEach((key) => {
    if (scope) {
      resolvedMap[key] = renderWithRefs(paramMap[key], scope);
    } else {
      resolvedMap[key] = paramMap[key].value;
    }
  });
  return resolvedMap;
}

const CONTROL_CHARS = new RegExp(`^${OPEN_TAG}|${CLOSE_TAG}$`, 'g');
const CONTROL_CHARS_WITH_PREFIX = new RegExp(`^${OPEN_TAG_WITH_PREFIX}|${CLOSE_TAG}$`, 'g');

function stripControlChars(value: string, withPrefix: boolean = false) {
  if (withPrefix) {
    return value.replace(CONTROL_CHARS_WITH_PREFIX, '');
  }
  return value.replace(CONTROL_CHARS, '');
}

const NUMERIC_MATCH = /^[+-]?(?:[0-9]+(?:[.][0-9]*)?|[.][0-9]+)$/;

function inferTypedValue(
  stringifiedValue: string,
  hints: InferenceHints,
): string | number | boolean {
  let valueFromHint;
  if (hints.tryEquation) {
    try {
      valueFromHint = mexp.eval(stringifiedValue);
    } catch (ex) {
      // failed, we should proceed with other default inference steps
    }
  }
  if (valueFromHint) {
    return valueFromHint;
  }
  if (stringifiedValue === 'true' || stringifiedValue === 'false') {
    return boolean(stringifiedValue);
  }
  if (NUMERIC_MATCH.test(stringifiedValue)) {
    return Number(stringifiedValue);
  }
  return stringifiedValue;
}

function replaceValueWithNewTags(value: string, mapping: Record<string, string>): string {
  if (isEmpty(mapping)) {
    return value;
  }
  return value.replace(
    TAG_MATCH,
    (_, refId) => `${OPEN_TAG_WITH_PREFIX}${mapping[refId]}${CLOSE_TAG}`,
  );
}

const ARRAY_INDEX_CHECK = /\[([0-9]+)\]$/;

function parseResourcePath(path: string = ''): string[] {
  if (!path) {
    return [];
  }
  const rawParts = path.split(DOT_NOTATION);
  return rawParts.flatMap((part) => {
    if (ARRAY_INDEX_CHECK.test(part)) {
      const arrIndex = part.match(ARRAY_INDEX_CHECK)[1];
      if (arrIndex) {
        const withoutArrIndex = part.split('[')[0];
        return [withoutArrIndex, arrIndex];
      }
    }
    return part;
  });
}

export {
  hasRefs,
  getResolvedValue,
  getRenderFormatForPropType,
  renderAs,
  renderWithRefs,
  buildParamsWithRefs,
  stripControlChars,
  inferTypedValue,
  replaceValueWithNewTags,
  parseResourcePath,
};
