import Lottie from 'react-lottie-player';

import { Stack } from '@mui/material';

import loadingMark from '@/components/Animations/loadingmark-subtle.json';
import { styled } from '@/theme/styled';

const Container = styled(Stack)({
  width: '100%',
  height: '100%',
});

Container.defaultProps = {
  direction: 'column',
  gap: 3,
  alignItems: 'center',
  justifyContent: 'center',
};

const SubtleLoader = styled(Lottie)({});

SubtleLoader.defaultProps = {
  loop: true,
  play: true,
  animationData: loadingMark,
};

export { Container, SubtleLoader };
