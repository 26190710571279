import { Typography } from '@mui/material';

import { Container, SubtleLoader } from './styled';
import { LoadingProps } from './types';

function Loading({ text, sx = {} }: LoadingProps) {
  const { height = 40, width = 40, ...rest } = sx;

  return (
    <Container sx={rest}>
      <SubtleLoader sx={{ height, width }} />
      {text && <Typography variant="h4">{text}</Typography>}
    </Container>
  );
}

export default Loading;
