import { useUpdateEffect } from 'react-use';

import { ErrorResetterProps } from './types';

/**
 * Component which can be rendered inside an error boundary's fallback element which clears
 * the error when one of the keys changes.
 */
function ErrorResetter({ keys, resetError }: ErrorResetterProps): JSX.Element {
  useUpdateEffect(() => {
    resetError();
  }, keys);
  return null;
}

export default ErrorResetter;
