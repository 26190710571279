/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CodeFileFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CodebaseQueryVariables = Types.Exact<{
  workflowId: Types.Scalars['String'];
  releaseVersion?: Types.InputMaybe<Types.Scalars['String']>;
  deploymentEnv?: Types.InputMaybe<Types.DeploymentEnvEnum>;
}>;


export type CodebaseQuery = { __typename?: 'QueryPrediction', codebase: { __typename?: 'CodeBaseType', id: string | null | undefined, code_files: Array<{ __typename?: 'CodeFileType', id: string | null | undefined, filepath: string, content: string, created: any | null | undefined, modified: any | null | undefined, versionId: string | null | undefined, entrypoints: Array<{ __typename?: 'FileEntrypoint', name: string | null | undefined, lineNo: number | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const CodebaseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Codebase"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"releaseVersion"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DeploymentEnvEnum"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"codebase"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"workflow_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workflowId"}}},{"kind":"Argument","name":{"kind":"Name","value":"release_version"},"value":{"kind":"Variable","name":{"kind":"Name","value":"releaseVersion"}}},{"kind":"Argument","name":{"kind":"Name","value":"deployment_env"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code_files"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CodeFileFragment"}}]}}]}}]}},...CodeFileFragmentDoc.definitions]} as unknown as DocumentNode<CodebaseQuery, CodebaseQueryVariables>;
export const namedOperations = {
  Query: {
    Codebase: 'Codebase'
  }
}

/**
 * __useCodebaseQuery__
 *
 * To run a query within a React component, call `useCodebaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodebaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodebaseQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      releaseVersion: // value for 'releaseVersion'
 *      deploymentEnv: // value for 'deploymentEnv'
 *   },
 * });
 */
export function useCodebaseQuery(baseOptions: Apollo.QueryHookOptions<CodebaseQuery, CodebaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CodebaseQuery, CodebaseQueryVariables>(CodebaseDocument, options);
      }
export function useCodebaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodebaseQuery, CodebaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CodebaseQuery, CodebaseQueryVariables>(CodebaseDocument, options);
        }
export type CodebaseQueryHookResult = ReturnType<typeof useCodebaseQuery>;
export type CodebaseLazyQueryHookResult = ReturnType<typeof useCodebaseLazyQuery>;
export type CodebaseQueryResult = Apollo.QueryResult<CodebaseQuery, CodebaseQueryVariables>;
export function refetchCodebaseQuery(variables: CodebaseQueryVariables) {
      return { query: CodebaseDocument, variables: variables }
    }