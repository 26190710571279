import { inputBaseClasses } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import { styled as muiStyled } from '@/theme/styled';

const INPUT_HEIGHT = 20;

const Input = muiStyled(TextField)({
  [`.${inputBaseClasses.root}`]: {
    border: 'none !important',
    padding: 0,
    fontSize: 12,
    height: INPUT_HEIGHT,
    width: 150,

    '> input': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

Input.defaultProps = {
  autoFocus: true,
  placeholder: 'Tell us more!',
};

export { Input };
