/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ApiKeyFragment = { __typename?: 'UserAPIKeyType', id: string | null | undefined, prefix: string, revoked: boolean, created: any, lastUsed: any | null | undefined, apiKeyType: Types.ApiKeyType | null | undefined, allowedOracles: Array<{ __typename?: 'ModelType', id: string, name: string | null | undefined }> };

export type AllowedModelsFragment = { __typename?: 'ModelType', id: string, name: string | null | undefined };

export const AllowedModelsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AllowedModels"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ModelType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<AllowedModelsFragment, unknown>;
export const ApiKeyFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"APIKey"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserAPIKeyType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"prefix"}},{"kind":"Field","name":{"kind":"Name","value":"revoked"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","alias":{"kind":"Name","value":"lastUsed"},"name":{"kind":"Name","value":"last_used"}},{"kind":"Field","alias":{"kind":"Name","value":"apiKeyType"},"name":{"kind":"Name","value":"api_key_type"}},{"kind":"Field","alias":{"kind":"Name","value":"allowedOracles"},"name":{"kind":"Name","value":"allowed_oracles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AllowedModels"}}]}}]}}]} as unknown as DocumentNode<ApiKeyFragment, unknown>;
export const namedOperations = {
  Fragment: {
    APIKey: 'APIKey',
    AllowedModels: 'AllowedModels'
  }
}
