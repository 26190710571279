import { ReleaseEnv } from '@/types/releaseEnv';
import { GraphQLError } from 'graphql';

import { WorkflowIdentifier } from '../Workflows/types';
import { CreateCodeFileMutation } from './__generated__/mutations.generated';

/* eslint-disable */
type FileEntrypoint = {
  name: string;
  lineNo: number;
};

enum FileSaveState {
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
  SAVING = 'SAVING',
}

type CodeFile = {
  id: string;
  versionId: string;
  created: Date;
  modified: Date;
  filepath: string;
  content: string;
  entrypoints: Array<FileEntrypoint>;
  errors?: CodeFileError[];
  saveState: FileSaveState;
};

type ServerCodeFile = CreateCodeFileMutation['create_or_update_code_file']['code_file'];

type CodeFileError = { message: string; line?: number; column?: number };

interface GraphQLInvalidCodeError extends GraphQLError {
  extensions: { code: 'SYNTAX_ERROR'; line: number; column: number; msg: string };
}

type CreateOrUpdateResponse = {
  create_or_update_code_file: {
    code_file: CodeFile;
  };
};

type FetchCodebaseAsyncPayload = {
  workflowIdentifier: WorkflowIdentifier;
};

type FetchCodebaseAsyncResult = WorkflowIdentifier & {
  data: Array<ServerCodeFile>;
};

type CreateFileAsyncPayload = WorkflowIdentifier & { fileContent: string; filePath: string };
type CreateFileAsyncResult = WorkflowIdentifier & { data: CreateCodeFileMutation };

type FileIdentifier = WorkflowIdentifier & {
  fileId: string;
};

type UpdateFilePayload = FileIdentifier & {
  /**
   * @deprecated
   */
  fileVersionId: string;
  fileContent: string;
  /**
   * Set to update the file name
   */
  filepath?: string;
};

type UpdateFileAfterSavePayload = FileIdentifier & {
  file: ServerCodeFile;
};

type UpdateSaveStatePayload = FileIdentifier & {
  saveState: FileSaveState;
};

type UpdateFileAsyncResult = WorkflowIdentifier & { data: CreateOrUpdateResponse };

type DeleteFileAsyncPayload = FileIdentifier;

type CodebasesType = {
  data: Record<ReleaseEnv, Record<string, Array<CodeFile>>>;
};

export {
  FileIdentifier,
  CodebasesType,
  CodeFile,
  ServerCodeFile,
  CreateOrUpdateResponse,
  FetchCodebaseAsyncPayload,
  FetchCodebaseAsyncResult,
  CreateFileAsyncPayload,
  CreateFileAsyncResult,
  UpdateFilePayload,
  UpdateFileAfterSavePayload,
  UpdateFileAsyncResult,
  DeleteFileAsyncPayload,
  FileSaveState,
  UpdateSaveStatePayload,
  CodeFileError,
  GraphQLInvalidCodeError,
};
