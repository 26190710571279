import { DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import XIcon from '@/components/Icons/XIcon';

import { DialogTitleWithCloseProps } from './types';

function DialogTitleWithX({
  children,
  onClose,
  dataCy = 'dialog-x-button',
  sx = {},
  ...other
}: DialogTitleWithCloseProps) {
  return (
    <DialogTitle sx={{ m: 0, p: (theme) => theme.spacing(2, 2, 0, 3), ...sx }} {...other}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography variant="h4">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            sx={{ justifyItems: 'end' }}
            onClick={onClose}
            data-cy={dataCy}
          >
            <XIcon />
          </IconButton>
        ) : null}
      </Stack>
    </DialogTitle>
  );
}

export default DialogTitleWithX;
