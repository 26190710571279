import styled from 'styled-components';

const FlexRowContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;

  &.centered {
    justify-content: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.around {
    justify-content: space-around;
  }

  &.evenly {
    justify-content: space-evenly;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  &.centered {
    justify-content: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.around {
    justify-content: space-around;
  }

  &.evenly {
    justify-content: space-evenly;
  }
`;

export { FlexRowContainer, FlexColumnContainer };
