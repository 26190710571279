import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { createAsyncThunk } from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import uniqueId from 'lodash/uniqueId';

import { ApplicationLog, LogType } from '@/pages/Application/Toolbar/Logs/types';
import {
  InferenceHints,
  RenderAs,
  Resolution,
  ResolutionStatus,
  ResolutionTask,
  ValueSupplier,
} from '@/pages/Application/View/types';
import { inferTypedValue, renderWithRefs } from '@/pages/Application/View/utils';
import { Level } from '@/sections/Application/Logs/types';
import { handleStaleGraphQLError } from '@/utils/errorHandling';
import { safeStringify } from '@/utils/json';
import humanize from 'humanize-string';

import { queriesSelector, workletsSelector } from '../../../Selectors';
import { ThunkAPI } from '../../../types';
import { addEventLogs, fetchLogs } from '../Toolbar';
import { stringifyParams } from '../Toolbar/utils';
import { runAction } from './Actions/runActions';
import {
  Action,
  ActionAPI,
  ActionType,
  ControlComponentOutput,
  CopyToClipboardOutput,
  GoToViewOutput,
  RunActionOutput,
  RunWorkletOutput,
  SetStateOutput,
  ShowNotificationOutput,
  TriggerQueryOutput,
} from './Actions/types';
import {
  CreateViewDocument,
  DeleteViewDocument,
  DuplicateViewDocument,
  UpdateViewNameDocument,
} from './__generated__/mutations.generated';
import { ViewDocument, ViewWithEnvDocument } from './__generated__/queries.generated';
import { buildDependentTasks, getResolvedRefsObject, resolveParams } from './data-resolution';
import { queueResolution } from './index';
import { configSelector, viewSelector } from './selectors';
import {
  CreateViewParams,
  OperatorViewWithEnv,
  RenameViewParams,
  RunActionParams,
  ViewEntity,
  ViewIdentifier,
} from './types';
import { getApiHeaders, makeNewView, viewFromResponse } from './utils';

export const fetchViewById = createAsyncThunk<
  ViewEntity,
  ViewIdentifier & {
    asOperator?: boolean;
  },
  ThunkAPI
>('views-entities/fetchViewById', async (thunkArg, { dispatch, getState, extra }) => {
  const { viewId, workflowId, releaseEnv, asOperator = true } = thunkArg;

  const targetView = viewSelector(getState(), thunkArg);

  if (targetView) {
    return targetView;
  }

  dispatch(showLoading());
  const { data } = await extra.apolloClient.query({
    query: ViewDocument,
    variables: { id: viewId, deploymentEnv: releaseEnv },
    context: {
      headers: getApiHeaders(workflowId, asOperator),
    },
  });
  dispatch(hideLoading());

  return viewFromResponse(data.view, workflowId);
});

export const fetchOperatorViewById = createAsyncThunk<
  OperatorViewWithEnv,
  Omit<ViewIdentifier, 'releaseEnv'>,
  ThunkAPI
>('views-entities/fetchOperatorViewById', async (thunkArg, { extra }) => {
  const { viewId, workflowId } = thunkArg;

  const viewWithEnv = (
    await extra.apolloClient.query({
      query: ViewWithEnvDocument,
      variables: { id: viewId },
      context: {
        headers: getApiHeaders(workflowId, true),
      },
    })
  ).data.view_with_env;

  return {
    ...viewWithEnv,
    workflowId,
    view: viewFromResponse(viewWithEnv.view, workflowId),
  };
});

// notice that we will accept the `config` param, this is a stop-gap to allow
// the old style views from being created still

export const createView = createAsyncThunk<ViewEntity, CreateViewParams, ThunkAPI>(
  'views-entities/createView',
  async ({ workflowId, name }, { extra }) => {
    const newView = makeNewView(workflowId, name);
    const configToSerialize = newView.config.present;

    const { data } = await extra.apolloClient.mutate({
      mutation: CreateViewDocument,
      variables: {
        workflowId,
        components: JSON.stringify(configToSerialize),
        name,
        nodeId: null,
      },
    });
    // `createView` is structured like { id, currentVersionId } (so not a full view object)
    const created = data?.createView;
    return {
      ...newView,
      id: created.id,
      currentVersionId: created.currentVersionId,
    };
  },
);

export const renameView = createAsyncThunk<string, RenameViewParams, ThunkAPI>(
  'views-entities/renameView',
  async (thunkArg, { dispatch, getState, extra }) => {
    const { viewId, name } = thunkArg;

    const currentView = viewSelector(getState(), thunkArg);

    try {
      const { data } = await extra.apolloClient.mutate({
        mutation: UpdateViewNameDocument,
        variables: {
          id: viewId,
          name,
          currentVersionId: currentView.currentVersionId,
        },
      });
      return data.updateViewName.currentVersionId;
    } catch (ex) {
      handleStaleGraphQLError(dispatch, ex, 'rename view');
    }
  },
);

export const deleteView = createAsyncThunk<string, ViewIdentifier, ThunkAPI>(
  'views-entities/deleteView',
  async (thunkArg, { dispatch, getState, extra }) => {
    const { viewId } = thunkArg;

    const currentView = viewSelector(getState(), thunkArg);

    try {
      await extra.apolloClient.mutate({
        mutation: DeleteViewDocument,
        variables: {
          viewId,
          currentVersionId: currentView.currentVersionId,
        },
      });

      return viewId;
    } catch (ex) {
      handleStaleGraphQLError(dispatch, ex, 'delete view');
    }
  },
);

export const duplicateView = createAsyncThunk<ViewEntity, ViewIdentifier, ThunkAPI>(
  'views-entities/duplicateView',
  async ({ viewId, workflowId }, { dispatch, extra }) => {
    const { data } = await extra.apolloClient.mutate({
      mutation: DuplicateViewDocument,
      variables: {
        viewId,
      },
    });

    return viewFromResponse(data?.duplicateView?.view, workflowId);
  },
);

export const runActions = createAsyncThunk<void, RunActionParams, ThunkAPI>(
  'data-resolver/runActions',
  async (
    { actions, asOperator = true, componentId, ...viewIdentifier },
    { dispatch, getState, extra, rejectWithValue },
  ) => {
    const originalTargetCollection = configSelector(getState(), viewIdentifier);

    if (!originalTargetCollection) {
      return rejectWithValue(
        `View ${viewIdentifier.viewId} does not exist or contain a valid config`,
      );
    }

    const component = originalTargetCollection.children[componentId];

    const queries = queriesSelector(getState());
    const worklets = workletsSelector(getState(), viewIdentifier.releaseEnv);

    function resolveTask(task: ResolutionTask) {
      return new Promise<void>((resolve) => {
        dispatch(
          queueResolution({
            ...viewIdentifier,
            task,
            callback: resolve,
          }),
        );
      });
    }

    async function resolveParamRef(
      paramRef: string,
      evaluatedTasks: ResolutionTask[] = [],
    ): Promise<Record<string, any>> {
      const updatedTargetCollection = configSelector(getState(), viewIdentifier);
      const resolution = resolveParams(updatedTargetCollection, paramRef, evaluatedTasks);
      if (resolution.status === ResolutionStatus.Unresolved && !isEmpty(resolution.requires)) {
        for (const task of resolution.requires) {
          await resolveTask(task);
          evaluatedTasks.push(task);
        }
        return resolveParamRef(paramRef, evaluatedTasks); // try again after datasources have been resolved
      }
      return resolution.params;
    }

    async function resolveValue(
      value: ValueSupplier,
      hints: InferenceHints = {
        tryEquation: false,
      },
    ): Promise<Resolution> {
      const resolution: Resolution = {
        status: ResolutionStatus.Resolved,
        resolvedValue: null,
        as: RenderAs.Raw,
      };
      if (!value) {
        return resolution;
      }
      const startingTargetCollection = configSelector(getState(), viewIdentifier);
      const unresolvedTasks = buildDependentTasks(startingTargetCollection, value.refs);
      for (const task of unresolvedTasks) {
        await resolveTask(task);
      }
      const updatedTargetCollection = configSelector(getState(), viewIdentifier);
      const resolvedRefs = getResolvedRefsObject(updatedTargetCollection, value.refs);
      if (resolvedRefs) {
        resolution.resolvedValue = renderWithRefs(value, resolvedRefs);
      } else {
        resolution.resolvedValue = value.value;
      }
      resolution.resolvedValue = inferTypedValue(resolution.resolvedValue, hints);
      return resolution;
    }

    function getActionFragmentLog(action: Action, output: RunActionOutput): object {
      switch (action.type) {
        case ActionType.SetState:
          return {
            name: action.saveState,
            value: (output as SetStateOutput)?.value,
          };
        case ActionType.TriggerQuery:
          const triggerQueryOutput = output as TriggerQueryOutput;
          return triggerQueryOutput.error
            ? {
                error: triggerQueryOutput.error as string,
              }
            : {
                query: queries[action.queryId].name,
                parameters: stringifyParams(triggerQueryOutput.params),
                outputSavedTo: action.saveState,
              };
        case ActionType.ControlComponent:
          return {
            name: action.componentId,
            value: (output as ControlComponentOutput).data.resolvedValue,
          };
        case ActionType.RunWorklet:
          const runWorkletOutput = output as RunWorkletOutput;
          return runWorkletOutput.error
            ? {
                error: runWorkletOutput.error,
              }
            : {
                worklet: worklets[action.workletId].name,
                parameters: JSON.stringify(runWorkletOutput.value),
                outputSavedTo: action.saveState,
              };
        case ActionType.GoToView:
          return {
            view: action.viewId,
            parameters: JSON.stringify((output as GoToViewOutput).params),
          };
        case ActionType.ShowNotification:
          return {
            message: (output as ShowNotificationOutput).message,
            intent: action.variant,
          };
        case ActionType.CopyToClipboard:
          return {
            value: (output as CopyToClipboardOutput).value,
          };
        case ActionType.RefreshDataSources:
          return {};
      }
    }

    const api: ActionAPI = {
      asOperator,
      viewIdentifier,
      dispatch,
      extra,
      resolveParamRef,
      resolveValue,
      getState,
    };

    function getEventLog(actionId: string, output: RunActionOutput): ApplicationLog {
      const action = originalTargetCollection.actions[actionId];
      const allHandlers = [
        ...(component?.eventHandlers ?? []),
        ...originalTargetCollection.handlers,
      ];

      const handler = allHandlers.find((h) => actionId === h.actionId);
      if (!handler) return null;

      return {
        ts: originalEventTime.getTime() * 1000000,
        type: LogType.EVENT,
        level: (output as RunWorkletOutput | TriggerQueryOutput)?.error ? Level.Error : Level.Info,
        uid: uniqueId('event-log-'),
        msg: {
          component: componentId,
          event: humanize(`${handler.event} ${action.type}`),
          ...mapValues(getActionFragmentLog(action, output), safeStringify),
        },
      };
    }

    const eventLogs = [];
    const originalEventTime = new Date();
    for (const actionId of actions) {
      const action = originalTargetCollection.actions[actionId];
      if (action) {
        const output = await runAction(api, action);
        if (action?.type && !asOperator && output) {
          eventLogs.push(getEventLog(actionId, output));
        }
      }
    }

    const shouldFetchLogs =
      !asOperator &&
      actions.some(
        (actionId) => originalTargetCollection.actions[actionId]?.type === ActionType.RunWorklet,
      );

    if (shouldFetchLogs) {
      await dispatch(fetchLogs(viewIdentifier));
    }

    dispatch(addEventLogs(eventLogs.filter(Boolean)));
  },
);
