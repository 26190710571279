import MuiButton from '@mui/material/Button';

import { BasetenTheme } from '@/theme';
import { ColorScale } from '@/theme/colors';
import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

import { CreditsButtonProps } from './types';

function getBorderColor(theme: BasetenTheme, progress: number, hasCreditCard: boolean) {
  if (!hasCreditCard) {
    if (progress === 100) {
      return theme.palette.border.dangerEmphasis;
    }

    if (progress <= 83) {
      return theme.palette.border.successEmphasis;
    }

    return theme.palette.border.warnEmphasis;
  }

  return theme.palette.border.default;
}

function getBackgroundColor(theme: BasetenTheme, progress: number, hasCreditCard: boolean) {
  if (!hasCreditCard) {
    if (progress === 100) {
      return ColorScale.red[50];
    }

    if (progress <= 83) {
      return ColorScale.green[50];
    }

    return ColorScale.yellow[50];
  }

  return theme.palette.background.muted;
}

const Button = styled(MuiButton, {
  shouldForwardProp: (propName) => !['progress', 'hasCreditCard'].includes(propName as string),
})<CreditsButtonProps>(({ theme, progress, hasCreditCard }) => ({
  border: getBorder(getBorderColor(theme as unknown as BasetenTheme, progress, hasCreditCard)),
  padding: theme.spacing(0.5, 1),
  position: 'relative',
  overflow: 'hidden',
  gap: theme.spacing(0.5),

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${progress}%`,
    height: '100%',
    background: getBackgroundColor(theme as unknown as BasetenTheme, progress, hasCreditCard),
  },
}));

export { Button, getBackgroundColor, getBorderColor };
