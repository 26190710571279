import { createSlice } from '@reduxjs/toolkit';

import { ShareViewState } from './types';

const initialState = {
  loading: false,
  isPubliclyShared: false,
  lastDeployedToProductionAt: null,
  hasUndeployedChanges: false,
  fetchError: '',
} as ShareViewState;

const ShareView = createSlice({
  name: 'share-view',
  initialState,
  reducers: {
    fetchPreShareStateStart(state) {
      state.loading = true;
      state.fetchError = '';
    },
    fetchPreShareStateDone(state, action) {
      const preShareState = action.payload.workflow;
      state.isPubliclyShared = preShareState.isPubliclyShared;
      state.lastDeployedToProductionAt = preShareState.lastDeployedToProductionAt;
      state.hasUndeployedChanges = preShareState.hasUndeployedChanges;
      state.loading = false;
    },
    fetchPreShareStateError(state, action) {
      state.loading = false;
      state.fetchError = action.payload;
      state.isPubliclyShared = false;
      state.lastDeployedToProductionAt = null;
      state.hasUndeployedChanges = false;
    },
    setPubliclySharedStart(state) {
      state.loading = true;
      state.fetchError = '';
    },
    setPubliclySharedDone(state, action) {
      const preShareState = action.payload.setPubliclyShared;
      state.isPubliclyShared = preShareState.isPubliclyShared;
      state.loading = false;
    },
    setPubliclySharedError(state, action) {
      state.loading = false;
      state.fetchError = action.payload;
    },
  },
});

export const {
  fetchPreShareStateStart,
  fetchPreShareStateDone,
  fetchPreShareStateError,
  setPubliclySharedStart,
  setPubliclySharedDone,
  setPubliclySharedError,
} = ShareView.actions;

export default ShareView.reducer;
