import { FC } from 'react';

import useIsReadOnly from '@/hooks/useIsReadOnly';

import { getDisplayName } from '../utils';
import { withReadOnlyOptions } from './types';

function withReadOnly<P extends object>(Component: FC<P>, options?: withReadOnlyOptions) {
  function ComponentwithReadOnly(props: P) {
    const isReadOnly = useIsReadOnly();

    if (options?.hide && isReadOnly) return null;
    return <Component {...(props as P)} disabled={isReadOnly} />;
  }

  ComponentwithReadOnly.displayName = `withReadOnly${getDisplayName(Component)}`;

  return ComponentwithReadOnly;
}

export { withReadOnly };
