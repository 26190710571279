import { freeze } from 'immer';

import { RootState } from '../../../types';
import { WorkflowIdentifier } from '../../entities/Workflows/types';
import { PyEnvViewState } from './types';
import { makeDefaultPyEnv } from './utils';

// Deep freeze to prevent accidental editing of state
const defaultPyEnvView = freeze(makeDefaultPyEnv(), true);

export const pyEnvViewAppScopedSelector = (
  state: RootState,
  workflowIdentifier: WorkflowIdentifier,
): PyEnvViewState => {
  return (
    state.ui.pyEnvView[workflowIdentifier.releaseEnv][workflowIdentifier.workflowId] ??
    defaultPyEnvView // Fallback if workflow doesn't have pynode requirements installed yet
  );
};
