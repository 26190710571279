import { batch } from 'react-redux';

import { AnyAction } from '@reduxjs/toolkit';
import { GroupByFunction } from 'redux-undo';

let currentBatchId: string | null = null;

/**
 * Wrapping multiple action dispatches in this funciton will result in those actions being combined in the undo/redo history.
 * This should be used whenever a single user interaction results in multiple actions being dispatched. All dispatches must
 * happen synchronously inside the `callback`.
 *
 * Automatically batches react rendering as well.
 *
 * Based off of https://redux-undo.js.org/main/examples/undo-redo-batch-actions
 *
 * @param callback - Callback containing dispatches. This is evoked immediately and exactly once
 */
function batchHistory(callback: () => void): void {
  if (currentBatchId) {
    // If nested inside other batchHistory, call callback immediately
    callback();
    return;
  }

  currentBatchId = Math.random().toString(16);
  try {
    batch(callback);
  } finally {
    currentBatchId = null;
  }
}

const groupByBatch: GroupByFunction<unknown, AnyAction> = (_action, _state, _previousHistory) =>
  currentBatchId;

export { groupByBatch };

export default batchHistory;
