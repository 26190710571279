import { styled } from '@mui/system';

import { FlexBox } from '../index';
import { COLORS, SIZES } from './configs';
import { BadgeProps } from './types';

const Badge = styled(FlexBox)<BadgeProps>(({ color = 'primary', size = 'medium' }) => ({
  minWidth: SIZES[size],
  height: SIZES[size],
  lineHeight: 16,
  padding: '0 4px',
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: '10px',
  background: COLORS[color].bgColor,
  color: COLORS[color].color,
}));

export { Badge };
