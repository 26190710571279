import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function KeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9999 1.33325L12.6666 2.66659M7.59327 7.73992L10.3333 4.99992M10.3333 4.99992L12.3333 6.99992L14.6666 4.66659L12.6666 2.66659M10.3333 4.99992L12.6666 2.66659M7.59327 7.73992C7.9375
        8.07956 8.21114 8.48394 8.39844 8.92978C8.58574 9.37561 8.683 9.8541 8.68462 10.3377C8.68624 10.8213 8.59219 11.3004 8.40788 11.7475C8.22357 12.1945 7.95265 12.6007 7.6107 12.9427C7.26876
        13.2846 6.86255 13.5556 6.41547 13.7399C5.96839 13.9242 5.48927 14.0182 5.00569 14.0166C4.52212
        14.015 4.04363 13.9177 3.5978 13.7304C3.15196 13.5431 2.74758 13.2695 2.40794 12.9253C1.74003 12.2337 1.37045 11.3075 1.3788 10.3461C1.38715 9.38473 1.77277
        8.46508 2.4526 7.78525C3.13243 7.10542 4.05208 6.7198 5.01347 6.71145C5.97486
        6.70309 6.90106 7.07267 7.59261 7.74059L7.59327 7.73992Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default KeyIcon;
