import { Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AppLink from '@/components/AppLink';
import CopyIcon from '@/components/Icons/CopyIcon';
import KeyIcon from '@/components/Icons/KeyIcon';
import { Tabs } from '@/pages/Settings/Tabs/types';
import { BasetenPageEnum } from '@/routes';
import GenerateAPIKey from '@/sections/GenerateAPIKey';
import { useModelDeployment } from '@/store/slices/ui/ModelDeployment/hooks';
import copyToClipboard from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import { CodeBlock } from '../Code/styled';

function GenerateKey() {
  const { apiKey, setApiKey } = useModelDeployment();

  const { enqueueSnackbar } = useSnackbar();

  function copyCommand() {
    copyToClipboard(apiKey);
    enqueueSnackbar('Copied to clipboard!');
  }

  return apiKey ? (
    <Fade in>
      <CodeBlock>
        <Typography variant="mono1" data-cy="api-key">
          {apiKey}
        </Typography>
        <Tooltip title="Copy API key">
          <IconButton onClick={copyCommand}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </CodeBlock>
    </Fade>
  ) : (
    <Stack gap={2} mt={2} direction="row" alignItems="center">
      <GenerateAPIKey
        buttonProps={{
          color: 'secondary',
          variant: 'outlined',
          startIcon: <KeyIcon />,
          sx: { ml: 3 },
        }}
        onCreate={(apiKey) => setApiKey(apiKey)}
        createOnlyPersonalKey
      />
      <Typography color="text.muted">
        or use an{' '}
        <AppLink page={BasetenPageEnum.Settings} params={{ tabId: Tabs.ApiKeys }}>
          existing key
        </AppLink>
      </Typography>
    </Stack>
  );
}

export default GenerateKey;
