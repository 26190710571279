import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function CircleFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g>
        <path
          d="M8.00001 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00001 1.33337C4.31811
          1.33337 1.33334 4.31814 1.33334 8.00004C1.33334 11.6819 4.31811 14.6667 8.00001 14.6667Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="8" cy="8" r="3" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1530_312">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CircleFilledIcon;
