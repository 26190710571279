import { StateData, StateSupplierWithData } from '@/pages/Application/View/states/types';
import { generateId } from '@/utils/ids';

import { ExpandedWorkflowFragment } from '../../../actions/Workflow/__generated__/fragments.generated';
import { NormalizedEntity, WorkflowEntity } from './types';

function makeNewWorkflow(workflowId: string): WorkflowEntity {
  return {
    id: workflowId,
    tags: {},
    worklets: [],
    views: [],
    startingView: null,
    sharedStates: [],
    hydrated: false,
  };
}

function buildState(name: string, withData?: StateData): StateSupplierWithData {
  return {
    name,
    supplierId: generateId('shared-state'),
    isShared: true,
    data: withData,
  };
}

function buildSharedStates(names: string[]): StateSupplierWithData[] {
  return names.map((name) => buildState(name));
}

function workflowFromResponse(
  response: NormalizedEntity<ExpandedWorkflowFragment>,
): WorkflowEntity {
  return {
    id: response.id,
    tags: response.tags,
    views: response.views,
    startingView: response.startingView,
    worklets: response.worklets,
    sharedStates: buildSharedStates(response.sharedStates ?? []),
    hydrated: true,
  };
}

export { makeNewWorkflow, buildState, buildSharedStates, workflowFromResponse };
