import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { useUserLoading } from '@/hooks/useUser';

function useSentryIdentify() {
  const { user } = useUserLoading();

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: user.username,
      });
    }
  }, [user]);
}

export { useSentryIdentify };
