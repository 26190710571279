import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const FooterWrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.subtle,
  margin: theme.spacing(3, 0),
  paddingTop: theme.spacing(3),
  fontSize: 12,
}));

export { FooterWrapper };
