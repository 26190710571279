import { matchPath, useLocation } from 'react-router';

import { useIsApplicationsAndDataEnabled } from '@/hooks/useIsApplicationsAndDataEnabled';
import { applicationsPagePath, dataPagePath, modelsPagePath } from '@/routes/constants';

import { WorkspaceSections } from './types';

function useWorkspaceSection() {
  const location = useLocation();
  const isApplicationsAndDataEnabled = useIsApplicationsAndDataEnabled();

  const match = (path: string) => matchPath(location.pathname, { path, exact: true });

  switch (true) {
    case !!match(applicationsPagePath):
      return isApplicationsAndDataEnabled
        ? WorkspaceSections.Applications
        : WorkspaceSections.Models;
    case !!match(dataPagePath):
      return isApplicationsAndDataEnabled ? WorkspaceSections.Data : WorkspaceSections.Models;
    case !!match(modelsPagePath):
      return WorkspaceSections.Models;
    default:
      return WorkspaceSections.Models;
  }
}

export { useWorkspaceSection };
