import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function GoogleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 6.54547V9.64368H12.3055C12.1164 10.64 11.5491 11.4837 10.6982 12.051L13.2945 14.0656C14.8073 12.6692 15.68 10.6183 15.68 8.18192C15.68 7.61466 15.6291
        7.06915 15.5345 6.54556L8 6.54547Z"
        fill="#4285F4"
      />
      <path
        d="M3.51657 9.52271L2.93099 9.97096L0.858215 11.5855C2.17458 14.1964 4.87258 16.0001 7.99985 16.0001C10.1598 16.0001 11.9707 15.2873 13.2944 14.0655L10.698
        12.051C9.98527 12.531 9.07617 12.8219 7.99985 12.8219C5.91985 12.8219 4.15261 11.4183 3.51984 9.52732L3.51657 9.52271Z"
        fill="#34A853"
      />
      <path
        d="M0.858216 4.41455C0.312788 5.49088 9.15527e-05 6.70545 9.15527e-05 7.99998C9.15527e-05 9.29451 0.312788 10.5091 0.858216 11.5854C0.858216 11.5926 3.52009
        9.51994 3.52009 9.51994C3.36009 9.03994 3.26552 8.53088 3.26552 7.99989C3.26552 7.46891 3.36009 6.95985 3.52009 6.47985L0.858216 4.41455Z"
        fill="#FBBC05"
      />
      <path
        d="M8.00001 3.18547C9.17821 3.18547 10.2255 3.59273 11.0618 4.3782L13.3527 2.08731C11.9636 0.792781 10.1601 0 8.00001 0C4.87274 0 2.17458 1.79637 0.858215
        4.41458L3.52001 6.48004C4.15271 4.5891 5.92001 3.18547 8.00001 3.18547Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
}

export default GoogleIcon;
