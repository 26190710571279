import mapValues from 'lodash/mapValues';
import partial from 'lodash/partial';

import { RefPath, RefType } from '@/pages/Application/View/refs/types';
import { ComponentCollection, ValueSupplier } from '@/pages/Application/View/types';
import { replaceValueWithNewTags } from '@/pages/Application/View/utils';
import { generateId } from '@/utils/ids';
import unreachable from '@/utils/unreachable';
import { nanoid } from 'nanoid';

import * as ActionTypes from './Actions/types';
import { ComponentConfigNode, ComponentConfigNodeType } from './strong-clone-types';
import { wrapResolutionValue } from './utils';

/**
 * Clones a part (called a node) of the component collection as well as recurvively cloning its strong
 * dependencies. Strong dependencies are defined as resourced owned by the node and not merely referenced.
 *
 * This function must be updated as the structure of the ComponentCollection changes.
 */
export function cloneStrongDependencies(
  sourceConfig: ComponentCollection,
  targetConfig: ComponentCollection,
  node: ComponentConfigNode,
): string {
  const cloneInternal = partial(cloneStrongDependencies, sourceConfig, targetConfig);

  function cloneInlineValueSupplier(supplier: ValueSupplier): ValueSupplier | undefined {
    if (!supplier) {
      return undefined;
    }
    const { value, refs } = supplier;
    const [newRefs, mapping] = cloneRefsAndProvideMapping(refs);
    return {
      value: typeof value === 'string' ? replaceValueWithNewTags(value, mapping) : value,
      refs: newRefs,
    };
  }

  function cloneRefsAndProvideMapping(
    originalRefs: string[] = [],
  ): [newRefs: string[], mapping: Record<string, string>] {
    const lookup: Record<string, string> = {};

    const newRefs = originalRefs.map((oldId) => {
      const newRefId = cloneInternal({
        type: ComponentConfigNodeType.Ref,
        id: oldId,
      });
      lookup[oldId] = newRefId;
      return newRefId;
    });

    return [newRefs, lookup];
  }

  switch (node.type) {
    case ComponentConfigNodeType.Ref: {
      const newRef: RefPath = { ...sourceConfig.refs[node.id] };
      if (newRef.type === RefType.DatasourceQueries || newRef.type === RefType.DatasourceWorklet) {
        // References to data source bindings are strong
        newRef.instanceId = cloneInternal({
          type: ComponentConfigNodeType.Datasource,
          id: newRef.instanceId,
        });
      }
      const newRefId = nanoid(7);
      targetConfig.refs[newRefId] = newRef;
      return newRefId;
    }
    case ComponentConfigNodeType.ParamMap: {
      const sourceParamMap = sourceConfig.params[node.id];

      // First, duplicate refs
      const [newRefs, mapping] = cloneRefsAndProvideMapping(sourceParamMap.refs);

      const newParamMapId = nanoid(7);

      // Then clone each param, using new ref ids
      targetConfig.params[newParamMapId] = {
        refs: newRefs,
        map: mapValues(
          sourceParamMap.map,
          ({ value, refs }): ValueSupplier => ({
            value: replaceValueWithNewTags(value, mapping),
            refs: refs?.map((refId) => mapping[refId]),
          }),
        ),
      };
      return newParamMapId;
    }
    case ComponentConfigNodeType.Datasource: {
      const source = sourceConfig.datasources[node.id];
      const newId = generateId(source.type);
      targetConfig.datasources[newId] = {
        ...source,
        paramRef:
          source.paramRef &&
          cloneInternal({ type: ComponentConfigNodeType.ParamMap, id: source.paramRef }),
      };
      return newId;
    }
    case ComponentConfigNodeType.Variable: {
      throw new Error('not implemented');
    }
    case ComponentConfigNodeType.Action: {
      const sourceAction = sourceConfig.actions[node.id];
      const newId = nanoid(7);

      if (sourceAction?.type === undefined) {
        // Special case empty actions since they screw up the type inference
        targetConfig.actions[newId] = { type: undefined };
        return newId;
      }
      const newAction: Exclude<ActionTypes.Action, ActionTypes.EmptyAction> = { ...sourceAction };

      // Handle each action field that references another node or is an inline value supplier. In this case, a shallow copy is not enough
      if (
        newAction.type === ActionTypes.ActionType.SetState ||
        newAction.type === ActionTypes.ActionType.ControlComponent ||
        newAction.type === ActionTypes.ActionType.CopyToClipboard
      ) {
        newAction.value = cloneInlineValueSupplier(
          (
            sourceAction as
              | ActionTypes.SetStateAction
              | ActionTypes.ControlComponentAction
              | ActionTypes.CopyToClipboard
          ).value,
        );
      }

      if (
        newAction.type === ActionTypes.ActionType.TriggerQuery ||
        newAction.type === ActionTypes.ActionType.RunWorklet ||
        newAction.type === ActionTypes.ActionType.GoToView
      ) {
        newAction.paramRef = cloneInternal({
          type: ComponentConfigNodeType.ParamMap,
          id: (
            sourceAction as
              | ActionTypes.TriggerQueryAction
              | ActionTypes.RunWorkletAction
              | ActionTypes.GoToViewAction
          ).paramRef,
        });
      }

      if (newAction.type === ActionTypes.ActionType.GoToUrl) {
        newAction.url = cloneInlineValueSupplier((sourceAction as ActionTypes.GoToUrlAction).url);
      }

      if (newAction.type === ActionTypes.ActionType.ShowNotification) {
        newAction.message = cloneInlineValueSupplier(
          (sourceAction as ActionTypes.ShowNotificationAction).message,
        );
      }

      targetConfig.actions[newId] = newAction;
      return newId;
    }
    case ComponentConfigNodeType.ValueSupplier: {
      const newId = generateId(node.subType);
      targetConfig.valueSuppliers[newId] = cloneInlineValueSupplier(
        sourceConfig.valueSuppliers[node.id],
      );
      return newId;
    }
    case ComponentConfigNodeType.ResolvedData: {
      const newId = generateId(node.subType);
      targetConfig.resolvedData[newId] = wrapResolutionValue(null);
      return newId;
    }
    default: {
      unreachable(node);
    }
  }
}
