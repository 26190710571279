import { ApiKeyType } from '@/graphql';
import {
  OrgApiKeysDocument,
  OrgApiKeysQuery,
  UserApiKeysDocument,
  UserApiKeysQuery,
} from '@/sections/APIKeys/__generated__/queries.generated';
import { InMemoryCache } from '@apollo/client';

import {
  CreateUserApiKeyMutationHookResult,
  CreateWorkspaceApiKeyMutationHookResult,
  useCreateUserApiKeyMutation as useBaseCreateUserApiKeyMutation,
  useCreateWorkspaceApiKeyMutation as useBaseCreateWorkspaceApiKeyMutation,
} from './__generated__/mutations.generated';
import { ModelNamesDocument } from './__generated__/queries.generated';

function useCreateUserApiKeyMutation(): CreateUserApiKeyMutationHookResult {
  return useBaseCreateUserApiKeyMutation({
    update: (cache: InMemoryCache, { data }) => {
      const newApiKey: UserApiKeysQuery['userApiKeys'][number] = {
        __typename: 'UserAPIKeyType',
        id: data.createUserApiKey.id,
        created: data.createUserApiKey.created,
        revoked: false,
        prefix: data.createUserApiKey.prefix,
        lastUsed: null,
        allowedOracles: [],
        apiKeyType: ApiKeyType.Personal,
      };
      const query = cache.readQuery({ query: UserApiKeysDocument });
      const userApiKeys = query?.userApiKeys || [];

      cache.writeQuery({
        query: UserApiKeysDocument,
        data: {
          userApiKeys: [...userApiKeys, newApiKey],
        },
      });
    },
  });
}

function useCreateWorkspaceApiKeyMutation(): CreateWorkspaceApiKeyMutationHookResult {
  return useBaseCreateWorkspaceApiKeyMutation({
    update: (cache: InMemoryCache, { data }, { variables, context }) => {
      const modelNamesData = cache.readQuery({ query: ModelNamesDocument });

      const newApiKey: OrgApiKeysQuery['orgApiKeys'][number] = {
        __typename: 'UserAPIKeyType',
        id: data.createWorkspaceApiKey.id,
        created: data.createWorkspaceApiKey.created,
        revoked: false,
        prefix: data.createWorkspaceApiKey.prefix,
        lastUsed: null,
        allowedOracles:
          modelNamesData?.models.filter(({ id }) => variables.allowedModelIds?.includes(id)) || [],
        apiKeyType: variables.apiKeyType as ApiKeyType,
      };
      const query = cache.readQuery({ query: OrgApiKeysDocument });
      const workspaceApiKeys = query?.orgApiKeys || [];

      cache.writeQuery({
        query: OrgApiKeysDocument,
        data: {
          orgApiKeys: [...workspaceApiKeys, newApiKey],
        },
      });
    },
  });
}

export { useCreateUserApiKeyMutation, useCreateWorkspaceApiKeyMutation };
