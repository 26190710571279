import { useLocation } from 'react-router';

import { Button, Divider, Stack } from '@mui/material';

import { links } from '@/localization';

function LoggedOut() {
  const location = useLocation();

  return (
    <Stack direction="row" gap={1}>
      <Stack direction="row">
        <Button variant="text" href={links.basetenBlog} target="_blank">
          Blog
        </Button>
        <Button variant="text" href={links.basetenDocs} target="_blank">
          Docs
        </Button>
      </Stack>
      <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
      <Stack direction="row" gap={1}>
        <Button
          data-cy="explore-signin"
          href={`/login?next=${location.pathname}`}
          variant="text"
          color="primary"
        >
          Sign in
        </Button>
        <Button
          href={`/signup?next=${location.pathname}`}
          color="primary"
          data-cy="explore-signup"
          variant="contained"
        >
          Deploy now
        </Button>
      </Stack>
    </Stack>
  );
}

export default LoggedOut;
