import { createContext, useContext } from 'react';

import { ReleaseEnv } from '@/types/releaseEnv';

export const ReleaseEnvContext = createContext<ReleaseEnv | undefined>(undefined);

export default function useReleaseEnv(): ReleaseEnv {
  const releaseEnv = useContext(ReleaseEnvContext);
  if (!releaseEnv) {
    throw new Error('Called `useReleaseEnv()` in a context without a ReleaseEnv');
  }
  return releaseEnv;
}
