enum RefType {
  ComponentProp = 'props',
  ComponentField = 'fields',
  ComponentState = 'states',
  DatasourceWorklet = 'worklets',
  DatasourceQueries = 'queries',
  Variables = 'variables',
  URLParameter = 'urlParam',
}

type PropPath = {
  type: RefType.ComponentProp;
  componentId: string;
  propId: string;
  accessor?: string;
};

type FieldPath = {
  type: RefType.ComponentField;
  componentId: string;
  fieldId: string;
  accessor?: string;
};

type StatePath = {
  type: RefType.ComponentState;
  collectionId?: string;
  stateId: string;
  accessor?: string;
};

type WorkletPath = {
  type: RefType.DatasourceWorklet;
  instanceId: string;
  workletId: string;
  accessor?: string;
};

type QueryPath = {
  type: RefType.DatasourceQueries;
  instanceId: string;
  queryId: string;
  accessor?: string;
};

type VarPath = {
  type: RefType.Variables;
  /**
   * @deprecated use `varId` moving forward
   */
  name?: string;
  varId: string;
  accessor?: string;
};

type URLParameterPath = {
  type: RefType.URLParameter;
  paramId: string;
  accessor?: string;
};

type RefPath =
  | PropPath
  | FieldPath
  | StatePath
  | WorkletPath
  | QueryPath
  | VarPath
  | URLParameterPath;

type RefMap = Record<string, RefPath>;

export { RefType, RefPath, RefMap };
