import { Link, Stack, Typography } from '@mui/material';

import BrandMarkIcon from '@/components/Icons/BrandMarkIcon';
import { links } from '@/localization';

import { AuthCard } from '../styled';
import { CardProps } from './types';

function Card({ title, contained, footer, children, withBranding }: CardProps) {
  return (
    <Stack direction="column" alignItems="center" spacing={4}>
      <AuthCard contained={contained}>
        <Stack direction="column" alignItems="center" spacing={4}>
          {withBranding && (
            <Link href={links.basetenHome} color="text.primary">
              <BrandMarkIcon color="success" sx={{ width: 'auto', height: 40 }} />
            </Link>
          )}
          <Typography variant="h2">{title}</Typography>
          <Stack sx={{ width: '100%' }} direction="column" spacing={1.5}>
            {children}
          </Stack>
        </Stack>
      </AuthCard>
      {footer && (
        <Typography color="text.muted" variant="body1">
          {footer}
        </Typography>
      )}
    </Stack>
  );
}

export default Card;
