import { CanvasSize } from './Canvas/types';

const GRID_SIZE_PX = 18;
const MIN_GRID_HEIGHT = 42;
const PANEL_WIDTH = 300;
const DROP_CANVAS_ID = 'canvas-drop';
const CANVAS_ROOT = '__ROOT';
const CANVAS_SIZE_MATRIX: Record<CanvasSize, number> = {
  [CanvasSize.Small]: 40,
  [CanvasSize.Medium]: 52,
  [CanvasSize.Large]: 64,
};
const EXTENDED_GRID_DELTA = 100;
const TAG_PREFIX = 't::';
const OPEN_TAG = '{{';
const CLOSE_TAG = '}}';
const DOT_NOTATION = '.';
const OPEN_TAG_WITH_PREFIX = `${OPEN_TAG}${TAG_PREFIX}`;
const CANVAS_EVENTS = ['onLoad'];
// TODO: should be generated
const DUMMY_MARKDOWN = `**Lorem ipsum dolor sit amet, consectetur adipiscing elit.**

\`Nulla consequat lectus libero, id pulvinar elit ultrices nec.\`
#### Phasellus pharetra et dolor gravida bibendum.

1. Phasellus dictum gravida lectus in facilisis.
2. Integer eleifend rhoncus dictum.
3. Nam eget diam quis eros consequat tempus.

Phasellus nec nulla nulla. Quisque vel arcu eget magna hendrerit consectetur. Suspendisse luctus lectus.
`;
const BASETEN_ENV_REQUEST_HEADER_KEY = 'X-BASETEN-ENV';

const CONTEXT_ACTION_VARIANTS = [
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
] as const;

export {
  GRID_SIZE_PX,
  MIN_GRID_HEIGHT,
  PANEL_WIDTH,
  DROP_CANVAS_ID,
  CANVAS_ROOT,
  CANVAS_SIZE_MATRIX,
  TAG_PREFIX,
  OPEN_TAG,
  CLOSE_TAG,
  DOT_NOTATION,
  OPEN_TAG_WITH_PREFIX,
  CANVAS_EVENTS,
  DUMMY_MARKDOWN,
  BASETEN_ENV_REQUEST_HEADER_KEY,
  CONTEXT_ACTION_VARIANTS,
  EXTENDED_GRID_DELTA,
};
