import { FC } from 'react';

import Loading from '@/components/Loading';

import asyncComponentLoader from './loader';
import { LoadComponent, LoaderDefaultOptions } from './types';

// TODO (Suren): create a config file and move this object to config
const loaderDefaultOptions: LoaderDefaultOptions = {
  delay: 500, // if your asynchronous process is finished during 500 milliseconds you will not see the loader at all
  minimumLoading: 1000, // but if it appears, it will stay for at least 1000 milliseconds
};

function configuredAsyncComponentLoader<P extends {} = {}>(
  loadComponent: LoadComponent<P>,
  additionalProps: Partial<P> = {},
  loaderOptions = loaderDefaultOptions,
  // Need type assertion because Loading takes optional `text` prop while FC<{}> takes optional `children`
  FallbackWaiting = Loading as FC<{}>,
) {
  return asyncComponentLoader<P>(loadComponent, additionalProps, loaderOptions, FallbackWaiting);
}

export { loaderDefaultOptions };
export default configuredAsyncComponentLoader;
