import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createWorkflowOnBackend,
  updateWorkflowOnBackend,
} from '../../../actions/WorkflowsAsyncActions';
import { ThunkAPI } from '../../../types';
import { fetchOperatorViewById } from '../Views';
import { BuilderVersion, OperatorViewWithEnv } from '../Views/types';
import { deleteWorkflow, updateWorkflow } from '../Workflows';
import { SwitchGitSyncDocument } from '../Workflows/__generated__/mutations.generated';
import { WorkflowIdentifier } from '../Workflows/types';
import { UpdateWorkflowMetadataPayload, WorkflowMetadata, WorkflowMetadataMap } from './types';

const initialState: WorkflowMetadataMap = {};

export const workflowMetadataFromOperatorView = (
  response: OperatorViewWithEnv,
): WorkflowMetadata => ({
  created: new Date(),
  modified: new Date(),
  id: response.workflowId,
  isStarterApp: response.isStarterApp,
  name: response.workflowName,
  lastDeployedToProductionAt: null,
  description: '',
  shouldShowNav: response.shouldShowNav,
  builderVersion: BuilderVersion.Initial,
  hasUndeployedChanges: false,
  isGitSynced: false,
});

export const workflowMetadataFromResponse = (response: any): WorkflowMetadata => {
  return {
    created: new Date(response.created),
    modified: new Date(response.modified),
    id: response.id,
    isStarterApp: response.isStarterApp,
    shouldShowNav: response.shouldShowNav,
    name: response.name,
    description: response.description,
    builderVersion: response.builderVersion,
    hasUndeployedChanges: response.hasUndeployedChanges,
    lastDeployedToProductionAt:
      response.lastDeployedToProductionAt && new Date(response.lastDeployedToProductionAt),
    isGitSynced: response.isGitSynced,
    gitHubRepository: response.gitHubRepository,
    operatorStartingView: response.startingView,
  };
};

export const switchGitSync = createAsyncThunk<
  { workflowId: string; isGitSynced: boolean },
  WorkflowIdentifier & { name: string; isGitSynced: boolean },
  ThunkAPI
>('workflow-metadata/switchGitSync', async ({ workflowId, name, isGitSynced }, { extra }) => {
  await extra.apolloClient.mutate({
    mutation: SwitchGitSyncDocument,
    variables: {
      workflowId,
      name,
      isGitSynced,
    },
  });

  return { workflowId, isGitSynced };
});

const slice = createSlice({
  initialState,
  name: 'workflow-metadata',
  reducers: {
    updateWorkflowMetadata(
      state,
      {
        payload: {
          entities: { workflows },
          workflowIdentifier: { workflowId, releaseEnv },
        },
      }: PayloadAction<UpdateWorkflowMetadataPayload>,
    ) {
      state[workflowId] = workflowMetadataFromResponse(workflows[workflowId]);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOperatorViewById.fulfilled, (state, { payload }) => {
        state[payload.workflowId] = workflowMetadataFromOperatorView(payload);
      })
      .addCase(
        updateWorkflow,
        (
          state,
          {
            payload: {
              entities: { workflows },
              workflowIdentifier: { workflowId },
            },
          },
        ) => {
          state[workflowId] = workflowMetadataFromResponse(workflows[workflowId]);
        },
      )
      .addCase(createWorkflowOnBackend.fulfilled, (state, { payload }) => {
        state[payload.id] = payload;
      })
      .addCase(switchGitSync.fulfilled, (state, { payload: { workflowId, isGitSynced } }) => {
        state[workflowId].isGitSynced = isGitSynced;
      })
      .addCase(updateWorkflowOnBackend.fulfilled, (state, { meta: { arg } }) => {
        state[arg.workflowId].name = arg.newName;
        state[arg.workflowId].description = arg.newDescription;
      })
      .addCase(deleteWorkflow.fulfilled, (state, action) => {
        delete state[action.meta.arg.workflowId];
      });
  },
});

export const { updateWorkflowMetadata } = slice.actions;

export default slice.reducer;
