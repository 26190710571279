import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import { RadioButton } from '@/components/RadioButton';

import { Input } from './styled';
import type { OtherProps } from './types';

function Other({ name, selected }: OtherProps) {
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  function handleInputBlur() {
    setShowWarning(!value);
  }

  function handleInputChange(ev: ChangeEvent<HTMLInputElement>) {
    setShowWarning(!ev.target.value);
    setValue(ev.target.value);
  }

  useEffect(() => {
    inputRef.current?.focus();

    if (selected) {
      setShowWarning(false);
    }
  }, [selected]);

  return (
    <RadioButton alert={showWarning} value="other">
      <Stack direction="row" justifyContent="center">
        <Typography sx={{ mr: value || selected ? 1 : 0 }}>Other</Typography>
        {selected && (
          <Input
            inputRef={inputRef}
            name={name}
            onBlur={handleInputBlur}
            defaultValue={value}
            onChange={handleInputChange}
          />
        )}
      </Stack>
    </RadioButton>
  );
}

export default Other;
