import { LoadingButtonProps } from '@mui/lab';

type GenerateAPIKeyProps = {
  buttonProps: LoadingButtonProps & {
    'data-cy'?: string;
  };
  onCreate?: (apiKey: string) => void;
  createOnlyPersonalKey?: boolean;
};

enum MainOptionsEnum {
  Personal = 'Personal',
  Workspace = 'Workspace',
}

enum PermissionOptionsEnum {
  InvokeAllModels = 'InvokeAllModels',
  CanInvokeModels = 'CanInvokeModels',
  ExportMetrics = 'ExportMetrics',
}

export { MainOptionsEnum, PermissionOptionsEnum };
export type { GenerateAPIKeyProps };
