/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ApiKeyFragmentDoc, AllowedModelsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserApiKeysQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserApiKeysQuery = { __typename?: 'QueryPrediction', userApiKeys: Array<{ __typename?: 'UserAPIKeyType', id: string | null | undefined, prefix: string, revoked: boolean, created: any, lastUsed: any | null | undefined, apiKeyType: Types.ApiKeyType | null | undefined, allowedOracles: Array<{ __typename?: 'ModelType', id: string, name: string | null | undefined }> } | null | undefined> | null | undefined };

export type OrgApiKeysQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgApiKeysQuery = { __typename?: 'QueryPrediction', orgApiKeys: Array<{ __typename?: 'UserAPIKeyType', id: string | null | undefined, prefix: string, revoked: boolean, created: any, lastUsed: any | null | undefined, apiKeyType: Types.ApiKeyType | null | undefined, allowedOracles: Array<{ __typename?: 'ModelType', id: string, name: string | null | undefined }> } | null | undefined> | null | undefined };


export const UserApiKeysDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserApiKeys"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"userApiKeys"},"name":{"kind":"Name","value":"user_api_keys"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"APIKey"}}]}}]}},...ApiKeyFragmentDoc.definitions,...AllowedModelsFragmentDoc.definitions]} as unknown as DocumentNode<UserApiKeysQuery, UserApiKeysQueryVariables>;
export const OrgApiKeysDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OrgApiKeys"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"orgApiKeys"},"name":{"kind":"Name","value":"org_api_keys"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"APIKey"}}]}}]}},...ApiKeyFragmentDoc.definitions,...AllowedModelsFragmentDoc.definitions]} as unknown as DocumentNode<OrgApiKeysQuery, OrgApiKeysQueryVariables>;
export const namedOperations = {
  Query: {
    UserApiKeys: 'UserApiKeys',
    OrgApiKeys: 'OrgApiKeys'
  }
}

/**
 * __useUserApiKeysQuery__
 *
 * To run a query within a React component, call `useUserApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<UserApiKeysQuery, UserApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserApiKeysQuery, UserApiKeysQueryVariables>(UserApiKeysDocument, options);
      }
export function useUserApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserApiKeysQuery, UserApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserApiKeysQuery, UserApiKeysQueryVariables>(UserApiKeysDocument, options);
        }
export type UserApiKeysQueryHookResult = ReturnType<typeof useUserApiKeysQuery>;
export type UserApiKeysLazyQueryHookResult = ReturnType<typeof useUserApiKeysLazyQuery>;
export type UserApiKeysQueryResult = Apollo.QueryResult<UserApiKeysQuery, UserApiKeysQueryVariables>;
export function refetchUserApiKeysQuery(variables?: UserApiKeysQueryVariables) {
      return { query: UserApiKeysDocument, variables: variables }
    }
/**
 * __useOrgApiKeysQuery__
 *
 * To run a query within a React component, call `useOrgApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<OrgApiKeysQuery, OrgApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgApiKeysQuery, OrgApiKeysQueryVariables>(OrgApiKeysDocument, options);
      }
export function useOrgApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgApiKeysQuery, OrgApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgApiKeysQuery, OrgApiKeysQueryVariables>(OrgApiKeysDocument, options);
        }
export type OrgApiKeysQueryHookResult = ReturnType<typeof useOrgApiKeysQuery>;
export type OrgApiKeysLazyQueryHookResult = ReturnType<typeof useOrgApiKeysLazyQuery>;
export type OrgApiKeysQueryResult = Apollo.QueryResult<OrgApiKeysQuery, OrgApiKeysQueryVariables>;
export function refetchOrgApiKeysQuery(variables?: OrgApiKeysQueryVariables) {
      return { query: OrgApiKeysDocument, variables: variables }
    }