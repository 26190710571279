import { PropertyType, Resource, ResourceCategory } from '../types';
import { RefPath, RefType } from './types';

function buildRefPathToResource({ category, type, path, accessor }: Resource): RefPath {
  switch (type) {
    case PropertyType.Field:
      return {
        type: RefType.ComponentField,
        componentId: path[0],
        fieldId: path[1],
        accessor,
      };
    case PropertyType.Prop:
      return {
        type: RefType.ComponentProp,
        componentId: path[0],
        propId: path[1],
        accessor,
      };
    case PropertyType.Self:
      if (category === ResourceCategory.Worklet) {
        return {
          type: RefType.DatasourceWorklet,
          instanceId: path[0],
          workletId: path[1],
          accessor,
        };
      }
      if (category === ResourceCategory.Query) {
        return {
          type: RefType.DatasourceQueries,
          instanceId: path[0],
          queryId: path[1],
          accessor,
        };
      }
      if (category === ResourceCategory.State) {
        return {
          type: RefType.ComponentState,
          stateId: path[0],
          accessor,
        };
      }
      if (category === ResourceCategory.URLParameter) {
        return {
          type: RefType.URLParameter,
          paramId: path[0],
        };
      }
      if (category === ResourceCategory.Variable) {
        return {
          type: RefType.Variables,
          varId: path[0],
          accessor,
        };
      }
      break;
    default:
      return null; // unable to build a ref path for this resource
  }
}

function getPathToSupplierId(refPath: RefPath): string[] {
  switch (refPath.type) {
    case RefType.ComponentProp:
      return ['children', refPath.componentId, 'props', refPath.propId];
    case RefType.ComponentField:
      return ['children', refPath.componentId, 'fields', refPath.fieldId];
    case RefType.ComponentState:
      return ['states', refPath.stateId, 'supplierId'];
    case RefType.DatasourceWorklet:
      return ['datasources', refPath.instanceId, 'id'];
    case RefType.DatasourceQueries:
      return ['datasources', refPath.instanceId, 'id'];
    case RefType.URLParameter:
      return ['urlParams', refPath.paramId];
    case RefType.Variables:
      return ['vars', refPath.varId, 'id'];
    default:
      return [];
  }
}

export { buildRefPathToResource, getPathToSupplierId };
