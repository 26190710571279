import { Typography } from '@mui/material';

import TerminalIcon from '@/components/Icons/TerminalIcon';

import { InstructionStep } from '../types';
import TerminalLine from './TerminalLine';
import { Bash, Container, Header } from './styled';

function Shell() {
  return (
    <Container>
      <Header direction="row" alignItems="center" gap={1}>
        <TerminalIcon color="secondary" />
        <Typography variant="mono2">Terminal</Typography>
      </Header>
      <TerminalLine instruction={InstructionStep.INSTALL_TRUSS}>
        <Bash>$</Bash>
        <Typography variant="mono2">pip install --upgrade truss</Typography>
      </TerminalLine>
      <TerminalLine multiline instruction={InstructionStep.PACKAGE_MODEL}>
        <Bash>$</Bash>
        <Typography variant="mono2">truss init ./model-name</Typography>
      </TerminalLine>
      <TerminalLine instruction={InstructionStep.PACKAGE_MODEL}>
        <Bash>$</Bash>
        <Typography variant="mono2">cd ./model-name</Typography>
      </TerminalLine>
      <TerminalLine instruction={InstructionStep.DEPLOY_MODEL}>
        <Bash>$</Bash>
        <Typography variant="mono2">truss push</Typography>
      </TerminalLine>
      <TerminalLine instruction={InstructionStep.API_KEY}>
        <Typography variant="mono2">
          🤫 Quietly paste your API key *************************
        </Typography>
      </TerminalLine>
      <TerminalLine isOutput instruction={InstructionStep.DEPLOY_MODEL}>
        <Typography variant="mono2">
          Compressing... ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ 100% 0:00:00
        </Typography>
        <Typography variant="mono2">
          Uploading... ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ 100% 0:00:00
        </Typography>
        <Typography variant="mono2">Your model was successfully pushed. 🚀</Typography>
        <Typography variant="mono2">
          👩‍💻 View your model here: http://app.baseten.co/models/Q04L9n0/
        </Typography>
        <Typography variant="mono2">
          🪵 View logs for your deployment at
          http://app.baseten.co/models/Q04L9n0/versions/gyqvxw8/logs
        </Typography>
        <Typography variant="mono2">🗂 or open logs in a new tab? Yes / No</Typography>
      </TerminalLine>
      <TerminalLine multiline instruction={InstructionStep.INVOKE_MODEL}>
        <Bash>$</Bash>
        <Typography variant="mono2">
          truss predict --model 4w572r32 -d &apos;&quot;howdy&quot;&apos;
        </Typography>
      </TerminalLine>
      <TerminalLine isOutput instruction={InstructionStep.INVOKE_MODEL}>
        <Typography whiteSpace="pre-wrap" variant="mono2">
          &quot;howdy&quot;
        </Typography>
      </TerminalLine>
      <TerminalLine instruction={InstructionStep.WATCH_MODEL}>
        <Bash>$</Bash>
        <Typography variant="mono2">truss watch </Typography>
      </TerminalLine>
    </Container>
  );
}

export default Shell;
