import { FlexBox } from '@/components/styled';
import { defaultPalette } from '@/theme';
import styled from 'styled-components';

const Container = styled(FlexBox)`
  height: 100%;
  background: ${defaultPalette.background.default};
  position: relative;

  .gutter {
    background: rgb(240, 240, 240);
    cursor: row-resize;
  }
`;

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export { Container, Page };
