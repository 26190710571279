import { Reducer } from 'redux';

import isEmpty from 'lodash/isEmpty';

import ActionType, { RpcActionPrefix } from '../actions/ActionType';
import { actionToNodeOutput, actionToWorkletOutput, rpcErrorState } from './ConsoleViewReducer';
import { NodeInvocation } from './state/NodeHistory';

export const CONSOLE_HEIGHTS = Object.freeze({
  MINIMUM_HEIGHT: 46,
  SET_HEIGHT: 400,
});

export const EDITOR_HEIGHTS = Object.freeze({
  MINIMUM_HEIGHT: 46,
  SET_HEIGHT: 300,
});

interface AddChild {
  type?: string;
  parent?: string;
  edge?: string;
}

interface ConsoleState {
  height: number;
  isFullscreen: boolean;
}

interface EditorState {
  height: number;
  isFullscreen: boolean;
}

interface WorkletViewState {
  graph: any;
  showPanel: boolean;
  showWorklet: boolean;
  selectedGroup?: string;
  nodesToGroup: Array<string>;
  selectedNodeId?: string;
  addingChildTo?: AddChild;
  workletId?: string;
  consoleState: ConsoleState;
  editorState: EditorState;
  lastWorkletInputs: Record<string, string>;
  lastWorkletOutputs: Record<string, string>;
  lastNodeInputs: Record<string, string>;
  lastNodeOutputs: Record<string, string>;
  nodeHistory: Record<string, Array<NodeInvocation>>;
  lastNodeHistorySelection: Record<string, string>;
  sampleWorkletInput: string;
  sampleNodeInput: string;
  outputData?: string;
}

const workletViewInit: WorkletViewState = {
  graph: {},
  showPanel: false,
  showWorklet: false,
  selectedNodeId: null,
  workletId: null,
  addingChildTo: null,
  selectedGroup: null,
  nodesToGroup: [],
  consoleState: {
    height: CONSOLE_HEIGHTS.SET_HEIGHT,
    isFullscreen: false,
  },
  editorState: {
    height: EDITOR_HEIGHTS.SET_HEIGHT,
    isFullscreen: false,
  },
  lastWorkletInputs: {},
  lastWorkletOutputs: {},
  lastNodeInputs: {},
  lastNodeOutputs: {},
  nodeHistory: {},
  lastNodeHistorySelection: {},
  sampleWorkletInput: null,
  sampleNodeInput: null,
  outputData: null,
};

const workletViewReducer: Reducer<WorkletViewState, any> = (
  state = workletViewInit,
  action: any,
) => {
  switch (action.type) {
    case ActionType.SET_GRAPH:
      return {
        ...state,
        graph: action.graph,
      };
    case ActionType.CLEAR_WORKLET:
      return {
        ...workletViewInit,
      };
    case ActionType.SET_WORKLET_ID:
      return {
        ...state,
        workletId: action.workletId,
        addingChildTo: null,
      };
    case ActionType.SELECT_NOTHING:
      return {
        ...state,
        showPanel: false,
        addingChildTo: null,
      };
    case ActionType.SHOW_PANEL:
      return {
        ...state,
        showPanel: true,
      };
    case ActionType.SELECT_WORKLET:
      return {
        ...state,
        showWorklet: true,
        showPanel: true,
        selectedGroup: null,
        selectedNodeId: null,
        addingChildTo: null,
      };
    case ActionType.MINIMIZE_NODE_SRC_EDITOR:
      return {
        ...state,
      };
    case ActionType.EDIT_NODE_SRC:
      return {
        ...state,
        showPanel: true,
      };
    case ActionType.SELECT_NODE:
      return {
        ...state,
        showWorklet: false,
        showPanel: true,
        selectedNodeId: action.nodeId,
        addingChildTo: null,
        selectedGroup: null,
      };
    case ActionType.SELECT_GROUP:
      return {
        ...state,
        showWorklet: false,
        showPanel: true,
        selectedNodeId: null,
        addingChildTo: null,
        selectedGroup: action.group,
      };
    case ActionType.SELECT_NODE_TO_GROUP:
      return {
        ...state,
        nodesToGroup: action.nodesToGroup,
      };
    case ActionType.CLEAR_NODES_TO_GROUP:
      return {
        ...state,
        nodesToGroup: [],
      };
    case ActionType.ADD_CHILD_TO:
      return {
        ...state,
        showPanel: true,
        selectedGroup: null,
        addingChildTo: {
          id: action.addingChildTo.id,
          type: action.addingChildTo.type,
          edge: action.addingChildTo.edge,
        },
      };
    case ActionType.GROUP_SELECTED_NODES:
      if (isEmpty(action.groupName)) {
        return {
          ...state,
          selectedGroup: null,
        };
      }

      return state;
    case ActionType.SET_CONSOLE_HEIGHT:
      return {
        ...state,
        consoleState: {
          ...state.consoleState,
          height: action.height,
        },
      };
    case ActionType.SET_CONSOLE_FULLSCREEN:
      return {
        ...state,
        consoleState: {
          ...state.consoleState,
          isFullscreen: action.isFullscreen,
        },
      };
    case ActionType.SET_EDITOR_HEIGHT:
      return {
        ...state,
        editorState: {
          ...state.editorState,
          height: action.height,
        },
      };
    case ActionType.SET_EDITOR_FULLSCREEN:
      return {
        ...state,
        editorState: {
          ...state.editorState,
          isFullscreen: action.isFullscreen,
        },
      };
    case ActionType.SET_LAST_WORKLET_INPUT:
      const newLastWorkletInputs = {
        ...state.lastWorkletInputs,
        [action.workletId]: action.lastWorkletInput,
      };
      return {
        ...state,
        lastWorkletInputs: newLastWorkletInputs,
      };
    case `${RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING}.Done`:
    case `${RpcActionPrefix.EXECUTE_WORKLET}.Done`: {
      const message = actionToWorkletOutput(action);
      const newLastWorkletOutput = {
        ...state.lastWorkletOutputs,
        [action.meta.workletId]: message,
      };
      return {
        ...state,
        lastWorkletOutputs: newLastWorkletOutput,
      };
    }
    case `${RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING}.Failed`:
    case `${RpcActionPrefix.EXECUTE_WORKLET}.Failed`:
      const rpcWorkletError = rpcErrorState(state, action, 'Error executing worklet');
      const newLastWorkletOutput = {
        ...state.lastWorkletOutputs,
        [action.meta.workletId]: rpcWorkletError.text,
      };
      return {
        ...state,
        lastWorkletOutputs: newLastWorkletOutput,
      };
    case ActionType.SET_LAST_NODE_INPUT:
      const newLastNodeInputs = {
        ...state.lastNodeInputs,
        [action.nodeId]: action.lastNodeInput,
      };
      return {
        ...state,
        lastNodeInputs: newLastNodeInputs,
      };
    case `${RpcActionPrefix.EXECUTE_NODE}.Done`: {
      const nodeOutput = actionToNodeOutput(action);
      const newLastNodeOutputs = {
        ...state.lastNodeOutputs,
        [action.meta.nodeId]: nodeOutput,
      };
      return {
        ...state,
        lastNodeOutputs: newLastNodeOutputs,
      };
    }
    case `${RpcActionPrefix.EXECUTE_NODE}.Failed`:
      const rpcNodeError = rpcErrorState(state, action, 'Node execution failed');
      const newLastNodeOutputs = {
        ...state.lastNodeOutputs,
        [action.meta.nodeId]: rpcNodeError.text,
      };
      return {
        ...state,
        lastNodeOutputs: newLastNodeOutputs,
      };
    case ActionType.NODE_HISTORY_FETCH:
      const newNodeHistory = {
        ...state.nodeHistory,
        [action.nodeId]: action.nodeInvocations,
      };
      return {
        ...state,
        nodeHistory: newNodeHistory,
      };
    case ActionType.SET_NODE_HISTORY_SELECTION:
      const newNodeHistorySelection = {
        ...state.lastNodeHistorySelection,
        [action.nodeId]: action.invocationId,
      };
      return {
        ...state,
        lastNodeHistorySelection: newNodeHistorySelection,
      };
    case ActionType.SET_SAMPLE_WORKLET_INPUT:
      return {
        ...state,
        sampleWorkletInput: action.input,
      };
    case ActionType.SET_SAMPLE_NODE_INPUT:
      return {
        ...state,
        sampleNodeInput: action.input,
      };
    case ActionType.GET_WORKLET_RUN_OUTPUT_DATA:
      return {
        ...state,
        outputData: action.outputData,
      };
    default:
      return state;
  }
};

export default workletViewReducer;
