/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PreReleaseDataFragment = { __typename?: 'PreReleaseData', next_release_views: any | null | undefined, next_release_worklets: any | null | undefined, next_release_codefiles: any | null | undefined, next_release_git_repositories: any | null | undefined, next_release_pynode_requirements: any | null | undefined, next_release_semver_major: string | null | undefined, next_release_semver_minor: string | null | undefined, next_release_semver_patch: string | null | undefined };

export type ReleaseDeploymentFragment = { __typename?: 'ReleaseDeploymentType', id: string, status: Types.ReleaseDeploymentStatus, error_message: string | null | undefined, git_push: { __typename?: 'GitIntegrationPushType', id: string, status: Types.GitPushStatus } | null | undefined };

export type ImportedGitRepositoryFragment = { __typename?: 'ImportedGitRepositoryType', modified: any, id: string | null | undefined, status: string | null | undefined, branchName: string, commitSHA: string, repositoryFullName: string | null | undefined, githubURL: string | null | undefined, lastFailedAt: any | null | undefined };

export const PreReleaseDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PreReleaseDataFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PreReleaseData"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"next_release_views"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_worklets"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_codefiles"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_git_repositories"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_pynode_requirements"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_semver_major"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_semver_minor"}},{"kind":"Field","name":{"kind":"Name","value":"next_release_semver_patch"}}]}}]} as unknown as DocumentNode<PreReleaseDataFragment, unknown>;
export const ReleaseDeploymentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ReleaseDeploymentFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReleaseDeploymentType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"error_message"}},{"kind":"Field","name":{"kind":"Name","value":"git_push"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<ReleaseDeploymentFragment, unknown>;
export const ImportedGitRepositoryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ImportedGitRepository"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ImportedGitRepositoryType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"modified"}},{"kind":"Field","alias":{"kind":"Name","value":"branchName"},"name":{"kind":"Name","value":"branch_name"}},{"kind":"Field","alias":{"kind":"Name","value":"commitSHA"},"name":{"kind":"Name","value":"commit_sha"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"repositoryFullName"},"name":{"kind":"Name","value":"repository_full_name"}},{"kind":"Field","alias":{"kind":"Name","value":"githubURL"},"name":{"kind":"Name","value":"github_url"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","alias":{"kind":"Name","value":"lastFailedAt"},"name":{"kind":"Name","value":"last_failed_at"}}]}}]} as unknown as DocumentNode<ImportedGitRepositoryFragment, unknown>;
export const namedOperations = {
  Fragment: {
    PreReleaseDataFragment: 'PreReleaseDataFragment',
    ReleaseDeploymentFragment: 'ReleaseDeploymentFragment',
    ImportedGitRepository: 'ImportedGitRepository'
  }
}
