import { FC } from 'react';

import CloudIcon from '@mui/icons-material/Cloud';
import { SvgIconProps } from '@mui/material';

import DatabaseIcon from '@/components/Icons/DatabaseIcon';
import { ParamsFormElementType, StandardValidator } from '@/components/ParamsForm/FormElem';
import ParamsFormSpec from '@/components/ParamsForm/ParamsFormSpec';
import { ExternalConnectionConnectionType } from '@/graphql';

interface ConnectionSpec {
  type: ExternalConnectionConnectionType;
  displayName: string;
  icon: FC<SvgIconProps>;
  paramsFormSpec: ParamsFormSpec;
}

const BaseDatabaseFormSpec: ParamsFormSpec = new ParamsFormSpec(
  [
    { id: 'host', displayName: 'Host', placeholder: 'e.g. 127.0.0.1' },
    { id: 'port', displayName: 'Port', defaultValue: '5432', placeholder: 'e.g. 5432' },
    { id: 'database', displayName: 'Database name', placeholder: 'e.g. my_db' },
    { id: 'user', displayName: 'Database username', placeholder: 'e.g. postgres_user' },
    {
      id: 'password',
      displayName: 'Database password',
      placeholder: 'e.g. top-secret-password',
      validate: StandardValidator.Noop,
      type: ParamsFormElementType.password,
    },
  ],
  [['host', 'port'], ['database'], ['user', 'password']],
);

const PostgresFormSpec: ParamsFormSpec = BaseDatabaseFormSpec.addElem({
  id: 'usessl',
  displayName: 'Connect using SSL',
  type: ParamsFormElementType.bool,
  validate: StandardValidator.Noop,
})
  .addElem({
    id: 'selfsigned',
    displayName: 'Use a self-signed certificate',
    type: ParamsFormElementType.bool,
    validate: StandardValidator.Noop,
    dependsOn: ['usessl'],
  })
  .addElem({
    id: 'clientkey',
    displayName: 'Client Key (client-key.pem)',
    placeholder: 'Paste your client key here',
    type: ParamsFormElementType.textarea,
    validate: StandardValidator.Noop,
    dependsOn: ['usessl'],
  })
  .addElem({
    id: 'clientcert',
    displayName: 'Client Certificate (client-cert.pem)',
    placeholder: 'Paste your client certificate here',
    type: ParamsFormElementType.textarea,
    validate: StandardValidator.Noop,
    dependsOn: ['usessl', 'selfsigned'],
  })
  .addElem({
    id: 'serverca',
    displayName: 'Server Certificate (server-ca.pem)',
    placeholder: 'Paste your server certificate here',
    type: ParamsFormElementType.textarea,
    validate: StandardValidator.Noop,
    dependsOn: ['usessl', 'selfsigned'],
  })
  .replaceLayout([
    ['host', 'port'],
    ['database'],
    ['user', 'password'],
    ['usessl', 'selfsigned'],
    ['clientkey'],
    ['clientcert'],
    ['serverca'],
  ]);

export const PostgresSpec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.Postgres,
  displayName: 'Postgres',
  icon: DatabaseIcon,
  paramsFormSpec: PostgresFormSpec,
};

const MySqlFormSpec = BaseDatabaseFormSpec.replaceElemProperty('port', 'placeholder', 'e.g. 3306')
  .replaceElemProperty('port', 'defaultValue', '3306')
  .replaceElemProperty('database', 'name', 'database');

export const MySqlSpec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.Mysql,
  displayName: 'MySQL',
  icon: DatabaseIcon,
  paramsFormSpec: MySqlFormSpec,
};

export const SnowflakeSpec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.Snowflake,
  displayName: 'Snowflake',
  icon: DatabaseIcon,
  paramsFormSpec: new ParamsFormSpec(
    [
      { id: 'account', displayName: 'Account', placeholder: 'e.g. wsa01234' },
      { id: 'warehouse', displayName: 'Warehouse', placeholder: 'e.g. COMPUTE_WH' },
      { id: 'schema', displayName: 'Schema', placeholder: 'e.g. MY_SCHEMA' },
      { id: 'database', displayName: 'Database name', placeholder: 'e.g. my_db' },
      { id: 'user', displayName: 'Username', placeholder: 'e.g. snowflake_user' },
      {
        id: 'password',
        displayName: 'Password',
        placeholder: 'e.g. top-secret-password',
        validate: StandardValidator.Noop,
        type: ParamsFormElementType.password,
      },
    ],
    [['account'], ['user', 'password'], ['warehouse'], ['database'], ['schema']],
  ),
};

export const S3Spec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.S3,
  displayName: 'S3',
  icon: CloudIcon,
  paramsFormSpec: new ParamsFormSpec(
    [
      {
        id: 'access_key_id',
        displayName: 'Access Key Id',
        placeholder: 'e.g. AKIAIOSFODNN7EXAMPLE',
      },
      {
        id: 'secret_access_key',
        displayName: 'Secret access key',
        placeholder: 'e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY',
        validate: StandardValidator.Noop,
        type: ParamsFormElementType.password,
      },
    ],
    [['access_key_id'], ['secret_access_key']],
  ),
};

const RedisFormSpec = BaseDatabaseFormSpec.replaceElemProperty('port', 'placeholder', 'e.g. 6739')
  .dropElemById('database')
  .dropElemById('user')
  .replaceElemProperty('port', 'defaultValue', '6379')
  .addElem({
    id: 'db',
    displayName: 'Database',
    defaultValue: '0',
  })
  .addElem({
    id: 'username',
    displayName: 'Username',
    placeholder: 'default',
    defaultValue: 'default',
  })
  .addElem({
    id: 'ssl',
    displayName: 'TLS/SSL',
    defaultValue: false,
    type: ParamsFormElementType.bool,
    validate: StandardValidator.Noop,
  })
  .replaceLayout([['host', 'port'], ['db'], ['username', 'password'], ['ssl']]);

export const RedisSpec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.Redis,
  displayName: 'Redis',
  icon: DatabaseIcon,
  paramsFormSpec: RedisFormSpec,
};

export const BigQuerySpec: ConnectionSpec = {
  type: ExternalConnectionConnectionType.Bigquery,
  displayName: 'BigQuery',
  icon: DatabaseIcon,
  paramsFormSpec: new ParamsFormSpec(
    [
      {
        id: 'service_account_key_json',
        displayName: 'Service account key in JSON',
        placeholder:
          'e.g. {"type": "service_account", "project_id": "my-project", "private_key_id": "*****", ... }',
      },
    ],
    [['service_account_key_json']],
  ),
};

const ConnectionSpecs: Record<string, ConnectionSpec> = {
  POSTGRES: PostgresSpec,
  MYSQL: MySqlSpec,
  BIGQUERY: BigQuerySpec,
  SNOWFLAKE: SnowflakeSpec,
  REDIS: RedisSpec,
  S3: S3Spec,
};

export default ConnectionSpecs;
