import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function XSocialIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.7709 1.5H13.7636L9.38837 7.01661L14.5 14.5H10.4887L7.34805 9.9524L3.75258 14.5H1.75991L6.39503 8.59963L1.5 1.5H5.61096L8.44835
        5.65424L11.7709 1.5ZM11.0735 13.2048H12.1781L5.03049 2.74723H3.84355L11.0735 13.2048Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default XSocialIcon;
