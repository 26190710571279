import { FormEvent, useCallback, useState } from 'react';

import { useLocation } from 'react-router';
import { useMount } from 'react-use';

import { Alert, AlertColor, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Meta from '@/components/Meta';
import { links } from '@/localization';
import { useSearchParams } from '@/routes';
import { getBorder } from '@/theme/utils';
import { PosthogEvents, capturePosthogEvent } from '@/utils/analytics/posthog';
import { EMAIL_REGEX } from '@/utils/email';

import Card from '../Card';
import CheckEmail from '../CheckEmail';
import Divider from '../Divider';
import SocialButton from '../SocialButton';
import { EMAIL_ERROR_MSG } from '../constants';
import { Form, SubmitButton, TextField } from '../styled';
import About from './About';
import { register } from './utils';

function Register() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState<string>(searchParams.get('email') || '');
  const [showCheckEmail, setShowCheckEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSeverity, setErrorSeverity] = useState<AlertColor>('error');
  const [error, setError] = useState<string>(null);

  useMount(() => {
    if (email) {
      // If email is provided via URL search param on mount, auto-submit the form
      setSearchParams('email', null, true);
      handleSubmit();
    }
  });

  const handleSubmit = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();

      if (!email || !EMAIL_REGEX.test(email)) {
        setError(EMAIL_ERROR_MSG);
        return;
      }

      setIsLoading(true);

      register(email)
        .then(({ success, next, message }) => {
          setIsLoading(false);

          if (success) {
            capturePosthogEvent(PosthogEvents.SIGNED_UP);
            if (next) {
              window.location.href = next;
            }

            setShowCheckEmail(true);
          } else if (message) {
            setErrorSeverity('warning');
            setError(message);
          } else {
            setErrorSeverity('error');
            setError('An account with that email address already exists.');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setErrorSeverity('error');
          setError('An unexpected error has occurred.');
        });
    },
    [email],
  );

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <Meta title="Create a Baseten account" responsive>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack alignItems="center" direction="column" gap={4} py={8}>
            <Card withBranding title="Create an account">
              {showCheckEmail ? (
                <CheckEmail />
              ) : (
                <>
                  {ENV_CONFIG.SOCIAL_LOGINS.map((type) => (
                    <SocialButton type={type} key={type} isRegistering />
                  ))}
                  {ENV_CONFIG.SOCIAL_LOGINS.length > 0 && <Divider>or</Divider>}
                  <Form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={1.5}>
                      <TextField
                        variant="outlined"
                        label="Email address"
                        name="email"
                        required
                        type="email"
                        placeholder="name@company.com"
                        data-cy="email"
                        fullWidth
                        autoFocus={!email}
                        value={email}
                        onChange={handleEmailChange}
                        disabled={isLoading}
                      />

                      {error && <Alert severity={errorSeverity}>{error}</Alert>}

                      <SubmitButton data-cy="submit-btn" color="primary" loading={isLoading}>
                        Create account
                      </SubmitButton>
                    </Stack>
                  </Form>

                  <Typography align="center" color="text.muted" variant="body2">
                    By signing in you agree to our
                    <br />
                    <Link href={links.basetenTerms}>Terms of Service</Link>
                    &nbsp;and&nbsp;
                    <Link href={links.basetenPrivacy}>Privacy Policy</Link>.
                  </Typography>
                </>
              )}
            </Card>

            <Typography color="text.muted">
              Already have an account?&nbsp;
              <Link href={`/login${location.search}`}>Sign in</Link>
            </Typography>
          </Stack>
        </Grid>
        <Grid
          xs={12}
          md={6}
          py={8}
          px={4}
          sx={{
            background: (theme) => theme.palette.background.subtle,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
            backgroundImage: {
              sm: '',
              md: "url('/static/deploy/squigle.svg')",
            },

            borderLeft: (theme) => ({
              xs: 'none',
              md: getBorder(theme.palette.border.default),
            }),
            borderTop: (theme) => ({
              xs: getBorder(theme.palette.border.default),
              md: 'none',
            }),
            boxShadow: '12px 0px 32px 0px rgba(0, 0, 0, 0.04) inset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <About />
        </Grid>
      </Grid>
    </Meta>
  );
}

export default Register;
