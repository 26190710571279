/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { QueryFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateQueryNameMutationVariables = Types.Exact<{
  queryId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  currentVersionId: Types.Scalars['String'];
}>;


export type UpdateQueryNameMutation = { __typename?: 'Mutation', updateQueryName: { __typename?: 'UpdateQueryName', query: { __typename?: 'QueryType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any | null | undefined, queryStr: string | null | undefined, queryParamNames: any | null | undefined, currentVersionId: string | null | undefined, dataSource: { __typename?: 'DataSourceType', id: string } | null | undefined } | null | undefined } | null | undefined };

export type OrgQueriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgQueriesQuery = { __typename?: 'QueryPrediction', queries: Array<{ __typename?: 'QueryType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any | null | undefined, queryStr: string | null | undefined, queryParamNames: any | null | undefined, currentVersionId: string | null | undefined, dataSource: { __typename?: 'DataSourceType', id: string } | null | undefined } | null | undefined> | null | undefined };


export const UpdateQueryNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateQueryName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"queryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateQueryName"},"name":{"kind":"Name","value":"update_query_name"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"queryId"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"current_version_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentVersionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"query"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QueryFragment"}}]}}]}}]}},...QueryFragmentDoc.definitions]} as unknown as DocumentNode<UpdateQueryNameMutation, UpdateQueryNameMutationVariables>;
export const OrgQueriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OrgQueries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"queries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QueryFragment"}}]}}]}},...QueryFragmentDoc.definitions]} as unknown as DocumentNode<OrgQueriesQuery, OrgQueriesQueryVariables>;
export const namedOperations = {
  Query: {
    OrgQueries: 'OrgQueries'
  },
  Mutation: {
    UpdateQueryName: 'UpdateQueryName'
  }
}

export type UpdateQueryNameMutationFn = Apollo.MutationFunction<UpdateQueryNameMutation, UpdateQueryNameMutationVariables>;

/**
 * __useUpdateQueryName__
 *
 * To run a mutation, you first call `useUpdateQueryName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueryName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueryName, { data, loading, error }] = useUpdateQueryName({
 *   variables: {
 *      queryId: // value for 'queryId'
 *      name: // value for 'name'
 *      currentVersionId: // value for 'currentVersionId'
 *   },
 * });
 */
export function useUpdateQueryName(baseOptions?: Apollo.MutationHookOptions<UpdateQueryNameMutation, UpdateQueryNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQueryNameMutation, UpdateQueryNameMutationVariables>(UpdateQueryNameDocument, options);
      }
export type UpdateQueryNameHookResult = ReturnType<typeof useUpdateQueryName>;
export type UpdateQueryNameMutationResult = Apollo.MutationResult<UpdateQueryNameMutation>;
export type UpdateQueryNameMutationOptions = Apollo.BaseMutationOptions<UpdateQueryNameMutation, UpdateQueryNameMutationVariables>;
/**
 * __useOrgQueriesQuery__
 *
 * To run a query within a React component, call `useOrgQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgQueriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgQueriesQuery(baseOptions?: Apollo.QueryHookOptions<OrgQueriesQuery, OrgQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgQueriesQuery, OrgQueriesQueryVariables>(OrgQueriesDocument, options);
      }
export function useOrgQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgQueriesQuery, OrgQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgQueriesQuery, OrgQueriesQueryVariables>(OrgQueriesDocument, options);
        }
export type OrgQueriesQueryHookResult = ReturnType<typeof useOrgQueriesQuery>;
export type OrgQueriesLazyQueryHookResult = ReturnType<typeof useOrgQueriesLazyQuery>;
export type OrgQueriesQueryResult = Apollo.QueryResult<OrgQueriesQuery, OrgQueriesQueryVariables>;
export function refetchOrgQueriesQuery(variables?: OrgQueriesQueryVariables) {
      return { query: OrgQueriesDocument, variables: variables }
    }