import { createElement } from 'react';

import { Route } from 'react-router-dom';

import flatten from 'lodash/flatten';

import { BasetenPageEnum, BasetenPageProps } from './types';

/**
 * Helper that renders an set of routes and the component for each route
 */
export function renderRoutes(
  routes: BasetenPageProps[],
  components: Partial<Record<BasetenPageEnum, React.FC<{}>>>,
): React.ReactNode {
  return routes.map((route) => (
    <Route key={String(route.path)} path={flatten([route.path, route.subPaths ?? []])} exact>
      {createElement(components[route.page], route.additionalProps ?? {})}
    </Route>
  ));
}
