import { useCallback, useEffect, useRef } from 'react';

import { useUpdate } from 'react-use';

/**
 * Like React's useState, except the state may also transition synchronously during rendering.
 *
 * @param transition A transition function which takes the previous state and can render a new state.
 * This function is always called synchronously by the hook and can capture (depend on) other data in
 * the calling code.
 */
export default function useSyncState<T>(
  transition: (prev: T | undefined) => T,
): [t: T, setState: (t: T) => void] {
  const update = useUpdate();
  const tRef = useRef<T>(transition(undefined));
  const next = transition(tRef.current);

  useEffect(() => {
    tRef.current = next;
  }, [next]);

  const setState = useCallback(
    (newT: T) => {
      tRef.current = newT;
      update();
    },
    [update],
  );

  return [next, setState];
}
