import { wrapResolutionValue } from '@/store/slices/entities/Views/utils';

import { Resolution, ResolutionStatus } from '../types';
import { StateData } from './types';

function fromStateDataToResolutionValue({ value, error }: StateData): Resolution {
  return error ? wrapResolutionValue(error, ResolutionStatus.Errored) : wrapResolutionValue(value);
}

function fromResolutionValueToStateData(resolution: Resolution): StateData {
  if (resolution.status === ResolutionStatus.Errored) {
    return { value: null, error: resolution.resolvedValue };
  }
  return { value: resolution.resolvedValue, error: null };
}

export { fromStateDataToResolutionValue, fromResolutionValueToStateData };
