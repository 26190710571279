import { useFeatureFlagsQuery } from './__generated__/queries.generated';

function useFeatureFlagsLoading() {
  const { data, ...rest } = useFeatureFlagsQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    featureFlags: data?.featureFlags,
    ...rest,
  };
}

function useFeatureFlags() {
  const data = useFeatureFlagsLoading();

  if (!data.featureFlags) {
    throw new Error('Feature flags not loaded');
  }

  return data.featureFlags;
}

function useCheckFeatureFlag() {
  const { featureFlags } = useFeatureFlagsLoading();

  return (featureFlag: string) =>
    featureFlags?.some((flag) => flag.featureFlag === featureFlag && flag.enabled);
}

export default useFeatureFlags;
export { useFeatureFlagsLoading, useCheckFeatureFlag };
