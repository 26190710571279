/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AvailableGitRepositoriesToImportFragment = { __typename?: 'AvailableGitRepositoryToImport', fullName: string | null | undefined, sizeKB: number | null | undefined, tooLargeToImport: boolean | null | undefined };

export const AvailableGitRepositoriesToImportFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AvailableGitRepositoriesToImport"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AvailableGitRepositoryToImport"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"fullName"},"name":{"kind":"Name","value":"full_name"}},{"kind":"Field","alias":{"kind":"Name","value":"sizeKB"},"name":{"kind":"Name","value":"size_kb"}},{"kind":"Field","alias":{"kind":"Name","value":"tooLargeToImport"},"name":{"kind":"Name","value":"too_large_to_import"}}]}}]} as unknown as DocumentNode<AvailableGitRepositoriesToImportFragment, unknown>;
export const namedOperations = {
  Fragment: {
    AvailableGitRepositoriesToImport: 'AvailableGitRepositoriesToImport'
  }
}
