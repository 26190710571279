/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ViewFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  releaseVersion?: Types.InputMaybe<Types.Scalars['String']>;
  deploymentEnv?: Types.InputMaybe<Types.DeploymentEnvEnum>;
}>;


export type ViewQuery = { __typename?: 'QueryPrediction', view: { __typename?: 'PubliclyVisibleViewType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any, components: any, layout: any, currentVersionId: string | null | undefined } | null | undefined };

export type ViewWithEnvQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  deploymentEnv?: Types.InputMaybe<Types.DeploymentEnvEnum>;
}>;


export type ViewWithEnvQuery = { __typename?: 'QueryPrediction', view_with_env: { __typename?: 'EnvAwarePubliclyVisibleViewType', releaseEnv: Types.DeploymentEnvEnum | null | undefined, sharedStates: Array<string | null | undefined> | null | undefined, workflowName: string | null | undefined, isStarterApp: boolean | null | undefined, shouldShowNav: boolean | null | undefined, view: { __typename?: 'PubliclyVisibleViewType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any, components: any, layout: any, currentVersionId: string | null | undefined } | null | undefined } | null | undefined };


export const ViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"View"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"releaseVersion"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DeploymentEnvEnum"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"view"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"release_version"},"value":{"kind":"Variable","name":{"kind":"Name","value":"releaseVersion"}}},{"kind":"Argument","name":{"kind":"Name","value":"deployment_env"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewFragment"}}]}}]}},...ViewFragmentDoc.definitions]} as unknown as DocumentNode<ViewQuery, ViewQueryVariables>;
export const ViewWithEnvDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ViewWithEnv"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DeploymentEnvEnum"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"view_with_env"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"deployment_env"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deploymentEnv"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"releaseEnv"},"name":{"kind":"Name","value":"from_environment"}},{"kind":"Field","alias":{"kind":"Name","value":"sharedStates"},"name":{"kind":"Name","value":"shared_states"}},{"kind":"Field","name":{"kind":"Name","value":"view"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewFragment"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"workflowName"},"name":{"kind":"Name","value":"workflow_name"}},{"kind":"Field","alias":{"kind":"Name","value":"isStarterApp"},"name":{"kind":"Name","value":"is_starter_app"}},{"kind":"Field","alias":{"kind":"Name","value":"shouldShowNav"},"name":{"kind":"Name","value":"should_show_nav"}}]}}]}},...ViewFragmentDoc.definitions]} as unknown as DocumentNode<ViewWithEnvQuery, ViewWithEnvQueryVariables>;
export const namedOperations = {
  Query: {
    View: 'View',
    ViewWithEnv: 'ViewWithEnv'
  }
}

/**
 * __useViewQuery__
 *
 * To run a query within a React component, call `useViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      releaseVersion: // value for 'releaseVersion'
 *      deploymentEnv: // value for 'deploymentEnv'
 *   },
 * });
 */
export function useViewQuery(baseOptions: Apollo.QueryHookOptions<ViewQuery, ViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewQuery, ViewQueryVariables>(ViewDocument, options);
      }
export function useViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewQuery, ViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewQuery, ViewQueryVariables>(ViewDocument, options);
        }
export type ViewQueryHookResult = ReturnType<typeof useViewQuery>;
export type ViewLazyQueryHookResult = ReturnType<typeof useViewLazyQuery>;
export type ViewQueryResult = Apollo.QueryResult<ViewQuery, ViewQueryVariables>;
export function refetchViewQuery(variables: ViewQueryVariables) {
      return { query: ViewDocument, variables: variables }
    }
/**
 * __useViewWithEnvQuery__
 *
 * To run a query within a React component, call `useViewWithEnvQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewWithEnvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewWithEnvQuery({
 *   variables: {
 *      id: // value for 'id'
 *      deploymentEnv: // value for 'deploymentEnv'
 *   },
 * });
 */
export function useViewWithEnvQuery(baseOptions: Apollo.QueryHookOptions<ViewWithEnvQuery, ViewWithEnvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewWithEnvQuery, ViewWithEnvQueryVariables>(ViewWithEnvDocument, options);
      }
export function useViewWithEnvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewWithEnvQuery, ViewWithEnvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewWithEnvQuery, ViewWithEnvQueryVariables>(ViewWithEnvDocument, options);
        }
export type ViewWithEnvQueryHookResult = ReturnType<typeof useViewWithEnvQuery>;
export type ViewWithEnvLazyQueryHookResult = ReturnType<typeof useViewWithEnvLazyQuery>;
export type ViewWithEnvQueryResult = Apollo.QueryResult<ViewWithEnvQuery, ViewWithEnvQueryVariables>;
export function refetchViewWithEnvQuery(variables: ViewWithEnvQueryVariables) {
      return { query: ViewWithEnvDocument, variables: variables }
    }