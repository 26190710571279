import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function CardIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2.66675H1.99996C1.26358 2.66675 0.666626 3.2637 0.666626 4.00008V12.0001C0.666626 12.7365 1.26358 13.3334 1.99996 13.3334H14C14.7363 13.3334 15.3333 12.7365 15.3333 12.0001V4.00008C15.3333 3.2637 14.7363 2.66675 14 2.66675Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666626 6.66675H15.3333"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default CardIcon;
