import { PageSwitchingMethod, Routes, calcSwitchPath, usePageSwitcher } from '@/routes';

import { useIsDualEnvEnabled } from '../useDualEnv';
import { AppLinkHookProps } from './types';

export default function useAppLink<TElement extends Element>({
  page,
  params,
  disabled = false,
  withNewTab = false,
  onClick,
  refresh,
  queryParams,
}: AppLinkHookProps<TElement>) {
  const gotoPage = usePageSwitcher();
  const resolvedPage = !disabled ? Routes.find((route) => route.page === page) : null;
  const isDualEnvEnabled = useIsDualEnvEnabled();
  const resolvedUrl =
    (page ? calcSwitchPath(page, isDualEnvEnabled, params as any, queryParams) : null) ?? '#';
  const shouldPreventDefault = resolvedPage || resolvedUrl === '#';

  function handleClick(e: React.MouseEvent<TElement>) {
    if (e.defaultPrevented || e.button !== 0 || isModifiedEvent(e)) {
      return;
    }

    if (shouldPreventDefault) {
      e.preventDefault();
    }

    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }

    let pageMethod = PageSwitchingMethod.Push;

    if (withNewTab) {
      pageMethod = PageSwitchingMethod.Open;
    }

    if (refresh) {
      pageMethod = PageSwitchingMethod.Refresh;
    }

    if (page && resolvedPage) {
      // Type checking already provided on prop types, but the inferred types here don't play well with
      // the overloads of `gotoPage`
      gotoPage(page as any, params as any, queryParams, pageMethod);
    }
  }

  return [resolvedUrl, handleClick] as const;
}

function isModifiedEvent(e: React.MouseEvent) {
  return !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
}
