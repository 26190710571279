function trackClick(category: string, label: string) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'click', {
      event_category: category,
      event_label: label,
    });
  }
}

function trackPageView() {
  if (typeof gtag !== 'undefined') {
    gtag('set', 'page_view', window.location.pathname);
    gtag('event', 'page_view');
  }
}

export { trackClick, trackPageView };
