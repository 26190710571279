/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExistingOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ExistingOrganizationQuery = { __typename?: 'QueryPrediction', existingOrganization: { __typename?: 'ExistingOrganizationToJoinType', domain: string | null | undefined, displayName: string | null | undefined, adminName: string | null | undefined } | null | undefined };


export const ExistingOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExistingOrganization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"existingOrganization"},"name":{"kind":"Name","value":"existing_organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"displayName"},"name":{"kind":"Name","value":"display_name"}},{"kind":"Field","name":{"kind":"Name","value":"domain"}},{"kind":"Field","alias":{"kind":"Name","value":"adminName"},"name":{"kind":"Name","value":"admin_name"}}]}}]}}]} as unknown as DocumentNode<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>;
export const namedOperations = {
  Query: {
    ExistingOrganization: 'ExistingOrganization'
  }
}

/**
 * __useExistingOrganizationQuery__
 *
 * To run a query within a React component, call `useExistingOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useExistingOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>(ExistingOrganizationDocument, options);
      }
export function useExistingOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>(ExistingOrganizationDocument, options);
        }
export type ExistingOrganizationQueryHookResult = ReturnType<typeof useExistingOrganizationQuery>;
export type ExistingOrganizationLazyQueryHookResult = ReturnType<typeof useExistingOrganizationLazyQuery>;
export type ExistingOrganizationQueryResult = Apollo.QueryResult<ExistingOrganizationQuery, ExistingOrganizationQueryVariables>;
export function refetchExistingOrganizationQuery(variables?: ExistingOrganizationQueryVariables) {
      return { query: ExistingOrganizationDocument, variables: variables }
    }