/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type QueryFragment = { __typename?: 'QueryType', id: string | null | undefined, name: string, created: any | null | undefined, modified: any | null | undefined, queryStr: string | null | undefined, queryParamNames: any | null | undefined, currentVersionId: string | null | undefined, dataSource: { __typename?: 'DataSourceType', id: string } | null | undefined };

export const QueryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QueryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QueryType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","alias":{"kind":"Name","value":"queryStr"},"name":{"kind":"Name","value":"query_str"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}},{"kind":"Field","alias":{"kind":"Name","value":"dataSource"},"name":{"kind":"Name","value":"data_source"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"queryParamNames"},"name":{"kind":"Name","value":"query_param_names"}},{"kind":"Field","alias":{"kind":"Name","value":"currentVersionId"},"name":{"kind":"Name","value":"current_version_id"}}]}}]} as unknown as DocumentNode<QueryFragment, unknown>;
export const namedOperations = {
  Fragment: {
    QueryFragment: 'QueryFragment'
  }
}
