import each from 'lodash/each';
import has from 'lodash/has';
import map from 'lodash/map';

import produce, { Draft } from 'immer';

import ActionType from '../actions/ActionType';
import { WorkletTestCase, workletTestCaseFromResponse } from './state/WorkletTestCase';

// test case id to a WorkletTestCase
export type WorkletTestCases = Record<string, WorkletTestCase>;
// workletId to map of test cases
type WorkletIdToTestCases = Record<string, WorkletTestCases>;

const INIT_STATE: WorkletIdToTestCases = {};

const workletTestCaseReducer = produce((draft: Draft<WorkletIdToTestCases>, action: any) => {
  switch (action.type) {
    case ActionType.WORKLET_TEST_CASES_FETCH_DONE: {
      const testCases = map(action.workletTestCases, workletTestCaseFromResponse);
      if (!has(draft, action.workletId)) {
        draft[action.workletId] = {};
      }
      each(testCases, (testCase) => {
        draft[action.workletId][testCase.id] = testCase;
      });
      break;
    }
    case ActionType.SINGLE_WORKLET_TEST_CASE_FETCH_DONE: {
      const testCase = workletTestCaseFromResponse(action.workletTestCase);
      draft[action.workletId][testCase.id] = testCase;
      break;
    }
    case ActionType.WORKLET_TEST_CASES_DELETE: {
      each(action.workletTestCaseIds, (testCaseId) => {
        delete draft[action.workletId][testCaseId];
      });
      break;
    }
    default:
      break;
  }
}, INIT_STATE);

export default workletTestCaseReducer;
