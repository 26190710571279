import { Breadcrumbs } from '@mui/material';

import SlashForwardIcon from '@/components/Icons/SlashForwardIcon';
import { useAsOperator, useLoggedIn } from '@/hooks';

import Logo from './Logo';
import Title from './Title';
import { LeftProps } from './types';

function Left({ breadcrumbs, page, logoStyle }: LeftProps) {
  const isLoggedIn = useLoggedIn();
  const asOperator = useAsOperator();

  if (!isLoggedIn) return <Logo variant={logoStyle} />;
  if (asOperator) return <Logo variant={logoStyle} />;

  return (
    <Breadcrumbs
      separator={<SlashForwardIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
    >
      <Logo variant={logoStyle} />
      {breadcrumbs?.(page) ?? <Title page={page} />}
    </Breadcrumbs>
  );
}

export default Left;
