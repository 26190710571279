import { Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

const Container = styled(Box)({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
});

const Img = styled('img')({
  height: '100%',
  width: '100%',
  objectFit: 'none',
});

Img.defaultProps = {
  src: '/static/onboarding/cover.svg',
  alt: 'Onboarding',
};

const Cover = styled(Box)(({ theme }) => ({
  height: '100%',
  flex: 1,
}));

Cover.defaultProps = {
  children: <Img />,
};

const Label = styled(Typography)({
  fontWeight: 500,
});

const Wrapper = styled(Stack)(({ theme }) => ({
  height: '100%',
  paddingLeft: theme.spacing(8),
  gap: theme.spacing(5),
  flexDirection: 'row',
}));

const Body = styled(Stack)(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  justifyContent: 'start',
  overflow: 'auto',
  alignItems: 'start',
  gap: theme.spacing(4),

  // hide scrollbar
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
}));

export { Container, Img, Cover, Label, Wrapper, Body };
