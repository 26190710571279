import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../types';
import createDeepEqualSelector from '../../../utils/DeepEqualSelector';
import { WorkflowIdentifier } from '../Workflows/types';
import { FileIdentifier } from './types';

const codebasesSelector = (state: RootState) => state.entities.codebases.data;

export const codebaseSelector = (
  state: RootState,
  { workflowId, releaseEnv }: WorkflowIdentifier,
) => codebasesSelector(state)[releaseEnv][workflowId];

export const fileSelector = (state: RootState, fileIdentifier: FileIdentifier) =>
  codebaseSelector(state, fileIdentifier)?.find((file) => file.id === fileIdentifier.fileId);

export const doesCodebaseExistSelector = createSelector(codebaseSelector, (codebase) =>
  Boolean(codebase),
);

export const codebaseHasErrorsSelector = createSelector(codebaseSelector, (codebase) =>
  codebase.some((file) => file.errors?.length),
);

export const codeFileIdSelector = createDeepEqualSelector(
  [codebaseSelector],
  // Only include code files that are loaded
  (codebase) => codebase.map((codeFile) => codeFile.id),
);
