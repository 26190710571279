import { AnyAction } from 'redux';

import isEmpty from 'lodash/isEmpty';

import { RpcActionPrefix } from '../actions/ActionType';
import { scheduleFromResponse } from '../slices/entities/Worklets';
import { WorkletEntity } from '../slices/entities/Worklets/types';
import { WorkletInit } from './state/Worklet';

const workletReducer = (state = WorkletInit, action: AnyAction): WorkletEntity => {
  switch (action.type) {
    case `${RpcActionPrefix.CREATE_OR_UPDATE_WORKLET_SCHDULE}.Done`:
      const newSchedule = scheduleFromResponse(
        action.payload.createOrUpdateWorkletSchedule.workletSchedule,
      );
      return {
        ...state,
        schedule: newSchedule,
      };
    case `${RpcActionPrefix.DISABLE_WORKLET_SCHDULE}.Done`:
      if (isEmpty(state.schedule)) {
        // Nothing to do
        return state;
      }
      return {
        ...state,
        schedule: {
          ...state.schedule,
          enabled: false,
        },
      };
    default:
      return state;
  }
};

export default workletReducer;
