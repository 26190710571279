import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';

export interface Arg {
  name: string;
  kind: string;
  default: string;
  annotation: string;
}

export interface Signature {
  args: Arg[];
  returnAnnotation: string;
}

export interface PyConf {
  callableName: string;
  callableArgsMetadata: Arg[];
  callableReturnAnnotation: string;
  // TODO: cleanup callableSource, as it's only used by the old app builder
  callableSource: string[];
  callableEncodedSerializedCode: string;
  callableFilepath: string;
  callableFunction: string;
}

export interface SendSlackMessageConf {
  webhookUrl: string;
  format: string;
}

export interface InvokeModelConf {
  modelId: string;
  modelVersionId: string;
}

export interface QueryConf {
  queryId: string;
}

export interface WorkletConf {
  workletId: string;
}

export type AtomConf = PyConf | SendSlackMessageConf | InvokeModelConf | WorkletConf | QueryConf;

export interface Atom {
  name: string;
  conf: AtomConf;
  originalConf: AtomConf;
}

export interface Node {
  id: string;
  name: string;
  atom?: Atom;
  signature: Signature;
  saving: boolean;
  unsaved: boolean;
  saveResponse: string;
  executing: boolean;
  runResponse: string;
  group: string;
}

export const NodeInit: Node = {
  id: '',
  name: '',
  atom: null,
  signature: {
    args: [],
    returnAnnotation: '',
  },
  saving: false,
  unsaved: false,
  saveResponse: '',
  executing: false,
  runResponse: '',
  group: undefined,
};

/**
 * Convert backend atom conf to frontend.
 * It's very basic at the moment, just converts snake case to camelcase
 * at the top level. This should be updated in case any nested keys are
 * snake case or other differences are introduced.
 */
function toFrontendAtomConf(backendAtomConf: object): AtomConf {
  return mapKeys(backendAtomConf, (_, name: string) => camelCase(name)) as unknown as AtomConf;
}

export function toBackendAtomConf(conf: AtomConf): object {
  return mapKeys(conf, (_, name: string) => snakeCase(name));
}

export function toBackendNode(node: Node): any {
  const conf = toBackendAtomConf(node.atom.conf);
  return {
    id: node.id,
    node_name: node.name,
    group_name: node.group,
    atom: {
      name: node.atom.name,
      conf,
    },
  };
}

export function nodeFromBackendNode(backendNode: any): Node {
  const backendAtom = JSON.parse(backendNode.atom);

  const atom = {
    name: backendAtom.name,
    conf: toFrontendAtomConf(backendAtom.conf),
    originalConf: toFrontendAtomConf(backendAtom.conf),
  };

  const node = {
    id: backendNode.id,
    name: backendNode.name,
    group: isEmpty(backendNode.groupName) ? undefined : backendNode.groupName,
    atom,
    signature: backendNode.signature,
    views: backendNode.viewNodeViews,
    currentVersionId: backendNode.currentVersionId,
  };

  return { ...NodeInit, ...node };
}
