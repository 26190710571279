import { FormEvent, useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import Meta from '@/components/Meta';
import TextField from '@/components/TextField';
import { ApprovalStatus } from '@/graphql';
import { useUserLoading } from '@/hooks/useUser';
import { useUpdateUserMutation } from '@/hooks/useUser/__generated__/mutations.generated';
import Onboarding from '@/pages/Onboarding';
import { OnboardingStep } from '@/pages/Onboarding/types';
import { useAppDispatch } from '@/store/hooks';
import { enableSegment } from '@/utils/analytics/segment/singleton';

import Card from '../Card';
import { Form, SubmitButton } from '../styled';
import { Rejected } from './Rejected';
import { Reviewing } from './Reviewing';
import { Body } from './styled';
import { validateURL } from './utils';

function WaitingRoom() {
  const { user, loading: userLoading } = useUserLoading();
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      setLoading(true);

      const data = new FormData(e.target as HTMLFormElement);

      const companyName = data.get('company') as string;
      const profileUse = data.get('use') as string;
      const linkedinUrl = data.get('linkedin-url') as string;

      if (!companyName || !profileUse || !linkedinUrl) {
        setLoading(false);
        setError('Please enter all fields before continuing.');
        return;
      }

      if (!validateURL(linkedinUrl)) {
        setLoading(false);
        setError('Please enter a valid website URL.');
        return;
      }

      updateUser({
        variables: {
          user: {
            company_name: data.get('company') as string,
            profile_use: data.get('use') as string,
            linkedin_url: data.get('linkedin-url') as string,
          },
        },
      });

      setLoading(false);
    },
    [updateUser],
  );

  useEffect(() => {
    enableSegment();
  }, [dispatch]);

  if (userLoading) return null;

  if (user.status === ApprovalStatus.PendingOrgApproval)
    return <Onboarding providedStep={OnboardingStep.RequestedToJoin} />;

  return (
    <Meta title="Baseten | Email verification">
      <Body>
        <Card withBranding title="Get your account approved">
          {user?.status === ApprovalStatus.Pending && <Reviewing />}

          {user?.status === ApprovalStatus.Rejected && <Rejected />}

          {user?.status === ApprovalStatus.Default && (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ lineHeight: '20px' }}>
                Please share your LinkedIn profile so we can approve your account.
              </Typography>
              <Form onSubmit={handleSubmit}>
                <Stack direction="column" gap={1}>
                  <TextField
                    defaultValue={user?.companyName}
                    label="Your company"
                    name="company"
                    required
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    label="LinkedIn or other professional profile"
                    name="linkedin-url"
                    defaultValue={user?.linkedinProfile}
                    variant="outlined"
                    type="url"
                    required
                    fullWidth
                    placeholder="https://www.linkedin.com/in/username"
                  />

                  <TextField
                    label="How do you want to use Baseten?"
                    name="use"
                    defaultValue={user?.profileUse}
                    variant="outlined"
                    multiline
                    inputProps={{ maxLength: 255 }}
                    rows={5}
                    fullWidth
                  />

                  {error && <Alert severity="error">{error}</Alert>}

                  <SubmitButton data-cy="submit-btn" loading={loading}>
                    Submit
                  </SubmitButton>
                </Stack>
              </Form>
            </>
          )}
        </Card>
      </Body>
    </Meta>
  );
}

export default WaitingRoom;
