// Action with just type, no arguments

export const nullaryAction = (actionType: string) => ({
  type: actionType,
});
// Action with just type and one argument

export function unaryAction<T>(actionType: string, argName: string, arg: T) {
  return {
    type: actionType,
    [argName]: arg,
  };
}
