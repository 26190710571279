/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PynodeRequirementsFragment = { __typename?: 'PyNodeRequirementType', id: string, status: Types.PyNodeRequirementStatus, modified: any, requirementsTxt: string | null | undefined, systemRequirements: string | null | undefined, errorMessage: string | null | undefined };

export const PynodeRequirementsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PynodeRequirementsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PyNodeRequirementType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"requirementsTxt"},"name":{"kind":"Name","value":"requirements_txt"}},{"kind":"Field","alias":{"kind":"Name","value":"systemRequirements"},"name":{"kind":"Name","value":"system_requirements"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","alias":{"kind":"Name","value":"errorMessage"},"name":{"kind":"Name","value":"error_message"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}}]}}]} as unknown as DocumentNode<PynodeRequirementsFragment, unknown>;
export const namedOperations = {
  Fragment: {
    PynodeRequirementsFragment: 'PynodeRequirementsFragment'
  }
}
