/* eslint-disable react/prop-types */
import * as Sentry from '@sentry/react';

import { Default as DefaultFallback } from './Fallbacks';

type ErrorBoundaryProps = InstanceType<typeof Sentry.ErrorBoundary>['props'];

function ErrorBoundary({ fallback, ...props }: ErrorBoundaryProps) {
  return <Sentry.ErrorBoundary fallback={fallback ?? <DefaultFallback />} {...props} />;
}

export default ErrorBoundary;
