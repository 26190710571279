type PosthogIdentifyPropertiesType = {
  name: string;
  email: string;
};

enum PosthogEvents {
  PAGEVIEW = '$pageview',
  SIGNED_IN = 'user_signed_in',
  SIGNED_UP = 'user_signed_up',
  ONBOARDING_STARTED = 'user_onboarding_started',
  ONBOARDING_FINISHED = 'user_onboarding_finished',
}

export { PosthogIdentifyPropertiesType, PosthogEvents };
