import { MatchParams as ChainMatchParams } from '@/pages/Chain/types';
import { MatchParams as ClassMatchParams } from '@/pages/Class/types';
import { MatchParams as DeployModelMatchParams } from '@/pages/DeployModel/types';
import { MatchParams as ModelMatchParams } from '@/pages/Model/types';
import { MatchParams as SettingsMatchParams } from '@/pages/Settings/types';
import { MatchParams as WorkspaceMatchParams } from '@/pages/Workspace/types';
import { FileIdentifier } from '@/store/slices/entities/Codebases/types';
import { ViewIdentifier } from '@/store/slices/entities/Views/types';
import { WorkflowIdentifier } from '@/store/slices/entities/Workflows/types';
import { WorkletIdentifier } from '@/store/slices/entities/Worklets/types';

import { BasetenPageEnum } from '../types';

export type PageParams = {
  [BasetenPageEnum.Application]: WorkflowIdentifier;
  [BasetenPageEnum.View]: ViewIdentifier;
  [BasetenPageEnum.ViewOperator]: ViewIdentifier;
  [BasetenPageEnum.Worklet]: WorkletIdentifier & WorkflowIdentifier & { nodeId?: string };
  [BasetenPageEnum.Code]: FileIdentifier;
  [BasetenPageEnum.Class]: ClassMatchParams;
  [BasetenPageEnum.Model]: ModelMatchParams;
  [BasetenPageEnum.Chain]: ChainMatchParams;
  [BasetenPageEnum.Settings]: SettingsMatchParams;
  [BasetenPageEnum.Workspace]: WorkspaceMatchParams;
  [BasetenPageEnum.DeployModel]: DeployModelMatchParams;
};

export type ParamsForPage<TPage extends BasetenPageEnum> = TPage extends keyof PageParams
  ? PageParams[TPage]
  : undefined;

export enum PageSwitchingMethod {
  Push = 'push',
  Open = 'open',
  Replace = 'replace',
  Refresh = 'refresh',
}

export type PageSwitcher = {
  // Pages that require params
  <TPage extends keyof PageParams>(
    page: TPage,
    pathParams: ParamsForPage<TPage>,
    queryParams?: Record<string, string>,
    method?: PageSwitchingMethod,
  ): void;
  // Pages for which params are empty/optional
  <TPage extends Exclude<BasetenPageEnum, keyof PageParams>>(
    page: TPage,
    pathParams?: undefined,
    queryParams?: Record<string, string>,
    method?: PageSwitchingMethod,
  ): void;
};
