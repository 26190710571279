import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DeploymentVariants, Frameworks, ModelDeploymentState } from './types';

const INITIAL_STATE: ModelDeploymentState = {
  selectedFramework: Frameworks.HuggingFace,
  selectedDeploymentVariant: DeploymentVariants.Custom,
  apiKey: null,
  name: '',
  files: '',
  trussVariable: '',
};

const ModelDeployment = createSlice({
  name: 'model-deployment',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedFramework(state: ModelDeploymentState, action: PayloadAction<Frameworks>) {
      state.selectedFramework = action.payload;
    },
    setSelectedDeploymentVariant(
      state: ModelDeploymentState,
      action: PayloadAction<DeploymentVariants>,
    ) {
      state.selectedDeploymentVariant = action.payload;
      if (action.payload === DeploymentVariants.Custom) {
        state.selectedFramework = null;
      }
    },
    setApiKey(state: ModelDeploymentState, action: PayloadAction<string>) {
      state.apiKey = action.payload;
    },
    setName(state: ModelDeploymentState, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setFiles(state: ModelDeploymentState, action: PayloadAction<string>) {
      state.files = action.payload;
    },
    setTrussVariable(state: ModelDeploymentState, action: PayloadAction<string>) {
      state.trussVariable = action.payload;
    },
    resetState(state: ModelDeploymentState, action: PayloadAction<string>) {
      return INITIAL_STATE;
    },
  },
});

export const {
  setSelectedFramework,
  setSelectedDeploymentVariant,
  setApiKey,
  resetState,
  setName,
  setTrussVariable,
  setFiles,
} = ModelDeployment.actions;

export default ModelDeployment.reducer;
