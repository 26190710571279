import { ReactNode } from 'react';

import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import initial from 'lodash/initial';
import isString from 'lodash/isString';
import last from 'lodash/last';

import SlashForwardIcon from '@/components/Icons/SlashForwardIcon';

import WorkspaceLink from '../WorkspaceLink';
import { AppLink } from './styled';
import { BreadcrumbItem, Page, PageTitleProps } from './types';

function Title({ page, isRoot = false }: PageTitleProps) {
  function getBreadcrumbs(page: Page): ReactNode {
    if (!page) return null;

    if (isString(page)) {
      return (
        <Typography variant="h5" color="text.default" key="breadcrumbs-current-page">
          {page}
        </Typography>
      );
    }

    if (Array.isArray(page)) {
      return [
        initial(page).map((p: BreadcrumbItem) => (
          <AppLink key={p.route} page={p.route} sx={{ '&:hover': { textDecoration: 'none' } }}>
            {p.name}
          </AppLink>
        )),
        getBreadcrumbs(last(page) as ReactNode),
      ];
    }

    return page;
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<SlashForwardIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
    >
      {!isRoot && <WorkspaceLink />}
      {getBreadcrumbs(page)}
    </Breadcrumbs>
  );
}

export default Title;
