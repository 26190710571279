import { useState } from 'react';

import { useLocation } from 'react-router';

import { Box, Button, Divider, Skeleton } from '@mui/material';
import MuiMenu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';

import first from 'lodash/first';
import noop from 'lodash/noop';

import ActivityIcon from '@/components/Icons/ActivityIcon';
import ArrowLeftIcon from '@/components/Icons/ArrowLeftIcon';
import CardIcon from '@/components/Icons/CardIcon';
import ChevronDownIcon from '@/components/Icons/ChevronDownIcon';
import KeyIcon from '@/components/Icons/KeyIcon';
import SettingsIcon from '@/components/Icons/SettingsIcon';
import ShieldIcon from '@/components/Icons/ShieldIcon';
import ToolIcon from '@/components/Icons/ToolIcon';
import UserPlusIcon from '@/components/Icons/UserPlusIcon';
import { WORKSPACE_NAME_FALLBACK } from '@/constants';
import { useAsOperator } from '@/hooks';
import useIsActivityFeedEnabled from '@/hooks/useIsActivityFeedEnabled';
import { useIsApplicationsAndDataEnabled } from '@/hooks/useIsApplicationsAndDataEnabled';
import { useOrgLoading } from '@/hooks/useOrg';
import { Tabs as SettingsTabs } from '@/pages/Settings/Tabs/types';
import { WorkspaceSections } from '@/pages/Workspace/types';
import { BasetenPageEnum, findMatchingPageFromPath } from '@/routes';

import { AppLink, MenuItem } from '../../styled';
import { Avatar } from './styled';

function WorkspaceLink() {
  const { org, loading } = useOrgLoading();
  const [workspaceMenuAnchorEl, setWorkspaceMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isWorkspaceMenuOpen = Boolean(workspaceMenuAnchorEl);
  const asOperator = useAsOperator();
  const isApplicationsAndDataEnabled = useIsApplicationsAndDataEnabled();
  const isActivityFeedEnabled = useIsActivityFeedEnabled();
  const { pathname } = useLocation();

  const workspaceName = org?.workspaceName || WORKSPACE_NAME_FALLBACK;

  function openWorkspaceMenu(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
    event.preventDefault();
    setWorkspaceMenuAnchorEl(event.currentTarget);
  }

  function handleCloseWorkspaceMenu(): void {
    setWorkspaceMenuAnchorEl(null);
  }

  const currentPage = findMatchingPageFromPath(pathname);
  const showBackToWorkspace = currentPage !== BasetenPageEnum.Workspace;

  return (
    <Stack direction="row" gap={0} alignItems="center">
      <Button
        variant="text"
        color="primary"
        onClick={asOperator ? noop : openWorkspaceMenu}
        aria-controls={isWorkspaceMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isWorkspaceMenuOpen ? 'true' : undefined}
        endIcon={!asOperator && <ChevronDownIcon color="secondary" sx={{ mr: 1 }} />}
        sx={{ p: 0.5, userSelect: 'auto' }}
        data-cy="workspace-name"
      >
        {loading ? (
          <Skeleton animation="wave" width={140} height={16} />
        ) : (
          <>
            <Avatar>{first(workspaceName)}</Avatar>
            {workspaceName}
          </>
        )}
      </Button>
      <MuiMenu
        anchorEl={workspaceMenuAnchorEl}
        open={isWorkspaceMenuOpen}
        onClose={handleCloseWorkspaceMenu}
        elevation={3}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {showBackToWorkspace && (
          <Box>
            <MenuItem>
              <AppLink
                onClick={handleCloseWorkspaceMenu}
                page={BasetenPageEnum.Workspace}
                params={{
                  section: WorkspaceSections.Models,
                }}
              >
                <ArrowLeftIcon color="secondary" /> Back to workspace
              </AppLink>
            </MenuItem>
            <Divider sx={{ mx: -1 }} />
          </Box>
        )}
        <MenuItem>
          <AppLink
            onClick={handleCloseWorkspaceMenu}
            page={BasetenPageEnum.Settings}
            params={{ tabId: SettingsTabs.General }}
          >
            <SettingsIcon color="secondary" /> General settings
          </AppLink>
        </MenuItem>
        <MenuItem>
          <AppLink
            onClick={handleCloseWorkspaceMenu}
            page={BasetenPageEnum.Settings}
            params={{ tabId: SettingsTabs.Members }}
          >
            <UserPlusIcon color="secondary" /> Members
          </AppLink>
        </MenuItem>
        {ENV_CONFIG.SHOW_BILLING_UI && (
          <MenuItem>
            <AppLink
              onClick={handleCloseWorkspaceMenu}
              page={BasetenPageEnum.Settings}
              params={{ tabId: SettingsTabs.Billing }}
            >
              <CardIcon color="secondary" /> Billing and usage
            </AppLink>
          </MenuItem>
        )}
        {isActivityFeedEnabled && (
          <MenuItem>
            <AppLink
              onClick={handleCloseWorkspaceMenu}
              page={BasetenPageEnum.Settings}
              params={{ tabId: SettingsTabs.ActivityFeed }}
            >
              <ActivityIcon color="secondary" /> Activity
            </AppLink>
          </MenuItem>
        )}
        {isApplicationsAndDataEnabled && (
          <MenuItem>
            <AppLink
              onClick={handleCloseWorkspaceMenu}
              page={BasetenPageEnum.Settings}
              params={{ tabId: SettingsTabs.Integrations }}
            >
              <ToolIcon color="secondary" /> Integrations
            </AppLink>
          </MenuItem>
        )}
        <MenuItem>
          <AppLink
            onClick={handleCloseWorkspaceMenu}
            page={BasetenPageEnum.Settings}
            params={{ tabId: SettingsTabs.Secrets }}
          >
            <ShieldIcon color="secondary" /> Secrets
          </AppLink>
        </MenuItem>
        <MenuItem>
          <AppLink
            onClick={handleCloseWorkspaceMenu}
            page={BasetenPageEnum.Settings}
            params={{ tabId: SettingsTabs.ApiKeys }}
          >
            <KeyIcon color="secondary" /> API keys
          </AppLink>
        </MenuItem>
      </MuiMenu>
    </Stack>
  );
}

export default WorkspaceLink;
