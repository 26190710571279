/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PaymentMethodFragment = { __typename?: 'StripePaymentMethodType', brand: string | null | undefined, last4: string | null | undefined, expMonth: number | null | undefined, expYear: number | null | undefined, paymentMethodType: Types.StripePaymentMethod | null | undefined, paymentMethodTitle: string | null | undefined, paymentMethodSubtitle: string | null | undefined };

export type UsagePerDayFragment = { __typename?: 'UsagePerDay', date: any | null | undefined, cost: any | null | undefined, minutes: number | null | undefined, requests: number | null | undefined };

export type BillingPeriodModelVersionFragment = { __typename?: 'ModelVersionType', id: string, semver: string, name: string | null | undefined, oracle: { __typename?: 'ModelType', id: string, name: string | null | undefined, currentVersion: { __typename?: 'ModelVersionType', id: string } | null | undefined, primaryVersion: { __typename?: 'ModelVersionType', id: string } | null | undefined } };

export const PaymentMethodFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PaymentMethod"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"StripePaymentMethodType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brand"}},{"kind":"Field","alias":{"kind":"Name","value":"expMonth"},"name":{"kind":"Name","value":"exp_month"}},{"kind":"Field","alias":{"kind":"Name","value":"expYear"},"name":{"kind":"Name","value":"exp_year"}},{"kind":"Field","name":{"kind":"Name","value":"last4"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentMethodType"},"name":{"kind":"Name","value":"payment_method_type"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentMethodTitle"},"name":{"kind":"Name","value":"payment_method_title"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentMethodSubtitle"},"name":{"kind":"Name","value":"payment_method_subtitle"}}]}}]} as unknown as DocumentNode<PaymentMethodFragment, unknown>;
export const UsagePerDayFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UsagePerDay"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UsagePerDay"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"cost"}},{"kind":"Field","name":{"kind":"Name","value":"minutes"}},{"kind":"Field","alias":{"kind":"Name","value":"requests"},"name":{"kind":"Name","value":"inference_requests"}}]}}]} as unknown as DocumentNode<UsagePerDayFragment, unknown>;
export const BillingPeriodModelVersionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BillingPeriodModelVersion"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ModelVersionType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"semver"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"oracle"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ModelType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"currentVersion"},"name":{"kind":"Name","value":"current_version"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"primaryVersion"},"name":{"kind":"Name","value":"primary_version"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<BillingPeriodModelVersionFragment, unknown>;
export const namedOperations = {
  Fragment: {
    PaymentMethod: 'PaymentMethod',
    UsagePerDay: 'UsagePerDay',
    BillingPeriodModelVersion: 'BillingPeriodModelVersion'
  }
}
