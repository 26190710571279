import { StateWithHistory } from 'redux-undo';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import unset from 'lodash/unset';

import { OPERATING_WORKFLOW_REQUEST_HEADER } from '@/constants';
import { getComponentDefinition } from '@/pages/Application/View/components/definitions';
import { CANVAS_ROOT } from '@/pages/Application/View/constants';
import { DatasourceType } from '@/pages/Application/View/datasources/types';
import { RefPath, RefType } from '@/pages/Application/View/refs/types';
import {
  ComponentCollection,
  RenderAs,
  Resolution,
  ResolutionStatus,
  SupplierId,
  ValueSupplier,
} from '@/pages/Application/View/types';
import { Variable, VariableType } from '@/pages/Application/View/vars/types';
import { generateId } from '@/utils/ids';

import { ViewFragment } from './__generated__/fragments.generated';
import { initializeSuppliers } from './data-management';
import { ViewEntity, ViewIdentifier } from './types';

const CURRENT_CONFIG_VERSION = '3';

function getApiHeaders(workflowId: string, asOperator: boolean): Record<string, string> {
  return asOperator
    ? {
        [OPERATING_WORKFLOW_REQUEST_HEADER]: workflowId,
      }
    : {};
}

function makeRootConfig(): ComponentCollection {
  return {
    meta: { name: CANVAS_ROOT, isFrameHidden: false },
    refs: {},
    params: {},
    datasources: {},
    children: {},
    vars: {},
    actions: {},
    props: {},
    states: {},
    urlParams: {},
    handlers: [],
    valueSuppliers: {},
    resolvedData: {},
    version: CURRENT_CONFIG_VERSION,
  };
}

function makeNewView(
  workflowId: string,
  name: string,
): Omit<ViewEntity, 'id' | 'currentVersionId'> {
  const now = new Date();
  return {
    _fetched: now,
    name,
    created: now,
    modified: now,
    config: wrapInHistory(makeRootConfig()),
    resolutionQueue: [],
    workflowId,
  };
}

function postProcessConfig(config: ComponentCollection) {
  // initialize props in case they have not been initialized
  if (!config.refs) {
    config.refs = {};
  }
  if (!config.params) {
    config.params = {};
  }
  if (!config.vars) {
    config.vars = {};
  }
  if (!config.handlers) {
    config.handlers = [];
  }
  if (!config.urlParams) {
    config.urlParams = {};
  }
  Object.values(config.children).forEach((child) => {
    const componentDef = getComponentDefinition(child.type);
    if (componentDef) {
      const { fields, props } = componentDef;
      // initialize fields
      if (fields) {
        child.fields = {}; // reset fields
        fields.forEach(({ name }) => {
          child.fields[name] = generateId('field');
        });
      }
      for (const propDef of props) {
        // Add any missing prop suppliers, such as for props that have been added to component definitions
        if (!has(child.propSuppliers, propDef.id) && !has(child.propSuppliers, propDef.alias)) {
          const newPropId = generateId('prop');
          child.propSuppliers[propDef.id] = newPropId;
          initializeSuppliers(config.valueSuppliers, [newPropId]);
        }
      }
    }
    Object.values(child.contextualActions ?? {}).forEach((action) => {
      // Upgrade actionId to handlerIds
      action.handlerIds = action.handlerIds ?? [];
      if (action.actionId) {
        action.handlerIds.push(action.actionId);
      }
      delete action.actionId;
    });
  });
  Object.values(config.vars).forEach((variable) => {
    if (!variable.type) {
      variable.type = VariableType.Javascript;
    }
  });
  config.resolvedData = {};
  hydrateStates(config);
  return config;
}

function wrapInHistory<T>(val: T): StateWithHistory<T> {
  return { past: [], present: val, future: [] };
}

function deserializeConfig(rawJson: string): Partial<ViewEntity> {
  try {
    const config = JSON.parse(rawJson);
    if (!config.version) {
      return { componentConfig: config };
    }
    if (config.version === '2') {
      return { config: wrapInHistory(postProcessConfig(upgradeConfigToV3(config))) };
    }
    return { config: wrapInHistory(postProcessConfig(config as ComponentCollection)) };
  } catch (ex) {
    // TOOD(mike): handle error parsing?
    return { config: wrapInHistory(makeRootConfig()) };
  }
}

function serializeConfig(originalConfig: ComponentCollection) {
  const config = cloneDeep(originalConfig);
  // clear fields from children
  Object.values(config.children).forEach((child) => {
    delete child.fields;
  });
  // clear states
  delete config.states;
  // clear container data
  delete config.resolvedData;
  return JSON.stringify(config);
}

function hydrateStates(collection: ComponentCollection) {
  collection.states = {};
  Object.values(collection.actions).forEach((action) => {
    const saveState = get(action, 'saveState');
    if (saveState) {
      const newState = {
        name: saveState,
        supplierId: generateId('state'),
      };
      collection.states[saveState] = newState;
      collection.resolvedData[newState.supplierId] = wrapResolutionValue(null);
    }
  });
}

// this function is so that when we use `setState` we don't have to
// write all the wrapping data since the structure has to match that
// of a Resolution
function wrapResolutionValue(
  value: any,
  status: ResolutionStatus = ResolutionStatus.Resolved,
): Resolution {
  return {
    status,
    resolvedValue: value,
    as: RenderAs.Raw,
  };
}

function upgradeConfigToV3(config: any): ComponentCollection {
  config.valueSuppliers = {};
  Object.keys(config.children).forEach((componentName) => {
    const { chrome, propSuppliers, fields } = config.children[componentName];
    if (chrome && chrome.headerText) {
      const supplierId = generateId('chrome');
      config.valueSuppliers[supplierId] = chrome.headerText;
      chrome.headerText = supplierId;
    }
    if (propSuppliers) {
      Object.keys(propSuppliers).forEach((prop) => {
        const supplierId = generateId('prop');
        config.valueSuppliers[supplierId] = propSuppliers[prop];
        propSuppliers[prop] = supplierId;
      });
    }
    if (fields) {
      Object.keys(fields).forEach((fieldName) => {
        fields[fieldName] = generateId('field');
      });
    }
  });
  const refs: RefPath[] = Object.values(config.refs);
  // rewrite datasource instances to a prefixed instanceId
  Object.keys(config.datasources).forEach((instanceId) => {
    const datasource = config.datasources[instanceId];
    if (datasource.id) {
      // skip for datasources that already have an `id`; likely upgraded this already
      return;
    }
    let generatedId: string;
    switch (datasource.type) {
      case DatasourceType.Worklet:
        generatedId = `worklet-${instanceId}`;
        break;
      case DatasourceType.Query:
        generatedId = `query-${instanceId}`;
        break;
      default:
        return;
    }
    datasource.id = generatedId;
    config.datasources[generatedId] = datasource;
    refs
      .filter(
        (ref) =>
          (ref.type === RefType.DatasourceWorklet || ref.type === RefType.DatasourceQueries) &&
          ref.instanceId === instanceId,
      )
      .forEach((matchingRef) => {
        set(matchingRef, 'instanceId', generatedId);
      });
    delete config.datasources[instanceId];
  });
  // inject supplierId for all vars and upgrade refs
  Object.values(config.vars).forEach((variable: Variable) => {
    if (variable.id) {
      // skip variables that already have an `id`; likely upgraded this already
      return;
    }
    const generatedId = generateId('var');
    variable.id = generatedId;
    config.vars[generatedId] = variable;
    refs
      .filter((ref) => ref.type === RefType.Variables && ref.name === variable.name)
      .forEach((matchingRef) => {
        unset(matchingRef, 'name');
        set(matchingRef, 'varId', generatedId);
      });
    delete config.vars[variable.name]; // remove the old name based variable
  });
  config.version = CURRENT_CONFIG_VERSION;
  return config as ComponentCollection;
}

function viewFromResponse(response: ViewFragment, workflowId: string): ViewEntity {
  const components = deserializeConfig(response.components);
  return {
    _fetched: new Date(),
    id: response.id,
    name: response.name,
    created: new Date(response.created),
    modified: new Date(response.modified),
    resolutionQueue: [],
    currentVersionId: response.currentVersionId,
    workflowId,
    ...components,
  };
}

const VALID_VIEW_ENTITY_ID_CHECK = /^[a-zA-Z]\w{0,31}$/;

function isValidComponentId(id: string) {
  return VALID_VIEW_ENTITY_ID_CHECK.test(id) && id !== CANVAS_ROOT;
}

function isValidParamId(id: string) {
  return VALID_VIEW_ENTITY_ID_CHECK.test(id) && !id.startsWith('bt-');
}

function isViewIdentifier(obj: unknown): obj is ViewIdentifier {
  if (!(typeof obj === 'object')) {
    return false;
  }
  const viewIdPartial = obj as Partial<ViewIdentifier>;
  return (
    typeof viewIdPartial?.viewId === 'string' &&
    typeof viewIdPartial?.workflowId === 'string' &&
    typeof viewIdPartial?.releaseEnv === 'string'
  );
}

function dereferenceValue(config: ComponentCollection, supplierId: SupplierId): ValueSupplier {
  return config.valueSuppliers[supplierId];
}

function dereferenceData(config: ComponentCollection, supplierId: SupplierId): any {
  const resolvedData = config.resolvedData[supplierId];
  const isResolved = resolvedData?.status === ResolutionStatus.Resolved;
  return isResolved ? resolvedData.resolvedValue : null;
}

export {
  getApiHeaders,
  makeRootConfig,
  makeNewView,
  deserializeConfig,
  serializeConfig,
  wrapResolutionValue,
  viewFromResponse,
  isValidComponentId,
  isValidParamId,
  isViewIdentifier,
  dereferenceValue,
  dereferenceData,
};
