/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { PaymentMethodFragmentDoc, UsagePerDayFragmentDoc, BillingPeriodModelVersionFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateStripeSetupIntentMutationVariables = Types.Exact<{
  includeBankAccount?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GenerateStripeSetupIntentMutation = { __typename?: 'Mutation', generateStripeSetupIntent: { __typename?: 'GenerateStripeSetupIntent', clientSecret: string | null | undefined } | null | undefined };

export type SetPaymentMethodMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type SetPaymentMethodMutation = { __typename?: 'Mutation', setStripeDefaultPaymentMethod: { __typename?: 'SetStripeDefaultPaymentMethod', allInvoicesPaid: boolean | null | undefined, paymentAttemptErrorMessage: string | null | undefined, paymentMethod: { __typename?: 'StripePaymentMethodType', brand: string | null | undefined, last4: string | null | undefined, expMonth: number | null | undefined, expYear: number | null | undefined, paymentMethodType: Types.StripePaymentMethod | null | undefined, paymentMethodTitle: string | null | undefined, paymentMethodSubtitle: string | null | undefined } | null | undefined } | null | undefined };


export const GenerateStripeSetupIntentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateStripeSetupIntent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"includeBankAccount"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"generateStripeSetupIntent"},"name":{"kind":"Name","value":"generate_stripe_setup_intent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"include_bank_account"},"value":{"kind":"Variable","name":{"kind":"Name","value":"includeBankAccount"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"clientSecret"},"name":{"kind":"Name","value":"client_secret"}}]}}]}}]} as unknown as DocumentNode<GenerateStripeSetupIntentMutation, GenerateStripeSetupIntentMutationVariables>;
export const SetPaymentMethodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetPaymentMethod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"setStripeDefaultPaymentMethod"},"name":{"kind":"Name","value":"set_stripe_default_payment_method"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payment_method_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"paymentMethod"},"name":{"kind":"Name","value":"payment_method"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaymentMethod"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"allInvoicesPaid"},"name":{"kind":"Name","value":"all_invoices_paid"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentAttemptErrorMessage"},"name":{"kind":"Name","value":"payment_attempt_error_message"}}]}}]}},...PaymentMethodFragmentDoc.definitions]} as unknown as DocumentNode<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>;
export const namedOperations = {
  Mutation: {
    GenerateStripeSetupIntent: 'GenerateStripeSetupIntent',
    SetPaymentMethod: 'SetPaymentMethod'
  }
}

export type GenerateStripeSetupIntentMutationFn = Apollo.MutationFunction<GenerateStripeSetupIntentMutation, GenerateStripeSetupIntentMutationVariables>;

/**
 * __useGenerateStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useGenerateStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStripeSetupIntentMutation, { data, loading, error }] = useGenerateStripeSetupIntentMutation({
 *   variables: {
 *      includeBankAccount: // value for 'includeBankAccount'
 *   },
 * });
 */
export function useGenerateStripeSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<GenerateStripeSetupIntentMutation, GenerateStripeSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateStripeSetupIntentMutation, GenerateStripeSetupIntentMutationVariables>(GenerateStripeSetupIntentDocument, options);
      }
export type GenerateStripeSetupIntentMutationHookResult = ReturnType<typeof useGenerateStripeSetupIntentMutation>;
export type GenerateStripeSetupIntentMutationResult = Apollo.MutationResult<GenerateStripeSetupIntentMutation>;
export type GenerateStripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<GenerateStripeSetupIntentMutation, GenerateStripeSetupIntentMutationVariables>;
export type SetPaymentMethodMutationFn = Apollo.MutationFunction<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>;

/**
 * __useSetPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaymentMethodMutation, { data, loading, error }] = useSetPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>(SetPaymentMethodDocument, options);
      }
export type SetPaymentMethodMutationHookResult = ReturnType<typeof useSetPaymentMethodMutation>;
export type SetPaymentMethodMutationResult = Apollo.MutationResult<SetPaymentMethodMutation>;
export type SetPaymentMethodMutationOptions = Apollo.BaseMutationOptions<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>;