import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  UpdateOnboardingStateMutationDocument,
  UpdateWalkthroughStateMutationDocument,
} from './__generated__/mutations.generated';
import { OnboardingChecklistDocument } from './__generated__/queries.generated';
import {
  OnboardingChecklistEntity,
  OnboardingChecklistEntityAsyncThunk,
  WalkthroughState,
} from './types';

const initialState = {
  onboarded: false,
  onboardingState: {},
  walkthroughState: 'VIEW',
  finishedOnboardingFlow: false,
} as OnboardingChecklistEntity;

const onboardingChecklistFromResponse = (response: any) => {
  return {
    onboarded: response.onboarded,
    walkthroughState: response.walkthrough_state,
    finishedOnboardingFlow: response.finished_onboarding_flow,
    onboardingState: JSON.parse(response.onboarding_state),
  } as OnboardingChecklistEntity;
};

export const fetchOnboardingChecklist: OnboardingChecklistEntityAsyncThunk = createAsyncThunk(
  'onboarding-checklist-entities/fetchOnboardingChecklist',
  async (_, { dispatch, getState, extra }) => {
    const { data } = await extra.apolloClient.query({ query: OnboardingChecklistDocument });
    return onboardingChecklistFromResponse(data.onboarding_checklist);
  },
);

export const updateOnboardingState = createAsyncThunk(
  'onboarding-checklist-entities/updateOnboardingState',
  async (onboardingState: Object, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: UpdateOnboardingStateMutationDocument,
      variables: { onboardingState },
    });
    dispatch(fetchOnboardingChecklist());
  },
);

export const updateWalkthroughState = createAsyncThunk(
  'onboarding-checklist-entities/updateWalkthroughState',
  async (walkthroughState: WalkthroughState, { dispatch, extra }) => {
    await extra.apolloClient.mutate({
      mutation: UpdateWalkthroughStateMutationDocument,
      variables: { walkthroughState },
    });
    dispatch(fetchOnboardingChecklist());
  },
);

const OnboardingChecklist = createSlice({
  name: 'onboarding-checklist-entities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOnboardingChecklist.fulfilled, (state, action) => {
      state.onboarded = action.payload.onboarded;
      state.finishedOnboardingFlow = action.payload.finishedOnboardingFlow;
      state.walkthroughState = action.payload.walkthroughState;
      state.onboardingState = action.payload.onboardingState;
    });
  },
});

export default OnboardingChecklist.reducer;
