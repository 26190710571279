import { MouseEvent, useState } from 'react';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { PaymentMethodStatus } from '@/graphql';
import useMonetaryCreditInfo from '@/hooks/useMonetaryCreditInfo';
import { formatPrice } from '@/utils/currency';

import CreditCallout from './CreditCallout';
import { Button } from './styled';

function Credits() {
  const { monetaryCreditInfo } = useMonetaryCreditInfo();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  if (
    !ENV_CONFIG.SHOW_BILLING_UI ||
    !monetaryCreditInfo ||
    monetaryCreditInfo.creditGranted === 0 ||
    (monetaryCreditInfo.creditBalance === 0 &&
      monetaryCreditInfo.paymentMethodStatus !== PaymentMethodStatus.NeedsPaymentMethod)
  ) {
    return null;
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const progress =
    ((monetaryCreditInfo.creditGranted - monetaryCreditInfo.creditBalance) /
      monetaryCreditInfo.creditGranted) *
    100;

  return (
    <>
      <Button
        progress={progress}
        onClick={handleClick}
        hasCreditCard={
          monetaryCreditInfo.paymentMethodStatus === PaymentMethodStatus.HasPaymentMethod
        }
      >
        <Typography zIndex={1} variant="h5">
          {formatPrice(monetaryCreditInfo.creditBalance)}
        </Typography>
        <Typography zIndex={1} color="secondary">
          remaining
        </Typography>
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <CreditCallout />
      </Popover>
    </>
  );
}

export default Credits;
