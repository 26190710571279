/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOnboardingStateMutationVariables = Types.Exact<{
  onboardingState: Types.Scalars['GenericScalar'];
}>;


export type UpdateOnboardingStateMutation = { __typename?: 'Mutation', updateOnboardingState: { __typename?: 'UpdateOnboardingState', ok: boolean | null | undefined } | null | undefined };

export type UpdateWalkthroughStateMutationVariables = Types.Exact<{
  walkthroughState: Types.WalkthroughState;
}>;


export type UpdateWalkthroughStateMutation = { __typename?: 'Mutation', updateWalkthroughState: { __typename?: 'UpdateWalkthroughState', ok: boolean | null | undefined } | null | undefined };


export const UpdateOnboardingStateMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateOnboardingStateMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"onboardingState"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenericScalar"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateOnboardingState"},"name":{"kind":"Name","value":"updated_onboarding_state"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"state"},"value":{"kind":"Variable","name":{"kind":"Name","value":"onboardingState"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>;
export const UpdateWalkthroughStateMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateWalkthroughStateMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"walkthroughState"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WalkthroughState"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"updateWalkthroughState"},"name":{"kind":"Name","value":"updated_walkthrough_state"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"state"},"value":{"kind":"Variable","name":{"kind":"Name","value":"walkthroughState"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}}]}}]}}]} as unknown as DocumentNode<UpdateWalkthroughStateMutation, UpdateWalkthroughStateMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdateOnboardingStateMutation: 'UpdateOnboardingStateMutation',
    UpdateWalkthroughStateMutation: 'UpdateWalkthroughStateMutation'
  }
}

export type UpdateOnboardingStateMutationMutationFn = Apollo.MutationFunction<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>;

/**
 * __useUpdateOnboardingStateMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStateMutation, { data, loading, error }] = useUpdateOnboardingStateMutation({
 *   variables: {
 *      onboardingState: // value for 'onboardingState'
 *   },
 * });
 */
export function useUpdateOnboardingStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>(UpdateOnboardingStateMutationDocument, options);
      }
export type UpdateOnboardingStateMutationHookResult = ReturnType<typeof useUpdateOnboardingStateMutation>;
export type UpdateOnboardingStateMutationMutationResult = Apollo.MutationResult<UpdateOnboardingStateMutation>;
export type UpdateOnboardingStateMutationMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>;
export type UpdateWalkthroughStateMutationMutationFn = Apollo.MutationFunction<UpdateWalkthroughStateMutation, UpdateWalkthroughStateMutationVariables>;

/**
 * __useUpdateWalkthroughStateMutation__
 *
 * To run a mutation, you first call `useUpdateWalkthroughStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalkthroughStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalkthroughStateMutation, { data, loading, error }] = useUpdateWalkthroughStateMutation({
 *   variables: {
 *      walkthroughState: // value for 'walkthroughState'
 *   },
 * });
 */
export function useUpdateWalkthroughStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWalkthroughStateMutation, UpdateWalkthroughStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWalkthroughStateMutation, UpdateWalkthroughStateMutationVariables>(UpdateWalkthroughStateMutationDocument, options);
      }
export type UpdateWalkthroughStateMutationHookResult = ReturnType<typeof useUpdateWalkthroughStateMutation>;
export type UpdateWalkthroughStateMutationMutationResult = Apollo.MutationResult<UpdateWalkthroughStateMutation>;
export type UpdateWalkthroughStateMutationMutationOptions = Apollo.BaseMutationOptions<UpdateWalkthroughStateMutation, UpdateWalkthroughStateMutationVariables>;