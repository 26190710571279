import { Helmet } from 'react-helmet';

import { MetaProps } from './types';

function Meta({
  description = '',
  meta = [],
  title = 'Baseten',
  children,
  responsive = false,
  favicon = '/static/favicon.ico',
}: MetaProps) {
  const viewportContent = responsive
    ? 'width=device-width, initial-scale=1.0'
    : 'width=1200, initial-scale=1, maximum-scale=1';
  return (
    <>
      <Helmet
        title={title}
        link={[
          {
            rel: 'icon',
            href: favicon,
            type: 'image/x-icon',
            id: 'favicon',
          },
        ]}
        meta={[
          {
            name: 'viewport', // Add the viewport meta tag dynamically
            content: viewportContent,
          },
          {
            name: 'description',
            content: description,
          },
          {
            property: 'og:title',
            content: title,
          },

          {
            property: 'og:description',
            content: description,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: description,
          },
        ].concat(meta)}
      />
      {children}
    </>
  );
}

export default Meta;
