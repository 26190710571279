import { batch } from 'react-redux';

import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import last from 'lodash/last';

import { LAST_SEEN_TS_KEY } from '@/pages/Application/Toolbar/LogTabLabel/configs';
import { ApplicationLog } from '@/pages/Application/Toolbar/Logs/types';
import { TabsEnum } from '@/pages/Application/Toolbar/types';
import { Shortcuts } from '@/sections/Application/Logs/types';
import { shortcuts } from '@/sections/Application/Logs/utils';
import { ConsoleRequestStatus, SetCellResultParams } from '@/sections/Console/types';
import { getEmptyCell } from '@/sections/Console/utils';
import { pickersShortcutsItemGetValueParams } from '@/utils/date';

import { startAppListening } from '../../../middleware/listener';
import { WorkflowIdentifier } from '../Workflows/types';
import { fetchLogs, restartConsoleSession } from './async';
import { LogFilters, LogPanel, TestingOutput, Toolbar as ToolbarState } from './types';

const DEFAULT_INPUT_VALUE = '{}';

const initialState: ToolbarState = {
  logs: {
    lastSeenTs: localStorage.getItem(LAST_SEEN_TS_KEY) || '0',
    serverLogs: [],
    uiLogs: [],
    filters: {
      regex: '',
      shortcut: shortcuts[Shortcuts.DEFAULT],
      dateRange: shortcuts[Shortcuts.DEFAULT].getValue(pickersShortcutsItemGetValueParams),
    },
    isLoading: false,
    panels: {},
  },
  testing: {
    input: DEFAULT_INPUT_VALUE,
    output: null,
    error: '',
    isLoading: false,
  },
  console: {
    data: {},
    isLoading: true,
  },
  config: {
    activeTabId: null,
  },
};

const Toolbar = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    // Toolbar
    setSelectedTab(state: ToolbarState, action: PayloadAction<TabsEnum>) {
      state.config.activeTabId = action.payload;
    },
    resetToolbar(state: ToolbarState, action: PayloadAction<TabsEnum>) {
      return initialState;
    },

    // Logs
    setIsLogsLoading(state: ToolbarState, action: PayloadAction<boolean>) {
      state.logs.isLoading = action.payload;
    },
    setLogFilters(
      state: ToolbarState,
      action: PayloadAction<{
        filters: Partial<LogFilters>;
        workflowIdentifier: WorkflowIdentifier;
      }>,
    ) {
      state.logs.filters = { ...state.logs.filters, ...action.payload.filters };
    },
    addEventLog(state: ToolbarState, action: PayloadAction<ApplicationLog>) {
      state.logs.uiLogs.push(action.payload);
    },
    addEventLogs(state: ToolbarState, action: PayloadAction<Array<ApplicationLog>>) {
      state.logs.uiLogs = state.logs.uiLogs.concat(action.payload);
    },
    setOpened(state: ToolbarState, action: PayloadAction<string>) {
      const uid = action.payload;
      state.logs.panels[uid] = { ...state.logs.panels[uid], isOpen: true };
    },
    setClosed(state: ToolbarState, action: PayloadAction<string>) {
      const uid = action.payload;
      state.logs.panels[uid] = { ...state.logs.panels[uid], isOpen: false };
    },
    setHeight(state: ToolbarState, action: PayloadAction<Record<string, Partial<LogPanel>>>) {
      const [uid, { height }] = Object.entries(action.payload)[0];
      state.logs.panels[uid] = { ...state.logs.panels[uid], height };
    },
    setLastSeen(state: ToolbarState) {
      const lastSeenTs = `${new Date().getTime() * 1000000}`;
      state.logs.lastSeenTs = lastSeenTs;
      localStorage.setItem(LAST_SEEN_TS_KEY, lastSeenTs);
    },
    // Testing
    setInput(state: ToolbarState, action: PayloadAction<string>) {
      state.testing.input = action.payload;
    },
    setOutput(state: ToolbarState, action: PayloadAction<TestingOutput>) {
      state.testing.output = action.payload;
    },
    setError(state: ToolbarState, action: PayloadAction<string>) {
      state.testing.error = action.payload;
    },
    setIsTestingLoading(state: ToolbarState, action: PayloadAction<boolean>) {
      state.testing.isLoading = action.payload;
    },
    setSelectedBlockId(state: ToolbarState, action: PayloadAction<string>) {
      state.testing.selectedBlockId = action.payload;
    },
    setSelectedWorkletId(state: ToolbarState, action: PayloadAction<string>) {
      state.testing.selectedWorkletId = action.payload;
    },
    // Console
    setCellResult(state, action: PayloadAction<SetCellResultParams>) {
      const { applicationId, sessionId, cellId, result, input } = action.payload;
      const console = state.console.data[applicationId];
      const { cells = [] } = console;

      const currentCell = cells.find((cell) => cell.id === cellId);

      if (sessionId !== console.sessionId) {
        // TODO (Suren): handle this case
      }

      if (!currentCell) {
        console.cells = [...cells, { ...result, id: cellId, input }];
      } else {
        currentCell.result = result;
        currentCell.input = input;
      }

      if (
        result.status === ConsoleRequestStatus.DONE &&
        !result.error &&
        last(cells).result !== null
      ) {
        console.cells = [...cells, getEmptyCell()];
      }
    },
  },
  extraReducers: (builder) => {
    // Logs
    builder.addCase(fetchLogs.fulfilled, (state, { payload }) => {
      if (!isEqual(payload, state.logs.serverLogs)) {
        state.logs.serverLogs = payload.map((log) => ({ ...log, uid: String(log.ts) }));
      }
      state.logs.isLoading = false;
    });
    // Console
    builder.addCase(restartConsoleSession.pending, (state) => {
      state.console.isLoading = true;
    });
    builder.addCase(restartConsoleSession.fulfilled, (state, action) => {
      state.console.data[action.payload.applicationId] = {
        sessionId: action.payload.sessionId,
        cells: [getEmptyCell()],
      };
      state.console.isLoading = false;
    });
  },
});

export const {
  resetToolbar,
  setSelectedTab,
  setIsLogsLoading,
  setLogFilters,
  setLastSeen,
  setHeight,
  setOpened,
  setClosed,
  addEventLog,
  addEventLogs,
  setInput,
  setOutput,
  setError,
  setIsTestingLoading,
  setSelectedBlockId,
  setSelectedWorkletId,
  setCellResult,
} = Toolbar.actions;

startAppListening({
  matcher: setLogFilters.match,
  effect(action: ReturnType<typeof setLogFilters>, { getState, dispatch }) {
    batch(() => {
      dispatch(setIsLogsLoading(true));
      dispatch(fetchLogs(action.payload.workflowIdentifier));
    });
  },
});

startAppListening({
  matcher: isAnyOf(addEventLog, addEventLogs, fetchLogs.fulfilled),
  effect(action, { getState, dispatch }) {
    const {
      entities: {
        toolbar: {
          config: { activeTabId },
        },
      },
    } = getState();
    if (activeTabId === TabsEnum.logs) {
      dispatch(setLastSeen());
    }
  },
});

export { fetchLogs, DEFAULT_INPUT_VALUE };

export default Toolbar.reducer;
