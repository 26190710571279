import { MouseEvent, useState } from 'react';

import { Button, Stack } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import AppLink from '@/components/AppLink';
import AlertTriangleIcon from '@/components/Icons/AlertTriangleIcon';
import { PaymentMethodStatus } from '@/graphql';
import useAddPaymentMethod from '@/hooks/useAddPaymentMethod';
import { Tabs as SettingsTabs } from '@/pages/Settings/Tabs/types';
import { BasetenPageEnum } from '@/routes';
import { getBorder } from '@/theme/utils';
import moment from 'moment';

import { usePaymentOverdueQuery } from './__generated__/queries.generated';

function PaymentOverdue() {
  const addPaymentMethod = useAddPaymentMethod();
  const { loading, data } = usePaymentOverdueQuery({
    fetchPolicy: 'cache-first',
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (loading || !data) {
    return null;
  }

  const { paymentMethodStatus, pendingDeactivationDate } = data.organization;

  if (
    !ENV_CONFIG.SHOW_BILLING_UI ||
    (paymentMethodStatus !== PaymentMethodStatus.PaymentMethodFailed && !pendingDeactivationDate)
  ) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleClick}
        color="error"
        variant="contained"
        startIcon={<AlertTriangleIcon color="inherit" />}
      >
        Payment overdue
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack
          sx={{
            width: 320,
            borderRadius: 1,
          }}
        >
          <Stack p={2} gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline">
              <Typography variant="h5" color="error.main" component="div">
                Your payment is overdue
              </Typography>
              <AppLink page={BasetenPageEnum.Settings} params={{ tabId: SettingsTabs.Billing }}>
                Manage billing
              </AppLink>
            </Stack>
            <Typography variant="body1">
              {pendingDeactivationDate ? (
                <>
                  <Typography variant="h5" component="span">
                    The last attempt at charging your payment method failed.
                  </Typography>{' '}
                  If your invoice is not paid
                  {moment(pendingDeactivationDate).isBefore()
                    ? ' immediately, '
                    : ` by ${moment(pendingDeactivationDate).calendar()}, `}
                  your models will be deactivated. Please update your payment method below.
                </>
              ) : (
                <>
                  <Typography variant="h5" component="span">
                    All your models have been deactivated.
                  </Typography>{' '}
                  To resume deployments, please update your payment method below.
                </>
              )}
            </Typography>
          </Stack>
          <Stack p={2} sx={{ borderTop: (theme) => getBorder(theme.palette.border.default) }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addPaymentMethod}
              sx={{ width: '100%' }}
            >
              Update payment method
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}

export default PaymentOverdue;
