const nameRegex = /^[a-zA-Z-]+$/;
const MAX_NAME_LENGTH = 50;

function validate(firstName: string, lastName: string) {
  if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
    throw new Error('First name and last name should only contain letters and hyphens.');
  }

  if (firstName.length > MAX_NAME_LENGTH || lastName.length > MAX_NAME_LENGTH) {
    throw new Error('First name and last name should be less than 50 characters.');
  }
}

export default validate;
