import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/API';

import { AsyncThunkConfig } from '../../../types';
import { ImportSMModelParams } from './types';

export const fetchSageMakerModels = createAsyncThunk<
  Array<ImportSMModelParams> & { error: any },
  string,
  AsyncThunkConfig
>('models-entities/fetchSageMakerModels', async (connectionName: string) => {
  const response = await API.postWithoutCallback('/sagemaker_import/list_from_connection', {
    data_connection_name: connectionName,
  });
  return response.data.endpoint_models;
});

export const importSageMakerModel = createAsyncThunk<
  { success: boolean; modelId: string },
  ImportSMModelParams,
  AsyncThunkConfig
>('models-entities/importSageMakerModels', async (model: ImportSMModelParams) => {
  const response = await API.postWithoutCallback('/sagemaker_import/import_model', model);
  return { ...response.data, modelId: response.data?.model_id };
});
