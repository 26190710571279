import ApplicationRoutes from '@/pages/Application/routes';
import CreatorRoutes from '@/pages/Creator/routes';
import AuthedOperatorRoutes from '@/pages/Operator/routes';

import { getMatchFromPath } from './getMatchFromPath';
import { BasetenPageEnum, BasetenPageProps } from './types';

const operatorViewRoute: BasetenPageProps = {
  page: BasetenPageEnum.ViewOperator,
  path: '/apps/:workflowId/operator_views/:viewId',
};

const Routes: Array<BasetenPageProps> = [
  operatorViewRoute,
  ...AuthedOperatorRoutes,
  ...CreatorRoutes,
  ...ApplicationRoutes,
];

export function findMatchingPageFromPath(path: string): BasetenPageEnum | null {
  return getMatchFromPath(path)?.page;
}

export * from './hooks';
export * from './pageSwitcher';
export * from './renderRoutes';
export * from './types';
export * from './utils';
export { Routes, operatorViewRoute, getMatchFromPath };
