import { Button } from '@mui/material';

import { useSearchParams } from '@/routes';
import { addQueryParam } from '@/utils/url';

import { socialNetworks } from './constants';
import { SocialButtonProps } from './types';

function SocialButton({ type, isRegistering }: SocialButtonProps) {
  const { link: baseLink, icon: SocialIcon, name } = socialNetworks[type];

  const [searchParams] = useSearchParams();
  const nextPage = searchParams.get('next');
  const link = nextPage ? addQueryParam(baseLink, 'next', nextPage) : baseLink;

  return (
    <Button
      href={link}
      variant="outlined"
      color="secondary"
      size="medium"
      startIcon={<SocialIcon />}
      data-cy={`social-button-${type}`}
    >
      {isRegistering ? 'Sign up' : 'Sign in'} with {name}
    </Button>
  );
}

export default SocialButton;
