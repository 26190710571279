import API from '@/API';
import { getApiHeaders } from '@/store/slices/entities/Views/utils';
import { WorkflowIdentifier } from '@/store/slices/entities/Workflows/types';
import { pollForResultWithExponentialBackoff } from '@/utils/network/poller';

import {
  WORKLET_RUN_POLLING_PERIOD_MULTIPLIER,
  WORKLET_RUN_POLLING_START_PERIOD_MS,
  WORKLET_RUN_POLLING_TIMEOUT_MS,
} from './constants';

const callWorklet =
  (workflowIdentifier: WorkflowIdentifier, asOperator: boolean) =>
  async (workletId: string, params: Record<string, any>) => {
    const workletBaseUrl = `/applications/${
      workflowIdentifier.workflowId
    }/${workflowIdentifier.releaseEnv.toLowerCase()}/worklets`;
    const apiHeaders = getApiHeaders(workflowIdentifier.workflowId, asOperator);

    const { data } = await API.postWithoutCallback(
      `${workletBaseUrl}/${workletId}/invoke`,
      { worklet_input: params },
      apiHeaders,
    );

    const workletRunId = data.scheduled_worklet_run_id;
    if (!workletRunId) {
      if (data.exception || !data.success) {
        throw new Error(data.exception?.message || 'An exception occured while running worklet.');
      }
      const invokeOutput = data.worklet_output;
      if (invokeOutput.error) {
        throw new Error(invokeOutput.error);
      }
      return invokeOutput;
    }

    return pollForResultWithExponentialBackoff(
      async () => {
        const statusUrl = `${workletBaseUrl}/${workletId}/${workletRunId}`;
        const { data: workletRunData } = await API.getWithoutCallback(statusUrl, apiHeaders);
        if (workletRunData.status === 'SCHEDULED' || workletRunData.status === 'SUSPENDED') {
          return {
            finished: false,
          };
        }
        const workletRunOutput = workletRunData.worklet_output;
        if (workletRunOutput.error) {
          throw new Error(workletRunOutput.error);
        }
        return {
          finished: true,
          result: workletRunOutput,
        };
      },
      WORKLET_RUN_POLLING_TIMEOUT_MS,
      WORKLET_RUN_POLLING_START_PERIOD_MS,
      WORKLET_RUN_POLLING_PERIOD_MULTIPLIER,
    );
  };

const runQuery =
  (workflowIdentifier: WorkflowIdentifier, asOperator: boolean) =>
  async (queryId: string, params: Record<string, any>, isDataEnabled: boolean) => {
    const queryUrl = `/queries/${queryId}/${workflowIdentifier.releaseEnv.toLowerCase()}/run`;
    const apiHeaders = getApiHeaders(workflowIdentifier.workflowId, asOperator);
    const payload = {
      query_params: params,
      workflow_id: isDataEnabled ? null : workflowIdentifier.workflowId,
    };
    const { data: queryData } = await API.postWithoutCallback(queryUrl, payload, apiHeaders);
    if (queryData.error) {
      throw new Error(queryData.error);
    }
    return queryData.results;
  };

export { callWorklet, runQuery };
