import { Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CircleFilledIcon from '@/components/Icons/CircleFilledIcon';
import CircleIcon from '@/components/Icons/CircleIcon';

import { MainOptionsEnum } from '../types';
import { MainOptionsProps } from './types';

function MainOptions({ onChange, selectedOption }: MainOptionsProps) {
  return (
    <FormControl>
      <RadioGroup sx={{ gap: 2 }} onChange={onChange} value={selectedOption}>
        <FormControlLabel
          value={MainOptionsEnum.Personal}
          control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
          sx={{
            alignItems: 'flex-start',
            gap: 1,
          }}
          label={
            <Stack gap={1}>
              <Typography variant="h5">Personal</Typography>
              <Typography color="text.muted" variant="body2">
                Personal keys have the same permissions as your account and any API calls made using
                the key will be associated with your identity.
              </Typography>
            </Stack>
          }
        />
        <FormControlLabel
          value={MainOptionsEnum.Workspace}
          control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
          sx={{
            alignItems: 'flex-start',
            gap: 1,
          }}
          label={
            <Stack gap={1}>
              <Typography variant="h5">Workspace</Typography>
              <Typography color="text.muted" variant="body2">
                Workspace keys are shared across the workspace and can be used in service-backed
                environments like CI/CD. You can grant these keys full access to the workspace or
                limit them to only being able to call specific models.
              </Typography>
            </Stack>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

export default MainOptions;
