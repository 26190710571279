import { createAsyncThunk } from '@reduxjs/toolkit';

import { PyNodeRequirementStatus } from '@/graphql';
import { ReleaseEnv } from '@/types/releaseEnv';
import { ApolloClient } from '@apollo/client';

import { AsyncThunkConfig } from '../../../types';
import { WorkflowIdentifier } from '../../entities/Workflows/types';
import { enqueueNotification } from '../Notifier';
import {
  CreatePynodeRequirementDocument,
  CreatePynodeRequirementMutation,
} from './__generated__/mutations.generated';
import { LatestPyEnvRequirementsFromWorkflowDocument } from './__generated__/queries.generated';
import { pyEnvViewAppScopedSelector } from './selectors';
import { BackendLatestPyEnvRequirements } from './types';

async function getPyEnvFromWorkflow(
  apolloClient: ApolloClient<object>,
  workflowIdentifier: WorkflowIdentifier,
): Promise<BackendLatestPyEnvRequirements> {
  const { data } = await apolloClient.query({
    query: LatestPyEnvRequirementsFromWorkflowDocument,
    variables: {
      deploymentEnv: workflowIdentifier.releaseEnv,
      workflowId: workflowIdentifier.workflowId,
    },
  });

  return data.workflow.latestPyEnvRequirements;
}

export const fetchPyEnvRequirements = createAsyncThunk<
  BackendLatestPyEnvRequirements,
  WorkflowIdentifier,
  AsyncThunkConfig
>(
  'py-env-view/fetchPyEnvRequirements',
  async (workflowIdentifier, { extra: { apolloClient }, getState, dispatch }) => {
    const wasInstalling =
      pyEnvViewAppScopedSelector(getState(), workflowIdentifier).latestPyEnvRequirements.status ===
      PyNodeRequirementStatus.Processing;
    const latestPyEnvRequirements = await getPyEnvFromWorkflow(apolloClient, workflowIdentifier);

    if (latestPyEnvRequirements) {
      const isInstalling = latestPyEnvRequirements.status === PyNodeRequirementStatus.Processing;

      if (wasInstalling && !isInstalling) {
        const succeeded = latestPyEnvRequirements.status === PyNodeRequirementStatus.Succeeded;
        const error = latestPyEnvRequirements?.errorMessage || '';

        dispatch(
          enqueueNotification({
            message: succeeded
              ? 'Successfully installed packages. They should be ready to import in a few seconds.'
              : `Failed to install packages.\n${error}`,
            variant: succeeded ? 'success' : 'error',
            autoHideDuration: 15000,
          }),
        );
      }
    }
    return latestPyEnvRequirements;
  },
);

export const installPyEnvRequirements = createAsyncThunk<
  CreatePynodeRequirementMutation,
  { requirementsTxt: string; systemRequirements: string; workflowIdentifier: WorkflowIdentifier },
  AsyncThunkConfig
>(
  'py-env-view/installPyEnvRequirements',
  async (
    { requirementsTxt, systemRequirements, workflowIdentifier },
    { extra: { apolloClient } },
  ) => {
    if (workflowIdentifier.releaseEnv !== ReleaseEnv.Draft) {
      throw new Error(
        `Installing new requirements not supported in ${workflowIdentifier.releaseEnv} environment`,
      );
    }
    const { data } = await apolloClient.mutate({
      mutation: CreatePynodeRequirementDocument,
      variables: {
        requirementsTxt,
        systemRequirements,
        workflowId: workflowIdentifier.workflowId,
      },
    });
    return data;
  },
);
