import { Badge, Box, Stack } from '@mui/material';

import { styled } from '@/theme/styled';
import { getBorder } from '@/theme/utils';

const ChangelogTitle = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 240,
}));

const HeaderBadge = styled(Badge)(({ theme }) => ({
  span: {
    width: 14,
    height: 14,
    minWidth: 14,
    fontSize: 11,
  },
}));

HeaderBadge.defaultProps = {
  color: 'primary',
};

const InlineBadge = styled(Badge)(({ theme }) => ({
  span: {
    width: 14,
    height: 14,
    minWidth: 14,
    fontSize: 11,
  },
}));

InlineBadge.defaultProps = {
  color: 'primary',
};

const AlertContainer = styled(Stack)(({ theme }) => ({
  width: 280,
  padding: theme.spacing(1),
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: getBorder(theme.palette.border.default),
  boxShadow: theme.shadows[3],
}));

export { AlertContainer, ChangelogTitle, HeaderBadge, InlineBadge };
