import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Badge } from '@/components/styled/Badge';

import { StepTitleProps } from './types';

function StepTitle({ step, text, isHighlighted }: StepTitleProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} mb={1}>
      {step ? (
        <Badge color={isHighlighted ? 'success' : 'info'}>{step}</Badge>
      ) : (
        <Box sx={{ mr: 2 }} />
      )}
      <Typography color={isHighlighted ? 'text.success' : 'text.default'} variant="h5">
        {text}
      </Typography>
    </Stack>
  );
}

export default StepTitle;
