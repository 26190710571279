import { schema } from 'normalizr';

const nodeSchema = new schema.Entity('nodes');
const workletSchema = new schema.Entity('worklets', { nodes: [nodeSchema] });
const externalConnectionSchema = new schema.Entity('externalConnections');
const viewSchema = new schema.Entity('views');
const pipeSchema = new schema.Entity('pipes');
const latestPyEnvRequirementsSchema = new schema.Entity('latestPyEnvRequirements');

export const workflowSchema = new schema.Entity('workflows', {
  worklets: [workletSchema],
  externalConnections: [externalConnectionSchema],
  views: [viewSchema],
  pipes: [pipeSchema],
  latestPyEnvRequirements: latestPyEnvRequirementsSchema,
});

export const duplicateApplicationResponseSchema = new schema.Entity(
  'duplicationApplicationResponse',
  {
    workflow: workflowSchema,
  },
);
