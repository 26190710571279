import { Stack } from '@mui/material';

import { Logo } from './styled';

function Header() {
  return (
    <Stack direction="column" justifyContent="start" alignItems="start" gap={8}>
      <Logo src="/static/logos/Baseten_Logo_Primary.svg" />
    </Stack>
  );
}

export default Header;
