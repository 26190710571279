/**
 * Helper function to enable the following syntax:
 * ```
 * [
 *   itemA,
 *   itemB,
 *   ...arrayInsertIf(someCondition, [conditionalItemC, conditionalItemD])
 * ]
 */
export function arrayInsertIf<T>(condition: unknown, items: T[]): T[] {
  return condition ? items : [];
}

/**
 * Helper function to enable the following syntax:
 * ```
 * {
 *   a: 1,
 *   b: 2,
 *   ...objectInsertIf(someCondition, { conditionalItemC: 3, conditionalItemD: 4 })
 * }
 */
export function objectInsertIf<T>(condition: unknown, item: T): T | null {
  return condition ? item : null;
}
