import { Stack } from '@mui/material';

import { useLoggedIn } from '@/hooks';

import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';

function Right() {
  const isLoggedIn = useLoggedIn();

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
      {isLoggedIn ? <LoggedIn /> : <LoggedOut />}
    </Stack>
  );
}

export default Right;
