type Coords = {
  x: number;
  y: number;
};

type Dimensions = {
  w: number;
  h: number;
};

type Layout = {
  id: string;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  centroid: Coords;
};

type LayoutMap = Record<string, Layout>;

type LocationMatrix = Array<string[][]>;

enum Direction {
  NW = 'nw',
  N = 'n',
  NE = 'ne',
  E = 'e',
  SE = 'se',
  S = 's',
  SW = 'sw',
  W = 'w',
}

enum DirectionLock {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

type OverflowFunction = (id: string, height: number) => void;

enum CanvasSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type RootProps = {
  showGrid: boolean;
  hasSelection?: boolean;
  gridWidth: number;
  gridHeight?: number;
  interacting: boolean;
  isCanvasFrameHidden: boolean;
};

type WrapperProps = {
  isCanvasFrameHidden: boolean;
};

export {
  Coords,
  Dimensions,
  Layout,
  LayoutMap,
  LocationMatrix,
  Direction,
  DirectionLock,
  OverflowFunction,
  CanvasSize,
  RootProps,
  WrapperProps,
};
