/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentOverdueQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaymentOverdueQuery = { __typename?: 'QueryPrediction', organization: { __typename?: 'OrganizationType', name: string, paymentMethodStatus: Types.PaymentMethodStatus | null | undefined, pendingDeactivationDate: any | null | undefined } | null | undefined };


export const PaymentOverdueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PaymentOverdue"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentMethodStatus"},"name":{"kind":"Name","value":"payment_method_status"}},{"kind":"Field","alias":{"kind":"Name","value":"pendingDeactivationDate"},"name":{"kind":"Name","value":"pending_deactivation_date"}}]}}]}}]} as unknown as DocumentNode<PaymentOverdueQuery, PaymentOverdueQueryVariables>;
export const namedOperations = {
  Query: {
    PaymentOverdue: 'PaymentOverdue'
  }
}

/**
 * __usePaymentOverdueQuery__
 *
 * To run a query within a React component, call `usePaymentOverdueQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentOverdueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentOverdueQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentOverdueQuery(baseOptions?: Apollo.QueryHookOptions<PaymentOverdueQuery, PaymentOverdueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentOverdueQuery, PaymentOverdueQueryVariables>(PaymentOverdueDocument, options);
      }
export function usePaymentOverdueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentOverdueQuery, PaymentOverdueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentOverdueQuery, PaymentOverdueQueryVariables>(PaymentOverdueDocument, options);
        }
export type PaymentOverdueQueryHookResult = ReturnType<typeof usePaymentOverdueQuery>;
export type PaymentOverdueLazyQueryHookResult = ReturnType<typeof usePaymentOverdueLazyQuery>;
export type PaymentOverdueQueryResult = Apollo.QueryResult<PaymentOverdueQuery, PaymentOverdueQueryVariables>;
export function refetchPaymentOverdueQuery(variables?: PaymentOverdueQueryVariables) {
      return { query: PaymentOverdueDocument, variables: variables }
    }