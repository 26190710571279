import moment from 'moment';

import { PollingResponse } from './types';

/**
 * TaskPoller is a function that can be queried to track the status
 * of a running operation.
 *
 * A polling function can implement a simple `finished` boolean interface
 * that informs whether or not the function needs to continue to poll.
 * Protocol is for the runner to keep calling TaskPoller until a finished status
 * is returned.
 */
export type TaskPoller<T> = () => Promise<PollingResponse<T>>;

const { setInterval, clearInterval } = window;

/**
 * TimerBasedPoller provides a registry for tracking tasks to be polled
 */
class TimerBasedPoller {
  register = (tracker: TaskPoller<any>, periodMillis: number, timeoutMillis: number) => {
    const startTime = moment();
    /* eslint-disable  @typescript-eslint/no-use-before-define */
    const timerId = setInterval(periodically, periodMillis);
    async function periodically() {
      const elapsedMillis = moment().diff(startTime);
      const timedOut = elapsedMillis + periodMillis > timeoutMillis;
      if (timedOut) {
        clearInterval(timerId);
      }
      const { finished } = await tracker();
      if (finished) {
        clearInterval(timerId);
      }
    }
  };
}

export function* makeExponentialIterator(
  start: number,
  multiplier: number,
  maxIntervalMs?: number,
) {
  let current = start;
  while (true) {
    if (maxIntervalMs && current > maxIntervalMs) {
      yield maxIntervalMs;
    } else {
      yield current;
    }

    current *= multiplier;
  }
}

export function* makeFixedIntervalIterator(interval: number) {
  while (true) {
    yield interval;
  }
}

/**
 * Polls for result on a tracker.
 * Tries with exponentially increasing interval durations.
 */
export function pollForResultWithExponentialBackoff<R>(
  tracker: TaskPoller<R>,
  timeoutMillis: number,
  startIntervalMs: number,
  intervalMultiplier: number,
  maxIntervalMs = Infinity,
): Promise<R> {
  return pollForResultWithGeneratedIntervals(
    tracker,
    makeExponentialIterator(startIntervalMs, intervalMultiplier, maxIntervalMs),
    timeoutMillis,
  );
}

export function pollForResultWithGeneratedIntervals<R>(
  tracker: TaskPoller<R>,
  intervalMsGen: Iterator<number>,
  timeoutMillis: number,
): Promise<R> {
  return new Promise((resolve, reject) => {
    const startTime = moment();
    const iterResult = intervalMsGen.next();
    if (iterResult.done) {
      reject(new Error("Request didn't finish in allocated attempts"));
      return;
    }
    const firstAttemptIntervalMs = iterResult.value;
    const poll = async () => {
      try {
        const { finished, result } = await tracker();
        if (finished) {
          resolve(result);
        } else {
          const elapsedMillis = moment().diff(startTime);
          const timedOut = elapsedMillis > timeoutMillis;
          if (timedOut) {
            reject(
              new Error(`Request timed out after ${moment.duration(timeoutMillis).humanize()}`),
            );
            return;
          }
          const nextAttemptIntervalResult = intervalMsGen.next();
          if (nextAttemptIntervalResult.done) {
            reject(new Error("Request didn't finish in allocated attempts"));
          } else {
            setTimeout(poll, nextAttemptIntervalResult.value);
          }
        }
      } catch (e) {
        reject(e);
      }
    };
    setTimeout(poll, firstAttemptIntervalMs);
  });
}

const AppTimerBasedPollerSingleton = new TimerBasedPoller();
export default AppTimerBasedPollerSingleton;
