import { Stack } from '@mui/material';

import capitalize from 'lodash/capitalize';

import Meta from '@/components/Meta';
import { PageContent } from '@/components/styled/page';
import { useIsApplicationsAndDataEnabled } from '@/hooks/useIsApplicationsAndDataEnabled';
import Footer from '@/sections/Footer';
import GlobalHeader from '@/sections/GlobalHeader';
import asyncComponentLoader from '@/utils/react/loader';

import { useWorkspaceSection } from './hooks';
import Navigation from './sections/Navigation';
import { WorkspaceSections } from './types';

const WorkspaceSectionComponents = {
  [WorkspaceSections.Applications]: asyncComponentLoader(() => import('./sections/Applications')),
  [WorkspaceSections.Data]: asyncComponentLoader(() => import('./sections/Data')),
  [WorkspaceSections.Models]: asyncComponentLoader(() => import('./sections/Models')),
};

function Workspace() {
  const section = useWorkspaceSection();
  const isApplicationsAndDataEnabled = useIsApplicationsAndDataEnabled();

  const SectionComponent = WorkspaceSectionComponents[section];

  return (
    <Meta title={`${capitalize(section)} | Baseten`}>
      <GlobalHeader />
      {isApplicationsAndDataEnabled && <Navigation section={section} />}
      <PageContent>
        <Stack sx={{ flex: 1 }}>
          <SectionComponent />
        </Stack>
        <Footer />
      </PageContent>
    </Meta>
  );
}

export default Workspace;
