import moment from 'moment';

import { Cron, WorkletEntity } from '../../slices/entities/Worklets/types';

export type Nodes = Record<string, Record<string, string>>;
export interface NodeGraph {
  entryPoint?: string;
  nodes: Nodes;
}

export enum ScheduleType {
  EveryFiveMinutes = 'every 5 minutes',
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
}

export function cronScheduleType(c: Cron): ScheduleType {
  const allStar = (ss: Array<string>) => ss.every((s: string) => s === '*');
  if (allStar([c.hour, c.dayOfWeek, c.dayOfMonth, c.monthOfYear]) && c.minute === '*/5') {
    return ScheduleType.EveryFiveMinutes;
  }
  if (allStar([c.hour, c.dayOfWeek, c.dayOfMonth, c.monthOfYear])) {
    return ScheduleType.Hourly;
  }
  if (allStar([c.dayOfWeek, c.dayOfMonth, c.monthOfYear])) {
    return ScheduleType.Daily;
  }
  if (allStar([c.dayOfMonth, c.monthOfYear])) {
    return ScheduleType.Weekly;
  }
  throw new Error(`Unsupported cron schedule ${c}`);
}

export const WorkletInit: WorkletEntity = {
  id: '',
  name: '',
  executeAsyncByDefault: false,
  allowedDomains: [],
  created: moment(),
  modified: moment(),
  nodeGraph: {
    entryPoint: null,
    nodes: {},
  },
  nodes: [],
  signature: {
    args: [],
    returnAnnotation: '',
  },
};

export function workletNodeChildren(
  worklet: WorkletEntity,
  nodeId: string,
): Record<string, string> {
  return worklet.nodeGraph.nodes[nodeId];
}
