import { ChipProps } from '@mui/material';

import { ChainDeploymentStatus, DeploymentStatus } from '@/graphql';

type StatusColor = ChipProps['color'];

export const STATUS_COLORS_UNKNOWN = 'error';

export const STATUS_COLORS: Record<DeploymentStatus | ChainDeploymentStatus, StatusColor> = {
  [DeploymentStatus.BuildingModel]: 'info',
  [DeploymentStatus.BuildingModelFailed]: 'error',
  [DeploymentStatus.BuildingModelStopped]: 'default',
  [DeploymentStatus.DeployingModel]: 'info',
  [DeploymentStatus.ModelDeployFailed]: 'error',
  [DeploymentStatus.ModelReady]: 'success',
  [DeploymentStatus.ModelUnhealthy]: 'warning',
  [DeploymentStatus.DeactivatingModel]: 'info',
  [DeploymentStatus.DeactivatedModel]: 'default',
  [DeploymentStatus.ModelDneError]: 'error',
  [DeploymentStatus.Updating]: 'info',
  [DeploymentStatus.ScaledToZero]: 'default',
  [DeploymentStatus.ScalingFromZero]: 'info',
  [DeploymentStatus.ModelLoading]: 'info',
  [DeploymentStatus.MigratingWorkloadPlanes]: 'info',
};

export const STATUS_UNKNOWN_DESCRIPTOR = 'Unknown status';

export const STATUS_DESCRIPTORS: Record<DeploymentStatus, string> = {
  [DeploymentStatus.BuildingModel]: 'Building',
  [DeploymentStatus.BuildingModelFailed]: 'Build failed',
  [DeploymentStatus.BuildingModelStopped]: 'Build stopped',
  [DeploymentStatus.DeployingModel]: 'Deploying',
  [DeploymentStatus.ModelDeployFailed]: 'Failed',
  [DeploymentStatus.ModelReady]: 'Active',
  [DeploymentStatus.ModelUnhealthy]: 'Unhealthy',
  [DeploymentStatus.DeactivatingModel]: 'Deactivating',
  [DeploymentStatus.DeactivatedModel]: 'Inactive',
  [DeploymentStatus.ModelDneError]: 'Failed',
  [DeploymentStatus.Updating]: 'Updating',
  [DeploymentStatus.ScaledToZero]: 'Scaled to zero',
  [DeploymentStatus.ScalingFromZero]: 'Waking up',
  [DeploymentStatus.ModelLoading]: 'Loading model',
  [DeploymentStatus.MigratingWorkloadPlanes]: 'Updating',
};

export const IS_TRANSIENT_STATUS: Record<DeploymentStatus, boolean> = {
  [DeploymentStatus.BuildingModel]: true,
  [DeploymentStatus.BuildingModelFailed]: false,
  [DeploymentStatus.BuildingModelStopped]: false,
  [DeploymentStatus.DeactivatedModel]: false,
  [DeploymentStatus.DeactivatingModel]: true,
  [DeploymentStatus.DeployingModel]: true,
  [DeploymentStatus.ModelDeployFailed]: false,
  [DeploymentStatus.ModelDneError]: false,
  [DeploymentStatus.ModelReady]: false,
  [DeploymentStatus.ModelUnhealthy]: false,
  [DeploymentStatus.Updating]: true,
  [DeploymentStatus.ScaledToZero]: false,
  [DeploymentStatus.ScalingFromZero]: true,
  [DeploymentStatus.ModelLoading]: true,
  [DeploymentStatus.MigratingWorkloadPlanes]: true,
};

export const STATUS_TOOLTIP_UNKNOWN =
  "This model's status cannot be determined. Confirm Minikube is running and refresh this screen.";

export const STATUS_TOOLTIP: Record<DeploymentStatus, string> = {
  [DeploymentStatus.BuildingModel]: 'This deployment is building.',
  [DeploymentStatus.BuildingModelFailed]: 'This deployment has failed to build.',
  [DeploymentStatus.BuildingModelStopped]: 'The build for this deployment was manually stopped.',
  [DeploymentStatus.DeployingModel]: 'This deployment is deploying.',
  [DeploymentStatus.ModelDeployFailed]: 'This deployment has failed to deploy.',
  [DeploymentStatus.ModelReady]: 'This deployment is deployed and active.',
  [DeploymentStatus.ModelUnhealthy]: 'This deployment is unhealthy and should be investigated.',
  [DeploymentStatus.DeactivatingModel]: 'This deployment is deactivating.',
  [DeploymentStatus.DeactivatedModel]:
    'This deployment is inactive. Activate deployment to call model.',
  [DeploymentStatus.ModelDneError]: "The deployment's service does not exist.",
  [DeploymentStatus.Updating]: 'This deployment is updating resources.',
  [DeploymentStatus.ScaledToZero]:
    'This deployment has been scaled to zero and is not consuming resources. It will scale up automatically.',
  [DeploymentStatus.ScalingFromZero]: 'This deployment is starting up.',
  [DeploymentStatus.ModelLoading]: 'The control server is running and the model is loading.',
  [DeploymentStatus.MigratingWorkloadPlanes]: 'This version is updating resources.',
};
