import { Button, Stack, Typography } from '@mui/material';

import AppLink from '@/components/AppLink';
import { PaymentMethodStatus } from '@/graphql/__generated__/types.generated';
import useAddPaymentMethod from '@/hooks/useAddPaymentMethod';
import useMonetaryCreditInfo from '@/hooks/useMonetaryCreditInfo';
import { Tabs as SettingsTabs } from '@/pages/Settings/Tabs/types';
import { BasetenPageEnum } from '@/routes';
import { getBorder } from '@/theme/utils';
import { formatPrice } from '@/utils/currency';

import Gauge from './Gauge';

function CreditCallout() {
  const { monetaryCreditInfo, loading } = useMonetaryCreditInfo();
  const addPaymentMethod = useAddPaymentMethod();

  if (loading) return null;

  const hasNoCredit = monetaryCreditInfo.creditBalance <= 0;

  const title = hasNoCredit ? 'No credit remaining' : 'Free credit remaining';

  const description = hasNoCredit
    ? 'Add a payment method to reactivate your models and deploy new versions.'
    : `${formatPrice(
        monetaryCreditInfo.creditBalance,
      )} of free credits remaining to deploy and serve models.`;

  return (
    <Stack
      sx={{
        width: 320,
        borderRadius: 1,
      }}
    >
      <Stack gap={1} p={2}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1" color="text.muted">
          {description}{' '}
          <AppLink page={BasetenPageEnum.Settings} params={{ tabId: SettingsTabs.Billing }}>
            View usage
          </AppLink>
        </Typography>
        <Gauge
          max={monetaryCreditInfo.creditGranted}
          value={monetaryCreditInfo.creditBalance}
          hasCreditCard={
            monetaryCreditInfo.paymentMethodStatus === PaymentMethodStatus.HasPaymentMethod
          }
        />
      </Stack>
      {monetaryCreditInfo.paymentMethodStatus === PaymentMethodStatus.NeedsPaymentMethod && (
        <Stack p={2} sx={{ borderTop: (theme) => getBorder(theme.palette.border.default) }}>
          <Button variant="contained" color="primary" onClick={addPaymentMethod}>
            Add payment method
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default CreditCallout;
