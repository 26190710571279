import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Form = styled('form')({});

const Title = styled(Typography)({});

Title.defaultProps = {
  variant: 'title',
};

const Description = styled(Typography)({});

Description.defaultProps = {
  variant: 'subtitle1',
  color: 'text.muted',
};

const Container = styled(Stack)(({ theme }) => ({
  flexShrink: 0,
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: theme.spacing(4),
}));

const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: theme.spacing(2),
}));

const Body = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: theme.spacing(4),
}));

const NextButton = styled(LoadingButton)({});

NextButton.defaultProps = {
  variant: 'contained',
  type: 'submit',
  color: 'primary',
};

export { Form, Title, Description, Container, Header, Body, NextButton };
