/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DataSourceFragmentDoc, ConnectionFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DataSourcesQuery = { __typename?: 'QueryPrediction', dataSources: Array<{ __typename?: 'DataSourceType', id: string, name: string, type: string | null | undefined, created: any, modified: any, useProductionSettingsForDraft: boolean, connections: Array<{ __typename?: 'ExternalConnectionType', id: string, created: any, dataSchema: any, isStreaming: boolean | null | undefined, connectionParameters: any | null | undefined, connectionName: string, connectionType: Types.ExternalConnectionConnectionType, deploymentEnv: string | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined };

export type TestExternalConnectionQueryVariables = Types.Exact<{
  connectionParameters: Types.Scalars['JSONString'];
  connectionType: Types.Scalars['String'];
}>;


export type TestExternalConnectionQuery = { __typename?: 'QueryPrediction', connectionStatus: any | null | undefined };

export type ExternalConnectionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ExternalConnectionQuery = { __typename?: 'QueryPrediction', externalConnection: { __typename?: 'ExternalConnectionType', connectionType: Types.ExternalConnectionConnectionType, connectionParameters: any | null | undefined } | null | undefined };


export const DataSourcesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DataSources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"dataSources"},"name":{"kind":"Name","value":"data_sources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DataSourceFragment"}}]}}]}},...DataSourceFragmentDoc.definitions,...ConnectionFragmentDoc.definitions]} as unknown as DocumentNode<DataSourcesQuery, DataSourcesQueryVariables>;
export const TestExternalConnectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TestExternalConnection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionParameters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JSONString"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"connectionStatus"},"name":{"kind":"Name","value":"test_external_connection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"connection_params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionParameters"}}},{"kind":"Argument","name":{"kind":"Name","value":"connection_type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionType"}}}]}]}}]} as unknown as DocumentNode<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>;
export const ExternalConnectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExternalConnection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"externalConnection"},"name":{"kind":"Name","value":"external_connection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"connectionType"},"name":{"kind":"Name","value":"connection_type"}},{"kind":"Field","alias":{"kind":"Name","value":"connectionParameters"},"name":{"kind":"Name","value":"connection_parameters"}}]}}]}}]} as unknown as DocumentNode<ExternalConnectionQuery, ExternalConnectionQueryVariables>;
export const namedOperations = {
  Query: {
    DataSources: 'DataSources',
    TestExternalConnection: 'TestExternalConnection',
    ExternalConnection: 'ExternalConnection'
  }
}

/**
 * __useDataSourcesQuery__
 *
 * To run a query within a React component, call `useDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataSourcesQuery(baseOptions?: Apollo.QueryHookOptions<DataSourcesQuery, DataSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSourcesQuery, DataSourcesQueryVariables>(DataSourcesDocument, options);
      }
export function useDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSourcesQuery, DataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSourcesQuery, DataSourcesQueryVariables>(DataSourcesDocument, options);
        }
export type DataSourcesQueryHookResult = ReturnType<typeof useDataSourcesQuery>;
export type DataSourcesLazyQueryHookResult = ReturnType<typeof useDataSourcesLazyQuery>;
export type DataSourcesQueryResult = Apollo.QueryResult<DataSourcesQuery, DataSourcesQueryVariables>;
export function refetchDataSourcesQuery(variables?: DataSourcesQueryVariables) {
      return { query: DataSourcesDocument, variables: variables }
    }
/**
 * __useTestExternalConnectionQuery__
 *
 * To run a query within a React component, call `useTestExternalConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestExternalConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestExternalConnectionQuery({
 *   variables: {
 *      connectionParameters: // value for 'connectionParameters'
 *      connectionType: // value for 'connectionType'
 *   },
 * });
 */
export function useTestExternalConnectionQuery(baseOptions: Apollo.QueryHookOptions<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>(TestExternalConnectionDocument, options);
      }
export function useTestExternalConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>(TestExternalConnectionDocument, options);
        }
export type TestExternalConnectionQueryHookResult = ReturnType<typeof useTestExternalConnectionQuery>;
export type TestExternalConnectionLazyQueryHookResult = ReturnType<typeof useTestExternalConnectionLazyQuery>;
export type TestExternalConnectionQueryResult = Apollo.QueryResult<TestExternalConnectionQuery, TestExternalConnectionQueryVariables>;
export function refetchTestExternalConnectionQuery(variables: TestExternalConnectionQueryVariables) {
      return { query: TestExternalConnectionDocument, variables: variables }
    }
/**
 * __useExternalConnectionQuery__
 *
 * To run a query within a React component, call `useExternalConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalConnectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExternalConnectionQuery(baseOptions: Apollo.QueryHookOptions<ExternalConnectionQuery, ExternalConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalConnectionQuery, ExternalConnectionQueryVariables>(ExternalConnectionDocument, options);
      }
export function useExternalConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalConnectionQuery, ExternalConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalConnectionQuery, ExternalConnectionQueryVariables>(ExternalConnectionDocument, options);
        }
export type ExternalConnectionQueryHookResult = ReturnType<typeof useExternalConnectionQuery>;
export type ExternalConnectionLazyQueryHookResult = ReturnType<typeof useExternalConnectionLazyQuery>;
export type ExternalConnectionQueryResult = Apollo.QueryResult<ExternalConnectionQuery, ExternalConnectionQueryVariables>;
export function refetchExternalConnectionQuery(variables: ExternalConnectionQueryVariables) {
      return { query: ExternalConnectionDocument, variables: variables }
    }