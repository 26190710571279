import mapTimezonesToCountries from './mapTimezonesToCountries.json';

function getTimeZoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';
}

function getGeoData() {
  const timezone = getTimeZoneName();

  return {
    timezone,
    country: mapTimezonesToCountries[timezone as keyof typeof mapTimezonesToCountries],
  };
}

export { getTimeZoneName };
export default getGeoData;
