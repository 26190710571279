import { ResourceCategory } from '../types';

enum RequestEnv {
  Draft = 'DRAFT',
  Production = 'PRODUCTION',
}

enum DatasourceType {
  Worklet = 'worklet',
  Query = 'query',
}

type WorkletParamBinding = {
  id?: string;
  type: DatasourceType.Worklet;
  workletId: string;
  paramRef?: string;
};

type QueryParamBinding = {
  id?: string;
  type: DatasourceType.Query;
  queryId: string;
  paramRef?: string;
};

type DatasourceBinding = WorkletParamBinding | QueryParamBinding;

type DatasourceMatch = {
  type: ResourceCategory.Query | ResourceCategory.Worklet;
  name: string;
};

export { RequestEnv, DatasourceType, DatasourceBinding, DatasourceMatch };
