import { createSlice } from '@reduxjs/toolkit';

import { ModalsState } from './types';

const initialState: ModalsState = {
  modalToShow: null,
  modalProps: null,
};

const Modals = createSlice({
  name: 'modals-ui',
  initialState,
  reducers: {
    showModal(state, action) {
      state.modalToShow = action.payload.type;
      state.modalProps = action.payload.props;
    },
    hideModal(state) {
      state.modalToShow = null;
      state.modalProps = null;
    },
  },
});

export const { showModal, hideModal } = Modals.actions;

export default Modals.reducer;
